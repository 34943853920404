import React, { useEffect, useState } from 'react';
import CommonModal from '../Home/modal.js';
import { useFormik } from 'formik';
import marriagePhoto2 from '../../assets/images/MettaMateImages/MIG1.avif';
import { Link } from 'react-router-dom';
import '../Home/css/register.css';
import apiUrl from '../server';
import axios from 'axios';
import waveIcon from '../../assets/images/MettaMateImages/Group189.svg';
import ourLogo from '../../assets/images/MettaMateImages/sblogo2.svg';
import googleIcon from '../../assets/images/MettaMateImages/Google.svg';
import linkedinIcon from '../../assets/images/MettaMateImages/linkedinIcon.svg';
import loginBanner from '../../assets/images/MettaMateImages/loginbanner2.gif';
import {
  Container,
  Col,
  Input,
  Label,
  Button,
  Form,
  FormFeedback,
  Card,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
} from 'reactstrap';
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import loginBackgroundBanner from '../../assets/images/mainBG-image.jpg';
import mm_vision from '../../assets/images/mettamate/mm_vision.jpg';
import AboutUsSection from './AboutUsSection.js';

const AboutUsPage = () => {
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList = '';
  }, []);

  return (
    <React.Fragment>
      {/* Section So far DONE */}
      <AboutUsSection />
      <section className="py-3 bg-white">
        <Container className="mt-70 ">
          {/* section title */}
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="pb-3">
                <h1 className="title mb-2"> Our Story </h1>
              </div>
              <p className="fontSize18">
                Welcome to{' '}
                <span className="text-primary fw-bold">Metta Mate</span> , a
                revolutionary app designed with the vision of empowering
                Ambedkarites through matrimony and community building. At
                Mettamate, we are committed to providing a platform that fosters
                meaningful connections and advances the principles and values of
                Dr. B.R. Ambedkar.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={6} key="1" className="col-12 mt-4 pt-2">
              <Card className="border-0">
                <CardBody className="p-0 ">
                  <Link to="/portfolio-detail-one">
                    <img
                      src={mm_vision}
                      className="img-fluid rounded work-image"
                      alt="vision"
                    />
                  </Link>
                  <div className=" pt-3">
                    <h4 className="title mb-4">Our Mission</h4>
                    <p className="fontSize18">
                      Our mission at Mettamate is to create a safe and inclusive
                      space where Ambedkarites can connect, build relationships,
                      and support each other in their personal and professional
                      journeys.We aim to promote unity, equality, and
                      empowerment within the community by leveraging technology
                      and social networking.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} key="1" className="col-12 mt-4 pt-2">
              <Card className="border-0">
                <CardBody className="p-0 ">
                  <Link to="/portfolio-detail-one">
                    <img
                      src={mm_vision}
                      className="img-fluid rounded work-image"
                      alt="vision"
                    />
                  </Link>
                  <div className=" pt-3">
                    <h4 className="title mb-4">Our Vision</h4>
                    <p className="fontSize18">
                      We envision a future where every Amberdkarite has access
                      to a supportive community, meaningful relationships, and
                      opportunities for personal and professional growth.
                      Through Mettamate, we strive to build a strong and united
                      community that upholds the ideals of social justice,
                      equality, and empowerment that  Dr. B.R. Ambedkar
                      championed.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr />
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="pb-3 pt-3">
                <h1 className="title mb-2"> Join Us </h1>
              </div>
              <p className="fontSize18">
                Whether you are looking for a life partner, seeking support and
                resources, or simply want to connect with like-minded an
                individuals,{' '}
                <span className="text-primary fw-bold">Metta Mate</span> is here
                for you. Join us in becoming part of MettaMate community.
                Together, we can create a brighter and more inclusive future for
                all Ambedkarites.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="pt-3 ">
                <Link
                  to="/user-registration-page"
                  className="btn btn-primary mt-70"
                >
                  {' '}
                  <span className="fontSize20">Join Now</span>{' '}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUsPage;
