import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import CommonModal from '../../Home/modal.js'
import 'react-phone-input-2/lib/style.css'; // Import the styles
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import apiUrl from '../../server';
import FeatherIcon from 'feather-icons-react';
import NameIcon from '../../../assets/images/MettaMateImages/signature.png'
import Equality from '../../../assets/images/MettaMateImages/wedding-ring.png';
import History from '../../../assets/images/MettaMateImages/calling.png';
import ImgCrop from 'antd-img-crop';
import { Button, Upload, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import galleryPhoto from '../../../assets/images/MettaMateImages/gallery.png';
import imgIcon from '../../../assets/images/MettaMateImages/background.png'
import {
    Row,
    Col,
    Label,
    Input,
    Form,
} from 'reactstrap';
const PersonalFunction = () => {
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [dateValue, setDateValue] = useState(null);

    const [phoneNumber, setPhoneNumber] = useState();
    const [countryCode, setCountryCode] = useState('');
    const [value, setValue] = useState();
    const [genderList, setGenderList] = useState([]);
    const [personalData, setpersonalData] = useState({});
    const [basicData, setBasicData] = useState({});
    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);
    const [file3, setFile3] = useState([]);
    const [imageIndex, setImageIndex] = useState('');
    const [genderselectedOption, setgenderSelectedOption] = useState(1);
    const [modalVisible, setModalVisible] = useState(false);
    const [previewmodalVisible, previewsetModalVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileToRemove, setFileToRemove] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [configuredata, setConfigureData] = useState({
        memberuuid: '',
        firstname: '',
        lastname: '',
        photopath1: '',
        photopath2: '',
        photopath3: '',
        subscription: '',
        jobprofession: '',
        linkedin: '',
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        snapchat: '',
        recommended: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [genderRes] = await Promise.all([
                    axios.get(`${apiUrl}codes/gender`),
                ]);
                setGenderList(genderRes.data.list);
            } catch (error) {
            }
        };
        fetchData();
        fetchconfigureDetails();
        fetchpersonalDetails();
        basicdetails();
    }, []);

    const basicdetails = async () => {
        try {
            const response = await axios.get(apiUrl + 'members/basic/' + localStorage.getItem('memberID'), {
                headers: {
                    Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
            });
            if (response.data.length > 0) {
                setBasicData(response.data[0]);
                setValue(
                    '+' + response.data[0].phonecountrycode + response.data[0].phonenumber
                );
                setCountryCode(response.data[0].phonecountrycode);
                setPhoneNumber(response.data[0].phonenumber);
                setgenderSelectedOption(response.data[0].gender)
            }
        } catch (error) {
        }
    };

    const fetchpersonalDetails = async () => {
        try {
            const response = await axios.get(
                apiUrl + 'members/personal/' + localStorage.getItem('memberID'),
                {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.data.length > 0) {
                let personalData = response.data[0]
                setFormData({
                    ...formData,
                    'dob': personalData.dob ? format(parseISO(personalData.dob), 'yyyy-MM-dd') : '',
                });
                configureDetails();
                if (response.data[0].dob == null) {
                    setDateValue('');
                } else {
                    const apiDate = new Date(response.data[0].dob);
                    setDateValue(apiDate);
                    const formatPattern = 'yyyy-MM-dd';
                    const formattedDate = format(apiDate, formatPattern);
                    formData.dob = formattedDate;
                }
            } else {

            }
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };

    const configureDetails = async () => {
        try {
            const response = await axios.get(
                apiUrl + 'members/short/' + localStorage.getItem('memberID'),
                {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            localStorage.setItem('configurationData', JSON.stringify(response.data));
        } catch (error) {
        }
    };
    const fetchconfigureDetails = async () => {
        setFile1([]);
        setFile2([]);
        setFile3([]);
        try {
            const response = await axios.get(
                apiUrl + 'members/short/' + localStorage.getItem('memberID'),
                {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );

            let fileurl1 = response.data.photopath1
                ? await getPhotoUrlDetails(response.data.photopath1)
                : undefined;
            let fileurl2 = response.data.photopath2
                ? await getPhotoUrlDetails(response.data.photopath2)
                : undefined;
            let fileurl3 = response.data.photopath3
                ? await getPhotoUrlDetails(response.data.photopath3)
                : undefined;
            setConfigureData(response.data);
            localStorage.setItem('configurationData', JSON.stringify(response.data));
            if (fileurl1) {
                setFile1((prevList) => [
                    ...prevList,
                    {
                        name: response.data.photopath1,
                        url: fileurl1,
                    },
                ]);
            } else {
                setFile1([]);
            }
            if (fileurl2) {
                setFile2((prevList) => [
                    ...prevList,
                    {
                        name: response.data.photopath2,
                        url: fileurl2,
                    },
                ]);
            } else {
                setFile2([]);
            }
            if (fileurl3) {
                setFile3((prevList) => [
                    ...prevList,
                    {
                        name: response.data.photopath3,
                        url: fileurl3,
                    },
                ]);
            } else {
                setFile3([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getPhotoUrlDetails = async (fileName) => {
        try {
            const response = await axios.get(
                apiUrl + 'members/filepath/' + fileName,
                {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            return response.data.filepath;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlecontrycodeOnChange = (value, country) => {
        setCountryCode(country.dialCode);
        setPhoneNumber(value.replace(countryCode, ''));
    };
    const openSelectionform1 = () => {
        const fileInput = document.getElementById('profileimage');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const openSelectionform2 = () => {
        const fileInput = document.getElementById('image1');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const openSelectionform3 = () => {
        const fileInput = document.getElementById('image2');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const handleChange = (fileListSetter, imageIndex) => ({ fileList: newFileList }) => {
        fileListSetter(newFileList);
        setImageIndex(imageIndex);
    };

    const handleUploadImage = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('memberUUID', localStorage.getItem('memberID'));
        formData.append('imageindex', imageIndex);

        let response = await axios
            .post(apiUrl + 'members/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                },
            })
            .then((res) => {
                onSuccess();
                if (imageIndex == 0) {
                    setConfigureData({ ...configuredata, photopath1: res.data.filename });
                    localStorage.setItem('profilePicture', res.data.filePath)
                }
                else if (imageIndex == 1) {
                    setConfigureData({ ...configuredata, photopath2: res.data.filename })
                }
                else if (imageIndex == 2) {
                    setConfigureData({ ...configuredata, photopath3: res.data.filename })
                }
                setSuccess({
                    title: 'Upload Complete!',
                    message: 'Your Image has been uploaded Successfully!',
                });
            })
            .catch((err) => {
                onError();
                console.log(err)
                setError({
                    title: 'Upload Failed!',
                    message: err.response.message,
                });
            });
    };
    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const onPreview = async (file) => {
        const src = file.url || (await getSrcFromFile(file));
        let originalFileUrl = await getPhotoUrlDetails(file.name.replace("_thumb", ""))
        setPreviewImage(originalFileUrl);
        previewsetModalVisible(true);
    };

    const showDeleteConfirm = async (file, index) => {
        setModalVisible(true);
        setFileToRemove(file);
        setImageIndex(index);
    };
    const handleOk = async () => {
        setModalVisible(false);
        let requestbody = {
            memberUUID: localStorage.getItem('memberID'),
            fileName: fileToRemove.name,
            index: imageIndex,
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(requestbody),
        };
        fetch(apiUrl + 'members/deleteImage', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message == 'OK') {
                    fetchconfigureDetails();
                    setSuccess({
                        title: 'Image Deleted!',
                        message: 'Your Image has been Deleted Successfully!',
                    });
                    if (imageIndex == 0) {
                        localStorage.setItem('profilePicture', 'undefined')
                    }
                } else {
                    setError({
                        title: 'An Error Occured!',
                        message: 'Your Image is not Deleted. Please Try Again',
                    });
                }
            });
        setFileToRemove(null);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setFileToRemove(null);
    };

    const handlepreviewOk = () => {
        setPreviewImage(''); // Clear the preview image when modal is closed
        setTimeout(() => { previewsetModalVisible(false); }, 50);
    };
    const [formData, setFormData] = useState({
        memberuuid: localStorage.getItem("memberID"),
        firstname: '',
        lastname: '',
        gender: '',
        emailid: '',
        photopath1: '',
        photopath2: '',
        photopath3: '',
        mobileno: '',
        countrycode: '',
    });

    const handleGenderChange = (event) => {
        setgenderSelectedOption(event.target.value);
    };
    const handleSubmit = async () => {
        setIsLoading(true);
        formData.countrycode = countryCode;
        formData.mobileno = phoneNumber;
        formData.gender = genderselectedOption;
        formData.firstname = basicData.firstname;
        formData.lastname = basicData.lastname;
        formData.memberuuid = localStorage.getItem('memberID')
        console.log(formData)
        try {
            const response = await axios
                .post(apiUrl + 'members/personal/', formData, {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                })
                .then((res) => {
                    basicdetails();
                    fetchpersonalDetails();
                    setIsLoading(false);
                    setSuccess({
                        title: 'SUCCESS!',
                        message: res.data.message,
                    });
                })
                .catch((err) => {
                    setIsLoading(false);
                    setError({
                        title: 'An Error Occured!',
                        message: err.response.data.message,
                    });
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleReset = () => {
        setFormData({})
        setBasicData({})
        setCountryCode({});
        setPhoneNumber({});
        setDateValue({})
    }
    const errorHandler = () => {
        setError(null);
    };
    const successHandler = () => {
        setSuccess(null);
    };
    const LoaderComponent = () => (
        <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        </div>
    );
    return (
        <React.Fragment>

            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            {
                isLoading ? <LoaderComponent /> :
                    (
                        <Form>
                            <Row className="inner_row">
                                <h5 className="title  text-center title_bar">
                                    Fill Your Personal Details
                                </h5>

                                <Col md={12} className='mt-3'>
                                    <img src={NameIcon} className='legendIcon' />
                                    &nbsp; <span className="m-2 legendName" >Your Full Name</span>

                                    <Row className="mt-3">
                                        <Col md={4}>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="user"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <Input
                                                        type="text" required
                                                        className="form-control ps-5 form_Input"
                                                        placeholder="First Name"
                                                        name="firstname"

                                                        id="firstName"
                                                        onChange={event => setFormData({ ...formData, firstname: event.target.value })}

                                                        value={basicData.firstname || ''}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={4}>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="user"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <Input
                                                        type="text"
                                                        className="form-control form_Input ps-5"
                                                        placeholder="Last Name"
                                                        name="lastname"
                                                        required
                                                        id="lastName"
                                                        onChange={event => setFormData({ ...formData, lastname: event.target.value })}

                                                        value={basicData.lastname || ''}

                                                    />

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                                </Col>

                                <Col md={12}>
                                    <img src={Equality} className='legendIcon' />
                                    &nbsp; <span className="m-2 legendName" >Identity</span>

                                    <Row className="mt-3">
                                        <Col md={12} xs={12}>
                                            <div className=" features feature-primary py-0">
                                                <div className="mb-3">
                                                    <Label className="form-label label_text">
                                                        Gender  <span className="text-danger">*</span>
                                                    </Label>
                                                    <section className="radio-section">
                                                        <div className="radio-list">
                                                            {(genderList || []).map((type, index) => (
                                                                <div className="radio-item" key={index}  >
                                                                    <Input name="genderradio" type="radio" defaultValue={type.value}
                                                                        checked={genderselectedOption == type.value} id={`radiogender${index}`}
                                                                        onChange={handleGenderChange} />
                                                                    <label htmlFor={`radiogender${index}`}> {type.label}</label>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </Col>


                                    </Row>
                                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                                </Col>

                                <Col md={12}>
                                    <img src={History} className='legendIcon' />
                                    &nbsp; <span className="m-2 legendName" >Records </span>
                                    <Row className="mt-3">

                                        <Col md={4} xs={12}>
                                            <div className=" features feature-primary py-0">
                                                <div className="mb-3">
                                                    <Label className="form-label label_text ">
                                                        Mobile Number
                                                    </Label>
                                                    <PhoneInput
                                                        className="  form_Input form_Input"
                                                        style={{ width: '100%' }}
                                                        international
                                                        minLength={10}
                                                        maxLength={13}
                                                        required
                                                        country={'in'}
                                                        value={value}
                                                        onChange={handlecontrycodeOnChange}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md={4} xs={12}>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <Label className="form-label label_text">
                                                        Date Of Birth
                                                    </Label>
                                                    <br />
                                                    <input type="date" className='form-control' defaultValue={formData.dob}
                                                        onChange={event => setFormData({ ...formData, dob: event.target.value })} />
                                                </div>
                                            </div>
                                        </Col>


                                    </Row>
                                    <hr style={{ boxShadow: `0px 1px 1px` }} />
                                </Col>
                                <Col md={12}>
                                    <img src={imgIcon} className='legendIcon' />
                                    &nbsp; <span className="m-2 legendName" >Upload Photos </span>
                                    <Row className="mt-3">

                                        <Col md={4} xs={12}>
                                            <div className=" features feature-primary py-0">
                                                <div className="mb-3 text-center">
                                                    <Label className="form-label label_text ">
                                                        Photo 1
                                                    </Label>
                                                    <div>
                                                        <ImgCrop
                                                            showGrid
                                                            rotationSlider
                                                            showReset remove={false}
                                                            aspect={1 / 1}
                                                        >
                                                            <Upload id="profileimage"
                                                                listType="picture-card"
                                                                fileList={file1}
                                                                onChange={handleChange(setFile1, 0)}
                                                                onPreview={onPreview}
                                                                customRequest={(file) =>
                                                                    handleUploadImage(file, 0)
                                                                }
                                                                onRemove={() => { showDeleteConfirm(file1[0], 0); }}

                                                            >


                                                                {file1.length < 1 && (
                                                                    <div>
                                                                        <img src={galleryPhoto} />
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                        </ImgCrop>

                                                    </div>
                                                    <div className='text-center'>
                                                        {file1.length < 1 && (
                                                            <Button onClick={openSelectionform1} icon={<UploadOutlined />}>Upload</Button>
                                                        )}
                                                        {file1.length > 0 && (
                                                            <Button onClick={() => { showDeleteConfirm(file1[0], 0) }} style={{ marginLeft: '5px' }}>Delete</Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={12}>
                                            <div className=" features feature-primary py-0">
                                                <div className="mb-3 text-center">
                                                    <Label className="form-label label_text ">
                                                        Photo 2
                                                    </Label>
                                                    <div >
                                                        <ImgCrop
                                                            showGrid
                                                            rotationSlider
                                                            aspect={1 / 1}
                                                            showReset remove={false}
                                                        >
                                                            <Upload id="image1"
                                                                listType="picture-card"
                                                                fileList={file2}
                                                                onChange={handleChange(setFile2, 1)}
                                                                onPreview={onPreview}
                                                                customRequest={handleUploadImage}
                                                                onRemove={(file) =>
                                                                    showDeleteConfirm(file, 1)
                                                                }
                                                            >
                                                                {file2.length < 1 && (
                                                                    <div>
                                                                        <img src={galleryPhoto} />
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                        </ImgCrop>
                                                    </div>
                                                    <div className='text-center'>
                                                        {file2.length < 1 && (
                                                            <Button onClick={openSelectionform2} icon={<UploadOutlined />}>Upload</Button>
                                                        )}
                                                        {file2.length > 0 && (
                                                            <Button onClick={() => { showDeleteConfirm(file2[0], 1); }} style={{ marginLeft: '5px' }}>Delete</Button>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={12}>
                                            <div className=" features feature-primary py-0">
                                                <div className="mb-3 text-center">
                                                    <Label className="form-label label_text ">
                                                        Photo 3
                                                    </Label>
                                                    <div >
                                                        <ImgCrop
                                                            showGrid
                                                            rotationSlider
                                                            aspect={1 / 1}
                                                            showReset remove={false}
                                                        >
                                                            <Upload id="image2"
                                                                listType="picture-card"
                                                                fileList={file3}
                                                                onChange={handleChange(setFile3, 2)}
                                                                onPreview={onPreview}
                                                                customRequest={handleUploadImage}
                                                                onRemove={(file) =>
                                                                    showDeleteConfirm(file, 2)
                                                                }
                                                            >
                                                                {file3.length < 1 && (
                                                                    <div>
                                                                        <img src={galleryPhoto} />
                                                                    </div>
                                                                )}

                                                            </Upload>
                                                        </ImgCrop>
                                                    </div>
                                                    <div className='text-center'>
                                                        {file3.length < 1 && (
                                                            <Button onClick={openSelectionform3} icon={<UploadOutlined />}>Upload</Button>
                                                        )}
                                                        {file3.length > 0 && (
                                                            <Button onClick={() => { showDeleteConfirm(file3[0], 2); }} style={{ marginLeft: '5px' }}>Delete</Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                    <Button
                                        type="submit"
                                        className="btn btn-primary mt-2 "
                                        style={{ background: `#2f55d4` }}
                                        onClick={handleSubmit}
                                    >
                                        {' '}
                                        Save Changes{' '}
                                    </Button>{' '}
                                    <Button
                                        onClick={handleReset}
                                        className="btn btn-outline-primary  mt-2 ms-2"
                                        style={{ background: `none`, color: `#2f55d4` }}
                                    >
                                        Reset
                                    </Button>
                                </div>
                            </Row>
                        </Form>
                    )
            }

            <Modal
                title="Confirm Deletion"
                open={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
                onClose={handleOk}
            >
                Are you sure you want to delete this image?
            </Modal>

            <Modal
                title="Avatar Preview"
                open={previewmodalVisible}
                onOk={handlepreviewOk}
                okText="Close"
                onClose={handlepreviewOk}
                onCancel={handlepreviewOk}
            >
                <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </React.Fragment>
    );
}
export default PersonalFunction;