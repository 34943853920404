import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CommonModal from '../Home/modal.js';
import PersonalDetails from '../Home/Profile/Personal.js';
import CareerDetails from '../Home/Profile/Career.js';
import EducationDetails from '../Home/Profile/Education.js';
import RegionalDetails from '../Home/Profile/Regional.js';
import LifestyleDetails from '../Home/Profile/Lifestyle.js';
import SpiritualDetails from '../Home/Profile/Spiritual.js';
import FamilyDetails from '../Home/Profile/FamilyB.js';
import SocialDetails from '../Home/Profile/Socials.js';
import UploadAds from '../Home/Profile/UploadAds.jsx'

import Profilecard from '../Home/Profile_card.jsx';
import 'react-datepicker/dist/react-datepicker.css';
//Import Icons
import '../Home/css/userHomePage.css';
import ImgCrop from 'antd-img-crop';
import { Button, Upload, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import DefaultPicture from './DefaultPicture.js';
import apiUrl from '../server';
import photosIcon from '../../assets/images/MettaMateImages/phtotframe.png';
import personalInfoIcon from '../../assets/images/MettaMateImages/personalInfo.jpg';
import careerInfo from  '../../assets/images/MettaMateImages/career.png';
import educationIcon from '../../assets/images/MettaMateImages/education.png';
import locationIcon from '../../assets/images/MettaMateImages/location.png';
import lifestyleIcon from  '../../assets/images/MettaMateImages/lifestyle.png';
import spiritualIcon from '../../assets/images/MettaMateImages/spiritual.png';
import socialsIcon from '../../assets/images/MettaMateImages/socialIcon.png';
import  adsIcon from '../../assets/images/MettaMateImages/ads.png';
import familyIcon from '../../assets/images/MettaMateImages/familyb.png';
const UserProfileEditFunction = () => {
  let configurationData = JSON.parse(localStorage.getItem('configurationData'));
  const menudata = [
    { icon: photosIcon, menuName: 'Photos', value: 'profile' },
    { icon: personalInfoIcon, menuName: 'Personal', value: 'personal' },
    { icon: careerInfo, menuName: 'Career', value: 'career' },
    { icon: educationIcon, menuName: 'Education', value: 'education' },
    { icon: locationIcon, menuName: 'Regional/Location', value: 'regional' },
    { icon: spiritualIcon, menuName: 'Spiritual', value: 'spiritual' },
    { icon: lifestyleIcon, menuName: 'Lifestyle', value: 'lifestyle' },
    { icon: familyIcon, menuName: 'Family Background', value: 'family' },
    { icon: socialsIcon, menuName: 'Socials', value: 'socials' },
    // { icon: 'uil-user-arrows', menuName: 'Connections', value: 'connections' },
    // { icon: 'uil-setting', menuName: 'Settings', value: 'settings' },
    { icon: adsIcon , menuName: 'Upload Ads', value: 'uploadAds'}

  ];
  const [isVisible, setVisibility] = useState('profile');
  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);
  const [file3, setFile3] = useState([]);
  const [imageIndex, setImageIndex] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [previewmodalVisible, previewsetModalVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileToRemove, setFileToRemove] = useState(null);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [activeLink, setActiveLink] = useState('profile');

  const [configuredata, setConfigureData] = useState({
    memberuuid: '',
    firstname: '',
    lastname: '',
    photopath1: '',
    photopath2: '',
    photopath3: '',
    subscription: '',
    jobprofession: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    recommended: '',
  });

  useEffect(() => {
    configurationData = JSON.parse(localStorage.getItem('configurationData'));
    fetchconfigureDetails();

  }, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setVisibility(link);
  };

  const fetchconfigureDetails = async () => {
    setFile1([]);
    setFile2([]);
    setFile3([]);
    try {
      const response = await axios.get(
        apiUrl + 'members/short/' + localStorage.getItem('memberID'),
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );

      let fileurl1 = response.data.photopath1
        ? await getPhotoUrlDetails(response.data.photopath1)
        : undefined;
      let fileurl2 = response.data.photopath2
        ? await getPhotoUrlDetails(response.data.photopath2)
        : undefined;
      let fileurl3 = response.data.photopath3
        ? await getPhotoUrlDetails(response.data.photopath3)
        : undefined;
      setConfigureData(response.data);
      localStorage.setItem('configurationData', JSON.stringify(response.data));
      if (fileurl1) {
        setFile1((prevList) => [
          ...prevList,
          {
            name: response.data.photopath1,
            url: fileurl1,
          },
        ]);
      } else {
        setFile1([]);
      }
      if (fileurl2) {
        setFile2((prevList) => [
          ...prevList,
          {
            name: response.data.photopath2,
            url: fileurl2,
          },
        ]);
      } else {
        setFile2([]);
      }
      if (fileurl3) {
        setFile3((prevList) => [
          ...prevList,
          {
            name: response.data.photopath3,
            url: fileurl3,
          },
        ]);
      } else {
        setFile3([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getPhotoUrlDetails = async (fileName) => {
    try {
      const response = await axios.get(
        apiUrl + 'members/filepath/' + fileName,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.filepath;

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChange =
    (fileListSetter, imageIndex) =>
      ({ fileList: newFileList }) => {
        fileListSetter(newFileList);
        setImageIndex(imageIndex);
      };
  const handleUploadImage = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('memberUUID', localStorage.getItem('memberID'));
    formData.append('imageindex', imageIndex);

    let response = await axios
      .post(apiUrl + 'members/uploadImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        onSuccess();
        fetchconfigureDetails()
        if (imageIndex == 0) {
          setConfigureData({ ...configuredata, photopath1: res.data.filename });
          localStorage.setItem('profilePicture', res.data.filePath)
        }
        else if (imageIndex == 1) {
          setConfigureData({ ...configuredata, photopath2: res.data.filename })
        }
        else if (imageIndex == 2) {
          setConfigureData({ ...configuredata, photopath3: res.data.filename })
        }
        setSuccess({
          title: 'Upload Complete!',
          message: 'Your Image has been uploaded Successfully!',
        });
      })
      .catch((err) => {
        onError();
        setError({
          title: 'Upload Failed!',
          message: err.response.data.message,
        });
      });
  };
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onPreview = async (file) => {
    // const src = file.url || (await getSrcFromFile(file));
    // setPreviewImage(src);
    // previewsetModalVisible(true);
    let originalFileUrl = await getPhotoUrlDetails(file.name.replace("_thumb", ""))
    setPreviewImage(originalFileUrl);
    previewsetModalVisible(true);
  };

  const showDeleteConfirm = async (file, index) => {
    setModalVisible(true);
    setFileToRemove(file);
    setImageIndex(index);
  };
  const handleOk = async () => {
    setModalVisible(false);
    let requestbody = {
      memberUUID: localStorage.getItem('memberID'),
      fileName: fileToRemove.name,
      index: imageIndex,
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(requestbody),
    };
    fetch(apiUrl + 'members/deleteImage', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.message == 'OK') {
          fetchconfigureDetails();
          setSuccess({
            title: 'Image Deleted!',
            message: 'Your Image has been Deleted Successfully!',
          });
          if (imageIndex == 0) {
            localStorage.setItem('profilePicture', 'undefined')
          }
        } else {
          setError({
            title: 'An Error Occured!',
            message: 'Your Image is not Deleted. Please Try Again',
          });
        }
      });
    setFileToRemove(null);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setFileToRemove(null);
  };

  const handlepreviewOk = () => {
    setPreviewImage(''); // Clear the preview image when modal is closed
    setTimeout(() => { previewsetModalVisible(false); }, 50);
  };
  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }


  };
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const openSelectionform1 = () => {
    const fileInput = document.getElementById('profileimage');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input element with id 'profileimage' not found");
    }
  }
  const openSelectionform2 = () => {
    const fileInput = document.getElementById('image1');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input element with id 'profileimage' not found");
    }
  }
  const openSelectionform3 = () => {
    const fileInput = document.getElementById('image2');
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input element with id 'profileimage' not found");
    }
  }
  const menuslider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // Show 5 items at a time
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (

    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <Profilecard />

      <Container  className={`mt-5 pt-1 ${(configurationData.memberstatuscode === 3 || configurationData.memberstatuscode === 0) ? 'pointer_event_none' : ''}`}>
        <section className="section_user_home_page">
          <Container>
            <Row>
              <Col lg="12" className='Col_12'>

                <Row>
                  <Col md={12} xs={12} className="inner_row ">
                    <Row >

                      <div className='w-5/6 m-auto'>
                        <div className='mt-20 pb-3'>

                          <Slider {...menuslider} className="overflow-hidden menu_slider ">
                            {(menudata || []).map((item, key) => (
                              <div className=' list-unstyled profession_ul text-center' key={key}>
                                <div
                                  style={{ padding: `5px 10px 5px 5px` }}
                                  className={`text-center profession_li mt-1 ${activeLink === item.value ? 'active' : ''
                                    }`}
                                  onClick={() => handleLinkClick(item.value)}>
                                  <img src={item.icon}  className='center' style={{ width: `80px`, height: `60px` }} />
                                </div>
                                <p className="filter_title fw-normal m-auto" style={{ fontSize: `16px` }}><span className={` ${activeLink === item.value ? 'active_text' : ''}`} >{item.menuName}</span></p>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </Row>
                  </Col>

                  {isVisible == 'profile' && (
                    <Col
                      md={12} className='inner_row p-0 mt-1'
                      xs={12}
                    >
                      <Row className=" m-0  inner_row_personal">
                        <h5 className="title title_bar">
                          Upload Your Photos
                        </h5>

                        <Col md={12} className=" text-center">
                          <h4 className="heading mb-3">
                            {configuredata.firstname}, help others to recognize you !
                          </h4>
                        </Col>

                        <Col md={4} className='text-center'>
                          <div className="fieldset shadow" style={{background: `white`}}>
                            <div className="legend text-left text-primary" style={{top: `-4px`}}>Upload Profile Photo</div>
                            <div className='uploadimg_div mt-2 ' >
                              <ImgCrop
                                showGrid
                                rotationSlider
                                showReset remove={false}
                                aspect={1 / 1}
                              >
                                <Upload id="profileimage"
                                  listType="picture-card"
                                  fileList={file1}
                                  onChange={handleChange(setFile1, 0)}
                                  onPreview={onPreview}
                                  customRequest={(file) =>
                                    handleUploadImage(file, 0)
                                  }
                                  onRemove={() => { showDeleteConfirm(file1[0], 0); }}

                                >
                                  {file1.length < 1 && (
                                    <div>
                                      <DefaultPicture gendercode={configuredata.gendercode}
                                      />
                                    </div>
                                  )}
                                </Upload>
                              </ImgCrop>

                            </div>
                            <div className='text-center'>
                              {file1.length < 1 && (
                                <Button onClick={openSelectionform1} icon={<UploadOutlined />}>Upload</Button>
                              )}
                              {file1.length > 0 && (
                                <Button onClick={() => { showDeleteConfirm(file1[0], 0) }} style={{ marginLeft: '5px' }}>Delete</Button>
                              )}
                            </div>
                          </div>
                        </Col>

                        <Col md={8} className='text-center'>
                          <div className="fieldset shadow" style={{background: `white`}}>
                            <div className="legend text-left text-primary" style={{top: `-4px`}}>Upload More Photos</div>
                            <Row>
                              <Col md={6} className='text-center'>

                                <div className='uploadimg_div mx-auto mt-2'>
                                  <ImgCrop
                                    showGrid
                                    rotationSlider
                                    aspect={1 / 1}
                                    showReset remove={false}
                                  >
                                    <Upload id="image1"
                                      listType="picture-card"
                                      fileList={file2}
                                      onChange={handleChange(setFile2, 1)}
                                      onPreview={onPreview}
                                      customRequest={handleUploadImage}
                                      onRemove={(file) =>
                                        showDeleteConfirm(file, 1)
                                      }
                                    >
                                      {file2.length < 1 && (
                                        <div>
                                          <DefaultPicture
                                            gendercode={configuredata.gendercode}
                                          />
                                        </div>
                                      )}

                                    </Upload>
                                  </ImgCrop>
                                </div>
                                <div className='text-center'>
                                  {file2.length < 1 && (
                                    <Button onClick={openSelectionform2} icon={<UploadOutlined />}>Upload</Button>
                                  )}
                                  {file2.length > 0 && (
                                    <Button onClick={() => { showDeleteConfirm(file2[0], 1); }} style={{ marginLeft: '5px' }}>Delete</Button>
                                  )}
                                </div>
                              </Col>

                              <Col md={6} className='sqs-block-html text-center'>
                                <div className='uploadimg_div mx-auto mt-2'>
                                  <ImgCrop
                                    showGrid
                                    rotationSlider
                                    aspect={1 / 1}
                                    showReset remove={false}
                                  >
                                    <Upload id="image2"
                                      listType="picture-card"
                                      fileList={file3}
                                      onChange={handleChange(setFile3, 2)}
                                      onPreview={onPreview}
                                      customRequest={handleUploadImage}
                                      onRemove={(file) =>
                                        showDeleteConfirm(file, 2)
                                      }
                                    >
                                      {file3.length < 1 && (
                                        <div>
                                          <DefaultPicture
                                            gendercode={configuredata.gendercode}
                                          />
                                        </div>
                                      )}

                                    </Upload>
                                  </ImgCrop>
                                </div>
                                <div className='text-center'>
                                  {file3.length < 1 && (
                                    <Button onClick={openSelectionform3} icon={<UploadOutlined />}>Upload</Button>
                                  )}
                                  {file3.length > 0 && (
                                    <Button onClick={() => { showDeleteConfirm(file3[0], 2); }} style={{ marginLeft: '5px' }}>Delete</Button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>


                        <Row className="mt-5 text-center">
                          <p className="text-center">
                            <h6>
                              On <span className="text-primary">MettaMate</span>, we require members to use their real identities, so take or upload a photo of yourself. Then crop, filter and adjust it to perfection to have <span className="text-primary">WoW</span> effect !
                            </h6>
                          </p>
                        </Row>
                      </Row>

                    </Col>
                  )}

                  {isVisible == 'personal' && (
                    <Col
                      md={12}
                      xs={12}
                      className=' p-0'
                    >
                      {' '}
                      <PersonalDetails />
                    </Col>
                  )}
                  {isVisible == 'career' && (
                    <Col
                      md={12}
                      xs={12}
                      className=' p-0'
                    >
                      <CareerDetails />
                    </Col>
                  )}
                  {isVisible == 'education' && (
                    <Col
                      md={12}
                      xs={12}
                      className=' p-0'
                    >
                      <EducationDetails />
                    </Col>
                  )}
                  {isVisible == 'regional' && (
                    <Col md={12} xs={12} className=' p-0'>
                      <RegionalDetails />
                    </Col>
                  )}
                  {isVisible == 'spiritual' && (
                    <Col md={12} xs={12} className=' p-0'>
                      <SpiritualDetails />
                    </Col>
                  )}
                  {isVisible == 'lifestyle' && (
                    <Col md={12} xs={12} className=' p-0'>
                      <LifestyleDetails />
                    </Col>
                  )}

                  {isVisible == 'family' && (
                    <Col md={12} xs={12} className=' p-0'>
                      <FamilyDetails />
                    </Col>
                  )}
                  {isVisible == 'socials' && (
                    <Col
                      md={12}
                      xs={12}
                      className=' p-0'
                    >
                      {' '}
                      <SocialDetails />
                    </Col>
                  )}
                  {isVisible == 'connections' && (
                    <Col
                      md={12}
                      xs={12}
                      className=' p-0'
                    >
                      {' '}
                      <div>To Be Designed</div>
                    </Col>
                  )}
                  {/* {isVisible == 'settings' && (
                    <Col
                      md={12}
                      xs={12}
                      className=' p-0'
                    >
                      {' '}
                      <SettingsDetails />
                    </Col>
                  )} */}
                    {isVisible == 'uploadAds' && (
                    <Col
                      md={12}
                      xs={12}
                      className=' p-0'
                    >
                      {' '}
                      <UploadAds />
                    </Col>
                  )}
                </Row>

              </Col>
            </Row>
          </Container>
        </section>
      </Container>



      <Modal
        title="Confirm Deletion"
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        onClose={handleOk}
      >
        Are you sure you want to delete this image?
      </Modal>

      <Modal
        title="Avatar Preview"
        open={previewmodalVisible}
        onOk={handlepreviewOk}
        okText="Close"
        onClose={handlepreviewOk}
        onCancel={handlepreviewOk}
      >
        <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </React.Fragment>
  );
};

export default UserProfileEditFunction;
