import React, { Component } from 'react';
import '../Home/css/userHomePage.css';
import ListProfilesSquareClass from './ListProfilesSquare';
import ProfileCard from '../Home/Profile_card';
import DataContext from './context';

class UserHomePageFunction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLink: ''
    };
  }
  setActiveLink = (link) => {
    this.setState({ activeLink: link });
  };

  componentDidMount() {
    document.body.classList = '';
    document.querySelectorAll('#buyButton').forEach((navLink) => {
      navLink.classList.add('btn-light');
      navLink.classList.remove('btn-soft-primary');
      document.getElementById('top-menu').classList.add('nav-light');
    });
    window.addEventListener('scroll', this.scrollNavigation, true);
    let memberID = localStorage.getItem('memberID')

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + localStorage.getItem('token')
      },
    };
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);

  }
  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
  };

  render() {
    return (
      <React.Fragment>
        <DataContext.Provider value={{ activeLink: this.state.activeLink, setActiveLink: this.setActiveLink }}>
          {/* <ProfileCard /> */}
          <ListProfilesSquareClass  />
        </DataContext.Provider>
      </React.Fragment >
    );
  }
}

export default UserHomePageFunction;