import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Label } from 'reactstrap';
import Profilecard from '../Profile_card.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MMpersonalDetails from '../matchmaker/MMpersonalDetails.jsx';
import CareerDetails from '../Profile/Career.js';
import EducationDetails from '../Profile/Education.js';
import RegionalDetails from '../Profile/Regional.js';
import CommonModal from '../modal.js'
import apiUrl from '../../server';
import presentIcon from '../../../assets/images/MettaMateImages/presentation.png';
import personalInfoIcon from '../../../assets/images/MettaMateImages/personalInfo.jpg';
import careerInfo from '../../../assets/images/MettaMateImages/career.png';
import educationIcon from '../../../assets/images/MettaMateImages/education.png';
import locationIcon from '../../../assets/images/MettaMateImages/location.png';
import memberofferingIcon from '../../../assets/images/MettaMateImages/memberoffering.png'
const ConfigureCommunityMFunction = () => {
    let configurationData = JSON.parse(localStorage.getItem('configurationData'));

    const [isVisible, setVisibility] = useState('personal');
    const [activeLink, setActiveLink] = useState('personal');
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [formData, setFormData] = useState({
        memberuuid: localStorage.getItem('memberID'),
        ismentor: '',
        lookingformentor: ''
    })
    const menudata = [
        { icon: personalInfoIcon, menuName: 'Personal', value: 'personal' },
        { icon: careerInfo, menuName: 'Career', value: 'career' },
        { icon: educationIcon, menuName: 'Education', value: 'education' },
        { icon: locationIcon, menuName: 'Regional/Location', value: 'regional' },
        { icon: memberofferingIcon, menuName: 'Member Offerings', value: 'memberoffering' },
    ];
    useEffect(() => {
        configurationData = JSON.parse(localStorage.getItem('configurationData'));
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                apiUrl + 'members/comserviceoffering/' + localStorage.getItem('memberID'),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.data.length > 0) {
                let memtorshipOfferingData = response.data[0];
                setFormData({
                    ...formData,
                    memberuuid: memtorshipOfferingData.memberuuid || localStorage.getItem('memberID'),
                    ismentor: memtorshipOfferingData.ismentor || null,
                    lookingformentor: memtorshipOfferingData.lookingformentor || null
                });

            } else {
                setFormData({
                    ...formData,
                    memberuuid: localStorage.getItem('memberID'),
                    ismentor: null,
                    lookingformentor: null
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleLinkClick = (link) => {
        setActiveLink(link);
        setVisibility(link);
    }
    const menuslider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6, // Show 5 items at a time
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const handleSubmit = async () => {
        try {
            const res = await axios.post(`${apiUrl}members/comserviceoffering`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            fetchData();
            setSuccess({
                title: 'SUCCESS!',
                message: res.data.message,
            });
        } catch (err) {

            setError({
                title: 'An Error Occurred!',
                message: err.response.data.message,
            });
        }
    };
    const errorHandler = () => {
        setError(null);
    };

    const successHandler = () => {
        setSuccess(null);
    };
    const handleReset = () => {
        setFormData({
            ...formData,
            memberuuid: '',
            ismentor: '',
            lookingformentor: '',
        });
    };
    return (

        <React.Fragment>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            <Profilecard />
            <Container className="mt-5">
                <section className={`section_user_home_page ${(configurationData.memberstatuscode === 3 || configurationData.memberstatuscode === 0) ? 'pointer_event_none' : ''}`} >
                    <Container>
                        <Row>
                            <Col lg="12" className='Col_12'>

                                <Row>
                                    <Col md={12} xs={12} className="inner_row ">
                                        <Row >
                                            <div className='w-5/6 m-auto'>
                                                <div className='mt-20 pb-3'>
                                                    <Slider {...menuslider} className="overflow-hidden menu_slider ">
                                                        {(menudata || []).map((item, key) => (
                                                            <div className=' list-unstyled profession_ul text-center' key={key}>
                                                                <div
                                                                    style={{ padding: `5px 10px 5px 5px` }}
                                                                    className={`text-center profession_li mt-1 ${activeLink === item.value ? 'active' : ''
                                                                        }`}
                                                                    onClick={() => handleLinkClick(item.value)}>
                                                                    <img src={item.icon} className='center' style={{ width: `80px`, height: `60px` }} />
                                                                </div>
                                                                <p className="filter_title fw-normal m-auto" style={{ fontSize: `16px` }}><span className={` ${activeLink === item.value ? 'active_text' : ''}`} >{item.menuName}</span></p>
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </div>
                                            </div>
                                        </Row>
                                    </Col>
                                    {isVisible == 'personal' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            {' '}
                                            <MMpersonalDetails />
                                        </Col>
                                    )}
                                    {isVisible == 'career' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            <CareerDetails />
                                        </Col>
                                    )}
                                    {isVisible == 'education' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            <EducationDetails />
                                        </Col>
                                    )}
                                    {isVisible == 'regional' && (
                                        <Col md={12} xs={12}>
                                            <RegionalDetails />
                                        </Col>
                                    )}
                                    {isVisible == 'memberoffering' && (
                                        <Col md={12} xs={12}>
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit();
                                                }}
                                            >
                                                <Row className="inner_row">
                                                    <h6 className="title fw-bold  text-center title_bar">
                                                        Member Offerings
                                                    </h6>
                                                    <Col md={12} className='mt-3'>
                                                        <img src={presentIcon} className='legendIcon' style={{ width: `40px` }} />
                                                        &nbsp; <span className="m-2 legendName" >Mentorship</span>
                                                        <Row className="mt-3">
                                                            <Col md={6} sm={12}>
                                                                <div className=" features feature-primary p-1">
                                                                    <div className="mb-3">
                                                                        <Label className="form-label label_text">
                                                                            Are You Want to be a Mentor?

                                                                        </Label>

                                                                        <section className="radio-section">
                                                                            <div className="radio-list">
                                                                                <div className="radio-item">
                                                                                    <input name="mentorradio" id="radioyesmentor" type="radio" value="true" checked={formData.ismentor == true} onChange={event => setFormData({ ...formData, ismentor: true })} />
                                                                                    <label htmlFor="radioyesmentor">Yes</label>
                                                                                </div>
                                                                                <div className="radio-item">
                                                                                    <input
                                                                                        name="mentorradio" id="radionomentor" type="radio" value="false" checked={formData.ismentor == false} onChange={event => setFormData({ ...formData, ismentor: false })} />
                                                                                    <label htmlFor="radionomentor">No</label>
                                                                                </div>
                                                                            </div>
                                                                        </section>

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={6} sm={12}>
                                                                <div className=" features feature-primary p-1">
                                                                    <div className="mb-3">
                                                                        <Label className="form-label label_text"> Looking For Mentorship?   </Label>

                                                                        <section className="radio-section">
                                                                            <div className="radio-list">
                                                                                <div className="radio-item">
                                                                                    <input name="mentorshipradio" id="radioyesmentorship" type="radio" value="true" checked={formData.lookingformentor === true} onChange={event => setFormData({ ...formData, lookingformentor: true })} /> <label htmlFor="radioyesmentorship">Yes</label>
                                                                                </div>
                                                                                <div className="radio-item">
                                                                                    <input name="mentorshipradio" id="radionomentorship" type="radio" value="false"
                                                                                        checked={formData.lookingformentor === false}
                                                                                        onChange={event => setFormData({ ...formData, lookingformentor: false })}
                                                                                    />
                                                                                    <label htmlFor="radionomentorship">No</label>
                                                                                </div>
                                                                            </div>
                                                                        </section>

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr style={{ boxShadow: `0px 1px 1px` }} />

                                                    </Col>
                                                    <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary mt-2 "
                                                            style={{ background: `#2f55d4` }}
                                                        >
                                                            {' '}
                                                            Save Changes{' '}
                                                        </button>{' '}
                                                        <button
                                                            onClick={handleReset}
                                                            className="btn btn-outline-primary  mt-2 ms-2"
                                                            style={{ background: `none`, color: `#2f55d4` }}
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                </Row>
                                            </Form>
                                        </Col>
                                    )}

                                </Row>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>

        </React.Fragment>
    );
};
export default ConfigureCommunityMFunction;