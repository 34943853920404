import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import {
  Container,
  Row,
  Col,
  Progress,
  Card,
  CardBody,
  Badge,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal';
import apiUrl from '../../server';
import '../../Home/css/userHomePage.css';
import noofsiblingIcon from '../../../assets/images/MettaMateImages/children.png';
import positionofsiblingIcon from '../../../assets/images/MettaMateImages/positionsibling.png';
import GuardianIcon from '../../../assets/images/MettaMateImages/happy.png';
import FamilytypeIcon from '../../../assets/images/MettaMateImages/family.png';
import geneticIcon from '../../../assets/images/MettaMateImages/dna.png';

const FamilyBackgroundFunction = () => {
  const [familyTypeList, setFamilyTypeList] = useState([]);
  const [yesNoList, setyesNoList] = useState([]);
  const [noOfSiblingList, setNoOfSublingList] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])

  const [familyBFGPreferenceFormData, setFamilyBFGPreferenceFormData] = useState({
    memberuuid: localStorage.getItem("memberID"),
    familytypes: [],
    motherrequired: 0,
    fatherrequired: 0,
    numofsiblings: 0,
    geneticdisease: 0
  });



  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [familyTypeRes, yesNoRes] = await Promise.all([
          axios.get(`${apiUrl}codes/familytype`),
          axios.get(`${apiUrl}codes/yesno`),
        ]);
        setFamilyTypeList(familyTypeRes.data.list);
        yesNoRes.data.list.push({ label: "Dont Care", value: 100 })
        setyesNoList(yesNoRes.data.list);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    fetchFamilyBGData();
  }, []);


  const fetchFamilyBGData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/familybg/' + localStorage.getItem('memberID'),
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {

        let familyBGData = response.data[0]
        setFamilyBFGPreferenceFormData({
          memberuuid: localStorage.getItem("memberID"),
          familytypes: familyBGData.familytypes ? familyBGData.familytypes : [],
          motherrequired: familyBGData.motherrequired ? familyBGData.motherrequired : 0,
          fatherrequired: familyBGData.fatherrequired ? familyBGData.fatherrequired : 0,
          numofsiblings: familyBGData.numofsiblings ? familyBGData.numofsiblings : 0,
          geneticdisease: familyBGData.geneticdisease ? familyBGData.geneticdisease : 0
        });
      } else {
        setFamilyBFGPreferenceFormData({
          memberuuid: localStorage.getItem("memberID"),
          familytypes: [],
          motherrequired: 0,
          fatherrequired: 0,
          numofsiblings: 0,
          geneticdisease: 0
        })

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlefamilyType = (checkedFlag, familyTypeObject) => {
    let selectedFamilyType = familyBFGPreferenceFormData.familytypes;
    if (checkedFlag) {
      if (!selectedFamilyType.includes(familyTypeObject.value)) {
        selectedFamilyType.push(familyTypeObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedFamilyType.includes(familyTypeObject.value)) {
        selectedFamilyType.splice(selectedFamilyType.findIndex(x => familyTypeObject.value === x), 1);
      }
    }

    setFamilyBFGPreferenceFormData({ ...familyBFGPreferenceFormData, familytypes: selectedFamilyType });
  };


  // const handlePrevReligion = (checkedFlag, prevReligionObject) => {
  //   let selectedPrevReligion = spiritualPreferenceFormData.previousreligion;
  //   if (checkedFlag) {
  //     if (!selectedPrevReligion.includes(prevReligionObject.value)) {
  //       selectedPrevReligion.push(prevReligionObject.value);
  //     }
  //   }
  //   if (!checkedFlag) {
  //     if (selectedPrevReligion.includes(prevReligionObject.value)) {
  //       selectedPrevReligion.splice(selectedPrevReligion.findIndex(x => prevReligionObject.value === x), 1);
  //     }
  //   }
  //   setFamilyBFGPreferenceFormData({ ...familyBFGPreferenceFormData, previousreligion: selectedPrevReligion });
  // };


  // const handleCaste = (checkedFlag, casteObject) => {
  //   let selectedCaste = spiritualPreferenceFormData.caste;
  //   if (checkedFlag) {
  //     if (!selectedCaste.includes(casteObject.value)) {
  //       selectedCaste.push(casteObject.value);
  //     }
  //   }
  //   if (!checkedFlag) {
  //     if (selectedCaste.includes(casteObject.value)) {
  //       selectedCaste.splice(selectedCaste.findIndex(x => casteObject.value === x), 1);
  //     }
  //   }
  //   setSpiritualPrefenerceFormData({ ...spiritualPreferenceFormData, caste: selectedCaste });
  // };

  const handleSubmit = async () => {
    setIsLoading(true);
    let result = await axios.post(`${apiUrl}preference/familybg`, familyBFGPreferenceFormData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json"
      },
    }).then((res) => {
      fetchFamilyBGData();
      setIsLoading(false);
      setSuccess({
        title: 'SUCCESS!',
        message: res.data.message,
      });
    }).catch((err) => {
      setIsLoading(false);
      setError({
        title: 'An Error Occured!',
        message: err.response.data.message,
      });
    });
  }


  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setSpritualData([]);
    // setCurrentReligion([]);
    // setCaste({});
  };
  const LoaderComponent = () => (
    <div id="preloader">
        <div id="status">
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>
    </div>
);

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {
        isLoading ? <LoaderComponent/> :
        (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          // validation.handleSubmit();
          handleSubmit()
        }}
      >
        <Row className="inner_row mt-1">
          <h6 className="title  title_bar">
            Family Background Preference
          </h6>

          <Col md={12} xs={12}>
          <img src={FamilytypeIcon} className='legendIcon' style={{ width: `40px` }} />
            &nbsp; <span className="m-2 legendName" >Family</span>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Preferred Family Types</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    {(familyTypeList || []).map((response, index) => (
                      <div className="form-check form-check-inline" key={index}>
                        <li>
                          <div className="mb-0">
                            <div className="form-check p-0">
                              <Input name='familytype' type="checkbox"
                                id={response.label + "familytype"}
                                checked={familyBFGPreferenceFormData.familytypes.includes(response.value)}
                                onChange={event => handlefamilyType(event.target.checked, response)}
                              />
                              <Label className="form-check-label" htmlFor={response.label + "familytype"}>{response.label}  </Label>
                            </div>
                          </div>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ boxShadow: `0px 1px 1px` }} />
          </Col>



          <Col md={12} xs={12}>
          <img src={GuardianIcon} className='legendIcon' style={{ width: `40px` }} />
            &nbsp; <span className="m-2 legendName" >Guardian</span>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Father Required !</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    <section className="radio-section">
                      <div className="radio-list">
                        {(yesNoList || []).map((response, index) => (

                          <div className="form-check form-check-inline" key={index}>
                            <div className="radio-item ">
                              <Input name={"father"} id={"father" + index} type="radio"
                                checked={response.value == familyBFGPreferenceFormData.fatherrequired}
                                onChange={event => setFamilyBFGPreferenceFormData({ ...familyBFGPreferenceFormData, fatherrequired: response.value })}
                              />
                              <label htmlFor={"father" + index}>{response.label}</label>
                            </div>
                          </div>
                        ))}

                      </div>
                    </section>
                  </ul>
                </div>
              </div>
            </div>
            {/* <hr style={{ boxShadow: `0px 1px 1px` }} /> */}
          </Col>

          <Col md={12} xs={12}>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Mother Required !</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    <section className="radio-section">
                      <div className="radio-list">
                        {(yesNoList || []).map((response, index) => (

                          <div className="form-check form-check-inline" key={index}>
                            <div className="radio-item ">
                              <Input name={"mother"} id={"mother" + index} type="radio"
                                checked={response.value == familyBFGPreferenceFormData.motherrequired}
                                onChange={event => setFamilyBFGPreferenceFormData({ ...familyBFGPreferenceFormData, motherrequired: response.value })}
                              />
                              <label htmlFor={"mother" + index}>{response.label}</label>
                            </div>
                          </div>
                        ))}

                      </div>
                    </section>
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ boxShadow: `0px 1px 1px` }} />
          </Col>


          <Col md={12} xs={12}>
          <img src={noofsiblingIcon} className='legendIcon' style={{ width: `40px` }} />
            &nbsp; <span className="m-2 legendName" >No. Of Sibling</span>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">No Of Sibling</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    <section className="radio-section">
                      <div className="radio-list">
                        {(noOfSiblingList || []).map((response, index) => (

                          <div className="form-check form-check-inline" key={index}>
                            <div className="radio-item ">
                              <Input name={"sibling"} id={"sibling" + index} type="radio"
                                checked={response == familyBFGPreferenceFormData.numofsiblings}
                                onChange={event => setFamilyBFGPreferenceFormData({ ...familyBFGPreferenceFormData, numofsiblings: response })}
                              />
                              <label htmlFor={"sibling" + index}>{response}</label>
                            </div>
                          </div>
                        ))}

                      </div>
                    </section>
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ boxShadow: `0px 1px 1px` }} />
          </Col>


          <Col md={12} xs={12}>
          <img src={geneticIcon} className='legendIcon' style={{ width: `40px` }} />
            &nbsp; <span className="m-2 legendName">Genes</span>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Genetic Disease !</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    <section className="radio-section">
                      <div className="radio-list">
                        {(yesNoList || []).map((response, index) => (
                          <div className="form-check form-check-inline" key={index}>
                            <div className="radio-item ">
                               <Input name="geneticdisease" id={"geneticdisease" + index} type="radio"
                                 checked={response.value == familyBFGPreferenceFormData.geneticdisease}
                                 onChange={event => setFamilyBFGPreferenceFormData({ ...familyBFGPreferenceFormData, geneticdisease: response.value })}
                               />
                               <label htmlFor={"geneticdisease" + index}>{response.label}</label>
                             </div>
                          </div>
                        ))}
                      </div>
                    </section>
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ boxShadow: `0px 1px 1px` }} />
          </Col>


          <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
            <button
              type="submit"
              className="btn btn-outline-primary mt-2 "
             
            >
              {' '}
              Save Changes{' '}
            </button>{' '}
            <button
              onClick={handleReset}
              className="btn btn-outline-secondary mt-2 ms-2"
            >
              Reset
            </button>
          </div>
        </Row>
      </Form>
        )
      }
    </React.Fragment>
  );
};
export default FamilyBackgroundFunction;
