import React, { useEffect, useState,Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  ModalBody, UncontrolledCarousel, UncontrolledCollapse, Modal
} from 'reactstrap'; import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Home/css/userHomePage.css';
import FeatherIcon from 'feather-icons-react';

import axios from 'axios';
import apiUrl from '../server';
import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg'
import candidate1 from '../../assets/images/MettaMateImages/female_avatar.jpeg'

import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';
import birthcake from '../../assets/images/MettaMateImages/birthday-cake.svg'
import location from '../../assets/images/MettaMateImages/carbon_location-filled.svg';
import heightimg from '../../assets/images/MettaMateImages/game-icons_body-height.svg';
import weightimg from '../../assets/images/MettaMateImages/fa6-solid_weight-scale.svg';
import cityimg from '../../assets/images/MettaMateImages/healthicons_city.svg';
import callimg from '../../assets/images/MettaMateImages/mingcute_phone-fill.svg';
import mailimg from '../../assets/images/MettaMateImages/material-symbols_mail-outline.svg';
import complexionimg from '../../assets/images/MettaMateImages/carbon_face-add.svg';
import bloodgroupimg from '../../assets/images/MettaMateImages/maki_blood-bank.svg';
import hairimg from '../../assets/images/MettaMateImages/mingcute_hair-fill.svg';
import eyeimg from '../../assets/images/MettaMateImages/icomoon-free_eye.svg';
import degreenameimg from '../../assets/images/MettaMateImages/icon-park-outline_degree-hat.svg';
import collegeimg from '../../assets/images/MettaMateImages/college.svg';
import professionimg from '../../assets/images/MettaMateImages/profession.svg';
import companyimg from '../../assets/images/MettaMateImages/company.svg';
import moneyimg from '../../assets/images/MettaMateImages/game-icons_money-stack.svg';
import nonvegimg from '../../assets/images/MettaMateImages/circum_wheat.svg';
import smokeimg from '../../assets/images/MettaMateImages/ic_outline-smoke-free.svg';
import drinkimg from '../../assets/images/MettaMateImages/carbon_drink-01.svg';
import excerciseimg from '../../assets/images/MettaMateImages/flat-color-icons_sports-mode.svg';
import gamesimg from '../../assets/images/MettaMateImages/uil_game.svg';
import hobbiesimg from '../../assets/images/MettaMateImages/hobbies.svg';
import petimg from '../../assets/images/MettaMateImages/streamline_pet-paw-solid.svg';
import ngoimg from '../../assets/images/MettaMateImages/ngo.svg';
import casteimg from '../../assets/images/MettaMateImages/caste.svg';
import meditationimg from '../../assets/images/MettaMateImages/healthicons_exercise-yoga.svg';
import familyimg from '../../assets/images/MettaMateImages/emojione-monotone_family.svg';
import fatherimg from '../../assets/images/MettaMateImages/father.svg';
import motherimg from '../../assets/images/MettaMateImages/mother.svg';
import siblingsimg from '../../assets/images/MettaMateImages/noofsibling.svg';
import positionsiblingimg from '../../assets/images/MettaMateImages/family.svg';
import religionimg from '../../assets/images/MettaMateImages/religion.svg';
import dnaimg from '../../assets/images/MettaMateImages/ph_dna.svg';




  const partnerExpectationsDetails = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/fullpreference/' + props.memberData.memberuuid,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {
        setPartnerDetailsData(response.data[0])
      } else {

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const getImage = (imagepath, gender) => {
    if (imagepath) {
      return imagepath
    }
    else {
      if (gender == 'Male') {
        return manavatar
      }
      else if (gender == 'Female') {
        return femaleavatar
      }
      else if (gender == 'Other') {
        return
      }
    }
  }
  const calculate_age = (dob) => {
    if (dob != null) {
      var today = new Date();
      var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return age_now ? age_now : '00';
    }
    else {
      return '00';
    }
  }
  const getPronoun = (gender) => {
    if (gender == 1) {
      return 'His'
    }
    else if (gender == 2) {
      return 'Her'
    }
    else {
      return ''
    }
  }

class PageProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: 'Landrick', link: '/' },
        { id: 2, name: 'Page', link: '#' },
        { id: 3, name: 'Account', link: '/page-profile' },
        { id: 4, name: 'Setting' },
      ],
      widgets: [
        {
          id: 1,
          icon: 'uil uil-dashboard',
          className: 'navbar-item account-menu px-0',
          title: 'Profile',
          link: '/page-profile',
        },
        {
          id: 2,
          icon: 'uil uil-users-alt',
          className: 'navbar-item account-menu px-0 mt-2',
          title: 'Members',
          link: '/page-members',
        },
        {
          id: 3,
          icon: 'uil uil-file',
          className: 'navbar-item account-menu px-0 mt-2',
          title: 'Portfolio',
          link: '/page-works',
        },
        {
          id: 4,
          icon: 'uil uil-comment',
          className: 'navbar-item account-menu px-0 mt-2',
          title: 'Chat',
          link: '/page-chat',
        },
        {
          id: 5,
          icon: 'uil uil-envelope-star',
          className: 'navbar-item account-menu px-0 mt-2',
          title: 'Messages',
          link: '/page-messages',
        },
        {
          id: 6,
          icon: 'uil uil-transaction',
          className: 'navbar-item account-menu px-0 mt-2',
          title: 'Payments',
          link: '/page-payments',
        },
        {
          id: 7,
          icon: 'uil uil-setting',
          className: 'navbar-item account-menu px-0 mt-2 active',
          title: 'Settings',
          link: '/page-profile-edit',
        },
        {
          id: 8,
          icon: 'uil uil-dashboard',
          className: 'navbar-item account-menu px-0 mt-2',
          title: 'Logout',
          link: '/auth-login-three',
        },
      ],
      successMsg: false,
      successMsg2: false,
      successMsg3: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ successMsg: true });
  }

  handleSubmit2(event) {
    event.preventDefault();
    this.setState({ successMsg2: true });
  }

  handleSubmit3(event) {
    event.preventDefault();
    this.setState({ successMsg3: true });
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelectorAll('#buyButton').forEach((navLink) => {
      navLink.classList.add('btn-light');
      navLink.classList.remove('btn-soft-primary');
      document.getElementById('top-menu')?.classList.add('nav-light');
    });
    window.addEventListener('scroll', this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
  };

  onChangeCheckbox = (selected, contact) => {
    let modifiedselectedContacts = [...this.state.selectedContacts];
    if (selected) {
      modifiedselectedContacts.push(contact);
    }
    this.setState({
      selectedContacts: modifiedselectedContacts,
    });
  };

  handleNewImage = (e) => {
    this.setState({ image: e.target.files[0] });
  };

  handlePositionChange = (position) => {
    this.setState({ position });
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}

        <section className="section mt-60">
          <Container className="mt-lg-3">
            <Row className="justify-content-center">
              <Col lg="8" xs="12">
                <Card className="border-1 rounded shadow">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col lg="4" md="6" className="text-md-start text-center">
                        <div className="position-relative">
                          <CardImg
                            top
                            src={candidate1}
                            className="border-1 rounded shadow"
                            alt=""
                            style={{
                              maxHeight: '100%',
                              maxWidth: '100%',
                              objectFit: 'contain',
                            }}
                          />
                          <div className="overlay rounded-top bg-dark"></div>
                        </div>
                      </Col>

                      <Col lg="8" md="6">
                        <Row className="align-items-end">
                          <Col
                            md="7"
                            className="text-md-start text-center mt-4 mt-sm-0"
                          >
                            <h3 className="title mb-0">Snehalata Kamble</h3>
                            <small className="text-muted h6 me-2">
                              Professor
                            </small>
                           
                          </Col>
                          <Col md="5" className="text-md-end text-center">
                            <ul className="list-unstyled social-icon social mb-0 mt-4">
                              <li className="list-inline-item">
                                <Link to="#" className="rounded">
                                  <i className="uil uil-user-plus align-middle"></i>
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link to="#" className="rounded">
                                  <i className="uil uil-comment align-middle"></i>
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link to="#" className="rounded">
                                  <i className="uil uil-bell align-middle"></i>
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link
                                  to="/page-profile-edit"
                                  className="rounded"
                                >
                                  <i className="uil uil-cog align-middle"></i>
                                </Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className="rounded shadow mt-4">
                  <div className="p-4 border-bottom">
                    <h5 className="mb-0">Account Notifications :</h5>
                  </div>

                  <div className="p-4">
                    <div className="d-flex justify-content-between pb-4">
                      <h6 className="mb-0">When someone mentions me</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch1"
                        ></label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-4 border-top">
                      <h6 className="mb-0">When someone follows me</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch2"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch2"
                        ></label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-4 border-top">
                      <h6 className="mb-0">When shares my activity</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch3"
                        ></label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-4 border-top">
                      <h6 className="mb-0">When someone messages me</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch4"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch4"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded shadow mt-4">
                  <div className="p-4 border-bottom">
                    <h5 className="mb-0">Marketing Notifications :</h5>
                  </div>

                  <div className="p-4">
                    <div className="d-flex justify-content-between pb-4">
                      <h6 className="mb-0">There is a sale or promotion</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch5"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch5"
                        ></label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-4 border-top">
                      <h6 className="mb-0">Company news</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch6"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch6"
                        ></label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-4 border-top">
                      <h6 className="mb-0">Weekly jobs</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch7"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch7"
                        ></label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-4 border-top">
                      <h6 className="mb-0">Unsubscribe News</h6>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch8"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch8"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded shadow mt-4">
                  <div className="p-4 border-bottom">
                    <h5 className="mb-0 text-danger">Delete Account :</h5>
                  </div>

                  <div className="p-4">
                    <h6 className="mb-0">
                      Do you want to delete the account? Please press below
                      "Delete" button
                    </h6>
                    <div className="mt-4">
                      <button className="btn btn-danger">Delete Account</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="6" xs="12" className="d-lg-block d-none">
                <div className="sidebar sticky-bar p-4 rounded shadow">
                  <div className="widget">
                    <h5 className="widget-title">Followers :</h5>
                    <div className="row mt-4">
                      <div className="col-6 text-center">
                        <FeatherIcon
                          icon="user-plus"
                          className="fea icon-ex-md text-primary mb-1"
                        />
                        <h5 className="mb-0">2588</h5>
                        <p className="text-muted mb-0">Followers</p>
                      </div>

                      <div className="col-6 text-center">
                        <FeatherIcon
                          icon="users"
                          className="fea icon-ex-md text-primary mb-1"
                        />
                        <h5 className="mb-0">454</h5>
                        <p className="text-muted mb-0">Following</p>
                      </div>
                    </div>
                  </div>
                  <div className="widget mt-4 pt-2">
                    <h5 className="widget-title">Projects :</h5>
                    <div className="progress-box mt-4">
                      <h6 className="title text-muted">Progress</h6>
                      {/* <Progress
                        value={50}
                        color="primary"
                        barClassName="position-relative"
                      >
                        <div className="progress-value d-block text-muted h6">
                          24 / 48
                        </div>
                      </Progress> */}
                    </div>
                  </div>

                  <div className="widget mt-4">
                    <ul
                      className="list-unstyled sidebar-nav mb-0"
                      id="navmenu-nav"
                    >
                      {this.state.widgets.map((widget, key) => (
                        <li className={widget.className} key={key}>
                          <Link
                            to={widget.link}
                            className="navbar-link d-flex rounded shadow align-items-center py-2 px-4"
                          >
                            <span className="h4 mb-0">
                              <i className={widget.icon}></i>
                            </span>
                            <h6 className="mb-0 ms-2">{widget.title}</h6>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="widget mt-4 pt-2">
                    <h5 className="widget-title">Follow me :</h5>
                    <ul className="list-unstyled social-icon social mb-0 mt-4">
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="github"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="youtube"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="gitlab"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default PageProfileEdit;
