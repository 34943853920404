// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, CardBody, Card } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

class PagePrivacy extends Component {
  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
        document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
        document
          .querySelector('.settingbtn')
          ?.classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <section
          className="section"
          style={{ padding: '50px', paddingBottom: '10px' }}
        >
          {/* <Container> */}
          <Row className="mt-5 justify-content-center">
            {/* <Col lg={12} className="text-center"> */}
            <div className="pages-heading">
              <h4 className="title text-center"> Privacy Policy </h4>
              {/* <ul className="list-unstyled mt-4 mb-0">
                                <li className="list-inline-item h6 date text-muted"> <span className="text-dark">Last Revised :</span> 23th Sep, 2021</li>
                            </ul> */}
            </div>
            {/* </Col>   */}
          </Row>
        </section>

        <section className="section" style={{ padding: '50px' }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="card shadow rounded border-0">
                  <CardBody>
                    <h5 className="card-title ">
                      Privacy Policy for MettaMate App :
                    </h5>
                    <p className="text-muted fontSize16">
                      At MettaMate, we are committed to protecting the privacy
                      and security of our users. This Privacy Policy outlines
                      how we collect, use, and safeguard your personal
                      information when you use our matrimony and community app.
                    </p>
                    {/* <p className="text-muted fontSize16">
                      In the 1960s, the text suddenly became known beyond the
                      professional circle of typesetters and layout designers
                      when it was used for Letraset sheets (adhesive letters on
                      transparent film, popular until the 1980s) Versions of the
                      text were subsequently included in DTP programmes such as
                      PageMaker etc.
                    </p>
                    <p className="text-muted fontSize16">
                      There is now an abundance of readable dummy texts. These
                      are usually used when a text is required purely to fill a
                      space. These alternatives to the classic Lorem Ipsum texts
                      are often amusing and tell short, funny or nonsensical
                      stories.
                    </p> */}

                    <h5 className="card-title">1. Information We Collect:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - When you register for an account, we may collect
                        personal information such as your name, email address,
                        phone number, date of birth, gender, location, and
                        preferences.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - We may also collect information about your
                        interactions with other users, messages, photos, and any
                        other content you share on the app.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 "
                          />
                        </i>
                        - We may collect data on your device and usage patterns,
                        such as IP address, device type, operating system, and
                        app usage metrics.
                      </li>
                    </ul>
                    <h5 className="card-title">
                      2. How We Use Your Information:
                    </h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - We use the information we collect to provide and
                        improve our services, personalize your experience, match
                        you with potential partners, and facilitate
                        communication between users.{' '}
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - Your information may be used for analytics, research,
                        and to enhance the security and integrity of the app.{' '}
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 "
                          />
                        </i>
                        - We may use your contact information to send you
                        notifications, updates, and promotional messages related
                        to our services.{' '}
                      </li>
                    </ul>
                    <h5 className="card-title">
                      3. Data Sharing and Security:
                    </h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - We do not sell, rent, or share your personal
                        information with third parties for marketing purposes.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - We may share your information with service providers
                        who assist us in delivering our services, but only to
                        the extent necessary.{' '}
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 "
                          />
                        </i>
                        - We implement security measures to protect your data
                        from unauthorized access, disclosure, alteration, or
                        destruction.{' '}
                      </li>
                    </ul>
                    <h5 className="card-title">4. User Choices and Rights:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - You can update, modify, or delete your personal
                        information through your account settings.{' '}
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - You have the right to request access to the personal
                        information we hold about you and request its deletion,
                        subject to legal requirements.{' '}
                      </li>
                    </ul>
                    <h5 className="card-title">5. Children's Privacy:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - Our app is not intended for users under the age of 18.
                        We do not knowingly collect or store information from
                        minors.{' '}
                      </li>
                    </ul>
                    <h5 className="card-title">6. Changes to Privacy Policy</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        - We reserve the right to update and modify this Privacy
                        Policy. Any changes will be communicated to you through
                        the app or our website.
                      </li>
                    </ul>
                    {/* <h5 className="card-title">
                      Information Provided Voluntarily :
                    </h5> */}
                    <p className="text-muted fontSize16">
                      By using MettaMate, you consent to the collection and use
                      of your information as described in this Privacy Policy.
                      If you have any questions or concerns about our privacy
                      practices, please contact us at
                      [info@mettamate.ai](mailto:info@mettamate.ai).
                    </p>
                    <p className="text-muted fontSize16">This Privacy Policy is effective as of [Date] and replaces any previous versions.</p>
                    <p className="text-muted fontSize16">Thank you for trusting MettaMate with your information.</p>
                    {/* <Link to="#" className="btn btn-soft-primary d-print-none">
                      Print
                    </Link> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PagePrivacy;
