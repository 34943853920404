import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CommonModal from '../modal';
import apiUrl from '../../server';
import ImgCrop from 'antd-img-crop';
import { Button, Upload, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import galleryPhoto from '../../../assets/images/MettaMateImages/gallery.png';
import imgIcon from '../../../assets/images/MettaMateImages/background.png';
import FeatherIcon from 'feather-icons-react';
import serviceIcon from '../../../assets/images/MettaMateImages/service.png'
import {
    Row,
    Col,
    Label,
    Input,
    Form,
} from 'reactstrap';

const ServiceInput = ({ formData, setSbFormData }) => {
    // return (
    //     <Row className="mt-3">
    //         <Col md={6}>
    //             <div className="features feature-primary p-1">
    //                 <div className="mb-3">
    //                     <div className="form-icon position-relative">
    //                         <i>
    //                             <FeatherIcon
    //                                 icon="settings"
    //                                 className="fea icon-sm icons"
    //                             />
    //                         </i>
    //                     </div>
    //                     <Input
    //                         type="text"
    //                         required
    //                         className="form-control ps-5 form_Input"
    //                         placeholder="Service Name"
    //                         name="servicename"
    //                         id="serviceName"
    //                         onChange={event => setSbFormData({ ...formData, servicename: event.target.value })}
    //                         value={formData.servicename || ''}
    //                     />
    //                 </div>
    //             </div>
    //         </Col>

    //         <Col md={6}>
    //             <div className="features feature-primary p-1">
    //                 <div className="mb-3">
    //                     <div className="form-icon position-relative">
    //                         <i>
    //                             <FeatherIcon
    //                                 icon="settings"
    //                                 className="fea icon-sm icons"
    //                             />
    //                         </i>
    //                     </div>
    //                     <Input
    //                         type="text"
    //                         className="form-control form_Input ps-5"
    //                         placeholder="Service Description"
    //                         name="serviceDesc"
    //                         required
    //                         id="serviceDesc"
    //                         onChange={event => setSbFormData({ ...formData, servicedescription: event.target.value })}
    //                         value={formData.servicedescription || ''}
    //                     />
    //                 </div>
    //             </div>
    //         </Col>
    //     </Row>
    // );
};
const SBServiceOfferingFunction = () => {
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);
    const [file3, setFile3] = useState([]);
    const [imageIndex, setImageIndex] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [previewmodalVisible, previewsetModalVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileToRemove, setFileToRemove] = useState(null);
    // const [serviceSets, setServiceSets] = useState([{}]);
    const [sbformData, setSbFormData] = useState({
        serviceofferinguuid: '',
        memberuuid: localStorage.getItem('memberID'),
        servicename: '',
        servicedescription: '',
        photopath1: '',
        photopath2: '',
        photopath3: ''
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                apiUrl + 'members/sbpartnerservice/' + localStorage.getItem('memberID'),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.data.length > 0) {
                let serviceOfferingData = response.data[0];
                setSbFormData({
                    ...sbformData,
                    serviceofferinguuid: serviceOfferingData.serviceofferinguuid || '',
                    memberuuid: serviceOfferingData.memberuuid || localStorage.getItem('memberID'),
                    servicename: serviceOfferingData.servicename || '',
                    servicedescription: serviceOfferingData.servicedescription || '',
                    photopath1: serviceOfferingData.photopath1 || '',
                    photopath2: serviceOfferingData.photopath2 || '',
                    photopath3: serviceOfferingData.photopath3 || '',
                });
                let fileurl1 = serviceOfferingData.photopath1
                ? await getPhotoUrlDetails(serviceOfferingData.photopath1)
                : undefined;
            let fileurl2 = serviceOfferingData.photopath2
                ? await getPhotoUrlDetails(serviceOfferingData.photopath2)
                : undefined;
            let fileurl3 = serviceOfferingData.photopath3
                ? await getPhotoUrlDetails(serviceOfferingData.photopath3)
                : undefined;
                if (fileurl1) {
                    setFile1((prevList) => [
                        ...prevList,
                        {
                            name: serviceOfferingData.photopath1,
                            url: fileurl1,
                        },
                    ]);
                } else {
                    setFile1([]);
                }
                if (fileurl2) {
                    setFile2((prevList) => [
                        ...prevList,
                        {
                            name: serviceOfferingData.photopath2,
                            url: fileurl2,
                        },
                    ]);
                } else {
                    setFile2([]);
                }
                if (fileurl3) {
                    setFile3((prevList) => [
                        ...prevList,
                        {
                            name: serviceOfferingData.photopath3,
                            url: fileurl3,
                        },
                    ]);
                } else {
                    setFile3([]);
                }
            } else {
                setSbFormData({
                    ...sbformData,
                    serviceofferinguuid: '',
                    memberuuid:  localStorage.getItem('memberID'),
                    servicename: '', // Ensure it's always defined
                    servicedescription: '',
                    photopath1: '',
                    photopath2:'',
                    photopath3:''
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getPhotoUrlDetails = async (fileName) => {
        try {
            const response = await axios.get(
                apiUrl + 'members/filepath/' + fileName,
                {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            return response.data.filepath;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleChange = (fileListSetter, imageIndex) => ({ fileList: newFileList }) => {
        fileListSetter(newFileList);
        setImageIndex(imageIndex);
    };
    const openSelectionform1 = () => {
        const fileInput = document.getElementById('image1');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const openSelectionform2 = () => {
        const fileInput = document.getElementById('image2');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const openSelectionform3 = () => {
        const fileInput = document.getElementById('image3');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const handleUploadImage = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('memberUUID', localStorage.getItem('memberID'));
        formData.append('imageindex', imageIndex);

        let response = await axios
            .post(apiUrl + 'members/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                },
            })
            .then((res) => {
                onSuccess();
                if (imageIndex == 3) {
                    setSbFormData({ ...sbformData, photopath1: res.data.filename });
                }
                else if (imageIndex == 4) {
                    setSbFormData({ ...sbformData, photopath2: res.data.filename })
                }
                else if (imageIndex == 5) {
                    setSbFormData({ ...sbformData, photopath3: res.data.filename })
                }
                setSuccess({
                    title: 'Upload Complete!',
                    message: 'Your Image has been uploaded Successfully!',
                });
            })
            .catch((err) => {
                onError();
                console.log(err)
                setError({
                    title: 'Upload Failed!',
                    message: err.response.message,
                });
            });
    };
    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    const onPreview = async (file) => {
        const src = file.url || (await getSrcFromFile(file));
        let originalFileUrl = await getPhotoUrlDetails(file.name.replace("_thumb", ""))
        setPreviewImage(originalFileUrl);
        previewsetModalVisible(true);
    };

    const showDeleteConfirm = async (file, index) => {
        setModalVisible(true);
        setFileToRemove(file);
        setImageIndex(index);
    };
    const handleOk = async () => {
        setModalVisible(false);
        let requestbody = {
            memberUUID: localStorage.getItem('memberID'),
            fileName: fileToRemove.name,
            index: imageIndex,
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(requestbody),
        };
        fetch(apiUrl + 'members/deleteImage', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message == 'OK') {
                    fetchconfigureDetails();
                    setSuccess({
                        title: 'Image Deleted!',
                        message: 'Your Image has been Deleted Successfully!',
                    });
                    if (imageIndex == 0) {
                        localStorage.setItem('profilePicture', 'undefined')
                    }
                } else {
                    setError({
                        title: 'An Error Occured!',
                        message: 'Your Image is not Deleted. Please Try Again',
                    });
                }
            });
        setFileToRemove(null);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setFileToRemove(null);
    };


    const handlepreviewOk = () => {
        setPreviewImage(''); // Clear the preview image when modal is closed
        setTimeout(() => { previewsetModalVisible(false); }, 50);
    };
    const handleSubmit = async () => {
        try {
            const res = await axios.post(`${apiUrl}members/sbpartnerservice`, sbformData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            fetchData();
            setSuccess({
                title: 'SUCCESS!',
                message: res.data.message,
            });
        } catch (err) {
            setError({
                title: 'An Error Occurred!',
                message: err.response.data.message,
            });
        }
    };
    const addServiceSet = () => {
        setServiceSets([...serviceSets, {}]); // Add a new service input set
    };
    const handleReset = () => {
        setSbFormData({
            ...sbformData,
            servicename: '', // Reset experience field
            servicedescription:'',
            photopath1:'',
            photopath2:'',
            photopath3:'',
            
        });
    };

    const errorHandler = () => {
        setError(null);
    };

    const successHandler = () => {
        setSuccess(null);
    };


    return (
        <>

            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <Row className="inner_row">
                    <h5 className="title  text-center title_bar">
                        Fill Your Personal Details
                    </h5>
                    <Col md={12} className='mt-3'>
                        <img src={serviceIcon} className='legendIcon' />
                        &nbsp; <span className="m-2 legendName" >Services</span>
                        {/* {serviceSets.map((_, index) => (
                            <ServiceInput key={index} formData={sbformData} setSbFormData={setSbFormData} />
                        ))} */}
                        <Row className="mt-3">
                            <Col md={6}>
                                <div className="features feature-primary p-1">
                                    <div className="mb-3">
                                        <div className="form-icon position-relative">
                                            <i>
                                                <FeatherIcon
                                                    icon="settings"
                                                    className="fea icon-sm icons"
                                                />
                                            </i>
                                        </div>
                                        <Input
                                            type="text"
                                            
                                            className="form-control ps-5 form_Input"
                                            placeholder="Service Name"
                                            name="servicename"
                                            id="serviceName"
                                            onChange={event => setSbFormData({ ...sbformData, servicename: event.target.value })}
                                            value={sbformData.servicename || ''}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="features feature-primary p-1">
                                    <div className="mb-3">
                                        <div className="form-icon position-relative">
                                            <i>
                                                <FeatherIcon
                                                    icon="settings"
                                                    className="fea icon-sm icons"
                                                />
                                            </i>
                                        </div>
                                        <Input
                                            type="text"
                                            className="form-control form_Input ps-5"
                                            placeholder="Service Description"
                                            name="serviceDesc"
                                            
                                            id="serviceDesc"
                                            onChange={event => setSbFormData({ ...sbformData, servicedescription: event.target.value })}
                                            value={sbformData.servicedescription || ''}
                                        />
                                    </div>
                                </div>
                              

                            </Col>

                        </Row>

                    </Col>
                    <Col md={12} className='text-right' style={{ display: `none` }}>
                        <button className="btn btn-transparent btn-outline-primary" onClick={addServiceSet}>Add More services</button>
                        <hr style={{ boxShadow: `0px 1px 1px` }} />

                    </Col>
                    <Col md={12}>
                        <img src={imgIcon} className='legendIcon' />
                        &nbsp; <span className="m-2 legendName" >Upload Photos </span>
                        <Row className="mt-3">

                            <Col md={4} xs={12}>
                                <div className=" features feature-primary py-0">
                                    <div className="mb-3 text-center">
                                        <Label className="form-label label_text ">
                                            Photo 1
                                        </Label>
                                        <div>
                                            <ImgCrop
                                                showGrid
                                                rotationSlider
                                                showReset remove={false}
                                                aspect={3 / 4}
                                            >
                                                <Upload id="image1"
                                                    listType="picture-card"
                                                    fileList={file1}
                                                    onChange={handleChange(setFile1, 3)}
                                                    onPreview={onPreview}
                                                    customRequest={(file) =>
                                                        handleUploadImage(file, 3)
                                                    }
                                                    onRemove={() => { showDeleteConfirm(file1[0], 3); }}

                                                >


                                                    {file1.length < 1 && (
                                                        <div>
                                                            <img src={galleryPhoto} />
                                                        </div>
                                                    )}
                                                </Upload>
                                            </ImgCrop>

                                        </div>
                                        <div className='text-center'>
                                            {file1.length < 1 && (
                                                <Button onClick={openSelectionform1} icon={<UploadOutlined />}>Upload</Button>
                                            )}
                                            {file1.length > 0 && (
                                                <Button onClick={() => { showDeleteConfirm(file1[0], 3) }} style={{ marginLeft: '5px' }}>Delete</Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} xs={12}>
                                <div className=" features feature-primary py-0">
                                    <div className="mb-3 text-center">
                                        <Label className="form-label label_text ">
                                            Photo 2
                                        </Label>
                                        <div >
                                            <ImgCrop
                                                showGrid
                                                rotationSlider
                                                aspect={3 / 4}
                                                showReset remove={false}
                                            >
                                                <Upload id="image2"
                                                    listType="picture-card"
                                                    fileList={file2}
                                                    onChange={handleChange(setFile2, 4)}
                                                    onPreview={onPreview}
                                                    customRequest={handleUploadImage}
                                                    onRemove={(file) =>
                                                        showDeleteConfirm(file, 4)
                                                    }
                                                >
                                                    {file2.length < 1 && (
                                                        <div>
                                                            <img src={galleryPhoto} />
                                                        </div>
                                                    )}
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                        <div className='text-center'>
                                            {file2.length < 1 && (
                                                <Button onClick={openSelectionform2} icon={<UploadOutlined />}>Upload</Button>
                                            )}
                                            {file2.length > 0 && (
                                                <Button onClick={() => { showDeleteConfirm(file2[0], 4); }} style={{ marginLeft: '5px' }}>Delete</Button>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </Col>
                            <Col md={4} xs={12}>
                                <div className=" features feature-primary py-0">
                                    <div className="mb-3 text-center">
                                        <Label className="form-label label_text ">
                                            Photo 3
                                        </Label>
                                        <div >
                                            <ImgCrop
                                                showGrid
                                                rotationSlider
                                                aspect={3 / 4}
                                                showReset remove={false}
                                            >
                                                <Upload id="image3"
                                                    listType="picture-card"
                                                    fileList={file3}
                                                    onChange={handleChange(setFile3, 5)}
                                                    onPreview={onPreview}
                                                    customRequest={handleUploadImage}
                                                    onRemove={(file) =>
                                                        showDeleteConfirm(file, 5)
                                                    }
                                                >
                                                    {file3.length < 1 && (
                                                        <div>
                                                            <img src={galleryPhoto} />
                                                        </div>
                                                    )}

                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                        <div className='text-center'>
                                            {file3.length < 1 && (
                                                <Button onClick={openSelectionform3} icon={<UploadOutlined />}>Upload</Button>
                                            )}
                                            {file3.length > 0 && (
                                                <Button onClick={() => { showDeleteConfirm(file3[0], 5); }} style={{ marginLeft: '5px' }}>Delete</Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <hr style={{ boxShadow: `0px 1px 1px` }} />

                        </Row>
                    </Col>
                    <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                        <button
                            type="submit"
                            className="btn btn-primary mt-2 "
                            style={{ background: `#2f55d4` }}
                        >
                            {' '}
                            Save Changes{' '}
                        </button>{' '}
                        <button
                            onClick={handleReset}
                            className="btn btn-outline-primary  mt-2 ms-2"
                            style={{ background: `none`, color: `#2f55d4` }}
                        >
                            Reset
                        </button>
                    </div>
                </Row>
            </Form>
            <Modal
                title="Confirm Deletion"
                open={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
                onClose={handleOk}
            >
                Are you sure you want to delete this image?
            </Modal>

            <Modal
                title="Avatar Preview"
                open={previewmodalVisible}
                onOk={handlepreviewOk}
                okText="Close"
                onClose={handlepreviewOk}
                onCancel={handlepreviewOk}
            >
                <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
};

export default SBServiceOfferingFunction;
