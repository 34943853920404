import React from 'react';
import { Navigate } from 'react-router-dom';
import MainIndex from '../apppages/Home/index';

import UserRegistrationPage from '../apppages/Home/UserRegistration';
import UserLoginPageFunction from '../apppages/Home/UserLoginPage';
import UserHomePageFunction from '../apppages/Home/UserHomePage';
import OTPInputGroup from '../apppages/Home/ConfirmUserRegistration';
import PageForgotPassword from '../apppages/Home/PageForgotPassword';
import SubscriptionPlan from '../apppages/Home/subscription';
import MyFavourites from '../apppages/Home/FavouriteListing';
import Myconnections from '../apppages/Home/Connections';
import MapFunction from '../apppages/Home/maps';
import MatchMakerFunction from '../apppages/Home/matchmaker/Configurematchmaker';
import SBpartnerFunction from '../apppages/Home/sbpartner/ConfigureSBpartner';
import ConfigureCommunityMember from '../apppages/Home/CommunityMember/configureCommunitymember';
import AdminPortal from '../apppages/Home/AdminPortal';
import ChatFunction from '../apppages/Home/chat/chats';
import SettingFunction from '../apppages/Home/Profile/Settings';
import CommunityMemberFunction from '../apppages/Home/CommunityMember/communityMemberdetails';

import MyProfileUpdateFunction from '../apppages/Home/PageProfileEdit';
import PartnerPreferenceFunction from '../apppages/Home/Preference/PartnerPreference';
import UserProfileEditFunction from '../apppages/Home/ProfileEditForUserSection';
import Mychat from './Home/chat/Mychat';
import Chat from './Home/chat/samplechat';
import SubscriptionManagement from './Home/SubscriptionManagement';
import Aboutus from './Home/AboutUs';
import PaymentSuccess from './Home/payment/paymentsuccess';
import PaymentFail from './Home/payment/paymentfail';
import Services from './Home/Services';
import Blogs from './Home/blogs/Blogs';

import BlogDetails1 from './Home/blogs/Blogdetails1';
import BlogDetails2 from './Home/blogs/Blogdetails2';
import BlogDetails3 from './Home/blogs/Blogdetails3';
import BlogDetails4 from './Home/blogs/Blogdetails4';
import BlogDetails5 from './Home/blogs/Blogdetails5';
import BlogDetails6 from './Home/blogs/Blogdetails6';
import BlogDetails7 from './Home/blogs/Blogdetails7';
import BlogDetails8 from './Home/blogs/Blogdetails8';
import BlogDetails9 from './Home/blogs/Blogdetails9';

import PagePrivacy from './Home/PrivacyPolicy';
import TermsAndConditions from './Home/TermsAndConditions';

import ViewProfileDetails from './Home/ViewProfileDetails';
import ViewProfileDetails2 from './Home/ViewProfileDetails2';
import PageContactDetail from './Home/ContactUs';
// import StripeFormElement from './stripeConnection';
import CheckoutPayment from './CheckoutPayment';
import RefundAndCancellation from './Home/RefundAndCancellation';
import ShippingandDelivery from './Home/ShippingAndDelivery';

const routes = [
  { path: '/', exact: true, component: <Navigate to="/index" /> },
  { path: '/index', component: <MainIndex />, isTopbarDark: true },

  {
    path: '/contactus',
    component: <PageContactDetail />,
    isTopbarDark: true,
  },

  // { path: '/index/:id', component: <Main />, isTopbarDark: true },
  {
    path: '/user-registration-page',
    component: <UserRegistrationPage />,
    isTopbarDark: true,
  },
  {
    path: '/confirm-user-registration',
    component: <OTPInputGroup />,
    isTopbarDark: true,
  },
  {
    path: '/user-login-page',
    component: <UserLoginPageFunction />,
    isTopbarDark: true,
  },
  {
    path: '/reset-password',
    component: <PageForgotPassword />,
    isTopbarDark: true,
  },
  {
    path: '/subscriptionplan',
    component: <SubscriptionPlan />,
    isTopbarDark: true,
  },
  { path: '/myFavourites', component: <MyFavourites />, isTopbarDark: true },
  { path: '/connections', component: <Myconnections />, isTopbarDark: true },
  {
    path: '/configuregeneralmember',
    component: <ConfigureCommunityMember />,
    isTopbarDark: true,
  },
  {
    path: '/communitymember-home-page',
    component: <CommunityMemberFunction />,
    isTopbarDark: true,
  },
  { path: '/adminportal', component: <AdminPortal />, isTopbarDark: true },
  { path: '/settings', component: <SettingFunction />, isTopbarDark: true },
  {
    path: '/allainceseeker-home-page',
    component: <UserHomePageFunction />,
    isTopbarDark: true,
  },

  {
    path: '/my-profile-edit-page1',
    component: <MyProfileUpdateFunction />,
    isTopbarDark: true,
  },
  {
    path: '/my-profile-edit-page',
    component: <UserProfileEditFunction />,
    isTopbarDark: true,
  },
  {
    path: '/partner-preference-page',
    component: <PartnerPreferenceFunction />,
    isTopbarDark: true,
  },
  { path: '/maps', component: <MapFunction /> },
  {
    path: '/matchmaker',
    component: <MatchMakerFunction />,
    isTopbarDark: true,
  },
  { path: '/sbpartner', component: <SBpartnerFunction />, isTopbarDark: true },
  { path: '/chats/:id', component: <ChatFunction />, isTopbarDark: true },
  { path: '/chats', component: <Mychat />, isTopbarDark: true },
  { path: '/samplechats', component: <Chat />, isTopbarDark: true },
  {
    path: '/subscribe',
    component: <SubscriptionManagement />,
    isTopbarDark: true,
  },
  { path: '/aboutus', component: <Aboutus />, isTopbarDark: true },
  {
    path: '/paymenthandler',
    component: <PaymentSuccess />,
    isTopbarDark: true,
  },
  { path: '/cancel', component: <PaymentFail />, isTopbarDark: true },
  { path: '/services', component: <Services />, isTopbarDark: true },
  { path: '/blogs', component: <Blogs />, isTopbarDark: true },
  { path: '/blogdetails1', component: <BlogDetails1 />, isTopbarDark: true },
  { path: '/blogdetails2', component: <BlogDetails2 />, isTopbarDark: true },
  { path: '/blogdetails3', component: <BlogDetails3 />, isTopbarDark: true },
  { path: '/blogdetails4', component: <BlogDetails4 />, isTopbarDark: true },
  { path: '/blogdetails5', component: <BlogDetails5 />, isTopbarDark: true },
  { path: '/blogdetails6', component: <BlogDetails6 />, isTopbarDark: true },
  { path: '/blogdetails7', component: <BlogDetails7 />, isTopbarDark: true },
  { path: '/blogdetails8', component: <BlogDetails8 />, isTopbarDark: true },
  { path: '/blogdetails9', component: <BlogDetails9 />, isTopbarDark: true },

  { path: '/privacypolicy', component: <PagePrivacy />, isTopbarDark: true },
  {
    path: '/termsandconditions',
    component: <TermsAndConditions />,
    isTopbarDark: true,
  },
  {
    path: '/viewprofiledetails',
    component: <ViewProfileDetails2 />,
    isTopbarDark: true,
  },

  // {
  //   path: '/stripeconnection',
  //   component: <StripeFormElement />,
  //   isTopbarDark: true,
  // },
  {
    path: '/refundandcancellation',
    component: <RefundAndCancellation />,
    isTopbarDark: true,
  },
  {
    path: '/shippinganddelivery',
    component: <ShippingandDelivery />,
    isTopbarDark: true,
  },
];
export default routes;
