import React, { useEffect, useState } from 'react';
import { Session } from '@talkjs/react';
import Talk from 'talkjs';

import { HmacSHA256, SHA256 } from 'crypto-js';

import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg'
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg'
import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';
import * as CryptoJS from 'crypto-js'

const ChatBoxFunction = (props) => {
    const [me, setMe] = useState();
    const [other, setOther] = useState();
    const [session, setSession] = useState();
    // Fetch owner data
    useEffect(() => {
        const fetchOwnerData = async () => {
            let configurationData = localStorage.getItem("configurationData");
            if (!configurationData) {
                window.location.href = '/index';
              }
            let ownerData = JSON.parse(configurationData);
            let photoUrl = localStorage.getItem("profilePicture") ? localStorage.getItem("profilePicture") : (ownerData.gendercode == 1 ? manavatar : (ownerData.gendercode == 2 ? femaleavatar : transgenderavatar))        
            const me1 = new Talk.User({
                id: ownerData.memberuuid,
                name: ownerData.firstname,
                photoUrl: photoUrl,
                email: ownerData.emailid
            });
            setMe(me1);
        };
        fetchOwnerData();

        if (props.memberdata?.memberuuid) {
            const other1 = new Talk.User({
                id: props.memberdata.memberuuid,
                name: props.memberdata.firstname,
                photoUrl : props.memberdata.photothumb1 ? props.memberdata.photothumb1 : (props.memberdata.gender.toLowerCase() =='male' ? manavatar : (props.memberdata.gender.toLowerCase() == 'famale' ? femaleavatar : transgenderavatar)), 
                email : props.memberdata.emailid,
                role: 'default',
            });
            setOther(other1);


        }
    }, [props.memberdata]);



    const createTalkJSSignature = (userData, secretKey) => {
        // Concatenate user ID and nonce
        const data = JSON.stringify(userData);

        // Create HMAC-SHA256 signature
        const signature = CryptoJS.HmacSHA256(data, secretKey);

        // Convert signature to base64
        const signatureBase64 = signature.toString(CryptoJS.enc.Base64);

        return signatureBase64;
    }


    // Example usage
    const secretKey = "sk_live_ihDM7Wf5qL63i64fiXMify1DVx50sEQo";


    useEffect(() => {
        if (me && other) {


            const session = new Talk.Session({
                appId: 'Dlxn8mVe',
                me: me,
                signature: CryptoJS.HmacSHA256(me.id, 'sk_live_ihDM7Wf5qL63i64fiXMify1DVx50sEQo').toString()

            });
            setSession(session);
            const conversation = session.getOrCreateConversation(Talk.oneOnOneId(me, other));
            conversation.setParticipant(me);
            conversation.setParticipant(other);
            const chatbox = session.createChatbox(conversation);
            chatbox.mount(document.getElementById('talkjs-container'));
        }


    }, [me, other])







    return (
        <>
            <div id="talkjs-container" style={{ height: "400px",  width: `314px` }}>
                <i>Loading chat...</i>
            </div>
        </>
    );
};

export default ChatBoxFunction;
