import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ModalBody, UncontrolledCarousel, UncontrolledCollapse, Modal
} from 'reactstrap'; import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Home/css/userHomePage.css';
import FeatherIcon from 'feather-icons-react';

import axios from 'axios';
import apiUrl from '../server';
import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg'
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg'

import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';
import birthcake from '../../assets/images/MettaMateImages/birthday-cake.svg'
import location from '../../assets/images/MettaMateImages/carbon_location-filled.svg';
import heightimg from '../../assets/images/MettaMateImages/game-icons_body-height.svg';
import weightimg from '../../assets/images/MettaMateImages/fa6-solid_weight-scale.svg';
import cityimg from '../../assets/images/MettaMateImages/healthicons_city.svg';
import callimg from '../../assets/images/MettaMateImages/mingcute_phone-fill.svg';
import mailimg from '../../assets/images/MettaMateImages/material-symbols_mail-outline.svg';
import complexionimg from '../../assets/images/MettaMateImages/carbon_face-add.svg';
import bloodgroupimg from '../../assets/images/MettaMateImages/maki_blood-bank.svg';
import hairimg from '../../assets/images/MettaMateImages/mingcute_hair-fill.svg';
import eyeimg from '../../assets/images/MettaMateImages/icomoon-free_eye.svg';
import degreenameimg from '../../assets/images/MettaMateImages/icon-park-outline_degree-hat.svg';
import collegeimg from '../../assets/images/MettaMateImages/college.svg';
import professionimg from '../../assets/images/MettaMateImages/profession.svg';
import companyimg from '../../assets/images/MettaMateImages/company.svg';
import moneyimg from '../../assets/images/MettaMateImages/game-icons_money-stack.svg';
import nonvegimg from '../../assets/images/MettaMateImages/circum_wheat.svg';
import smokeimg from '../../assets/images/MettaMateImages/ic_outline-smoke-free.svg';
import drinkimg from '../../assets/images/MettaMateImages/carbon_drink-01.svg';
import excerciseimg from '../../assets/images/MettaMateImages/flat-color-icons_sports-mode.svg';
import gamesimg from '../../assets/images/MettaMateImages/uil_game.svg';
import hobbiesimg from '../../assets/images/MettaMateImages/hobbies.svg';
import petimg from '../../assets/images/MettaMateImages/streamline_pet-paw-solid.svg';
import ngoimg from '../../assets/images/MettaMateImages/ngo.svg';
import casteimg from '../../assets/images/MettaMateImages/caste.svg';
import meditationimg from '../../assets/images/MettaMateImages/healthicons_exercise-yoga.svg';
import familyimg from '../../assets/images/MettaMateImages/emojione-monotone_family.svg';
import fatherimg from '../../assets/images/MettaMateImages/father.svg';
import motherimg from '../../assets/images/MettaMateImages/mother.svg';
import siblingsimg from '../../assets/images/MettaMateImages/noofsibling.svg';
import positionsiblingimg from '../../assets/images/MettaMateImages/family.svg';
import religionimg from '../../assets/images/MettaMateImages/religion.svg';
import dnaimg from '../../assets/images/MettaMateImages/ph_dna.svg';


const ProfileModal = (props) => {
  const [partnerDetailsData, setPartnerDetailsData] = useState([]);

  const partnerExpectationsDetails = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/fullpreference/' + props.memberData.memberuuid,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {
        setPartnerDetailsData(response.data[0])
      } else {

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const getImage = (imagepath, gender) => {
    if (imagepath) {
      return imagepath
    }
    else {
      if (gender == 'Male') {
        return manavatar
      }
      else if (gender == 'Female') {
        return femaleavatar
      }
      else if (gender == 'Other') {
        return
      }
    }
  }
  const calculate_age = (dob) => {
    if (dob != null) {
      var today = new Date();
      var birthDate = new Date(dob);  // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return age_now ? age_now : '00';
    }
    else {
      return '00';
    }
  }
  const getPronoun = (gender) => {
    if (gender == 1) {
      return 'His'
    }
    else if (gender == 2) {
      return 'Her'
    }
    else {
      return ''
    }
  }
  return (
    <section>

      {/* {props.memberData.membertypeid === 1 && ( */}
      <ModalBody>
        <Row>
          <Col lg="12">
            <Card
              className=" border-0 rounded shadow"
              style={{ zIndex: '9' }}
            >
              <CardBody className="card-body profile_modal_font">
                <Row className="">
                  <Col md={5} sm={12} className='d-flex'>
                    <div>
                      <UncontrolledCarousel
                        interval={4000}
                        items={[
                          {
                            altText: " ",
                            caption: " ",
                            key: 1, height: '330px',
                            src: getImage(props.memberData.photothumb1, props.memberData.gender),
                            className: "bg-home-75vh memberImage",
                            style: { height: '330px' }
                          },
                          {
                            altText: " ",
                            caption: " ",
                            key: 2,
                            src: getImage(props.memberData.photothumb2, props.memberData.gender),
                            className: "bg-home-75vh memberImage",
                            style: { height: '330px' }
                          },
                          {
                            altText: " ",
                            caption: " ",
                            key: 3,
                            src: getImage(props.memberData.photothumb3, props.memberData.gender),
                            className: "bg-home-75vh memberImage",
                            style: { height: '330px' }
                          },
                        ]}
                      />
                      <div className='m-2'>
                        <h3 className=" h3 name text-center">
                          <span className=''>{props.memberData.firstname}  {props.memberData.lastname} </span>
                        </h3>

                        <div>
                          <h5 className='text-center'>{props.memberData.jobprofession == '' ? (
                            <span className="">Unknown Job Profession</span>
                          ) : (
                            <span className='text-primary'>{props.memberData.jobprofession}</span>
                          )}
                          </h5>
                          <div className='d-flex align-items-center'>
                            <p className='mb-0 fontSize18'>
                              <FeatherIcon
                                icon="user"
                                className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} fea text-white me-3 modal_svg`}
                              />
                              <span className=' '>{props.memberData.gender}, </span>
                            </p>
                            <span className='text-right '> &nbsp;{calculate_age(props.memberData.dob)}</span>
                          </div>
                          <div>
                            <img src={birthcake}  className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} label_img`} />
                            {props.dob == 'NaNth undefined, NaN' ? (
                              <span className='px-3 fontSize18'>Unknown Birth Date</span>
                            ) : (
                              <span className="px-3 fontSize18 ">{props.dob}</span>
                            )}
                          </div>
                          <div>
                            <img src={location} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} label_img`} />
                            <span className="px-3 fontSize18">{props.location[0]}</span>
                          </div>
                        </div>
                        <hr />


                      </div>

                      <ul className="list-unstyled m-2">
                        <h4><span>{getPronoun(props.memberData.gendercode)} Personal Details</span></h4>
                        {props.memberData.membertypeid === 1 && (
                        <><li className=" mx-2 my-1 ">
                            <div className='d-flex'>
                              <img src={complexionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`}/>
                              <p className='mb-0 fontSize18 mt-1'>Complexion</p>
                            </div>
                            <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.complexion ? props.memberData.complexion : 'Unknown Complexion'}</p>
                          </li>
                          <li className=" mx-2 my-1 ">
                              <div className='d-flex'>
                                <img src={heightimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                <p className='mb-0 fontSize18 mt-1'>Height</p>
                              </div>
                              <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.height ? props.memberData.height : 'Unknown Height'} ft</p>
                            </li><li className=" mx-2 my-1 ">
                              <div className='d-flex'>
                                <img src={weightimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                <p className='mb-0 fontSize18 mt-1'>Weight</p>
                              </div>
                              <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.weight ? props.memberData.weight : 'Unknown Weight'} kg</p>
                            </li><li className=" mx-2 my-1 ">
                              <div className='d-flex mt-1'>
                                <img src={hairimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                <p className='mb-0 fontSize18'>Hair Color</p>
                              </div>
                              <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.haircolor ? props.memberData.haircolor : 'Unknown Hair Color'}</p>
                            </li><li className=" mx-2 my-1 ">
                              <div className='d-flex mt-1'>
                                <img src={eyeimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                <p className='mb-0 fontSize18'>Eye Color</p>
                              </div>
                              <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.eyecolor ? props.memberData.eyecolor : 'Unknown Eye Color'}</p>
                            </li><li className=" mx-2 my-1 ">
                              <div className='d-flex mt-1'>
                                <img src={bloodgroupimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                <p className='mb-0 fontSize18'>Blood Group</p>
                              </div>
                              <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.bloodgroup ? props.memberData.bloodgroup : 'Unknown Blood Group'}</p>
                            </li></>
                        )}
                        <li className=" mx-2 my-1 ">
                          <div className='d-flex mt-1'>
                            <img src={cityimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                            <p className='mb-0 fontSize18'>City</p>
                          </div>
                          <p className='fw-bold value_text fontSize18 mb-1'> {props.location[1] ? props.location[1] : 'Unknown Location'}</p>
                        </li>

                        <li className="mx-2 my-1" style={{cursor:'not-allowed'}}>
                          <div>
                            <img src={mailimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                            <span className="fontSize18 me-2">Email&nbsp;</span>
                            {props.memberData.emailid == '' && (
                              <span className="">Unknown </span>
                            )}
                            {(props.memberData.emailid != 'Subscription_Required') && (
                              <>
                                <p className='fw-bold value_text fontSize18 mb-1'>{props.memberData.emailid}</p></>
                            )}
                            {props.memberData.emailid == 'Subscription_Required' && (
                              <>
                                <span className='badge rounded bg-soft-success border-1 border-primary font_size18'>
                                  <i>
                                    <FeatherIcon
                                      icon="lock"
                                      className="fea icon-sm text-accent me-1"
                                    /> Subscription Required
                                  </i>
                                </span>
                              </>
                            )}
                          </div>

                        </li>

                        <li className="mx-2 my-1">
                          <div>
                            <img src={callimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                            <span className="fontSize18">Mobile </span>
                            {props.memberData.phonenumber == '' && (
                              <span className="">Unknown </span>
                            )}
                            {(props.memberData.phonenumber != 'Subscription_Required') && (
                              <>
                                <p className='fw-bold value_text fontSize18 mb-1'> (+{props.memberData.phonecountrycode})-
                                  {props.memberData.phonenumber}</p></>
                            )}
                            {props.memberData.phonenumber == 'Subscription_Required' && (
                              <>
                                <span className='badge rounded bg-soft-success border-1 border-primary font_size18'>
                                  <i>
                                    <FeatherIcon
                                      icon="lock"
                                      className="fea icon-sm text-accent me-1"
                                    /> Subscription Required
                                  </i>
                                </span>
                              </>
                            )}
                          </div>

                        </li>

                        <li >
                          <ul className="list-unstyled social-icon social mb-0 fontSize18 mt-4">
                            <li className="list-inline-item">
                              <Link disabled={props.memberData.facebook == 'Subscription_Required'} to={props.memberData.facebook} className="rounded">
                                <FeatherIcon
                                  icon="facebook"
                                  className="fea icon-sm fea-social"
                                />
                              </Link>
                            </li>{' '}
                            <li className="list-inline-item">
                              <Link to={props.memberData.instagram} className="rounded">
                                <FeatherIcon
                                  icon="instagram"
                                  className="fea icon-sm fea-social"
                                />
                              </Link>
                            </li>{' '}
                            <li className="list-inline-item">
                              <Link to={props.memberData.twitter} className="rounded">
                                <FeatherIcon
                                  icon="twitter"
                                  className="fea icon-sm fea-social"
                                />
                              </Link>
                            </li>{' '}
                            <li className="list-inline-item">
                              <Link to={props.memberData.linkedin} className="rounded">
                                <FeatherIcon
                                  icon="linkedin"
                                  className="fea icon-sm fea-social"
                                />
                              </Link>
                            </li>{' '}

                            <li className="list-inline-item">
                              <Link to={props.memberData.youtube} className="rounded">
                                <FeatherIcon
                                  icon="youtube"
                                  className="fea icon-sm fea-social"
                                />
                              </Link>
                            </li>{' '}

                          </ul>
                        </li>
                      </ul>
                    </div>

                    <div className="verticalLine d-none d-md-block" style={{
                      borderRight: ` 1px solid rgb(195 195 195)`,
                      paddingLeft: `13px`
                    }}></div>
                  </Col>

                  <Col md={6}>
                    <div>
                      <h4><span>{getPronoun(props.memberData.gendercode)} Educational Details</span></h4>
                      <div className='d-flex mt-1'>
                        <img src={degreenameimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                        <p className='mb-0 fontSize18'>Degree</p>
                      </div>
                      <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.degreelevel ? props.memberData.degreelevel : 'Unknown Degree'} &nbsp; ,{props.memberData.degreename ?
                        props.memberData.degreename : 'Unknown Degree Name'}</p>


                      <p className="mb-1 ">
                        <div className='d-flex mt-1'> 
                          <img src={collegeimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Degree College</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.degreecollege ? props.memberData.degreecollege : 'Unknown Degree College'}</p>

                      </p>

                      <hr />
                    </div>
                    <div>
                      <h4><span>{getPronoun(props.memberData.gendercode)} Professioal Details</span></h4>
                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={professionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Job Title</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.jobtitle ? props.memberData.jobtitle : 'Unknown Job title'}</p>
                      </div>

                      <div className="mb-1 ">
                        <div className='d-flex mt-1'>
                          <img src={companyimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Company Name</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.companyname ? props.memberData.companyname : 'Unknown Company'}</p>
                      </div>

                      <div className="mb-1 ">
                        <div className='d-flex mt-1'>
                          <img src={location} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Company Location</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.location[2] ? props.location[2] : 'Unknown Company Location'}</p>
                      </div>

                      <div className="mb-1 ">
                        <div className='d-flex mt-1'>
                          <img src={moneyimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Salary</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.salctc ? props.memberData.salctc : 'Unknown CTC'}</p>
                      </div>

                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={professionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Skill sets</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.skillset ? props.memberData.skillset : 'Unknown skillset'}</p>
                      </div>

                      <hr />
                    </div>
                    {props.memberData.membertypeid === 1 && (
                      <>
                    <div>
                      <h4><span>{getPronoun(props.memberData.gendercode)} Lifestyle Details</span></h4>
                      <div className="mb-1 ">
                        <div className='d-flex mt-1'>
                          <img src={nonvegimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Diet Preferences</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.diet ? props.memberData.diet : 'Unknown'}</p>
                      </div>


                      <Row>
                        <Col md={6} >
                          <div className="mb-1 ">
                            <div className='d-flex mt-1'>
                              <img src={smokeimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                              <p className='mb-0 fontSize18'>Smoking</p>
                            </div>
                            <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.smokinghabit ? props.memberData.smokinghabit : 'Unknown'}</p>
                          </div>

                          <div className="mb-1 ">
                            <div className='d-flex mt-1'>
                              <img src={drinkimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                              <p className='mb-0 fontSize18'>Drinking</p>
                            </div>
                            <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.drinkinghabit ? props.memberData.drinkinghabit : 'Unknown'}</p>
                          </div>

                        </Col>
                      </Row>

                      <div className="mb-1 ">
                        <div className='d-flex mt-1'>
                          <img src={excerciseimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Exercise Routines</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.exerciseroutine ? props.memberData.exerciseroutine : 'Unknown'}</p>
                      </div>

                      <div className="mb-1 ">
                        <div className='d-flex mt-1'>
                          <img src={gamesimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Games</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.gameList ? props.memberData.gameList : 'Unknown'}</p>
                      </div>

                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={hobbiesimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Hobbies_Interest</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.hobbies ? props.memberData.hobbies : 'Unknown'}</p>
                      </div>

                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={petimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Pet Preference</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.petpreference ? props.memberData.petpreference : 'Unknown'}</p>
                      </div>
                      <hr />

                      <div className="mb-1">
                        <h4><span>{getPronoun(props.memberData.gendercode)} Social Details</span></h4>
                        <div className='d-flex mt-1'>
                          <img src={ngoimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Part Of NGO</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.partofngo ? props.memberData.partofngo : 'Unknown'}</p>
                      </div>
                      <hr />

                    </div>
                   
                    <div>
                      <p className="m-0 font_size16 " >
                        <h4><span>{getPronoun(props.memberData.gendercode)} Religious Details</span></h4>
                        <div className="mb-1">
                          <div className='d-flex mt-1'>
                            <img src={religionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                            <p className='mb-0 fontSize18'>Current Religion</p>
                          </div>
                          <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.currentreligion ? props.memberData.currentreligion : 'Unknown'}</p>
                        </div>


                        <div className="mb-1">
                          <div className='d-flex mt-1'>
                            <img src={religionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                            <p className='mb-0 fontSize18'>Previous Religion</p>
                          </div>
                          <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.currentreligion ? props.memberData.previousreligion : 'Unknown'}</p>
                        </div>

                      </p>

                    </div>
                    
                    <div>

                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={casteimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Caste</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.caste ? props.memberData.caste : 'Unknown'}</p>
                      </div>
                    </div>
                    <div>
                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={meditationimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Number Of Vipassana Courses Completed</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.noofvipassana ? props.memberData.noofvipassana : 'Unknown'}</p>
                      </div>

                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={meditationimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Other Meditation Done</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.othermeditation ? props.memberData.othermeditation : 'Unknown'}</p>
                      </div>
                      <hr />
                    </div>

                    <div>

                      <p className="m-0 font_size16 " >
                        <h4><span>{getPronoun(props.memberData.gendercode)} Family Details</span></h4>

                        <div className="mb-1">
                          <div className='d-flex mt-1'>
                            <img src={familyimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                            <p className='mb-0 fontSize18'>Family Type</p>
                          </div>
                          <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.familytype ? props.memberData.familytype : 'Unknown'}</p>
                        </div>



                      </p>
                      <Row>
                        <Col md={6} >

                          <div className="mb-1">
                            <div className='d-flex mt-1'>
                              <img src={fatherimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                              <p className='mb-0 fontSize18'>Father</p>
                            </div>
                            <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.father ? props.memberData.father : 'Unknown'}</p>
                          </div>

                          <div className="mb-1">
                            <div className='d-flex mt-1'>
                              <img src={motherimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                              <p className='mb-0 fontSize18'>Mother</p>
                            </div>
                            <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.mother ? props.memberData.mother : 'Unknown'}</p>
                          </div>


                        </Col>

                        <div className="mb-1">
                          <div className='d-flex mt-1'>
                            <img src={siblingsimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                            <p className='mb-0 fontSize18'>No Of Siblings</p>
                          </div>
                          <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.noofsibling ? props.memberData.noofsibling : 'Unknown'}</p>
                        </div>

                        <div className="mb-1">
                          <div className='d-flex mt-1'>
                            <img src={positionsiblingimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                            <p className='mb-0 fontSize18'>Your position in siblings</p>
                          </div>
                          <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.yourposition ? props.memberData.yourposition : 'Unknown'}</p>
                        </div>


                      </Row>
                    </div>
                    <div>
                      <div className="mb-1">
                        <div className='d-flex mt-1'>
                          <img src={dnaimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                          <p className='mb-0 fontSize18'>Genetic Disease</p>
                        </div>
                        <p className='fw-bold value_text fontSize18 mb-1'> {props.memberData.geneticdiseases ? props.memberData.geneticdiseases : 'Unknown'}</p>
                      </div>

                    </div>
                    </>
                     )}
                  </Col>
                  {props.memberData.membertypeid === 1 && (
                    <Col md={12}>
                      <div className="accordion-item rounded mt-2">
                        <h2 className="accordion-header" style={{ margin: `auto`, width: `253px` }}>
                          <button id="headingTwo" className="accordion-button border-0 bg-primary collapsed text-white"
                            type="button" onClick={partnerExpectationsDetails}>
                            {getPronoun(props.memberData.gendercode)} Partner Expectations

                          </button>
                        </h2>
                        <UncontrolledCollapse toggler="#headingTwo" className="border-0"
                        >
                          <div className="accordion-body mt-5 ">
                            <Row>
                              <Col md={5} sm={12} className='d-flex'>
                                <ul className="list-unstyled m-2">
                                  <h4><span>{getPronoun(props.memberData.gendercode)} Partner's Personal Details</span></h4>
                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={complexionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                      <p className='mb-0 fontSize18 mt-1'>Complexion</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.complexions ? partnerDetailsData.complexions : 'Unknown Complexion'}</p>
                                  </li>
                                  <li className=" mx-2 my-1">
                                    <div className='d-flex'>
                                      <FeatherIcon
                                        icon="user"
                                        className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} fea text-white me-3 modal_svg`}
                                      />
                                      <p className='mb-0 fontSize18'>Gender</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.gender ? partnerDetailsData.gender : 'Not Mentioned'}</p>
                                  </li>

                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={heightimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                      <p className='mb-0 fontSize18 mt-1'>Height</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.minheight ? partnerDetailsData.minheight : 'Not Mentioned'}
                                      - {partnerDetailsData.maxheight ? partnerDetailsData.maxheight : 'Not Mentioned'} </p>
                                  </li>

                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={weightimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                      <p className='mb-0 fontSize18 mt-1'>Weight</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.startweight ? partnerDetailsData.startweight : 'Not Mentioned'}
                                      - {partnerDetailsData.endweight ? partnerDetailsData.endweight : 'Not Mentioned'} </p>
                                  </li>

                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={hairimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18 mt-1'>Hair Colors</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.haircolors ? partnerDetailsData.haircolors : 'Not Mentioned'}</p>
                                  </li>

                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={eyeimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                      <p className='mb-0 fontSize18 mt-1'>Eye Colors</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.eyecolors ? partnerDetailsData.eyecolors : 'Not Mentioned'}</p>
                                  </li>

                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={bloodgroupimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                      <p className='mb-0 fontSize18 mt-1'>Blood Group</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.bloodgroups ? partnerDetailsData.bloodgroups : 'Not Mentioned'}</p>
                                  </li>


                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={complexionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                      <p className='mb-0 fontSize18 mt-1'>Marital Status</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.maritalstatus ? partnerDetailsData.maritalstatus : 'Not Mentioned'}</p>
                                  </li>

                                  <li className=" mx-2 my-1 ">
                                    <div className='d-flex'>
                                      <img src={location} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3 mt-1`} />
                                      <p className='mb-0 fontSize18 mt-1'>City</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> Not Mentioned</p>
                                  </li>
                                </ul>
                                <div className="verticalLine d-none d-md-block" style={{
                                  borderRight: ` 1px solid rgb(195 195 195)`,
                                  paddingLeft: `13px`
                                }}></div>
                              </Col>
                              <Col md={7} sm={12}>
                                <h4><span>{getPronoun(props.memberData.gendercode)} Partner's  Educational Details</span></h4>
                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={degreenameimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Degree Level</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.degreelevels ? partnerDetailsData.degreelevels : 'Not Mentioned'}</p>
                                </div>


                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={degreenameimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Degree Name</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.degreenames ? partnerDetailsData.degreenames : 'Not Mentioned'}</p>
                                </div>
                                <hr />
                                <h4><span>{getPronoun(props.memberData.gendercode)} Partner's  Professional Details</span></h4>
                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={professionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Job Title</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.jobtitles ? partnerDetailsData.jobtitles : 'Not Mentioned'}</p>
                                </div>

                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={professionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Job Profession</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.jobprofessions ? partnerDetailsData.jobprofessions : 'Not Mentioned'}</p>
                                </div>

                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={moneyimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Salary</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.startsalary ? partnerDetailsData.startsalary : 'Not Mentioned'} - {partnerDetailsData.endsalary ? partnerDetailsData.endsalary : 'Not Mentioned'}   {partnerDetailsData.salarycurrency}</p>
                                </div>
                                <hr />


                                <h4><span>{getPronoun(props.memberData.gendercode)} Partner's Lifestyle Details</span></h4>
                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={nonvegimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Diet Preferences</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.diettypes ? partnerDetailsData.diettypes : 'Not Mentioned'}</p>
                                </div>

                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={smokeimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Smoking</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.smoking ? partnerDetailsData.smoking : 'Not Mentioned'}</p>
                                </div>

                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={drinkimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Drinking</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.drinking ? partnerDetailsData.drinking : 'Not Mentioned'}</p>
                                </div>



                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={excerciseimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Exercise Routines</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.excercise ? partnerDetailsData.excercise : 'Not Mentioned'}</p>
                                </div>
                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={gamesimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Games</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.playgames ? partnerDetailsData.playgames : 'Not Mentioned'}</p>
                                </div>

                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={hobbiesimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Hobbies_Interest</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.hobbies ? partnerDetailsData.hobbies : 'Not Mentioned'}</p>
                                </div>

                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={petimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Pet Preference</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.petpreference ? partnerDetailsData.petpreference : 'Not Mentioned'}</p>
                                </div>

                                <hr />
                                <h4><span>{getPronoun(props.memberData.gendercode)} Partner's Social Details</span></h4>

                                <div className="mb-1">
                                  <div className='d-flex mt-1'>
                                    <img src={ngoimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                    <p className='mb-0 fontSize18'>Part Of NGO</p>
                                  </div>
                                  <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.partofngo ? partnerDetailsData.partofngo : 'Not Mentioned'}</p>
                                </div>

                                <hr />
                                <h4><span>{getPronoun(props.memberData.gendercode)} Partner's Religious Details</span></h4>

                                <div>

                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={religionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>Current Religion</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.currentreligion ? partnerDetailsData.currentreligion : 'Not Mentioned'}</p>
                                  </div>

                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={religionimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>Previous Religion</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.previousreligion ? partnerDetailsData.previousreligion : 'Not Mentioned'}</p>
                                  </div>

                                </div>
                                <div>

                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={casteimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>Caste</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.caste ? partnerDetailsData.caste : 'Not Mentioned'}</p>
                                  </div>

                                </div>
                                <div>


                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={meditationimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>Number Of Vipassana Courses Completed</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.vipassananumbers ? partnerDetailsData.vipassananumbers : 'Not Mentioned'}</p>
                                  </div>

                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={meditationimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>Other Meditation Done</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.familytypes ? partnerDetailsData.othermeditationstatus : 'Not Mentioned'}</p>
                                  </div>
                                </div>
                                <hr />

                                <div>
                                  <h4><span>{getPronoun(props.memberData.gendercode)} Partner's Family Details</span></h4>
                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={familyimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>Family Type</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.familytypes ? partnerDetailsData.familytypes : 'Not Mentioned'}</p>
                                  </div>

                                  <Row>
                                    <Col md={6}>

                                      <div className="mb-1">
                                        <div className='d-flex mt-1'>
                                          <img src={fatherimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                          <p className='mb-0 fontSize18'>Father</p>
                                        </div>
                                        <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.fatherrequired ? partnerDetailsData.fatherrequired : 'Not Mentioned'}</p>
                                      </div>
                                    </Col>

                                    <Col md={6} >
                                      <div className="mb-1">
                                        <div className='d-flex mt-1'>
                                          <img src={motherimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                          <p className='mb-0 fontSize18'>Mother</p>
                                        </div>
                                        <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.motherrequired ? partnerDetailsData.motherrequired : 'Not Mentioned'}</p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={siblingsimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>No Of Siblings</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.numofsiblings ? partnerDetailsData.numofsiblings : 'Not Mentioned'}</p>
                                  </div>
                                  <div className="mb-1">
                                    <div className='d-flex mt-1'>
                                      <img src={dnaimg} className={`${props.memberData.membertypeid==1?'modal-img-bg-green':'modal-img-bg-blue'} modal-img me-3`} />
                                      <p className='mb-0 fontSize18'>Genetic Disease</p>
                                    </div>
                                    <p className='fw-bold value_text fontSize18 mb-1'> {partnerDetailsData.geneticdisease ? partnerDetailsData.geneticdisease : 'Not Mentioned'}</p>
                                  </div>
                                  <hr />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </UncontrolledCollapse>
                      </div>
                    </Col>
                  )}


                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      {/* )} */}


      {/* {
        (props.memberData.membertypeid == 2 || props.memberData.membertypeid == 3 || props.memberData.membertypeid == 4) && (
          <ModalBody>
            <Row>
              <Col lg="12">
                <Card
                  className=" border-0 rounded shadow">
                  <CardBody className="card-body">
                    <Row className="p-2 memberheader_div">
                      <Col md={3} className='memberImage_col'>
                        <UncontrolledCarousel
                          interval={4000}
                          items={[
                            {
                              altText: " ",
                              caption: " ",
                              key: 1,
                              src: getImage(props.memberData.photothumb1, props.memberData.gender),
                              className: "bg-home-75vh memberImage",
                            },
                            {
                              altText: " ",
                              caption: " ",
                              key: 2,
                              src: getImage(props.memberData.photothumb2, props.memberData.gender),
                              className: "bg-home-75vh memberImage",
                            },
                            {
                              altText: " ",
                              caption: " ",
                              key: 3,
                              src: getImage(props.memberData.photothumb3, props.memberData.gender),
                              className: "bg-home-75vh memberImage",
                            },
                            {
                              altText: " ",
                              caption: " ",
                              key: 4,
                              src: getImage(props.memberData.photothumb4, props.memberData.gender),
                              className: "bg-home-75vh memberImage",
                            },
                            {
                              altText: " ",
                              caption: " ",
                              key: 5,
                              src: getImage(props.memberData.photothumb5, props.memberData.gender),
                              className: "bg-home-75vh memberImage",
                            },
                            {
                              altText: " ",
                              caption: " ",
                              key: 6,
                              src: getImage(props.memberData.photothumb6, props.memberData.gender),
                              className: "bg-home-75vh memberImage",
                            },
                          ]}
                        />
                      </Col>
                      <Col md={9}>
                        <div className='name_div p-4'>
                          <h3 className=" h3 name " >
                            <span>{props.memberData.firstname}  {props.memberData.lastname} </span>
                          </h3>
                          <h6 className='text-white  text-capitalize'>
                            {props.memberData.jobprofession == '' ? (
                              <span className="">Unknown Job Profession</span>
                            ) : (
                              <span>{props.memberData.jobprofession}</span>
                            )}
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    
                    <Row className='p-2 mt-2' style={{ background: `azure` }}>
                      <Col md={3} className='' style={{
                        borderRight: ` 1px solid rgb(195 195 195)`,
                        paddingLeft: `13px`
                      }}>

                        <h6 className=''>

                          {props.dob == 'NaNth undefined, NaN' ? (
                            <span>Unknown Birth Date</span>
                          ) : (
                            <span className="h6">{props.dob}</span>
                          )}

                        </h6>

                        <hr />
                        <h5 className='text-uppercase memberheader_label'>CONTACT</h5>
                        <h6>
                          {props.memberData.phonenumber == '' && (
                            <span className="">
                              <i>
                                <FeatherIcon
                                  icon="phone"
                                  className="fea icon-sm  me-2 membericon"
                                /> </i>Unknown </span>
                          )}
                          {(props.memberData.phonenumber != 'Subscription_Required') ? (
                            <><span className="">
                              <i>
                                <FeatherIcon
                                  icon="phone"
                                  className="fea icon-sm  me-2 membericon"
                                />
                              </i> (+{props.memberData.phonecountrycode})
                              {props.memberData.phonenumber}</span></>
                          ) : (

                            <>
                              <span className='badge rounded bg-soft-success border-1 border-primary font_size14'>
                                <i>
                                  <FeatherIcon
                                    icon="phone"
                                    className="fea icon-sm membericon me-2"
                                  />
                                </i>

                                <i>
                                  <FeatherIcon
                                    icon="lock"
                                    className="fea icon-sm  me-2"
                                  /> Subscription Required
                                </i>

                              </span>
                            </>
                          )}
                        </h6>

                        <h6>

                          {props.memberData.emailid == 'Subscription_Required' ? (
                            <span className='badge rounded bg-soft-success border-1 border-primary font_size14'>
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm me-2 membericon"
                                />
                              </i>
                              <i>
                                <FeatherIcon
                                  icon="lock"
                                  className="fea icon-sm  me-2"
                                /> Subscription Required
                              </i>
                            </span>
                          ) : (
                            <span>
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm me-2 membericon"
                                />
                              </i> {props.memberData.emailid}
                            </span>
                          )}
                        </h6>
                        <h6> <i>
                          <FeatherIcon
                            icon="map-pin"
                            className="fea icon-sm membericon me-3"
                          />
                        </i><span>{props.location[0]}</span>
                        </h6>
                        <hr />

                      </Col>
                      <Col md={9}>
                        {props.memberData.membertypeid === 4 && (
                          <>
                            <h5 className='text-uppercase memberheader_label'>MEMBER OFFERINGS</h5>
                            <h6>
                              <i>
                                <FeatherIcon
                                  icon="fast-forward"
                                  className="fea icon-sm  membericon me-3"
                                />
                              </i>Providing Mentorship
                            </h6>
                            <h6>
                              <i>
                                <FeatherIcon
                                  icon="fast-forward"
                                  className="fea icon-sm  membericon me-3"
                                />
                              </i>I need Mentorship
                            </h6>
                            <h6>
                              <i>
                                <FeatherIcon
                                  icon="fast-forward"
                                  className="fea icon-sm  membericon me-3"
                                />
                              </i>50% Discount in Consultation
                            </h6>
                            <hr />
                          </>
                        )}
                        {props.memberData.membertypeid == 3 && (
                          <>
                            <h5 className='text-uppercase memberheader_label'>Service Offerings</h5>
                            <h6>
                              <i>
                                <FeatherIcon
                                  icon="fast-forward"
                                  className="fea icon-sm  membericon me-3"
                                />
                              </i>10% Discount in Hall Booking
                            </h6>
                            <h6>
                              <i>
                                <FeatherIcon
                                  icon="fast-forward"
                                  className="fea icon-sm  membericon me-3"
                                />
                              </i>15% Discount in Caters
                            </h6>
                            <h6>
                              <i>
                                <FeatherIcon
                                  icon="fast-forward"
                                  className="fea icon-sm  membericon me-3"
                                />
                              </i>50% Discount in Consultation
                            </h6>
                          </>
                        )}
                        <h5 className='text-uppercase memberheader_label'>EDUCATION</h5>

                        <h6>
                          <i>
                            <FeatherIcon
                              icon="book"
                              className="fea icon-sm membericon me-3"
                            />
                          </i>
                          {props.memberData.degreelevel == '' ? (
                            <span className="">Unknown Degree</span>
                          ) : (<span>{props.memberData.degreelevel}</span>)}
                        </h6>

                        <h6>
                          <i>
                            <FeatherIcon
                              icon="bookmark"
                              className="fea icon-sm membericon me-3"
                            />
                          </i>
                          {props.memberData.degreename == '' ? (
                            <span className="">Unknown Degree Name</span>) : (
                            <span> {props.memberData.degreename}</span>)}
                        </h6>

                        <h6>
                          <i>
                            <FeatherIcon
                              icon="map-pin"
                              className="fea icon-sm membericon me-3"
                            />
                          </i>
                          {props.memberData.degreecollege == '' ? (
                            <span className="">Unknown College</span>
                          ) : (
                            <span>{props.memberData.degreecollege}</span>
                          )}
                        </h6>
                        <hr />
                        <h5 className='text-uppercase memberheader_label'>CAREER</h5>
                        <h6>
                          <i>
                            <FeatherIcon
                              icon="briefcase"
                              className="fea icon-sm membericon me-3"
                            />
                          </i>
                          {props.memberData.jobtitle == '' ? (
                            <span className="">Unknown Job Title</span>
                          ) : (
                            <span>{props.memberData.jobtitle}</span>
                          )}

                        </h6>
                        <h6>
                          <i>
                            <FeatherIcon
                              icon="briefcase"
                              className="fea icon-sm membericon me-3"
                            />
                          </i>
                          {props.memberData.jobprofession == '' ? (
                            <span className="">Unknown Job Profession</span>
                          ) : (
                            <span>{props.memberData.jobprofession}</span>
                          )}
                        </h6>
                        <h6>
                          <FeatherIcon
                            icon="smartphone"
                            className="fea icon-sm membericon me-3"
                          />
                          {props.memberData.companyname == '' ? (
                            <span className="" >Unknown Company</span>
                          ) : (
                            <span >
                              {props.memberData.companyname}</span>
                          )}

                        </h6>
                        <h6>
                          <i>
                            <FeatherIcon
                              icon="map-pin"
                              className="fea icon-sm  membericon me-3"
                            />
                          </i>{props.location[2]}
                        </h6>
                        <hr />


                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        )
      } */}

    </section >
  );
};

export default ProfileModal;

