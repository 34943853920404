import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  CardImg,
} from 'reactstrap';
import { Link } from 'react-router-dom';
//import Images
// import online02 from '../../assets/images/ab02.jpg';
// import subscribe from '../../assets/images/subscribe.png';
// import cart from '../../assets/images/cart.png';
//Import Icons
import FeatherIcon from 'feather-icons-react';
// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import allainceseekerImg from '../../assets/images/mettamate/AllianceSeeker3.jpeg';
//import allainceseekerImg from '../../assets/images/mettamate/allianceSeekerCard.jpeg';
// import allainceseekerImg from '../../assets/images/mettamate/AdjustedAllianceTheme.jpeg';
import allainceseekerImg from '../../assets/images/mettamate/AllianceSeekerCropped.png';

import communitymemberImg from '../../assets/images/mettamate/CommunityMembers.jpeg';
import SectionTitle from '../../components/Shared/SectionTitle';
import Mentorship from '../../assets/images/mettamate/Mentorship.jpeg';
import communityAdvertisement from '../../assets/images/mettamate/CommunityEngagementLarge2.png';
//import communityAdvertisement from '../../assets/images/mettamate/communityAdvertisement.jpeg';
import personalConsultation from '../../assets/images/mettamate/personalConsultationPurchased.jpg';
//import personalConsultation from '../../assets/images/mettamate/personalConsultation.jpeg';
//import personalConsultation from '../../assets/images/mettamate/personalConsultation_pgk.jpeg';
import NewConnectionSafe from './newConnectSafe';
// import communityMemberImg from '../../assets/images/mettamate/Community Members Icon.svg';

const Modals = () => {
  const state = {
    isOpen: false,
  };
  const blogs = [
    {
      id: 1,
      image: personalConsultation,
      title: 'Consultation: Personal Growth',
      like: '33',
      comment: '08',
      autor: 'Calvin Carlo',
      date: '13th August, 2019',
    },
    {
      id: 2,
      image: communityAdvertisement,
      title: 'Community Engagement',
      like: '33',
      comment: '08',
      autor: 'Calvin Carlo',
      date: '13th August, 2019',
    },
    {
      id: 3,
      image: Mentorship,
      title: 'Mentorship: Professional Growth',
      like: '33',
      comment: '08',
      autor: 'Calvin Carlo',
      date: '13th August, 2019',
    },
  ];
  const studioWorksData = [
    {
      imageUrl: allainceseekerImg,
      title: 'Join as an',
      subtitle: 'Alliance Seeker',
      btnColor: 'btn btn-warning mt-70',
      textColor: '',
      contentText:
        "Embark on a journey of connection and empowerment with Mettamate's alliance seekers. Like stars in a vast universe, together we illuminate the path to endless possibilities. Join us, where bonds are forged, dreams are pursued, and every alliance is a masterpiece waiting to be painted. Discover the beauty of collaboration and let your spirit soar with us at Mettamate!",
        styles:{
          // backgroundColor:'#ff7f00',
          color:'#ff7f00'
        },
        style:{
          backgroundColor:'#ff7f00',
          color:'white'
        },

    },
    {
      imageUrl: communitymemberImg,
      title: 'Join as a',
      subtitle: 'Community Member',
      btnColor: 'btn btn-primary mt-70',
      textColor: 'text-primary',
      contentText:
        'Community members are the heartbeat of Mettamate, each one a vibrant thread in the tapestry of connection and support that weaves us all together. Together, we shine brighter, lift each other higher, and create a symphony of positivity that resonates far and wide. Join us, be a part of something extraordinary, be a Mettamate community member today!',
    },
  ];
  return (
    <React.Fragment>
      <section className="py-3 bg-white">
        <Container className="mt-70 ">
          {/* section title */}
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="">
                <h1 className="title mb-0"> Find your community</h1>
              </div>
            </Col>
          </Row>

          <Row>
            {studioWorksData.map((work, key) => (
              <Col md={6} key={key} className="col-12 mt-4 pt-2">
                <Card className="border-0">
                  <CardBody className="p-0 ">
                    <Link to="/portfolio-detail-one">
                      <img
                        src={work.imageUrl}
                        className="img-fluid rounded work-image"
                        alt="work"
                      />
                    </Link>
                    <div className=" pt-3">
                      <h2 className="mb-0">
                        <Link
                          to="/user-registration-page"
                          className="text-dark title"
                        >
                          {work.title}{' '}
                          <span className={work.textColor} style={work.styles} >
                            {work.subtitle}
                          </span>
                        </Link>
                      </h2>
                      <p className="title-heading para-desc fontSize18">
                        {work.contentText}
                      </p>
                      <div className="pt-3">
                        <Link
                          to="/user-registration-page"
                          className={work.btnColor}
                          style={work.style}
                        >
                          {' '}
                          Join Now{' '}
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="">
                <h1 className="title mb-0 mt-100">Our Services</h1>
              </div>
            </Col>
          </Row>

          <Row className="shadshadow-md p-3 mb-5 bg-body rounded">
            {blogs.map((blog, key) => (
              <Col lg="4" md="6" className="mb-4 pb-2" key={key} name="blog">
                <Card className="blog rounded border-0 shadow overflow-hidden">
                  <div className="position-relative">
                    <CardImg
                      top
                      src={blog.image}
                      className="rounded-top"
                      alt=""
                    />
                    <div className="overlay rounded-top bg-dark"></div>
                  </div>
                  <CardBody className="content">
                    <h5>
                      <Link to="#" className="card-title title text-dark">
                        {blog.title}
                      </Link>
                    </h5>
                    <div className="post-meta d-flex justify-content-between mt-3">
                      <Link
                        // to="/page-blog-detail"
                        className="text-muted readmore"
                      >
                        {/* Read More <i className="uil uil-angle-right-b"></i> */}
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <h1 className=" mb-3  text-center ">
              What inspired the creation of{' '}
              <span className="text-primary">MettaMate</span>
            </h1>
            <div className="blockquote-wrapper">
              <div className="blockquote p-4 p-md-5 rounded-lg position-relative">
                <h5 style={{fontWeight:'400'}}>
                  The creation of MettaMate was inspired by the concept of
                  fostering connections and relationships in a safe and
                  inclusive environment, particularly for Ambedkarites
                  individuals. The platform aims to provide a space for
                  community members right from matrimony, network building,
                  catering to the needs of men, women, and individuals seeking
                  meaningful connections in both personal and professional
                  spheres. The name "MettaMate" itself suggests a focus on
                  companionship, friendship, and networking, drawing from the
                  idea of "Metta" representing loving and kindness and "Mate"
                  symbolizing partnership and connection. This combination
                  reflects the platform's vision of bringing people together,
                  supporting them in finding compatible partners and building
                  valuable networks.
                </h5>
                <div
                  className="position-absolute start-0"
                  style={{ zIndex: '-1', top: '-30px' }}
                >
                  <i
                    className="mdi mdi-format-quote-open text-primary opacity-1"
                    style={{ fontSize: '100px' }}
                  ></i>
                </div>
              </div>
              <h6 className="author">- MettaMate Team</h6>
            </div>

            {/* <blockquote className="blockquote mt-3 p-3 mb-5">
              <br />
              <p className=" mb-0 fst-italic">
                The creation of MettaMate was inspired by the concept of
                fostering connections and relationships in a safe and inclusive
                environment, particularly for educated individuals who may be
                lacking support. The platform aims to provide a space for
                matrimony and network building, catering to the needs of men,
                women, and individuals seeking meaningful connections in both
                personal and professional spheres. The name "MettaMate" itself
                suggests a focus on companionship, friendship, and networking,
                drawing from the idea of "Metta" representing loving and
                kindness and "Mate" symbolizing partnership and connection. This
                combination reflects the platform's vision of bringing people
                together, supporting them in finding compatible partners and
                building valuable networks.
              </p>
              <br /> */}
            {/* <p className=" mb-0 fst-italic text-center">- MettaMate Team</p>
            </blockquote>*/}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Modals;
