import { HmacSHA256, SHA256 } from 'crypto-js';
import React, { useEffect, useState, useMemo } from 'react';
import Talk from 'talkjs';


const Chat = () => {

    const [me, setMe] = useState();
    const [other, setOther] = useState();
    const [session, setSession] = useState();


    useEffect(() => {
        let ownerData = JSON.parse(localStorage.getItem("configurationData"));

        const me = new Talk.User({
            id: ownerData.memberuuid,
            name: ownerData.firstname,
            role: 'default',
            email: ownerData.email,
            photoUrl : localStorage.getItem("profilePicture")
        });

        setMe(me);

        const session = new Talk.Session({
            appId: 'Dlxn8mVe',
            me: me,
            signature: HmacSHA256(me.id, 'sk_live_ihDM7Wf5qL63i64fiXMify1DVx50sEQo').toString()
        });
        setSession(session);
        const conversation = session.getOrCreateConversation(Talk.oneOnOneId(me, other));
        conversation.setParticipant(me);
        const inbox = session.createInbox();
        // inbox.select(null);
        inbox.mount(document.getElementById('talkjs-container'));
    }, [])

    return (

        <>

            <div id="talkjs-container" style={{ marginTop: '100px', height: '500px', width: `316px` }}>
                <i>Loading chat...</i>
            </div>

        </>
    )
}

export default Chat;
