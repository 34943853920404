import corporate from '../../assets/images/demo/corporate.png';
import crypto from '../../assets/images/demo/crypto.png';
import shop from '../../assets/images/demo/shop.png';
import portfolio from '../../assets/images/demo/portfolio.png';
import helpCenter from '../../assets/images/demo/help-center.png';
import hosting from '../../assets/images/demo/hosting.png';
import job from '../../assets/images/demo/job.png';
import forums from '../../assets/images/demo/forums.png';
import blog from '../../assets/images/demo/blog.png';
import nft from '../../assets/images/demo/nft.png';

//import networkicon from '../../assets/images/network-icon.png';
//import empowericon from '../../assets/images/empower-icon.png';
//import messegingicon from '../../assets/images/messeging-icon.png';

const keyConstantMenus = [
  {
    id: 1,
    title: 'Login',
    link: '/user-login-page',
    icon: 'log-in',
    linkClassName: 'rounded btn btn-warning py-1  d-flex',
    styles:{
      backgroundColor:'#ff7f00',
      color:'white'
    }
  },
  {
    id: 2,
    title: 'Register',
    link: '/user-registration-page',
    icon: 'edit',
    linkClassName: 'rounded btn btn-primary py-1  d-flex',
  },
];

const keyConstantMenusAfterLogin = [
  {
    id: 1,
    title: 'Messaging',
    link: '/chats',
    icon: 'message-circle',
    linkClassName: 'rounded btn btn-warning py-1  d-flex',
    liClassName: '',
  },
  {
    id: 2,
    title: 'Profile',
    link: '/my-profile-edit-page',
    icon: 'edit',
    linkClassName: 'me="nav-link',
    liClassName: 'nav-item list-inline-item mb-0 d-f',
  },
  {
    id: 3,
    title: 'Logout',
    link: '/user-login-page',
    icon: 'log-out',
    linkClassName: 'me="nav-link',
    liClassName: 'nav-item list-inline-item mb-0 d-f',
  },
];

const navLinks = [
  //Note : each child and nested child must have unique id
  {
    id: 1,
    title: 'Home',
    link: '/',
    linkClassName: 'rounded btn btn-warning py-1  d-flex',
  },
  {
    id: 2,
    title: 'Services',
    link: '/#',
    linkClassName: 'rounded btn btn-warning py-1  d-flex',
  },
  {
    id: 3,
    title: 'Blogs',
    link: '/blogs',
    linkClassName: 'rounded btn btn-warning py-1  d-flex',
  },
];

// Alliance Seeker
const sessionnavLinks = [
  //Note : each child and nested child must have unique id
  {
    id: 1,
    title: 'Find your Community',
    link: '/communitymember-home-page',
    logowidth: '18px',
    isMegaMenu: true,
    icon: 'fa-sitemap',
    linkClassName: 'rounded btn btn-primary py-1  d-flex',
  },
  {
    id: 2,
    title: 'Home',
    link: '/allainceseeker-home-page',
    logowidth: '18px',
    img: 'home',
    icon: 'fa-home',
    linkClassName: 'rounded btn btn-warning py-1  d-flex',
  },
  {
    id: 3,
    title: 'My Network',
    link: '/connections',
    logowidth: '18px',
    img: 'home',
    icon: 'fa-home',
    linkClassName: 'rounded btn btn-warning py-1  d-flex',
  },
  {
    id: 4,
    title: 'Messaging',
    link: '/chats',
    logowidth: '18px',
    img: 'messages',
    icon: 'fa-home',
    linkClassName: 'rounded btn btn-primary py-1  d-flex',
  },
];

// Community Members
const communityMsessionnavLinks = [
  //Note : each child and nested child must have unique id
  {
    id: 1,
    title: 'Seek an Alliance',
    link: '/allainceseeker-home-page',
    isMegaMenu: true,
    img: 'home',
    icon: 'edit',
    linkClassName: 'btn btn-soft-warning py-1  d-flex',
  },
  {
    id: 2,
    title: 'Home',
    link: '/communitymember-home-page',
    img: 'home',
    icon: 'edit',
    linkClassName: 'btn btn-soft-primary py-1  d-flex',
  },
  {
    id: 3,
    title: 'My Network',
    link: '/connections',
    img: 'home',
    icon: 'edit',
    linkClassName: 'btn btn-soft-primary py-1  d-flex',
  },
  {
    id: 4,
    title: 'Messaging',
    link: '/chats',
    logowidth: '18px',
    img: 'messages',
    icon: 'fa-home',
    linkClassName: 'rounded btn btn-primary py-1  d-flex',
  },
];

export {
  navLinks,
  sessionnavLinks,
  communityMsessionnavLinks,
  keyConstantMenus,
  keyConstantMenusAfterLogin,
};
