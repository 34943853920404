import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Card, CardBody, CardImg } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

class CandidateBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.candidates.map((candidate, key) => (
          <Col key={key} lg="3" md="6" className="mt-4 pt-2">
            <Card className="team team-primary text-left border-0">
              <CardBody>
                <div className="position-relative">
                  <CardImg
                    top
                    src={candidate.image}
                    className="rounded-top"
                    alt=""
                    style={{ maxHeight:'90%',maxWidth:'100%',objectFit:'contain'}}
                  />
                  <div className="overlay rounded-top bg-dark"></div>
                </div>
                <div className="position-relative">
                  <ul className="list-unstyled mb-0 team-icon">
                    {candidate.socialIds.map((socialId, key) => (
                      <li key={key} className="list-inline-item me-1">
                        <Link
                          to={socialId.link}
                          className="btn btn-primary btn-pills btn-sm btn-icon"
                        >
                          <i>
                            <FeatherIcon
                              icon={socialId.icon}
                              className="fea icon-sm fea-social"
                            />
                          </i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className=" pt-3 pb-3">
                  <h5 className="mb-0 pb-2 ">
                      {candidate.name}
                  </h5>
                  <p className="designation " style={{fontSize:'16px' }}>{candidate.designation}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default CandidateBox;
