import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import apiUrl from "../server";
import { Button, Card, CardBody, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/userHomePage.css'
import { Link, useNavigate } from 'react-router-dom';

import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import ProfileModal from '../Home/Profile_modal';
import ModalBodySkeleton from './skeleton';
import CommonModal from '../Home/modal' 

import manavatar from '../../assets/images/MettaMateImages/profileImages/maleAIIamge1.png';
import femaleavatar from '../../assets/images/MettaMateImages/profileImages/femaleAIImage3D.jpg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';
import ChatBoxFunction from '../Home/chat/chatbox'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Cluster, Clusterer } from "@react-google-maps/marker-clusterer";

const MapFunction = (props) => {
  const navigate = useNavigate();

  let configureData = JSON.parse(localStorage.getItem('configurationData'));

  const [activeMarker, setActiveMarker] = useState(null);
  const [chatMemberData, setChatMemberData] = useState({
    memberuuid: '',
    firstname: '',
    lastname: '',
    gendercode: 0,
    memberstatuscode: 0,
    memberstatusname: '',
    gendername: '',
    photopath1: '',
    photopath2: '',
    photopath3: '',
    subscription: '',
    jobprofession: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    recommended: 0,
});
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  useEffect(() => {
    configureData = JSON.parse(localStorage.getItem('configurationData'));
    getAllMemberDetails();
  }, [])

  const mapRef = useRef(null);


  const [memberData, setMemberData] = useState([]);
  const [currentaddress, setCurrentAddress] = useState('');
  const [selectedPlaces, setSelectedPlace] = useState([]);
  const [selectedplaceId, setSelectedplaceId] = useState([]);
  const [currentmapvalue, setcurrentmapValue] = useState(null);
  const [currentcity, setcurrentCity] = useState('');
  const [currentstate, setcurrentState] = useState('');
  const [currentcountry, setcurrentCountry] = useState('');
  const [currentLocation, setCurrentLocation] = useState({});
  const [nativeLocation, setNativeLocation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [memberFullData, setMemberFullData] = useState([]);
  const [memberModal, setModal] = useState(false);
  const [isModalLoading, setModalLoading] = useState(true);
  const [copyModalOpen, setcopyModalOpen] = useState(false);
  const [sharedMemberUuid, setsharedMemberUuid] = useState({});
  const [copyButton, setCopyButton] = useState('Copy Url')
  const [pageUrl, setpageUrl] = useState({});
  const [dob, setDOB] = useState({});
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [confirmConnectionmodalOpen, setconfirmConnectionmodalOpen] = useState(false)
  const [selectedMemberUUID, setselectedMemberUUID] = useState({});


  const handleCurrentAddressChange = (address) => {
    setCurrentAddress(address);
  };
  const openCopyModal = (memberdata) => {
    setcopyModalOpen(true);
    setsharedMemberUuid(memberdata.memberuuid)

  }
  const toggleCopyModal = () => {
    setcopyModalOpen(false);
  };

  const handleCopyText = () => {
    const urlToCopy = `${pageUrl}index/${sharedMemberUuid}`;
    const textArea = document.createElement('textarea');
    textArea.value = urlToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopyButton('Copied!')
    setTimeout(() => {
      setCopyButton('Copy Url')
    }, 3000);
  };

  const handleCurrentAddressSelect = async (selected) => {
    const results = await geocodeByAddress(selected);
    const latLng = await getLatLng(results[0]);

    const { location, bounds } = results[0].geometry;
    // console.log(location , bounds.getNorthEast() , '\n' , bounds.getSouthWest().lat())


    mapRef.current.fitBounds(bounds);
    setTimeout(() => {
      mapRef.current.panToBounds(bounds, 100); // Adjust the duration as needed
    }, 100);
    // const center = bounds.getCenter();

    // // Use animateCamera for smooth zoom animation
    // mapRef.current.animateCamera({
    //   center,
    //   zoom: 5, // Adjust the zoom level as needed
    //   duration: 1000, // Animation duration in milliseconds
    // });


  };


  // getting members data
  const getAllMemberDetails = async () => {
    try {
      const response = await axios.post(`${apiUrl}members/getallmembers`, {
        pageNo: 1,
        pageSize: 100,
        memberuuid: localStorage.getItem('memberID'),
        membertype: props.membertype
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      let memberdata = response.data.details.filter(element => element.currentlocationid != '')
      await fetchLocationDetails(memberdata);

    } catch (error) {
      console.error('Error fetching members:', error);
    }
  }
  // get members data 

  // get coordinates of members
  const fetchLocationDetails = async (memberdata) => {
    for (let member of memberdata) {
      let placeid = member.currentlocationid;
      const apiKey = 'AIzaSyAF8Wpa6HaYM0Sp3B-RnnO8wtpYoTIV-2Q';
      const geocodingEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeid}&key=${apiKey}`;
      let locationRes = await axios.get(geocodingEndpoint).then(response => {
        const data = response.data;
        if (data.status === 'OK' && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          member.location = location
        } else {
          console.error('Error getting coordinates:', data.status);
        }
      }).catch(error => {
        console.error('Axios error:', error);
      });
    }
    setMemberData(memberdata);
  }
  // get coordinates of members

  const handleOnLoad = (map) => {

    mapRef.current = map;
    const center = {
      lat: 20.5937,
      lng: 78.9629
    };
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    console.log(mapRef.current)

    if (mapRef.current) {

      setTimeout(() => {
        // Set the extent (bounds) of the map to cover a specific area
        const bounds = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(8.18, 68.09), // Southwest corner
          new window.google.maps.LatLng(37.09, 97.4) // Northeast corner
        );

        // Access the map instance using mapRef and set the extent
        if (mapRef.current) {
          mapRef.current.fitBounds(bounds);

          // mapRef.current.setZoom(3);
          mapRef.current.setOptions({
            minZoom: 3,
            maxZoom: 15,
            restriction: {
              latLngBounds: {
                south: -85,
                west: -179,
                north: 85,
                east: 180,
              },
              strictBounds: false,
            },
          });
        }
      }, 3000);
    }

  };

  const handleActiveMarker = (marker) => {
    console.log(marker)
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };
  const displayImage = (imagepath, gender) => {
    if (!imagepath) {
      if (gender == "Male") { return manavatar }
      else if (gender == "Female") { return femaleavatar }
      else if (gender == "Other") { return transgenderavatar }
    }
    else { return imagepath }
  }
  const calculate_age = (dob1) => {
    if (dob1 != null) {
      var today = new Date();
      var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return age_now ? age_now : 'XX';
    }
    else {
      return 'XX'
    }
  }
  const openModal = async (memberuuid) => {
    setModal(!memberModal);
    try {
      const result = await axios.get(`${apiUrl}members/fulldetails/${memberuuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const { data } = result;
      if (data && data.length > 0) {
        const member = data[0];
        const date = new Date(member.dob);
        setDOB(`${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`);
        setNativeLocation(member.nativelocationid ? await handlePlaceId(member.nativelocationid) : 'Not known');
        setCurrentLocation(member.currentlocationid ? await handlePlaceId(member.currentlocationid) : 'Not known');
        setCompanyLocation(member.companylocationid ? await handlePlaceId(member.companylocationid) : 'Not known ');
        setModalLoading(false)
        setMemberFullData(member);
      }
    } catch (error) {
      console.error("Error fetching member details: ", error);
    }
  }
  const closeModal = () => {
    setModal(false)
  }
  const getOrdinalSuffix = (day) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }
  const getMonthName = (month) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[month];
  }
  const handlePlaceId = async (placeId) => {
    try {
      return new Promise((resolve, reject) => {
        const service = new window.google.maps.places.PlacesService(
          document.createElement('div')
        );
        service.getDetails({ placeId }, (place, status) => {
          if (status === 'OK' && place && place.formatted_address) {
            const address = place.formatted_address;
            resolve(address);
          } else {
            reject(new Error('Place details not found'));
          }
        });
      });
    } catch (error) {
      console.error('Error fetching place details:', error);
      throw error;
    }
  };
  const redirectToSubscription = () => {
    navigate('/subscriptionPlan')
  }


  const [clusterer, setClusterer] = useState(null);
  const [clusterInfo, setClusterInfo] = useState(null);

  const [clusterPopupInfo, setClusterPopupnfo] = useState([]);

  const onClustererLoad = (clusterer) => {
    setClusterer(clusterer);
  };

  const handleClusterClick = (cluster) => {
    let clusterdata = new Cluster(clusterer);
    clusterdata = cluster;
    setClusterInfo(clusterdata);

    let memberUUIDList = clusterdata.getMarkers().map(element => element.getTitle())
    setClusterPopupnfo(memberData.filter((element) => memberUUIDList.includes(element.memberuuid)));
    setTimeout(() => {
      console.log('cluster popup info is', clusterPopupInfo)
    }, 5000);
  };

  const getClusterCenter = (cluster) => {
    const bounds = new window.google.maps.LatLngBounds();
    cluster.getMarkers().forEach((marker) => bounds.extend(marker.getPosition()));
    return bounds.getCenter();
  };
  const openConnectionModal = (connectedmemberuuid, isConnected) => {
    if (configureData.subscription != 'Basic' && isConnected == '') {
      setselectedMemberUUID(connectedmemberuuid);
      setconfirmConnectionmodalOpen(true);

    }
    else if ((configureData.subscription == 'Gold' || configureData.subscription == 'Platinum') && (isConnected == 'Connecting' || isConnected == 'Connected')) {

    }
    else if (configureData.subscription == 'Basic') {
      redirectToSubscription()
    }
  }
  const closeConnectionModal = () => {
    setconfirmConnectionmodalOpen(false)
  }
  const requestForConnection = async () => {
    try {
      let request = {
        memberuuid: localStorage.getItem('memberID'),
        connectedmemberuuid: selectedMemberUUID
      }
      let result = await axios.post(`${apiUrl}connection/requestforconnection`, request, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }).then(async (res) => {
        setSuccess({
          title: 'SUCCESS!',
          message: res.data.message,
        });
        getAllMemberDetails();
        setconfirmConnectionmodalOpen(false)
      })
    } catch (error) {
      setError({
        title: 'An Error Occured!',
        message: error.message,
      });
    }
  }
  const errorHandler = () => {
    setError(null);
  };

  const successHandler = () => {
    setSuccess(null);
  };
  const openShortChat = (memberdata) => {
    setChatMemberData(memberdata)
    if (document.getElementById('chatbox').style.display == 'none') {
      document.getElementById('chatbox').style.display = '';
    }

  }

  const closeShortChat = () => {
    document.getElementById('chatbox').style.display = 'none';
  }
  return (

    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <Row className="mt-3 mb-3">
        <Col md={4}></Col>
        <Col md={4}>
          <PlacesAutocomplete
            value={currentaddress}
            onChange={handleCurrentAddressChange}
            onSelect={handleCurrentAddressSelect}
            className="form-control form_input"
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              const uniqueSuggestions = new Set();

              return (


                <div >
                  <input
                    {...getInputProps({
                      placeholder: 'Search Places ...',
                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';

                      const words = suggestion.description.split(',');
                      const trimmedArray = words.map((str) => str.trim());
                      const lastThreeWords = trimmedArray
                        .slice(-3)
                        .join(',');
                      // Check if the suggestion is unique based on place_id
                      if (!uniqueSuggestions.has(lastThreeWords)) {
                        uniqueSuggestions.add(lastThreeWords);

                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer',
                          }
                          : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                          };

                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{lastThreeWords}</span>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>

              );
            }}
          </PlacesAutocomplete>
        </Col>
        <Col md={4}></Col>
      </Row>

      <GoogleMap
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        mapContainerStyle={{ width: "100%", height: "100vh" }}
        ref={mapRef}
      >


        <MarkerClusterer onLoad={onClustererLoad} onClick={(clusterer) => handleClusterClick(clusterer)} options={{
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', zoomOnClick: false
        }}>
          {(clusterer) =>
            memberData.map((element) => (
              <Marker key={element.memberuuid} title={element.memberuuid} position={element.location} clusterer={clusterer} label={{ text: element.firstname, fontSize: '15px', fontWeight: 'bold', className: 'markerlabel' }}
                icon={{
                  url: element.photothumb1 ? element.photothumb1 : (element.gender.toLowerCase() === 'female' ? femaleavatar : manavatar),
                  scaledSize: new window.google.maps.Size(40, 40), // Adjust the size of the image
                  anchor: new window.google.maps.Point(15, 30),

                }}
                onClick={() => handleActiveMarker(element.memberuuid)}>

                {/* for icon in marker */}
                <div style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  overflow: 'scroll !important',
                  border: '2px solid #fff', // Add a border for better visibility
                }}>
                  <img
                    src={element.photothumb1 || (element.gender.toLowerCase() === 'female' ? femaleavatar : manavatar)}
                    alt="Marker"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                </div>

                {/* for icon in marker */}
                {/* Your marker components go here */}

                {activeMarker === element.memberuuid ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>


                    <Card className="card catagories  rounded border-0 small">
                      <ul className="list-unstyled align-items-center position-absolute p-1" style={{ zIndex: `9`, marginBottom: `0px` }}>

                        <li className="rounded-pill text-dark border bg-light px-2 border-1 border-dark font_size14">
                          {(element.maritalstatus == '' || element.maritalstatus == null) && (
                            <span className='font_size14'> Unknown Status</span>
                          )}
                          {element.maritalstatus != '' && (
                            <span className='font_size14'>  {element.maritalstatus}</span>
                          )}
                        </li>
                      </ul>

                      <Slider className='imageSlider ' {...settings}>
                        <img src={displayImage(element.photothumb1, element.gender)} className='img-fluid slider_img' />
                        <img src={displayImage(element.photothumb2, element.gender)} className='img-fluid slider_img ' />
                        <img src={displayImage(element.photothumb3, element.gender)} className='img-fluid slider_img' />
                      </Slider>

                      <CardBody >
                        <p className="text-dark h5 name mb-0">
                          <span style={{ color: `#0D6EFD` }}>{element.firstname} </span>
                        </p>
                        <p className="m-0 font_size14">
                          <i className="uil uil-graduation-cap h4 mb-0 me-2 text-primary"></i>
                          {element.jobprofession ? element.jobprofession : "Unknown Profession"} </p>

                        <div className=' d-flex justify-content-center'>
                          <div className='m-1'>
                            <button title='View Profile' onClick={() => openModal(element.memberuuid)} className="btn  designation btn-outline-primary viewBtn">
                              <FeatherIcon
                                icon="eye"
                                className="fea icon-sm icons" />
                            </button>
                          </div>
                          <div className='m-1'>
                            <button title='Message' onClick={() => openShortChat(element)} className="btn  designation btn-outline-info viewBtn">
                              <i className="fa fa-comments" ></i>
                            </button>
                          </div>
                          <div className='m-1'>
                            {!element.connectionstatus &&
                              <button title='Send Connection Request' className='btn btn-outline-success' onClick={() => openConnectionModal(element.memberuuid, element.connectionstatus)}>
                                <span><i className="fa fa-handshake-o" aria-hidden="true"></i></span>
                              </button>
                            }
                            {element.connectionstatus == 'Connecting' &&
                              (<button title='Waiting To Connect' className='btn btn-outline-danger'>
                                <span><i className="fa fa-exchange" aria-hidden="true"></i></span>
                              </button>)}

                            {element.connectionstatus == 'Connected' &&
                              <button className='btn btn-outline-success' title='Connected'>
                                <FeatherIcon
                                  icon="user-check"
                                  className="fea icon-sm icons " />
                              </button>
                            }
                          </div>
                        </div>
                      </CardBody>
                    </Card>

                  </InfoWindow>
                ) : null}
              </Marker>
            ))
          }
        </MarkerClusterer>
        {clusterInfo && (
          <InfoWindow
            position={getClusterCenter(clusterInfo)} // Use getClusterCenter to get the center of the cluster's bounds
            onCloseClick={() => setClusterInfo(null)}>
            <>
              <div style={{ "overflowY": "auto" }}>
                {(clusterPopupInfo || []).map((item, key) => (
                  <div key={key} >
                    <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary job-box job-primary candidate-list  border-0 shadow card mt-2">
                      <CardBody className='rounded overflow-hidden p-0 '>
                        <ul className="list-unstyled align-items-center  position-absolute  " style={{ zIndex: `9`, marginBottom: `0px` }}>
                          <li className="rounded-pill text-dark border bg-light px-2 border-1 border-dark font_size14">
                            {item.maritalstatus}
                          </li>
                        </ul>

                        <Slider className='imageSlider image-container' {...settings}>
                          <img src={displayImage(item.photothumb1, item.gender)} className='img-fluid img_thumb' />
                          <img src={displayImage(item.photothumb2, item.gender)} className='img-fluid img_thumb' />
                          <img src={displayImage(item.photothumb3, item.gender)} className='img-fluid img_thumb' />
                        </Slider>

                      </CardBody>
                    </Card>
                    <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow text-center">
                      <CardBody className='rounded shadow p-2 '>
                        <p className="text-dark h5 name mb-0">
                          <span style={{ color: `#0D6EFD` }}>{item.firstname} </span>
                        </p>
                        <p className="m-0 font_size14">
                          <i className="uil uil-graduation-cap h4 mb-0 me-2 text-primary"></i>
                          {item.jobprofession ? item.jobprofession : "Unknown Profession"} </p>

                        <div className=' d-flex justify-content-center'>
                          <div className='m-1'>
                            <button title='View Profile' onClick={() => openModal(item.memberuuid)} className="btn  designation btn-outline-primary viewBtn">
                              <FeatherIcon
                                icon="eye"
                                className="fea icon-sm icons" />
                            </button>
                          </div>
                          <div className='m-1'>
                            <button title='Message' onClick={() => openShortChat(item)} className="btn  designation btn-outline-info viewBtn">
                              <i className="fa fa-comments" ></i>
                            </button>
                          </div>
                          <div className='m-1'>
                            {!item.connectionstatus &&
                              <button title='Send Connection Request' className='btn btn-outline-success' onClick={() => openConnectionModal(item.memberuuid, item.connectionstatus)}>
                                <span><i className="fa fa-handshake-o" aria-hidden="true"></i> Connect</span>
                              </button>
                            }
                            {item.connectionstatus == 'Connecting' &&
                              (<button title='Waiting To Connect' className='btn btn-outline-danger'>
                                <span><i className="fa fa-exchange" aria-hidden="true"></i>Connecting</span>
                              </button>)}

                            {item.connectionstatus == 'Connected' &&
                              <button className='btn btn-outline-success' title='Message'>
                                <FeatherIcon
                                  icon="message-circle"
                                  className="fea icon-sm icons " /> Message
                              </button>
                            }
                          </div>
                        </div>


                      </CardBody>
                    </Card>
                  </div>
                ))}
              </div>
              <div>
              </div>
            </>
          </InfoWindow>
        )}

      </GoogleMap>
      <div id='chatbox' style={{ display: "none", position: "fixed", bottom: '20px', right: '20px', zIndex: 99999, width: "300px" }}>
        <div className='card'>
          <div className='card-header'>
            <h6 className='m-0'> Messages
              <button className='btn btn-sm btn-link float-end' onClick={() => closeShortChat()}>
                <i className='fa fa-times fa-2x position-static'></i>
              </button>
            </h6>
          </div>
          <div className='card-body p-0'>
            <ChatBoxFunction memberdata={chatMemberData} />
          </div>
        </div>
      </div>
      <Modal isOpen={memberModal}
        size="xl">
        <ModalHeader toggle={openModal}>Profile Details
          <button type="button" className="close" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
          <button type="button" className="btn shareBtn" onClick={() => openCopyModal(memberFullData)} >
            <span aria-hidden="true" className='d-flex'>Share This Profile {' '}
              <i>
                <FeatherIcon
                  icon="share-2"
                  className="fea icon-sm icons px-1" style={{ width: `30px` }}
                />
              </i>
            </span>
          </button>
        </ModalHeader>
        {isModalLoading == true ? (
          <ModalBody>
            <ModalBodySkeleton />
          </ModalBody>
        ) : (
          <div>
            {memberFullData && Object.keys(memberFullData).length > 0 && (
              <ProfileModal memberData={memberFullData} location={[nativeLocation, currentLocation, companyLocation]} dob={dob} />
            )}
          </div>

        )}
      </Modal>

      <Modal isOpen={copyModalOpen} toggle={toggleCopyModal}>
        <ModalHeader toggle={toggleCopyModal}>Share Url
          <button type="button" className="btn shareBtn shareUrlbtn btn-dark" onClick={handleCopyText} >
            <span aria-hidden="true">
              {copyButton == 'Copy Url' ? (
                <i>
                  <FeatherIcon
                    icon="copy"
                    className="fea icon-sm icons"
                  />
                </i>

              ) : (
                <i>
                  <FeatherIcon
                    icon="check"
                    className="fea icon-sm icons"
                  />
                </i>

              )}
              {' '}{copyButton}

            </span>
          </button>
          <button type="button" className="close" aria-label="Close" onClick={toggleCopyModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody className='copy_Modal_body'>
          <Row>
            <Col lg="12">
              <p className='mb-0'>{pageUrl}{'index/'}{sharedMemberUuid}</p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal isOpen={confirmConnectionmodalOpen} toggle={closeConnectionModal}>
        <ModalHeader toggle={closeConnectionModal}>Confirmation
          <button type="button" className="close" aria-label="Close" onClick={closeConnectionModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className='mb-0'>Are You Sure to Connect?</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className='p-1'>
          <Button
            color="secondary"
            onClick={closeConnectionModal}
            type="button"
          >
            Close
          </Button>
          <Button color="success" type="button" onClick={requestForConnection}>
            Connect
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>

  )
}

export default MapFunction;