import React, { useEffect, useState } from 'react';
import '../../Home/css/userHomePage.css';
import CommonModal from '../modal.js';
import apiUrl from '../../server.js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ChatBoxFunction from '../chat/chatbox.jsx';

import FeatherIcon from 'feather-icons-react';
import {
    Row, Col, CardBody, Card,
    Label,
    Input,
    Button, Modal, ModalHeader, ModalBody, ModalFooter, PaginationItem, PaginationLink
} from 'reactstrap';
import Slider from 'react-slick';
import Sliderr, { Range } from 'rc-slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import manavatar from '../../../assets/images/MettaMateImages/profileImages/maleAIIamge1.png';
// import femaleavatar from '../../../assets/images/MettaMateImages/profileImages/femaleAIImage3D.jpg';

import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg'
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg'
import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';
import 'rc-slider/assets/index.css';
import ProfileModal from '../../Home/Profile_modal.jsx';
import ContentLoader, { List } from 'react-content-loader';
import ModalBodySkeleton from '../../Home/skeleton.jsx';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import filterIcon from '../../../assets/images/MettaMateImages/filter.png';
import ConnectingIcon from '../../../assets/images/MettaMateImages/connecting.png';

const MyConnectionsFunction = () => {
    const [myconnectionList, setMyConnectionList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [likedmemberuuid, setlikedmemberuuid] = useState({});
    const [likeStatus, setLikedStatus] = useState('Like');
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [memberModal, setModal] = useState(false);
    const [memberFullData, setMemberFullData] = useState([]);
    const [dob, setDOB] = useState({});
    const [currentLocation, setCurrentLocation] = useState({});
    const [nativeLocation, setNativeLocation] = useState({});
    const [companyLocation, setCompanyLocation] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isModalLoading, setModalLoading] = useState(true);
    const [copyModalOpen, setcopyModalOpen] = useState(false);
    const [sharedMemberUuid, setsharedMemberUuid] = useState({});
    const [copyButton, setCopyButton] = useState('Copy Url');
    const [search, setsearch] = useState('')
    const [pageUrl, setpageUrl] = useState({});
    const [refreshpage, setrefreshPage] = useState(false);
    const [connectionmodalOpen, setConnectionmodalOpen] = useState(false);
    const [activeFilterLink, setActiveFilterLink] = useState('');
    const [filtermodalOpen, setFilterModalopen] = useState(false);
    const [rangeValues, setRangeValues] = useState([0, 100]);
    const [degreeLevelList, setdegreeLevelList] = useState([]);
    const [degreeNameList, setdegreeNameList] = useState([]);
    const [religionList, setreligionList] = useState([]);
    const [occupationList, setoccupationList] = useState([]);

    // const [serviceList, setserviceList] = useState([]);
    // const [showAllService, setShowAllService] = useState(false);
    const [currentAddress, setCurrentAddress] = useState('');
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const [mentorshipList, setmentorshipList] = useState(
        [{ value: 1, label: 'Mentor' }, { value: 2, label: 'Mentee' }])
    const [genderList, setgenderList] = useState([]);

    const [showAllDegreeNames, setShowAllDegreeNames] = useState(false);
    const [showAllreligion, setshowAllreligion] = useState(false);
    const [showAllOccupation, setshowAllOccupation] = useState(false);

    const visibleDegreeNameList = showAllDegreeNames ? degreeNameList : degreeNameList.slice(0, 3);
    const visiblereligionList = showAllreligion ? religionList : religionList.slice(0, 3);
    const visibleOccupationList = showAllOccupation ? occupationList : occupationList.slice(0, 3);
    const [otherFilterRequestBody, setOtherFilterRequestBody] = useState({
        degreeLevelList: [],
        degreeNameList: [],
        genderList: [],
        mentorshipList: [],
        religionList: [],
        // serviceList: [],
        minage: 0,
        maxage: 100,
        locationidlist: [],
        occupationList: [],
        pageNo: 1,
        pageSize: 5,
        search: ''

    });
    const [chatMemberData, setChatMemberData] = useState({
        memberuuid: '',
        firstname: '',
        lastname: '',
        gendercode: 0,
        memberstatuscode: 0,
        memberstatusname: '',
        gendername: '',
        photopath1: '',
        photopath2: '',
        photopath3: '',
        subscription: '',
        jobprofession: '',
        linkedin: '',
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        snapchat: '',
        recommended: 0,
      });
    const [currentPage, setCurrentPage] = useState(1);
    // this state is for itemperpage
    const [pageSize, setPageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    useEffect(() => {
        getAllConnectionDetails(currentPage, pageSize);
        setpageUrl(apiUrl.replace('8080', '3000'))
    }, []);

    const openShortChat = (memberdata) => {
        setChatMemberData(memberdata);
        if (document.getElementById('chatbox').style.display == 'none') {
          document.getElementById('chatbox').style.display = '';
        }
      };
    
      const closeShortChat = () => {
        document.getElementById('chatbox').style.display = 'none';
      };
    
    const getAllConnectionDetails = async (currentPage, pageSize) => {
        setLoading(true);
        try { setFilterModalopen(false) } catch (error) { }
        otherFilterRequestBody.pageNo = currentPage;
        otherFilterRequestBody.pageSize = pageSize;
        const selectedPlacesIdOnly = selectedPlaces.map(place => place.placeid);;
        const updatedRequestBody = {
            ...otherFilterRequestBody,
            locationidlist: selectedPlacesIdOnly
        };
        try {
            const response = await axios.post(`${apiUrl}connection/connectedmembers`, updatedRequestBody, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            const allMembers = await Promise.all(response.data.details.map(async member => {
                try {
                    if (member.currentlocationid) {
                        const locationName = await getLocationName(member.currentlocationid);
                        member.currentlocationname = locationName || 'Unknown Location';
                    } else {
                        member.currentlocationname = 'Unknown Location';
                    }
                    return member;
                } catch (error) {
                    console.error('Error fetching location details:', error);
                    return null;
                }
            }));
            setMyConnectionList(allMembers.filter(member => member !== null));
            setLoading(false);
            setrefreshPage(false)
            setTotalCount(response.data.totalCount)
            setTotalPages(Math.ceil((response.data.totalCount / pageSize)));
        } catch (error) {
            console.error('Error fetching members:', error);
            setLoading(false);
        }
    }

    const getLocationName = async (placeId) => {
        try {
            const service = new window.google.maps.places.PlacesService(
                document.createElement('div')
            );
            return new Promise((resolve, reject) => {
                service.getDetails({ placeId }, (place, status) => {
                    if (status === 'OK') {
                        const address = place.formatted_address;
                        resolve(address);
                    } else {
                        reject(status);
                    }
                });
            });
        } catch (error) {

            console.error('Error fetching place details:', error);
            throw error;
        }
    };
    const calculate_age = (dob1) => {
        if (dob1 != null) {
            var today = new Date();
            var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age_now--;
            }
            return age_now ? age_now : '00';
        }
        else {
            return '00'
        }
    }
    const openProfileModal = async (memberuuid) => {
        setModal(!memberModal);
        try {
            const result = await axios.get(`${apiUrl}members/fulldetails/${memberuuid}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            const { data } = result;
            if (data && data.length > 0) {
                const member = data[0];
                const date = new Date(member.dob);
                setDOB(`${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`);
                setNativeLocation(member.nativelocationid ? await handlePlaceId(member.nativelocationid) : 'Unknown Location');
                setCurrentLocation(member.currentlocationid ? await handlePlaceId(member.currentlocationid) : 'Unknown Location');
                setCompanyLocation(member.companylocationid ? await handlePlaceId(member.companylocationid) : 'Unknown Location ');
                setMemberFullData(member);
                setModalLoading(false);
            }
        } catch (error) {
            console.error("Error fetching member details: ", error);
        }
    }
    const closeProfileModal = () => {
        setModal(!memberModal);
    }
    const getOrdinalSuffix = (day) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = day % 100;
        return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    }
    const getMonthName = (month) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[month];
    }
    const handlePlaceId = async (placeId) => {
        try {
            return new Promise((resolve, reject) => {
                const service = new window.google.maps.places.PlacesService(
                    document.createElement('div')
                );
                service.getDetails({ placeId }, (place, status) => {
                    if (status === 'OK' && place && place.formatted_address) {
                        const address = place.formatted_address;
                        resolve(address);
                    } else {
                        reject(new Error('Place details not found'));
                    }
                });
            });
        } catch (error) {
            console.error('Error fetching place details:', error);
            throw error;
        }
    };
    const errorHandler = () => {
        setError(null);
    };
    const successHandler = () => {
        setSuccess(null);
    };
    const favoriteMember = async () => {
        setOpenModal(false);
        setLoading(true)
        try {
            let request = {
                likedmemberuuid: likedmemberuuid,
                memberuuid: localStorage.getItem('memberID')
            }
            let result = await axios.post(`${apiUrl}members/favourites`, request, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then(async (res) => {
                getAllConnectionDetails();
            })

        } catch (error) {

        }
    }
    const openConfirmModal = (memberUUID, likedstatus) => {
        if (likedstatus === 1) {
            setlikedmemberuuid(memberUUID);
            setLikedStatus('Unlike');
            setOpenModal(true)
        }
        else {
            setlikedmemberuuid(memberUUID);
            setLikedStatus('Like');
            setOpenModal(true)
        }
    }
    const closelikeModal = () => {
        setOpenModal(false);
    }
    const openCopyModal = (memberdata) => {
        setcopyModalOpen(true);
        setsharedMemberUuid(memberdata.memberuuid)

    }
    const toggleCopyModal = () => {
        setcopyModalOpen(false);
    };
    const handleCopyText = () => {
        const urlToCopy = `${pageUrl}index/${sharedMemberUuid}`;
        const textArea = document.createElement('textarea');
        textArea.value = urlToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setCopyButton('Copied!')
        setTimeout(() => {
            setCopyButton('Copy Url')
        }, 3000);
    };
    const closeModal = () => {
        setModal(false)
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };
    const displayImage = (imagepath, gender) => {
        if (!imagepath) {
            if (gender.toLowerCase() == "male") { return manavatar }
            else if (gender.toLowerCase() == "female") { return femaleavatar }
            else { return transgenderavatar }
        }
        else { return imagepath }
    }
    const openConnectionModal = (memberuuid) => {
        setConnectionmodalOpen(true)
        setlikedmemberuuid(memberuuid);

    }
    const updateConnection = async () => {
        try {
            let request = {
                connectedmemberuuid: likedmemberuuid,
                memberuuid: localStorage.getItem('memberID'),
                status: 4
            }
            let result = await axios.post(`${apiUrl}connection/updateconnection`, request, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then(async (res) => {

                if (res.status === 200) {
                    setConnectionmodalOpen(false);
                    getAllConnectionDetails(currentPage, pageSize);
                    setSuccess({
                        title: 'SUCCESS!',
                        message: res.data.message,
                    });
                }
                else {
                    setConnectionmodalOpen(false);
                    setError({
                        title: 'An Error Occured!',
                        message: res.data.message,
                    });
                }
            })

        } catch (error) {

        }
    }
    const closeConnectionModal = () => {
        setConnectionmodalOpen(false);
        setlikedmemberuuid('')
    }
    const openfilterModal = async () => {

        setFilterModalopen(true);
        setActiveFilterLink('other')

        try {
            const [degreelevelRes, degreenamesRes, genderRes, religionRes, occupationRes] = await Promise.all([
                axios.get(`${apiUrl}codes/degreelevel`),
                axios.get(`${apiUrl}codes/degreenames`),
                axios.get(`${apiUrl}codes/gender`),
                axios.get(`${apiUrl}codes/religion`),
                axios.get(`${apiUrl}codes/occupationalcategory`)
            ]);

            setdegreeLevelList(degreelevelRes.data.list);
            setdegreeNameList(degreenamesRes.data.list);
            setgenderList(genderRes.data.list)
            setreligionList(religionRes.data.list);
            setoccupationList(occupationRes.data.list);
            // sbPartnerserviceList()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    // const sbPartnerserviceList = async () => {
    //     try {
    //         const response = await axios.get(apiUrl + 'members/servicesOfferinglistpublic', {
    //             headers: {
    //                 Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         setserviceList(response.data);
    //     } catch (error) {
    //         console.error('Error fetching servicelist:', error);
    //     }
    // }
    const handleDegreeLevel = (isChecked, response) => {
        setOtherFilterRequestBody(prevState => {
            let degreeLevelList;
            if (isChecked) {
                if (!prevState.degreeLevelList.includes(response)) {
                    degreeLevelList = [...prevState.degreeLevelList, response];
                } else {
                    degreeLevelList = [...prevState.degreeLevelList];
                }
            } else {
                degreeLevelList = prevState.degreeLevelList.filter(degreeLevel => degreeLevel !== response);
            }
            return {
                ...prevState,
                degreeLevelList: degreeLevelList
            };
        });
    };
    const handleDegreeNames = (isChecked, response) => {
        setOtherFilterRequestBody(prevState => {
            let degreeNameList;
            if (isChecked) {
                if (!prevState.degreeNameList.includes(response)) {
                    degreeNameList = [...prevState.degreeNameList, response];
                } else {
                    degreeNameList = [...prevState.degreeNameList];
                }
            } else {
                degreeNameList = prevState.degreeNameList.filter(degreeName => degreeName !== response);
            }
            return {
                ...prevState,
                degreeNameList: degreeNameList
            };
        });
    };
    const handleGender = (isChecked, response) => {
        setOtherFilterRequestBody(prevState => {
            let updatedGenderList;
            if (isChecked) {
                if (!prevState.genderList.includes(response)) {
                    updatedGenderList = [...prevState.genderList, response];
                } else {
                    updatedGenderList = [...prevState.genderList];
                }
            } else {
                updatedGenderList = prevState.genderList.filter(gender => gender !== response);
            }
            return {
                ...prevState,
                genderList: updatedGenderList
            };
        });
    };
    const handleMentor = (isChecked, response) => {
        setOtherFilterRequestBody(prevState => {
            let updatedList;
            if (isChecked) {
                if (!prevState.mentorshipList.includes(response)) {
                    updatedList = [...prevState.mentorshipList, response];
                } else {
                    updatedList = [...prevState.mentorshipList];
                }
            } else {
                updatedList = prevState.mentorshipList.filter(mentor => mentor !== response);
            }
            return {
                ...prevState,
                mentorshipList: updatedList
            };
        });
    };
    const handleReligion = (isChecked, response) => {
        setOtherFilterRequestBody(prevState => {
            if (isChecked) {
                return {
                    ...prevState,
                    religionList: [...prevState.religionList, response]
                };
            } else {
                return {
                    ...prevState,
                    religionList: prevState.religionList.filter(religion => religion !== response)
                };
            }
        });
    };
    const handleOccupation = (isChecked, response) => {
        setOtherFilterRequestBody(prevState => {
            if (isChecked) {
                return {
                    ...prevState,
                    occupationList: [...prevState.occupationList, response]
                };
            } else {
                return {
                    ...prevState,
                    occupationList: prevState.occupationList.filter(occupation => occupation !== response)
                };
            }
        });
    };
    const handleServices = (isChecked, response) => {
        setOtherFilterRequestBody(prevState => {
            if (isChecked) {
                return {
                    ...prevState,
                    serviceList: [...prevState.serviceList, response]
                };
            } else {
                return {
                    ...prevState,
                    serviceList: prevState.serviceList.filter(service => service !== response)
                };
            }
        });
    };
    const handleRangeChange = (values) => {
        setRangeValues(values);
        setOtherFilterRequestBody(prevState => ({
            ...prevState,
            minage: values[0],
            maxage: values[1]
        }));
    };

    // const otherFilterApply = async () => {
    //     setLoading(true);
    //     setFilterModalopen(false);
    //     const selectedPlacesIdOnly = selectedPlaces.map(place => place.placeid);;
    //     const updatedRequestBody = {
    //         ...otherFilterRequestBody,
    //         locationidlist: selectedPlacesIdOnly
    //     };

    //     try {
    //         // Assuming apiUrl is defined
    //         const res = await axios.post(`${apiUrl}connection/connectedmembers`, updatedRequestBody, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("token")}`
    //             }
    //         });

    //         const allMembers = await Promise.all(res.data.details.map(async member => {
    //             try {
    //                 if (member.currentlocationid) {
    //                     const locationName = await getLocationName(member.currentlocationid);
    //                     member.currentlocationname = locationName || 'Unknown Location';
    //                 } else {
    //                     member.currentlocationname = 'Unknown Location';
    //                 }
    //                 return member;
    //             } catch (error) {
    //                 console.error('Error fetching location details:', error);
    //                 return null;
    //             }
    //         }));
    //         if(res.data.details.length > 0){
    //             const filteredMembers = allMembers.filter(member => member !== null);
    //             setMyConnectionList(filteredMembers);
    //             setLoading(false);
    //         }
    //         else{
    //             setMyConnectionList([]);
    //             setLoading(false)
    //         }

    //     } catch (error) {
    //         console.error('Error fetching members:', error);
    //     }
    // };
    const toggleShowAllDegreeNames = () => {
        setShowAllDegreeNames(prevState => !prevState);
    };
    const toggleShowAllReligion = () => {
        setshowAllreligion(prevState => !prevState);
    };
    const toggleShowAllOccupation = () => {
        setshowAllOccupation(prevState => !prevState);
    };
    const toggleShowAllService = () => {
        setShowAllService(prevState => !prevState);
    };
    const handleCurrentAddressChange = (address) => {
        setCurrentAddress(address);
    };
    const handleCurrentAddressSelect = (address, placeid) => {
        setSelectedPlaces(prevState => [...prevState, { address: address, placeid: placeid }]);
        setCurrentAddress('');

    };
    const handleRemovePlace = (index) => {
        setSelectedPlaces(prevState => prevState.filter((place, i) => i !== index));
    }
    const tipFormatter = (value) => {
        return `${value}`;
    }
    const closeFilterModal = () => {
        setFilterModalopen(false)
    }

  //server side pagination
    const renderPaginationItems = () => {
        const items = [];

        // Always include the first page
        items.push(
            <PaginationItem key={1} active={currentPage === 1}>
                <PaginationLink onClick={() => handlePageChange(1)}>
                    1
                </PaginationLink>
            </PaginationItem>
        );

        // Conditionally add ellipses
        if (currentPage > 3) {
            items.push(
                <PaginationItem key="ellipsis1" disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            );
        }

        // Determine the range of pages to display
        let start = Math.max(2, currentPage - 1);
        let end = Math.min(currentPage + 1, totalPages - 1);

        for (let i = start; i <= end; i++) {
            items.push(
                <PaginationItem key={i} active={currentPage === i}>
                    <PaginationLink onClick={() => handlePageChange(i)}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        // Optionally add ellipses before the last page
        if (currentPage < totalPages - 2) {
            items.push(
                <PaginationItem key="ellipsis2" disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            );
        }

        // Always include the last page if it's not the first page
        if (totalPages > 1) {
            items.push(
                <PaginationItem key={totalPages} active={currentPage === totalPages}>
                    <PaginationLink onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return items;
    };
    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber); // Increment currentPage using functional update
        await getAllConnectionDetails(pageNumber, pageSize)
    }
    return (
        <>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}
            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            <Col lg="12" className='Col_12'>
                <Row>
                    <Col md={12} xs={12} className={`col_whole_card ${myconnectionList.length == 0?'d-none':''}`} >
                        <div className=''>
                            <div className='mt-20 pb-3'>
                                <div className='d-flex list-unstyled profession_ul justify-content-end ml-2'>
                                    <div style={{ padding: `5px 0px 5px 0px` }}
                                        className={`text-center profession_li mx-2 mt-1 d-flex justify-content-evenly ${activeFilterLink == 'other' ? 'active' : ''}`}
                                        onClick={() => openfilterModal()}>
                                        <img src={filterIcon} style={{
                                            height: `19px`,
                                            width: `17px`,

                                        }} />
                                        <p className='filter mb-0 fw-normal' style={{ paddingTop: `0px` }}>Custom Filter</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} xs={12} className=" ">
                        {isLoading ? (
                            <Row>
                                {[1, 2, 3, 4].map((key) => (
                                    <Col lg="3" md="6" xs="12" key={key} className="mt-4 pt-2 text-center">
                                        <Card className="catagories overflow-hidden rounded border-0">

                                            <ContentLoader
                                                speed={2}
                                                width={380}
                                                height={500}
                                                viewBox="0 0 380 500"
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#ecebeb"

                                            >
                                                {/* Image skeleton */}
                                                <rect x="0" y="0" rx="5" ry="5" width="280" height="200" />

                                                {/* Other content placeholders */}
                                                <rect x="0" y="220" rx="5" ry="5" width="280" height="20" />
                                                <rect x="0" y="250" rx="5" ry="5" width="280" height="20" />
                                                <rect x="0" y="280" rx="5" ry="5" width="140" height="20" />
                                                <rect x="150" y="280" rx="5" ry="5" width="130" height="20" />
                                                <circle cx="30" cy="330" r="15" />
                                                <circle cx="70" cy="330" r="15" />
                                                <circle cx="110" cy="330" r="15" />
                                            </ContentLoader>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <>
                                {myconnectionList.length == 0 ? (
                                    <div className='text-center mt-3 label_text' >No Connections</div>
                                ) : (
                                    <Row>
                                        {(myconnectionList || []).map((item, key) => (


                                            <Col key={key} className="mt-4 pt-2 text-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12  ">

                                                <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary shadow">

                                                    <CardBody className='rounded overflow-hidden p-0 '>

                                                        <ul className="list-unstyled align-items-center width100 position-absolute  " style={{ zIndex: `9`, marginBottom: `0px` }}>

                                                            <li className="list-inline-item float-start m-2">
                                                                <span className="badge rounded-pill text-dark border  bg-light border-1 border-dark font_size14">

                                                                    {(item.maritalstatus == '' || item.maritalstatus == null) && (
                                                                        <span className='font_size14'> Unknown Status</span>
                                                                    )}
                                                                    {item.maritalstatus != '' && (
                                                                        <span className='font_size14'>  {item.maritalstatus}</span>
                                                                    )}
                                                                </span>
                                                            </li>
                                                            <li className="list-inline-item float-end m-2">
                                                                <Button className="like btn-transparent p-0" style={{ background: `none`, border: `none` }} onClick={() => this.openConfirmModal(item.memberuuid, item.likedstatus)}>
                                                                    <i className={`mdi mdi-heart h5 mb-0 ${item.likedstatus === 1 ? 'redheart' : ''}`}></i>
                                                                </Button>
                                                            </li>



                                                        </ul>

                                                        <Slider className='imageSlider image-container' {...settings}>
                                                            <img src={displayImage(item.photothumb1, item.gender)} className='img-fluid img_thumb' />
                                                            <img src={displayImage(item.photothumb2, item.gender)} className='img-fluid img_thumb' />
                                                            <img src={displayImage(item.photothumb3, item.gender)} className='img-fluid img_thumb' />
                                                        </Slider>

                                                    </CardBody>
                                                </Card>
                                                <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow">
                                                    <CardBody className='rounded shadow p-2 '>
                                                        <p className="text-dark h5 name mb-0">
                                                            <span style={{ color: `#0D6EFD` }}>{item.firstname}, </span>{' '}<span className="text-primary">{calculate_age(item.dob)}</span><span className='h6'>, 00</span>
                                                        </p>

                                                        <p className="m-0 font_size14">
                                                            <FeatherIcon icon="briefcase" className="fea icon-sm me-1" />
                                                            {item.jobprofession ? item.jobprofession : "Unknown Profession"}</p>
                                                        <p className="m-0 font_size14">
                                                            <FeatherIcon icon="map-pin" className="fea icon-sm me-1" />
                                                            {item.currentlocationname}
                                                        </p>

                                                        <div className=' d-flex justify-content-center'>
                                                            <div className='m-1'>
                                                                <button onClick={() => openProfileModal(item.memberuuid)} className="btn  designation btn-outline-primary viewBtn">
                                                                    {/* <i> */}
                                                                    <FeatherIcon
                                                                        icon="eye"
                                                                        className="fea icon-sm icons" />
                                                                    {/* </i> */}
                                                                </button>
                                                            </div>
                                                            <div className='m-1'>
                                                                <Link onClick={() => openShortChat(item)} className="btn  designation btn-outline-info viewBtn">
                                                                    <i className="fa fa-comments" aria-hidden="true" ></i>
                                                                    
                                                                </Link>
                                                            </div>
                                                            <div className='m-1'>

                                                                {!item.connectionstatus &&
                                                                    <button className='btn btn-outline-secondary' onClick={() => this.openConnectionModal(item.memberuuid, item.connectionstatus)}>
                                                                        <span><i className="fa fa-handshake-o" aria-hidden="true"></i></span>
                                                                    </button>
                                                                }
                                                                {item.connectionstatus == 'Connecting' &&
                                                                    (<button className='btn btn-outline-danger'>
                                                                        <span><i className="fa fa-exchange" aria-hidden="true"></i></span>
                                                                    </button>)}

                                                                {item.connectionstatus == 'Connected' &&

                                                                    <button className='btn btn-outline-success'>
                                                                        <FeatherIcon
                                                                            icon="user-check"
                                                                            className="fea icon-sm icons " />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='row d-none'>
                                                            <div className='col-md-12'>
                                                                <span style={{ border: `1px solid #0d6efd`, padding: `4px 10px 5px 10px`, borderRadius: ` 24px` }}>
                                                                    <label className="title text-dark font_weight300 font_size14 mt-2">Referred By:</label>
                                                                    <button onClick={() => openModal(item.recommendersuuid)} className='btn btn-transparent p-0 text-primary cursor-pointer font-size15 font_weight300'>
                                                                        {item.recommendertype === '' ? 'Unknown Referral' : item.recommendertype}
                                                                    </button>
                                                                </span>


                                                            </div>
                                                        </div>


                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                        <Col xs={12} className="mt-4 pt-2" >
                                            {totalCount > pageSize && (
                                                <>
                                                    {myconnectionList.length > 0 && (
                                                        <ul className="pagination justify-content-center mb-0">
                                                            <PaginationItem disabled={currentPage === 1}>
                                                                <PaginationLink
                                                                    to="#"
                                                                    aria-label="Previous"
                                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                                >
                                                                    {'<<'}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                            {renderPaginationItems()}
                                                            <PaginationItem disabled={currentPage===totalPages}>
                                                                <PaginationLink
                                                                    to="#"
                                                                    aria-label="Next"
                                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                                >
                                                                    {'>>'}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        </ul>
                                                    )}

                                                </>
                                            )}


                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Col >
            <Modal isOpen={memberModal}
                size="xl">
                <ModalHeader toggle={openModal}>Profile Details
                    <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <button type="button" className="btn shareBtn" onClick={() => openCopyModal(memberFullData)} >
                        <span aria-hidden="true" className='d-flex'>  <span className='d-none d-md-block'>Share This Profile  {' '}</span>
                            <i>
                                <FeatherIcon
                                    icon="share-2"
                                    className="fea icon-sm icons px-1" style={{ width: `30px` }}
                                />
                            </i>
                        </span>
                    </button>
                </ModalHeader>
                {isModalLoading ? (
                    <ModalBody>
                        <ModalBodySkeleton />
                    </ModalBody>
                ) : (
                    <>
                        {memberFullData && Object.keys(memberFullData).length > 0 &&
                            (
                                <ProfileModal memberData={memberFullData} location={[nativeLocation, currentLocation, companyLocation]} dob={dob} />
                            )}
                    </>
                )}
            </Modal>
            <Modal isOpen={openModal} toggle={closelikeModal}>
                <ModalHeader toggle={closelikeModal}>Confirmation
                    <button type="button" className="close" aria-label="Close" onClick={closelikeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <p className='mb-0'>Are You Sure to {likeStatus} ?</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className='p-1'>
                    <Button
                        color="secondary"
                        onClick={closelikeModal}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button color="danger" type="button" onClick={favoriteMember}>
                        {likeStatus}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={copyModalOpen} toggle={toggleCopyModal}>
                <ModalHeader toggle={toggleCopyModal}>Share Url
                    <button type="button" className="btn shareBtn shareUrlbtn btn-dark text-white btn-outline-secondary" onClick={handleCopyText} >
                        <span aria-hidden="true">
                            {copyButton == 'Copy Url' ? (
                                <i>
                                    <FeatherIcon
                                        icon="copy"
                                        className="fea icon-sm icons"
                                    />
                                </i>

                            ) : (
                                <i>
                                    <FeatherIcon
                                        icon="check"
                                        className="fea icon-sm icons"
                                    />
                                </i>

                            )}
                            {' '}{copyButton}

                        </span>
                    </button>
                    <button type="button" className="close" aria-label="Close" onClick={toggleCopyModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className='copy_Modal_body'>
                    <Row>
                        <Col lg="12">
                            <p className='mb-0'>{pageUrl}{'index/'}{sharedMemberUuid}</p>
                        </Col>
                    </Row>
                </ModalBody>

            </Modal>
            <Modal isOpen={connectionmodalOpen} toggle={closeConnectionModal}>
                <ModalHeader toggle={closeConnectionModal}>Confirmation
                    <button type="button" className="close" aria-label="Close" onClick={closeConnectionModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <p className='mb-0'>Are You Sure to Disconnect?</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className='p-1'>
                    <Button
                        color="secondary"
                        onClick={closeConnectionModal}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button color="danger" type="button" onClick={() => updateConnection()}>
                        Disconnect
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={filtermodalOpen} toggle={closeFilterModal} size="lg">
                <ModalHeader toggle={closeFilterModal}>Custom Filters
                    <button type="button" className="close" aria-label="Close" onClick={closeFilterModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <Card
                                className=" border-0 rounded shadow"
                                style={{ zIndex: '9' }}
                            >
                                <CardBody className="card-body">
                                    <Row className="">
                                        <Col md={6} sm={12}>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <Label className="form-label label_text">Member Name</Label>
                                                    <Input type='text' className='form-control' onChange={(event) => setOtherFilterRequestBody({ ...otherFilterRequestBody, search: event.target.value })} placeholder='Search By Member Name' />
                                                </div>
                                            </div>
                                        </Col>
                                        <hr />
                                        <div className="features feature-primary p-1">
                                            <div className="mb-3">
                                                <Label className="form-label label_text">Age Range</Label>
                                                <Sliderr range
                                                    defaultValue={[0, 100]}
                                                    onChange={handleRangeChange}
                                                    tipFormatter={tipFormatter} />
                                                <Row >
                                                    <Col md={2} className='d-flex mt-2'>

                                                        <p className='form-control'> {rangeValues[0]} </p>
                                                        <span className="mx-4 mt-2"> - </span>
                                                        <p className='form-control'> {rangeValues[1]} </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <hr />
                                        <div>
                                            <Label className="form-label label_text">Profession </Label>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <div className="form-icon position-relative">
                                                        <ul className="ks-cboxtags p-0">
                                                            {visibleOccupationList.map((response, index) => (
                                                                <div className="form-check form-check-inline form_check_box" key={index}>
                                                                    <li>
                                                                        <div className="mb-0">
                                                                            <div className="form-check p-0">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={response.label + "occupation"}
                                                                                    onChange={event => handleOccupation(event.target.checked, response.value)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={response.label + "occupation"}>
                                                                                    {response.label}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    {(occupationList.length > 3) &&
                                                        <button className='btn show_more_btn' onClick={toggleShowAllOccupation}>
                                                            {showAllOccupation ? 'Show Less' : 'Show More'}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="features feature-primary p-1">
                                            <div className="mb-3">
                                                <Label className="form-label label_text">Degree Level</Label>
                                                <div className="form-icon position-relative">
                                                    <ul className="ks-cboxtags p-0">
                                                        {degreeLevelList.map((response, index) => (
                                                            <div className="form-check form-check-inline form_check_box" key={index}>
                                                                <li>
                                                                    <div className="mb-0">
                                                                        <div className="form-check p-0">
                                                                            <Input type="checkbox" checked={otherFilterRequestBody.degreeLevelList.includes(response.value)}
                                                                                id={response.label + "degreelevel"}

                                                                                onChange={event => handleDegreeLevel(event.target.checked, response.value)}
                                                                            />
                                                                            <Label className="form-check-label" htmlFor={response.label + "degreelevel"}>{response.label}  </Label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="features feature-primary p-1">
                                            <div className="mb-3">
                                                <Label className="form-label label_text">Preferred Degree Names</Label>
                                                <div className="form-icon position-relative">
                                                    <ul className="ks-cboxtags p-0">
                                                        {visibleDegreeNameList.map((response, index) => (
                                                            <div className="form-check form-check-inline form_check_box" key={index}>
                                                                <li>
                                                                    <div className="mb-0">
                                                                        <div className="form-check p-0">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={response.label + "degreename"} checked={otherFilterRequestBody.degreeNameList.includes(response.value)}
                                                                                onChange={event => handleDegreeNames(event.target.checked, response.value)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={response.label + "degreename"}>
                                                                                {response.label}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </div>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {(degreeNameList.length > 3) &&
                                                    <button className='btn show_more_btn' onClick={toggleShowAllDegreeNames}>
                                                        {showAllDegreeNames ? 'Show Less' : 'Show More'}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <hr />

                                        <div>
                                            <Row>
                                                <Label className="form-label label_text">Location</Label>
                                                <Col md={6}>
                                                    <div className="features feature-primary p-1 filter">
                                                        <div className="mb-3">

                                                            <PlacesAutocomplete
                                                                value={currentAddress}
                                                                onChange={handleCurrentAddressChange}
                                                                onSelect={handleCurrentAddressSelect}
                                                                className="form-control form_input"
                                                            >
                                                                {({
                                                                    getInputProps,
                                                                    suggestions,
                                                                    getSuggestionItemProps,
                                                                    loading,
                                                                }) => {
                                                                    const uniqueSuggestions = new Set();

                                                                    return (
                                                                        <div>
                                                                            <input
                                                                                {...getInputProps({
                                                                                    placeholder: 'Search Places ...',
                                                                                    className: 'location-search-input',
                                                                                })}
                                                                            />
                                                                            <div className="autocomplete-dropdown-container">
                                                                                {loading && <div>Loading...</div>}
                                                                                {suggestions.map((suggestion, index) => {
                                                                                    const className = suggestion.active
                                                                                        ? 'suggestion-item--active'
                                                                                        : 'suggestion-item';

                                                                                    const words = suggestion.description.split(',');
                                                                                    const trimmedArray = words.map((str) => str.trim());
                                                                                    const lastThreeWords = trimmedArray
                                                                                        .slice(-3)
                                                                                        .join(',');
                                                                                    // Check if the suggestion is unique based on place_id
                                                                                    if (!uniqueSuggestions.has(lastThreeWords)) {
                                                                                        uniqueSuggestions.add(lastThreeWords);

                                                                                        // inline style for demonstration purpose
                                                                                        const style = suggestion.active
                                                                                            ? {
                                                                                                backgroundColor: '#fafafa',
                                                                                                cursor: 'pointer',
                                                                                            }
                                                                                            : {
                                                                                                backgroundColor: '#ffffff',
                                                                                                cursor: 'pointer',
                                                                                            };

                                                                                        return (
                                                                                            <div
                                                                                                key={index}
                                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                                    className,
                                                                                                    style,
                                                                                                })}
                                                                                            >
                                                                                                <span>{lastThreeWords}</span>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return null; // Skip rendering duplicate suggestions
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </PlacesAutocomplete>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div>
                                                        {selectedPlaces.map((place, index) => (
                                                            <div key={index} className="selected-place">
                                                                <span>{place.address}</span> {/* Render address property */}
                                                                {/* Optionally, you can render placeid as well */}

                                                                <Link onClick={() => handleRemovePlace(index)} className="rounded p-2">
                                                                    <FeatherIcon
                                                                        icon="delete"
                                                                        className="fea icon-sm fea-social text-danger"
                                                                    />
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr />

                                        <div>
                                            <Label className="form-label label_text">Gender </Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {genderList.map((response, index) => (
                                                        <div className="form-check form-check-inline form_check_box" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox" checked={otherFilterRequestBody.genderList.includes(response.value)}
                                                                            id={response.label + "gender"}
                                                                            onChange={event => handleGender(event.target.checked, response.value)}

                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "gender"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <hr />
                                        <div>
                                            <Label className="form-label label_text">Religion </Label>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">

                                                    <div className="form-icon position-relative">
                                                        <ul className="ks-cboxtags p-0">
                                                            {visiblereligionList.map((response, index) => (
                                                                <div className="form-check form-check-inline form_check_box" key={index}>
                                                                    <li>
                                                                        <div className="mb-0">
                                                                            <div className="form-check p-0">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={response.label + "religion"}
                                                                                    onChange={event => handleReligion(event.target.checked, response.value)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={response.label + "religion"}>
                                                                                    {response.label}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    {(religionList.length > 3) &&
                                                        <button className='btn show_more_btn' onClick={toggleShowAllReligion}>
                                                            {showAllreligion ? 'Show Less' : 'Show More'}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr />


                                        {/* <div>
                                            <Label className="form-label label_text">Services Offerings </Label>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <div className="form-icon position-relative">
                                                        <ul className="ks-cboxtags p-0">
                                                            {serviceList.map((response, index) => (
                                                                <div className="form-check form-check-inline form_check_box" key={index}>
                                                                    <li>
                                                                        <div className="mb-0">
                                                                            <div className="form-check p-0">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={response.serviceofferinguuid + "religion"}
                                                                                    onChange={event => handleServices(event.target.checked, response.serviceofferinguuid)}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={response.serviceofferinguuid + "religion"}>
                                                                                    {response.servicename}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    {showAllService && (
                                                        <button className='btn show_more_btn' onClick={toggleShowAllService}>
                                                            {showAllService ? 'Show Less' : 'Show More'}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div> */}
                                        <hr />
                                        <div>
                                            <Label className="form-label label_text">Mentorship</Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {mentorshipList.map((response, index) => (
                                                        <div className="form-check form-check-inline form_check_box" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox" checked={otherFilterRequestBody.mentorshipList.includes(response.value)}
                                                                            id={response.label + "mentorship"}
                                                                            onChange={event => handleMentor(event.target.checked, response.value)}

                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "mentorship"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary' onClick={() => {
                        getAllConnectionDetails(1, 5);
                    }}>Show Results</button>
                </ModalFooter>
            </Modal>
            <div
        id="chatbox"
        style={{
          display: 'none',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 99999,
          width: '300px',
        }}
      >
        <div className="card">
          <div className="card-header">
            <h6 className="m-0">
              {' '}
              Messages
              <button
                className="btn btn-sm btn-link float-end"
                onClick={() => closeShortChat()}
              >
                <i className="fa fa-times fa-2x position-static"></i>
              </button>
            </h6>
          </div>
          <div className="card-body p-0">
            <ChatBoxFunction memberdata={chatMemberData} />
          </div>
        </div>
      </div>
        </>
    );
};

export default MyConnectionsFunction;
