import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Progress,
  Card,
  CardBody,
  Badge,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal';
import { useFormik } from 'formik';
import apiUrl from '../../server';
import '../../Home/css/userHomePage.css';
import FeatherIcon from 'feather-icons-react';
import religionIcon from '../../../assets/images/MettaMateImages/korea.png';
import casteIcon from '../../../assets/images/MettaMateImages/caste.png';
import meditationIcon from '../../../assets/images/MettaMateImages/yoga.png';

const SpiritualFunction = () => {
  let memberID = localStorage.getItem('memberID');
  const [currentReligion, setCurrentReligion] = useState('3');
  const [previousReligion, setPreviousReligion] = useState('3');
  const [religionList, setReligionList] = useState([]);
  const [casteList, setcasteList] = useState([]);
  const [caste, setCaste] = useState('SC');
  const [spiritualData, setSpritualData] = useState({});
  const [otherMeditation, setOtherMeditation] = useState({});
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [currentRselectedOption, setcurrentRselectedOption] = useState('');
  const [previousRselectedOption, setpreviousRselectedOption] = useState('');
  const [casteselectedOption, setcasteselectedOption] = useState('');
  const [meditationSelectedOption, setmeditationSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSpiritualData();
    const fetchData = async () => {
      try {
        const [religionRes, casteRes,] = await Promise.all([
          axios.get(`${apiUrl}codes/religion`),
          axios.get(`${apiUrl}codes/caste`),
        ]);
        setReligionList(religionRes.data.list);
        setcasteList(casteRes.data.list);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  const fetchSpiritualData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'members/spiritual/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {
        setSpritualData(response.data[0]);
        setcurrentRselectedOption(response.data[0].currentreligion);
        setpreviousRselectedOption(response.data[0].previousreligion);
        setcasteselectedOption(response.data[0].caste);
        setmeditationSelectedOption(response.data[0].othermeditation);
      } else {
        setSpritualData([]);
        setcurrentRselectedOption({});
        setpreviousRselectedOption({});
        setcasteselectedOption({});
        setmeditationSelectedOption({});
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      memberuuid: localStorage.getItem('memberID'),
      currentreligion: '',
      previousreligion: '',
      caste: '',
      noofvipassana: '',
      othermeditation: '',
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      values.currentreligion = parseInt(currentRselectedOption);
      values.previousreligion = parseInt(previousRselectedOption);
      values.caste = parseInt(casteselectedOption);
      var noofvipassana = document.getElementById('noofvipassana');
      values.noofvipassana = parseInt(noofvipassana.value);
      values.othermeditation = parseInt(meditationSelectedOption);

      try {
        const response = await axios
          .post(apiUrl + 'members/spiritual/', values, {
            headers: {
              Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            fetchSpiritualData();
            setIsLoading(false);
            setSuccess({
              title: 'SUCCESS!',
              message: res.data.message,
            });
          })
          .catch((err) => {
            setIsLoading(false);
            setError({
              title: 'An Error Occured!',
              message: err.response.data.message,
            });
          });
      } catch (error) {
        setError({
          title: 'An error occurred!',
          message: error.data.message,
        });
      }
    },
  });
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setSpritualData([]);
    setCurrentReligion({});
    setPreviousReligion({});
    setCaste({});
    setOtherMeditation({});
  };
  const handlecurrentROptionChange = (event) => {
    setcurrentRselectedOption(event.target.value)
  }

  const handlepreviousROptionChange = (event) => {
    setpreviousRselectedOption(event.target.value);
  }
  const handlecasteOptionChange = (event) => {
    setcasteselectedOption(event.target.value);
  }
  const handlemeditationOptionChange = (event) => {
    setmeditationSelectedOption(event.target.value);
  }
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {
        isLoading ? <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Row className="inner_row m-0 mt-1">
                <h6 className="title title_bar">
                  Spiritual
                </h6>
                <Col md={12} className='mt-3'>
                  <img src={religionIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Religion</span>
                  <Row className="mt-3">
                    <Col md={12} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Current Religion

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(religionList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="curentradio" type="radio" value={type.value}
                                    checked={currentRselectedOption == type.value} id={`radiocurrent${index}`}
                                    onChange={handlecurrentROptionChange} />
                                  <label htmlFor={`radiocurrent${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>

                    <Col md={12} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Previous Religion

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(religionList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="previousradio" type="radio" value={type.value}
                                    checked={previousRselectedOption == type.value} id={`radioprevious${index}`}
                                    onChange={handlepreviousROptionChange} />
                                  <label htmlFor={`radioprevious${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={casteIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Caste Category</span>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Caste

                          </Label>

                          <section className="radio-section">
                            <div className="radio-list">
                              {(casteList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="casteradio" type="radio" value={type.value}
                                    checked={casteselectedOption == type.value} id={`radiocaste${index}`}
                                    onChange={handlecasteOptionChange} />
                                  <label htmlFor={`radiocaste${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={meditationIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName">Meditation</span>
                  <Row className="mt-3">
                    <Col md={6} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Number Of Vipassana Courses Completed?

                          </Label>
                          <Input style={{ width: `140px` }}
                            type="number"
                            className="form-control form_Input ps-5"
                            placeholder="No. Of Vipassana"
                            name="vipassana"
                            id="noofvipassana"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            defaultValue={spiritualData.noofvipassana}
                            invalid={
                              validation.touched.vipassana && validation.errors.vipassana
                                ? true
                                : false
                            }
                          />
                          {validation.touched.vipassana && validation.errors.vipassana ? (
                            <FormFeedback type="invalid">
                              {validation.errors.vipassana}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>

                    <Col md={6} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Other Meditation Done?

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              <div className="radio-item">
                                <input
                                  name="meditation"
                                  id="yesradio"
                                  type="radio"
                                  value="1"
                                  checked={meditationSelectedOption == 1}
                                  onChange={handlemeditationOptionChange}
                                />
                                <label htmlFor="yesradio">Yes</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  name="meditation"
                                  id="noradio"
                                  type="radio"
                                  value="0"
                                  checked={meditationSelectedOption == 0}
                                  onChange={handlemeditationOptionChange}
                                />
                                <label htmlFor="noradio">No</label>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>
                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "
                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary mt-2 ms-2"
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment>
  );
};
export default SpiritualFunction;
