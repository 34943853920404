import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal.js';
import FeatherIcon from 'feather-icons-react';
import '../../Home/css/userHomePage.css';
import { useFormik } from 'formik';
import apiUrl from '../../server';
import JobIcon from '../../../assets/images/MettaMateImages/career.png';
import CompanyIcon from '../../../assets/images/MettaMateImages/corporate.png';
import DollarIcon from '../../../assets/images/MettaMateImages/dollar.png';
import DateIcon from '../../../assets/images/MettaMateImages/time-management.png'
const CareerFunction = () => {
  let memberID = localStorage.getItem('memberID');
  const [startdateValue, setstartDateValue] = useState(new Date());
  const [enddateValue, setendDateValue] = useState(new Date());
  const [address, setAddress] = useState('');
  const [professiondata, setProfessionData] = useState({})
  const [experiencedata, setexperienceData] = useState({})
  const experienceList = [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15]

  const [careerData, setCareerData] = useState({
    occupationalid: 0,
    memberuuid: localStorage.getItem('memberID'),
    jobtitle: '',
    jobprofession: '',
    companyname: '',
    companylocationid: '',
    companytype: '',
    jobstatus: '',
    salctc: '',
    salcurrency: '',
    startdate: '',
    enddate: '',
    skillset: [],
    experience: ''
  });
  const [mapvalue, setmapValue] = useState(null);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [companyStatusList, setCompanyStatusList] = useState([]);
  const [salaryCurrencyList, setSalaryCurrencyList] = useState([]);
  const [occupationalList, setOccupationalList] = useState([]);
  const [skillsetList, setSkillSetList] = useState([]);

  const [companyTypeData, setCompanyTypedata] = useState({});
  const [jobStatusData, setjobStatusData] = useState({});
  const [salCurrency, setsalCurrency] = useState({});
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [jobStsselectedOption, setjobStsSelectedOption] = useState('');
  const [companytypeselectedOption, setCompanytypeselectedOption] = useState('');
  const [currencyselectedOption, setcurrencyselectedOption] = useState('');
  const [skillset, setSkillset] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchcareerDetails();
    const fetchData = async () => {
      try {
        const [companyTypeRes, companyStatusRes, salarycurrencyRes, occupationalRes, skillsetRes] = await Promise.all([
          axios.get(`${apiUrl}codes/companytype`),
          axios.get(`${apiUrl}codes/companystatus`),
          axios.get(`${apiUrl}codes/salarycurrency`),
          axios.get(`${apiUrl}codes/occupationalcategory`),
          axios.get(`${apiUrl}codes/skillset`)

        ]);
        setCompanyTypeList(companyTypeRes.data.list);
        setCompanyStatusList(companyStatusRes.data.list);
        setSalaryCurrencyList(salarycurrencyRes.data.list);
        setOccupationalList(occupationalRes.data.list);
        setSkillSetList(skillsetRes.data.list);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const fetchcareerDetails = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'members/occupation/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.length > 0) {
        let careerreponseData = response.data[0]
        // setCareerData(response.data[0]);
        // validation.setFieldValue('occupationalid', response.data[0].occupationalid ? response.data[0].occupationalid : 0);
        // validation.setFieldValue('jobprofession', response.data[0].jobprofession ? response.data[0].jobprofession : 0);
        // setProfessionData(response.data[0].jobprofession)
        // setCompanytypeselectedOption(response.data[0].companytype);
        // setjobStsSelectedOption(response.data[0].jobstatus);
        // setcurrencyselectedOption(response.data[0].salcurrency);
        // setSkillset(response.data[0].skillset)

        setCareerData({
          occupationalid: careerreponseData.occupationalid || 0,
          memberuuid: localStorage.getItem('memberID'),
          jobtitle: careerreponseData.jobtitle || '',
          jobprofession: careerreponseData.jobprofession || 0,
          companyname: careerreponseData.companyname || '',
          companylocationid: careerreponseData.companylocationid || '',
          companytype: careerreponseData.companytype || 0,
          jobstatus: careerreponseData.jobstatus || 0,
          salctc: careerreponseData.salctc || 0,
          salcurrency: careerreponseData.salcurrency || 0,
          skillset: careerreponseData.skillset || [],
          experience: careerreponseData.experience || 0
        })

        if (response.data[0].startdate) {
          const startDate = format(parseISO(response.data[0].startdate), 'yyyy-MM-dd');
          setstartDateValue(startDate);
          careerData.startdate = startDate;
        }
        if (response.data[0].enddate) {
          const endDate = format(parseISO(response.data[0].enddate), 'yyyy-MM-dd');
          setendDateValue(endDate);
          careerData.enddate = endDate
        }
        if (response.data[0].companylocationid) {
          setmapValue(response.data[0].companylocationid);
          handlePlaceId(response.data[0].companylocationid);
        }
      } else {
        setCareerData({
          occupationalid: 0,
          memberuuid: localStorage.getItem('memberID'),
          jobtitle: '',
          jobprofession: 0,
          companyname: '',
          companylocationid: '',
          companytype: 0,
          jobstatus: 0,
          salctc: 0,
          salcurrency: 0,
          startdate: '',
          enddate: '',
          skillset: [],
          experience: 0
        });
        setstartDateValue('');
        setendDateValue('');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePlaceId = async (placeId) => {
    try {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.getDetails({ placeId }, (place, status) => {
        if (status === 'OK') {
          const address = place.formatted_address;
          setAddress(address);
        }
      });
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };
  const handleAddressSelect = async (address, placeid) => {
    setmapValue(placeid);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setAddress(address)
      const addressComponents = results[0].address_components;
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.long_name || '';

      // Store the city, state, and country in your state or do something else with this information
      setCity(city);
      setState(state);
      setCountry(country);

      // setAddress(city +','+ state + ','+ country);
      // Use latLng if needed
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };
  const handlejobtitleChange = (e) => {
    setCareerData({ ...careerData, [e.target.name]: e.target.value });
    validation.setFieldValue('jobtitle', e.target.value);
  };
  const dateOnChange = (e) => {
    setstartDateValue(e.target.value);

  };
  const enddateOnChange = (e) => {
    setendDateValue(e.target.value);

  };

  // const validation = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     occupationalid: 0,
  //     memberuuid: localStorage.getItem('memberID'),
  //     jobtitle: '',
  //     jobprofession: '',
  //     companyname: '',
  //     companylocationid: '',
  //     companytype: '',
  //     jobstatus: '',
  //     salctc: '',
  //     salcurrency: '',
  //     startdate: '',
  //     enddate: '',
  //     skillset: [],
  //     experience: ''
  //   },

  //   onSubmit: async (values) => {
  //     var jobtitleElement = document.getElementById('jobTitlee');
  //     values.jobtitle = jobtitleElement.value;
  //     values.jobprofession = professiondata;
  //     var companyname = document.getElementById('companyname');
  //     values.companyname = companyname.value;
  //     values.companytype = parseInt(companytypeselectedOption);
  //     values.jobstatus = parseInt(jobStsselectedOption);
  //     var salary = document.getElementById('salctc');
  //     values.salctc = salary.value ? salary.value : 0;
  //     values.salcurrency = parseInt(currencyselectedOption);
  //     values.companylocationid = mapvalue ? mapvalue : '';
  //     values.startdate = startdateValue ? startdateValue : '';
  //     values.enddate = enddateValue ? enddateValue : '';
  //     values.skillset = careerData.skillset;
  //     values.experience = careerData.experience;
  //     try {
  //       const response = await axios
  //         .post(apiUrl + 'members/occupation/', values, {
  //           headers: {
  //             Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
  //             'Content-Type': 'application/json',
  //           },
  //         })
  //         .then((res) => {
  //           if (res.status == 200) {
  //             fetchcareerDetails()
  //             setSuccess({
  //               title: 'SUCCESS!',
  //               message: res.data.message,
  //             });

  //           }
  //           else {
  //             setError({
  //               title: 'An Error Occured!',
  //               message: res.data.message,
  //             });
  //           }

  //         })
  //         .catch((err) => {


  //         });
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   },
  // });

  const handleSubmit = async () => {
    setIsLoading(true);
    careerData.jobtitle = document.getElementById('jobTitlee').value;
    careerData.companylocationid = mapvalue ? mapvalue : '';
    careerData.startdate = startdateValue ? startdateValue : '';
    careerData.enddate = enddateValue ? enddateValue : '';
    console.log(careerData)
    let result = await axios.post(`${apiUrl}members/occupation/`, careerData, {
      headers: {
        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
        "Content-Type": "application/json"
      },
    }).then((res) => {
      fetchcareerDetails();
      setIsLoading(false);

      setSuccess({
        title: 'SUCCESS!',
        message: res.data.message,
      });
    }).catch((err) => {
      setIsLoading(false);

      setError({
        title: 'An Error Occured!',
        message: err.response.data.message,
      });
    });

  }
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setCareerData([]);
    setstartDateValue('');
    setendDateValue('');
    setjobStatusData(0);
    setsalCurrency(0);
    setCompanyTypedata(0);
  };
  const handlejobStsOptionChange = (event) => {
    setjobStsSelectedOption(event.target.value); // Update the selected value when a radio button is clicked
  };
  const handlecompanyTypeOptionChange = (event) => {
    setCompanytypeselectedOption(event.target.value);
  };
  const handlesalaryCurrencyOptionChange = (event) => {
    setcurrencyselectedOption(event.target.value);
  };
  const handleSkillset = (checkedFlag, skillsetObject) => {
    // let selectedSkillSet = [...skillset]; // Create a copy of the current state
    // if (checkedFlag) {
    //   if (!selectedSkillSet.includes(skillsetObject.value)) {
    //     selectedSkillSet.push(parseInt(skillsetObject.value));
    //   }
    // } else {
    //   const index = selectedSkillSet.indexOf(skillsetObject.value);
    //   if (index !== -1) {
    //     selectedSkillSet.splice(index, 1); // Remove the skill from the array
    //   }
    // }
    // setSkillset(selectedSkillSet); // Update the state with the new array
    let selectedskills = careerData.skillset;
    if (checkedFlag) {
      if (!selectedskills.includes(skillsetObject.value)) {
        selectedskills.push(skillsetObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedskills.includes(skillsetObject.value)) {
        selectedskills.splice(selectedskills.findIndex(x => skillsetObject.value === x), 1);
      }
    }
    setCareerData({ ...careerData, skillset: selectedskills });
  }
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      {
        isLoading ? <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit()
              }}
            >
              <Row className="inner_row m-0 mt-1">
                <h6 className="title title_bar">
                  Career{' '}
                </h6>


                <Col md={12} className='mt-3'>

                  <img src={JobIcon} className='legendIcon' />
                  &nbsp; <span className="m-2 legendName" >Job Description</span>

                  <Row className="mt-3">
                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Job Profession

                          </Label>
                          <select
                            className="form-select form-control form_Input"
                            id="jobprofession"
                            value={careerData.jobprofession}
                            onChange={(e) => setCareerData({ ...careerData, jobprofession: e.target.value, skillset: [] })}>
                            {(occupationalList || []).map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Job Title

                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="briefcase"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="(Ex. Java Developer) "
                            name="jobTitle"
                            id="jobTitlee"
                            defaultValue={careerData.jobtitle || ''}
                          />

                        </div>
                      </div>
                    </Col>
                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Experience In year

                          </Label>
                          <select
                            className="form-select form-control form_Input"
                            id="experience"
                            value={careerData.experience}
                            onChange={(e) => setCareerData({ ...careerData, experience: e.target.value })}>
                            {(experienceList || []).map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>

                    <Col md={12} xs={12}>

                      {careerData.jobprofession ? (
                        <div className="features feature-primary p-1">
                          <div className="mb-3">
                            <Label className="form-label label_text"> Skill Set</Label>

                            <div className="form-icon position-relative">
                              <ul className="ks-cboxtags p-0">
                                {(skillsetList || []).filter(x => x.professiontype == careerData.jobprofession).map((response, index) => (
                                  <li key={index}>
                                    <div className={`form-check form-check-inline`}>
                                      <input
                                        type="checkbox"
                                        defaultValue={careerData.skillset}
                                        id={response.label + "skillset"}
                                        checked={careerData.skillset.includes(+response.value)}
                                        onChange={event => handleSkillset(event.target.checked, response)}
                                        disabled={(careerData.skillset.length >= 5) && (!careerData.skillset.includes(response.value))}


                                      />
                                      <label className="form-check-label" htmlFor={response.label + "skillset"}>
                                        {response.label}
                                      </label>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : ''}
                    </Col>


                    <Col md={12} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Job Status
                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(companyStatusList || []).map((item, key) => (
                                <div className="radio-item" key={key} >
                                  <Input name="jobradio" id={`radiojob${key}`} type="radio" defaultValue={item.value}
                                    checked={careerData.jobstatus == item.value}
                                    onChange={(e) => setCareerData({ ...careerData, jobstatus: e.target.value })} />
                                  <label htmlFor={`radiojob${key}`}>{item.label} </label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>
                  <img src={CompanyIcon} className='legendIcon' />
                  &nbsp; <span className="m-2 legendName" >Company Description</span>
                  <Row className="mt-3">

                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Company Name

                          </Label>

                          <div className="form-icon position-relative">
                            <i className="fa fa-building" aria-hidden="true"></i>
                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="Company Name"
                            id="companyname"
                            name="companyName"
                            defaultValue={careerData.companyname}
                            onChange={(e) => setCareerData({ ...careerData, companyname: e.target.value })}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Company Location

                          </Label>

                          <PlacesAutocomplete
                            value={address}
                            onChange={handleAddressChange}
                            onSelect={handleAddressSelect}
                            className="form-control form_input"
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => {
                              const uniqueSuggestions = new Set();

                              return (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';

                                      const words = suggestion.description.split(',');
                                      const trimmedArray = words.map((str) => str.trim());
                                      const lastThreeWords = trimmedArray
                                        .slice(-3)
                                        .join(',');
                                      // Check if the suggestion is unique based on place_id
                                      if (!uniqueSuggestions.has(lastThreeWords)) {
                                        uniqueSuggestions.add(lastThreeWords);

                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                          : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };

                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{lastThreeWords}</span>
                                          </div>
                                        );
                                      } else {
                                        return null; // Skip rendering duplicate suggestions
                                      }
                                    })}
                                  </div>
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </Col>
                    <Col md={12} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Company Type

                          </Label>


                          <section className="radio-section">
                            <div className="radio-list">
                              {(companyTypeList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="companytyperadio" type="radio" value={type.value}
                                    checked={careerData.companytype == type.value} id={`radiocompany${index}`}
                                    onChange={(e) => setCareerData({ ...careerData, companytype: e.target.value })} />
                                  <label htmlFor={`radiocompany${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>

                  <img src={DollarIcon} className='legendIcon' />
                  &nbsp; <span className="m-2 legendName" >Salary Description</span>

                  <Row className="mt-3">
                    <Col md={6} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Salary Currency

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(salaryCurrencyList || []).map((type, index) => (
                                <div className="radio-item" key={index}  >
                                  <Input name="salarycurrencyradio" type="radio" defaultValue={type.value}
                                    checked={careerData.salcurrency == type.value} id={`radiocurrency${index}`}
                                    onChange={(e) => setCareerData({ ...careerData, salcurrency: e.target.value })} />
                                  <label htmlFor={`radiocurrency${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} xs={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Salary CTC(Per Annum)

                          </Label>
                          <div className="form-icon position-relative">
                            <i>

                              <span className="fea icon-sm icons" style={{
                                fontSize: `20px`,
                                top: `5px`
                              }}>&#8377;</span>
                            </i>
                          </div>
                          <Input
                            type="number"
                            className="form-control form_Input ps-5"
                            placeholder="Salary CTC"
                            id="salctc"
                            name="salaryCtc"
                            defaultValue={careerData.salctc}
                            onChange={(e) => setCareerData({ ...careerData, salctc: e.target.value })}
                          />
                        </div>
                      </div>
                    </Col>


                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>

                  <img src={DateIcon} className='legendIcon' />
                  &nbsp; <span className="m-2 legendName" >Date Description</span>

                  <Row className="mt-3">
                    <Col md={3} >
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Start Date

                          </Label>

                          <input type="date" className='form-control' defaultValue={startdateValue}
                            onChange={dateOnChange} />
                        </div>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">End Date</Label>
                          <input type="date" className='form-control' defaultValue={enddateValue}
                            onChange={enddateOnChange} />
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "
                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary  mt-2 ms-2"
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }

    </React.Fragment>
  );
};

export default CareerFunction;
