import React from 'react';
import '../Home/css/userHomePage.css';
import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';

const defaultPicture = (props) => {
  return (
    <div className="text-center">
      {props.gendercode == 1 && (
        <img className="" style={{ width: '100%', height: '258px',opacity: `0.5` }} src={manavatar} />
      )}
      {props.gendercode == 2 && (
        <img className="" style={{ width: '100%',height: '258px',opacity: `0.5` }} src={femaleavatar} />
      )}
        {props.gendercode == 3 && (
        <img className="" style={{ width: '100%', height: '258px',opacity: `0.5` }} src={transgenderavatar} />
      )}
    </div>
  );
};
export default defaultPicture;
