import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, CardBody, Progress } from 'reactstrap';
import Profilecard from '../../Home/Profile_card.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MMpersonalDetails from './MMpersonalDetails.jsx';
import CareerDetails from '../Profile/Career.js';
import EducationDetails from '../Profile/Education.js';
import RegionalDetails from '../Profile/Regional.js';
import ExpertiseDetails from '../matchmaker/Expertise.jsx'
const matchMakerFunction = () => {
    const [isVisible, setVisibility] = useState('personal');
    const [activeLink, setActiveLink] = useState('personal');
   
    const menudata = [
        { icon: 'uil-users-alt', menuName: 'Personal', value: 'personal' },
        { icon: 'uil-bag-alt', menuName: 'Career', value: 'career' },
        { icon: 'uil-graduation-cap', menuName: 'Education', value: 'education' },
        { icon: 'uil-directions', menuName: 'Regional/Location', value: 'regional' },
        { icon: 'uil-book-reader', menuName: 'Expertise', value: 'expertise' },
    ];
    useEffect(() => {
    }, []);
    const handleLinkClick = (link) => {
        setActiveLink(link);
        setVisibility(link);
    }
    const menuslider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6, // Show 5 items at a time
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (

        <React.Fragment>
            <Profilecard />
            <Container className="">
                <section className="section_user_home_page mt-3">
                    <Container>
                        <Row>
                            <Col lg="12" className='Col_12'>

                                <Row>
                                    <Col md={12} xs={12} className="inner_row ">
                                        <Row style={{ background: `rgb(222, 255, 253)` }}>
                                            <div className='w-5/6 m-auto'>
                                                <div className='mt-20 pb-3'>
                                                    <Slider {...menuslider} className="overflow-hidden menu_slider ">
                                                        {(menudata || []).map((item, key) => (
                                                            <div className='d-flex list-unstyled profession_ul' key={key}>
                                                                <div
                                                                    style={{ padding: `5px 10px 5px 5px` }}
                                                                    className={`text-center profession_li mt-1 ${activeLink === item.value ? 'active' : ''
                                                                        }`}
                                                                    onClick={() => handleLinkClick(item.value)}>
                                                                    <i className={item.icon} style={{ fontSize: `20px` }}></i>
                                                                    <p className='filter_title m-0' style={{ fontSize: `16px` }}>{item.menuName}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </div>
                                            </div>
                                        </Row>

                                    </Col>

                                    {isVisible == 'personal' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            {' '}
                                            <MMpersonalDetails/>
                                        </Col>
                                    )}
                                    {isVisible == 'career' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            <CareerDetails />
                                        </Col>
                                    )}
                                    {isVisible == 'education' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            <EducationDetails />
                                        </Col>
                                    )}
                                    {isVisible == 'regional' && (
                                        <Col md={12} xs={12}>
                                            <RegionalDetails />
                                        </Col>
                                    )}
                                    {isVisible == 'expertise' && (
                                        <Col md={12} xs={12}>
                                        <ExpertiseDetails />
                                    </Col>
                                    )}
                                 
                                </Row>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>

        </React.Fragment>
    );
};
export default matchMakerFunction;