import React, { useEffect, useMemo, useState } from 'react';
import '../../Home/css/userHomePage.css';
import CommonModal from '../modal.js';
import apiUrl from '../../server.js';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PaginationItem,
  PaginationLink,
  Alert,
  Fade,
  Progress,
} from 'reactstrap';
import Slider from 'react-slick';
import Sliderr, { Range } from 'rc-slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import globeIcon from '../../../assets/images/MettaMateImages/worldglobe.png';
import doctorIcon from '../../../assets/images/MettaMateImages/doctor.png';
import engineerIcon from '../../../assets/images/MettaMateImages/engineers.png';
import softwareEngIcon from '../../../assets/images/MettaMateImages/Software-Engineer.png';
import lawyerIcon from '../../../assets/images/MettaMateImages/lawyer.png';
import managementIcon from '../../../assets/images/MettaMateImages/management.png';
import professorIcon from '../../../assets/images/MettaMateImages/Teacher.png';
import scientistIcon from '../../../assets/images/MettaMateImages/scientist.png';
import businessIcon from '../../../assets/images/MettaMateImages/businessexecutives.png';
import financialIcon from '../../../assets/images/MettaMateImages/financial-management-.png';
import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';

import indiaIcon from '../../../assets/images/MettaMateImages/flag.png';
import usaIcon from '../../../assets/images/MettaMateImages/united-states-of-america.png';
import ausIcon from '../../../assets/images/MettaMateImages/australia.png';
import ukIcon from '../../../assets/images/MettaMateImages/united-kingdom.png';
import filterIcon from '../../../assets/images/MettaMateImages/filter.png';
import communityicon from '../../../assets/images/MettaMateImages/communitymembers.png';
import weddingicon from '../../../assets/images/MettaMateImages/wedding.png';

import 'rc-slider/assets/index.css';
import ProfileModal from '../../Home/Profile_modal.jsx';
import ContentLoader, { List } from 'react-content-loader';
import ModalBodySkeleton from '../../Home/skeleton.jsx';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ChatBoxFunction from '../chat/chatbox.jsx';
import MapFunction from '../maps.jsx';
import { logoutFunction } from '../logout.jsx';
const CommunityMemberFunction = () => {
  // Declaring the enum as a static property of the class
  const MemberType = Object.freeze({
    ALLIANCE_SEEKER: 1,
    COMMUNITY_MEMBER: 4,
  });

  const functionMemberType = MemberType.COMMUNITY_MEMBER;
  const numberOfProfilesPerPage = 20;

  const [communityMemberList, setCommunityMemberList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [likedmemberuuid, setlikedmemberuuid] = useState({});
  const [likeStatus, setLikedStatus] = useState('Like');
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [memberModal, setModal] = useState(false);
  const [memberFullData, setMemberFullData] = useState([]);
  const [dob, setDOB] = useState({});
  const [currentLocation, setCurrentLocation] = useState({});
  const [nativeLocation, setNativeLocation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isModalLoading, setModalLoading] = useState(true);
  const [copyModalOpen, setcopyModalOpen] = useState(false);
  const [sharedMemberUuid, setsharedMemberUuid] = useState({});
  const [copyButton, setCopyButton] = useState('Copy Url');
  const [search, setsearch] = useState('');
  const [pageUrl, setpageUrl] = useState({});
  const [refreshpage, setrefreshPage] = useState(false);
  const [connectionmodalOpen, setConnectionmodalOpen] = useState(false);
  const [activeFilterLink, setActiveFilterLink] = useState('');
  const [filtermodalOpen, setFilterModalopen] = useState(false);
  const [rangeValues, setRangeValues] = useState([0, 100]);
  const [degreeLevelList, setdegreeLevelList] = useState([]);
  const [degreeNameList, setdegreeNameList] = useState([]);
  const [religionList, setreligionList] = useState([]);
  const [occupationList, setoccupationList] = useState([]);
  const [skillsetList, setskillsetList] = useState([]);
  const [selectedMemberUUID, setselectedMemberUUID] = useState({});
  const [showMapPage, setshowMapPage] = useState(false);
  const [openSkillsModal, setopenSkillsModal] = useState(false);
  const [skillsetData, setSkillsetData] = useState([]);
  const [profilePercentage, setProfilePercentage] = useState(15);

  const [progressFadeAlert, setProgressFadeAlert] = useState(false);
  const [progressAlert, setProgressAlert] = useState(false);

  const [chatMemberData, setChatMemberData] = useState({
    memberuuid: '',
    firstname: '',
    lastname: '',
    gendercode: 0,
    memberstatuscode: 0,
    memberstatusname: '',
    gendername: '',
    photopath1: '',
    photopath2: '',
    photopath3: '',
    subscription: '',
    jobprofession: '',
    linkedin: '',
    instagram: '',
    facebook: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    recommended: 0,
  });
  const filterdata = [
    { image: globeIcon, filterName: 'Location', value: 10, type: 'location' },
    { image: doctorIcon, filterName: 'Doctors', value: 1, type: 'profession' },
    {
      image: engineerIcon,
      filterName: 'Engineers',
      value: 4,
      type: 'profession',
    },
    {
      image: softwareEngIcon,
      filterName: 'Software Engineers',
      value: 3,
      type: 'profession',
    },
    { image: lawyerIcon, filterName: 'Lawyers', value: 2, type: 'profession' },
    {
      image: professorIcon,
      filterName: 'Professors',
      value: 9,
      type: 'profession',
    },
    {
      image: managementIcon,
      filterName: 'Management',
      value: 6,
      type: 'profession',
    },
    {
      image: scientistIcon,
      filterName: 'Scientist',
      value: 5,
      type: 'profession',
    },
    {
      image: financialIcon,
      filterName: 'Financial Executives',
      value: 7,
      type: 'profession',
    },
    {
      image: businessIcon,
      filterName: 'Business Executives',
      value: 8,
      type: 'profession',
    },
    { image: usaIcon, filterName: 'USA', type: 'country', value: 'US' },
    { image: ukIcon, filterName: 'UK', type: 'country', value: 'UK' },
    { image: indiaIcon, filterName: 'India', type: 'country', value: 'IN' },
    { image: ausIcon, filterName: 'AUS', type: 'country', value: 'AU' },
  ];

  // const [serviceList, setserviceList] = useState([]);
  // const [showAllService, setShowAllService] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [mentorshipList, setmentorshipList] = useState([
    { value: 1, label: 'Mentor' },
    { value: 2, label: 'Mentee' },
  ]);
  const [genderList, setgenderList] = useState([]);

  const [showAllDegreeNames, setShowAllDegreeNames] = useState(false);
  const [showAllreligion, setshowAllreligion] = useState(false);
  const [showAllOccupation, setshowAllOccupation] = useState(false);
  const [showAllskills, setshowAllSkills] = useState(false);

  const visibleDegreeNameList = showAllDegreeNames
    ? degreeNameList
    : degreeNameList.slice(0, 3);
  const visiblereligionList = showAllreligion
    ? religionList
    : religionList.slice(0, 3);
  const visibleOccupationList = showAllOccupation
    ? occupationList
    : occupationList.slice(0, 3);
  const visibleskillsList = showAllskills
    ? skillsetList
    : skillsetList.slice(0, 3);

  const [otherFilterRequestBody, setOtherFilterRequestBody] = useState({
    currentPage: 1,
    membertypeid: 4,
    pageSize: numberOfProfilesPerPage,
    genderList: [],
    degreeLevelList: [],
    degreeNameList: [],
    jobProfessionList: [],
    religionList: [],
    locationidlist: [],
    serviceList: [],
    mentorshipList: [],
    minage: 0,
    maxage: 100,
    occupationList: [],
    skillsetList: [],
    search: '',
    companyname: '',
    degreecollege: '',
  });
  const [pageSize, setPageSize] = useState(numberOfProfilesPerPage);

  const [showAlert, setShow] = useState(false);
  const [fadeAlert, setFade] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState();

  const [searchItem, setSearchItem] = useState('');

  const [filterData, setFilterData] = useState(null);
  const [custumFilterData, setCustumFilterData] = useState(null);

  let configuredata = JSON.parse(localStorage.getItem('configurationData'));
  const navigate = useNavigate();
  const itemsPerPage = 5;

  useEffect(() => {
    configuredata = JSON.parse(localStorage.getItem('configurationData'));
    getAllDetails(currentPage);
    setpageUrl(apiUrl.replace('8080', '3000'));
    if (configuredata.memberstatuscode === 3) {
      setShow(true);
      setFade(true);
    }
    progressBarUpdate();
  }, []);

  const getAllDetails = async (currentPage) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${apiUrl}members/getallmembers`,
        {
          pageNo: currentPage,
          pageSize: `${numberOfProfilesPerPage}`,
          membertypeid: `${functionMemberType}`,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      const allMembers = await Promise.all(
        response.data.details.map(async (member) => {
          try {
            if (member.currentlocationid) {
              const locationName = await getLocationName(
                member.currentlocationid,
              );
              member.currentlocationname = locationName || 'Unknown Location';
            } else {
              member.currentlocationname = 'Unknown Location';
            }
            return member;
          } catch (error) {
            console.error('Error fetching location details:', error);
            return member;
          }
        }),
      );
      setCommunityMemberList(allMembers);
      setLoading(false);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      setrefreshPage(false);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 401) {
        setError({
          title: 'Session Is Expired!',
          message: 'Please Login',
        });

        await logoutFunction();
        setTimeout(() => {
          navigate('/user-login-page');
        }, 3000);
      }
      console.error('Error fetching members:', error);
    }
  };

  const professionLink = async (link, filterValue, filterType) => {
    setLoading(true);
    setActiveFilterLink(link);
    setCurrentPage(1);
    setCurrentPageNo(1);
    try {
      if (filterType == 'profession') {
        setshowMapPage(false);
        let request = {
          currentPage: currentPage,
          pageSize: pageSize,
          occupationacategoryid: filterValue,
        };

        let response = await axios.post(
          `${apiUrl}members/getmembersbyoccupation`,
          request,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );
        const allMembers = await Promise.all(
          response.data.details.map(async (member) => {
            try {
              if (member.currentlocationid) {
                const locationName = await this.getLocationName(
                  member.currentlocationid,
                );
                member.currentlocationname = locationName || 'Unknown Location';
              } else {
                member.currentlocationname = 'Unknown Location';
              }
              return member;
            } catch (error) {
              console.error('Error fetching location details:', error);
              return member;
            }
          }),
        );
        const indexOfLastItem = 1 * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        setFilterData(allMembers.slice(indexOfFirstItem, indexOfLastItem));
        setCommunityMemberList(allMembers.filter((member) => member !== null));
        setLoading(false);
        // setCurrentPage(1);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
        setTotalCount(response.data.totalCount);
      } else if (filterType == 'country') {
        console.log(filterValue);
        let request = {
          currentPage: 1,
          pageSize: pageSize,
          occupationacategoryid: filterValue,
        };
        let response = await axios.post(
          `${apiUrl}members/getmembersbycountry`,
          request,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        const allMembers = await Promise.all(
          response.data.details.map(async (member) => {
            try {
              if (member.currentlocationid) {
                const locationName = await this.getLocationName(
                  member.currentlocationid,
                );
                member.currentlocationname = locationName || 'Unknown Location';
              } else {
                member.currentlocationname = 'Unknown Location';
              }
              return member;
            } catch (error) {
              console.error('Error fetching location details:', error);
              return member;
              // return null;
            }
          }),
        );
        const indexOfLastItem = 1 * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        setFilterData(allMembers.slice(indexOfFirstItem, indexOfLastItem));
        setCommunityMemberList(allMembers.filter((member) => member !== null));
        setLoading(false);
        // setCurrentPage(1);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
        setTotalCount(response.data.totalCount);
      } else if (filterType == 'location') {
        setshowMapPage(true);
        setLoading(false);
        setCommunityMemberList([]);
      }
    } catch (error) {
      console.error('Error fetching members:', error);
      setCommunityMemberList([]);
      setLoading(false);
    }
  };

  const toggleSkills = (skills) => {
    setopenSkillsModal(true);
    setSkillsetData(skills);
  };
  const closeSkillModal = () => {
    setopenSkillsModal(false);
  };
  const getLocationName = async (placeId) => {
    try {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
      return new Promise((resolve, reject) => {
        service.getDetails({ placeId }, (place, status) => {
          if (status === 'OK') {
            const address = place.formatted_address;
            resolve(address);
          } else {
            reject(status);
          }
        });
      });
    } catch (error) {
      console.error('Error fetching place details:', error);
      throw error;
    }
  };

  const openProfileModal = async (memberuuid) => {
    setModal(!memberModal);
    try {
      const result = await axios.get(
        `${apiUrl}members/fulldetails/${memberuuid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const { data } = result;
      if (data && data.length > 0) {
        const member = data[0];
        const date = new Date(member.dob);
        setDOB(
          `${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`,
        );
        setNativeLocation(
          member.nativelocationid
            ? await handlePlaceId(member.nativelocationid)
            : 'Not known',
        );
        setCurrentLocation(
          member.currentlocationid
            ? await handlePlaceId(member.currentlocationid)
            : 'Not known',
        );
        setCompanyLocation(
          member.companylocationid
            ? await handlePlaceId(member.companylocationid)
            : 'Not known ',
        );
        setMemberFullData(member);
        setModalLoading(false);
      }
    } catch (error) {
      console.error('Error fetching member details: ', error);
    }
  };
  const closeProfileModal = () => {
    setModal(!memberModal);
  };
  const getOrdinalSuffix = (day) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };
  const getMonthName = (month) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[month];
  };
  const handlePlaceId = async (placeId) => {
    try {
      return new Promise((resolve, reject) => {
        const service = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        );
        service.getDetails({ placeId }, (place, status) => {
          if (status === 'OK' && place && place.formatted_address) {
            const address = place.formatted_address;
            resolve(address);
          } else {
            reject(new Error('Place details not found'));
          }
        });
      });
    } catch (error) {
      console.error('Error fetching place details:', error);
      throw error;
    }
  };
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const favoriteMember = async () => {
    setOpenModal(false);
    setLoading(true);
    try {
      let request = {
        likedmemberuuid: likedmemberuuid,
        memberuuid: localStorage.getItem('memberID'),
      };
      let result = await axios
        .post(`${apiUrl}members/favourites`, request, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(async (res) => {
          getAllDetails();
        });
    } catch (error) { }
  };
  const openConfirmModal = (memberUUID, likedstatus) => {
    if (likedstatus === 1) {
      setlikedmemberuuid(memberUUID);
      setLikedStatus('Unlike');
      setOpenModal(true);
    } else {
      setlikedmemberuuid(memberUUID);
      setLikedStatus('Like');
      setOpenModal(true);
    }
  };
  const closelikeModal = () => {
    setOpenModal(false);
  };
  const openCopyModal = (memberdata) => {
    setcopyModalOpen(true);
    setsharedMemberUuid(memberdata.memberuuid);
  };
  const toggleCopyModal = () => {
    setcopyModalOpen(false);
  };
  const handleCopyText = () => {
    const urlToCopy = `${pageUrl}index/${sharedMemberUuid}`;
    const textArea = document.createElement('textarea');
    textArea.value = urlToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopyButton('Copied!');
    setTimeout(() => {
      setCopyButton('Copy Url');
    }, 3000);
  };
  const closeModal = () => {
    setModal(false);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  const displayImage = (imagepath, gender) => {
    if (!imagepath) {
      if (gender.toLowerCase() == 'male') {
        return manavatar;
      } else if (gender.toLowerCase() == 'female') {
        return femaleavatar;
      } else {
        return transgenderavatar;
      }
    } else {
      return imagepath;
    }
  };
  const openConnectionModal = (memberuuid) => {
    setConnectionmodalOpen(true);
    setlikedmemberuuid(memberuuid);
  };
  const updateConnection = async () => {
    try {
      let request = {
        connectedmemberuuid: likedmemberuuid,
        memberuuid: localStorage.getItem('memberID'),
        status: 4,
      };
      let result = await axios
        .post(`${apiUrl}connection/requestforconnection`, request, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(async (res) => {
          if (res.status === 200) {
            setConnectionmodalOpen(false);
            getAllDetails(currentPage);
            setSuccess({
              title: 'SUCCESS!',
              message: res.data.message,
            });
          } else {
            setConnectionmodalOpen(false);
            setError({
              title: 'An Error Occured!',
              message: res.data.message,
            });
          }
        });
    } catch (error) { }
  };
  const closeConnectionModal = () => {
    setConnectionmodalOpen(false);
    setlikedmemberuuid('');
  };
  const openfilterModal = async () => {
    setFilterModalopen(true);
    setActiveFilterLink('other');

    try {
      const [
        degreelevelRes,
        degreenamesRes,
        genderRes,
        religionRes,
        occupationRes,
        skillsetRes,
      ] = await Promise.all([
        axios.get(`${apiUrl}codes/degreelevel`),
        axios.get(`${apiUrl}codes/degreenames`),
        axios.get(`${apiUrl}codes/gender`),
        axios.get(`${apiUrl}codes/religion`),
        axios.get(`${apiUrl}codes/occupationalcategory`),
        axios.get(`${apiUrl}codes/skillset`),
      ]);

      setdegreeLevelList(degreelevelRes.data.list);
      setdegreeNameList(degreenamesRes.data.list);
      setgenderList(genderRes.data.list);
      setreligionList(religionRes.data.list);
      setoccupationList(occupationRes.data.list);
      setskillsetList(skillsetRes.data.list);
      // sbPartnerserviceList()
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDegreeLevel = (isChecked, response) => {
    setOtherFilterRequestBody((prevState) => {
      let degreeLevelList;
      if (isChecked) {
        if (!prevState.degreeLevelList.includes(response)) {
          degreeLevelList = [...prevState.degreeLevelList, response];
        } else {
          degreeLevelList = [...prevState.degreeLevelList];
        }
      } else {
        degreeLevelList = prevState.degreeLevelList.filter(
          (degreeLevel) => degreeLevel !== response,
        );
      }
      return {
        ...prevState,
        degreeLevelList: degreeLevelList,
      };
    });
  };
  const handleDegreeNames = (isChecked, response) => {
    setOtherFilterRequestBody((prevState) => {
      let degreeNameList;
      if (isChecked) {
        if (!prevState.degreeNameList.includes(response)) {
          degreeNameList = [...prevState.degreeNameList, response];
        } else {
          degreeNameList = [...prevState.degreeNameList];
        }
      } else {
        degreeNameList = prevState.degreeNameList.filter(
          (degreeName) => degreeName !== response,
        );
      }
      return {
        ...prevState,
        degreeNameList: degreeNameList,
      };
    });
  };
  const handleGender = (isChecked, response) => {
    setOtherFilterRequestBody((prevState) => {
      let updatedGenderList;
      if (isChecked) {
        if (!prevState.genderList.includes(response)) {
          updatedGenderList = [...prevState.genderList, response];
        } else {
          updatedGenderList = [...prevState.genderList];
        }
      } else {
        updatedGenderList = prevState.genderList.filter(
          (gender) => gender !== response,
        );
      }
      return {
        ...prevState,
        genderList: updatedGenderList,
      };
    });
  };
  const handleMentor = (isChecked, response) => {
    setOtherFilterRequestBody((prevState) => {
      let updatedList;
      if (isChecked) {
        if (!prevState.mentorshipList.includes(response)) {
          updatedList = [...prevState.mentorshipList, response];
        } else {
          updatedList = [...prevState.mentorshipList];
        }
      } else {
        updatedList = prevState.mentorshipList.filter(
          (mentor) => mentor !== response,
        );
      }
      return {
        ...prevState,
        mentorshipList: updatedList,
      };
    });
  };
  const handleReligion = (isChecked, response) => {
    setOtherFilterRequestBody((prevState) => {
      if (isChecked) {
        return {
          ...prevState,
          religionList: [...prevState.religionList, response],
        };
      } else {
        return {
          ...prevState,
          religionList: prevState.religionList.filter(
            (religion) => religion !== response,
          ),
        };
      }
    });
  };
  const handleOccupation = (isChecked, response) => {
    setOtherFilterRequestBody((prevState) => {
      if (isChecked) {
        return {
          ...prevState,
          occupationList: [...prevState.occupationList, response],
        };
      } else {
        return {
          ...prevState,
          occupationList: prevState.occupationList.filter(
            (occupation) => occupation !== response,
          ),
        };
      }
    });
  };
  const handleSkillset = (isChecked, response) => {
    setOtherFilterRequestBody((prevState) => {
      if (isChecked) {
        return {
          ...prevState,
          skillsetList: [...prevState.skillsetList, response],
        };
      } else {
        return {
          ...prevState,
          skillsetList: prevState.skillsetList.filter(
            (skill) => skill !== response,
          ),
        };
      }
    });
  };

  const handleRangeChange = (values) => {
    setRangeValues(values);
    setOtherFilterRequestBody((prevState) => ({
      ...prevState,
      minage: values[0],
      maxage: values[1],
    }));
  };

  const openShortChat = (memberdata) => {
    setChatMemberData(memberdata);
    if (document.getElementById('chatbox').style.display == 'none') {
      document.getElementById('chatbox').style.display = '';
    }
  };

  const closeShortChat = () => {
    document.getElementById('chatbox').style.display = 'none';
  };

  const toggleShowAllDegreeNames = () => {
    setShowAllDegreeNames((prevState) => !prevState);
  };
  const toggleShowAllReligion = () => {
    setshowAllreligion((prevState) => !prevState);
  };
  const toggleShowAllOccupation = () => {
    setshowAllOccupation((prevState) => !prevState);
  };
  const toggleShowAllSkills = () => {
    setshowAllSkills((prevState) => !prevState);
  };

  const toggleShowAllService = () => {
    setShowAllService((prevState) => !prevState);
  };
  const handleCurrentAddressChange = (address) => {
    setCurrentAddress(address);
  };
  const handleCurrentAddressSelect = (address, placeid) => {
    setSelectedPlaces((prevState) => [
      ...prevState,
      { address: address, placeid: placeid },
    ]);
    setCurrentAddress('');
  };
  const handleRemovePlace = (index) => {
    setSelectedPlaces((prevState) =>
      prevState.filter((place, i) => i !== index),
    );
  };
  const tipFormatter = (value) => {
    return `${value}`;
  };
  const closeFilterModal = () => {
    setFilterModalopen(false);
  };
  const handleAlert = () => {
    setFade(false);
    setTimeout(() => {
      setShow(false);
    }, 800);
  };
  const otherFilterApply = async () => {
    setLoading(true);
    setFilterModalopen(false);
    const selectedPlacesIdOnly = selectedPlaces.map((place) => place.placeid);
    const updatedRequestBody = {
      ...otherFilterRequestBody,
      locationidlist: selectedPlacesIdOnly,
    };

    try {
      // Assuming apiUrl is defined
      const res = await axios.post(
        `${apiUrl}members/getmembersbyallfilter`,
        updatedRequestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const allMembers = await Promise.all(
        res.data.details.map(async (member) => {
          try {
            if (member.currentlocationid) {
              const locationName = await getLocationName(
                member.currentlocationid,
              );
              member.currentlocationname = locationName || 'Unknown Location';
            } else {
              member.currentlocationname = 'Unknown Location';
            }
            return member;
          } catch (error) {
            console.error('Error fetching location details:', error);
            return null;
          }
        }),
      );
      if (res.data.details.length > 0) {
        const indexOfLastItem = 1 * pageSize;
        const indexOfFirstItem = indexOfLastItem - pageSize;
        setCustumFilterData(
          allMembers.slice(indexOfFirstItem, indexOfLastItem),
        );
        const filteredMembers = allMembers.filter((member) => member !== null);
        setCommunityMemberList(filteredMembers);
        setLoading(false);
        setCurrentPage(1);
        setCurrentPageNo(1);
        setrefreshPage(false);
        setTotalPages(Math.ceil(res.data.totalCount / pageSize));
        setTotalCount(res.data.totalCount);
      } else {
        setCommunityMemberList([]);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };
  const filterslider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // Show 5 items at a time
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const progressBarUpdate = async () => {
    const configurationData = JSON.parse(
      localStorage.getItem('configurationData'),
    );
    var memberID = configurationData.memberuuid;

    try {
      //educational
      const educationalResponse = await axios.get(
        apiUrl + 'members/educational/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (educationalResponse.data.length) {
        setProfilePercentage((prevState) => prevState + 15);
      }
      //personal
      const personalResponse = await axios.get(
        apiUrl + 'members/personal/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (personalResponse.data[0] != null) {
        setProfilePercentage((prevState) => prevState + 15);
      }
      // occupation
      const occupationResponse = await axios.get(
        apiUrl + 'members/occupation/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (occupationResponse.data.length) {
        setProfilePercentage((prevState) => prevState + 15);
      }
      //lifestyle
      const lifestyleResponse = await axios.get(
        apiUrl + 'members/lifestyle/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (lifestyleResponse.data.length) {
        setProfilePercentage((prevState) => prevState + 15);
      }
      //family
      const familyResponse = await axios.get(
        apiUrl + 'members/family/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (familyResponse.data.length) {
        setProfilePercentage((prevState) => prevState + 15);
      }
      setProgressAlert(true);
      setProgressFadeAlert(true);
    } catch (e) {
      console.log('error in updating progressbar', e);
    }
  };

  const handleSearchItem = async () => {
    if (!searchItem) return;
    try {
      setLoading(true);
      const response = await axios.get(
        apiUrl + `members/getmembersbysearch/${searchItem}`,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      await response?.data?.map(async (member) => {
        if (member.currentlocationid) {
          const locationName = await getLocationName(member.currentlocationid);
          member.currentlocationname = locationName || 'Unknown Location';
        }
      });
      setCommunityMemberList(response.data);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      setTotalCount(response.data.totalCount);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleAlertProgress = () => {
    setProgressFadeAlert(false);
    setTimeout(() => {
      setProgressAlert(false);
    }, 800);
  };
  //client side pagination
  const handlePageClick = (newPage) => {
    if (newPage == 0) return;
    const indexOfLastItem = newPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    if (filterData) {
      setFilterData(
        communityMemberList.slice(indexOfFirstItem, indexOfLastItem),
      );
    } else if (custumFilterData) {
      setCustumFilterData(
        communityMemberList.slice(indexOfFirstItem, indexOfLastItem),
      );
    } else {
      getAllDetails(newPage);
    }
    setCurrentPageNo(newPage);
    setCurrentPage(newPage);
  };

  let pageNumbers = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }, [totalPages]);

  pageNumbers = useMemo(() => {
    const pages = [1];

    // Calculate dynamic start and end indices
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(currentPage + 1, totalPages - 1);

    // Add ellipsis if there's a gap after the first page
    if (startPage > 2) pages.push('...');

    // Add page numbers in the current range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add ellipsis before the last page if there's a gap
    if (endPage < totalPages - 1) pages.push('....');

    // Include the last page if more than one page exists
    if (totalPages > 1) pages.push(totalPages);
    return pages;
  }, [currentPage, totalPages]);

  console.log(custumFilterData);
  return (
    <>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}
      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <div className="contianer-fluid half-screen-background">
        <div className="container">
          <Row style={{ marginTop: '5.5rem' }}>
            {showAlert && (
              <Fade in={fadeAlert}>
                <div className="alert alert-light">
                  <Row>
                    <div className="text-end">
                      <i
                        className="fa fa-times text-end start-0 z-index-1 dissmissicon position-relative"
                        aria-hidden="true"
                        aria-controls="example-fade-text"
                        aria-expanded={fadeAlert}
                        onClick={() => handleAlert()}
                      ></i>
                    </div>

                    <Col md={6} className="px-4">
                      <div className="icon">
                        <img src={weddingicon} alt="img" className="popupImg" />
                        <h3>Allaince Seeker</h3>
                      </div>
                      <p>
                        MettaMake is a trusted connected network. Each element
                        of the network is verified and known using
                        recommendations only.
                      </p>
                    </Col>
                    <Col md={6} className="px-4">
                      <div className="icon">
                        <img
                          src={communityicon}
                          alt="img"
                          className="popupImg"
                        />
                        <h3>Community Members</h3>
                      </div>
                      <p>
                        Please ping your recommender to approve your joining
                        request. Then you can update your profile and search
                        your preferred matches or help community member to match
                        alliances !
                      </p>
                    </Col>
                  </Row>
                </div>
              </Fade>
            )}
          </Row>
        </div>

        <Col
          lg="12"
          className={`mb-1 container ${configuredata.memberstatuscode === 3 ||
            configuredata.memberstatuscode === 0 ? 'pointer_event_none' : ''}`}
        >
          {profilePercentage < 90 && progressAlert && (
            <Fade in={progressFadeAlert}>
              <div className="text-end" style={{ marginTop: '-19px' }}>
                <i
                  className="fa fa-times text-end start-0 z-index-1 prog-dismiss position-relative"
                  style={{ top: '32px' }}
                  aria-hidden="true"
                  aria-controls="example-fade-text"
                  aria-expanded={progressAlert}
                  onClick={() => handleAlertProgress()}
                ></i>
              </div>
              <Row className="inner_row mx-0 row mt-1 mb-3 p-3">
                <Col md={12}>
                  <div className="progress-box">
                    <h6 className="title ">
                      Profile Completion ({profilePercentage}
                      %)
                    </h6>
                    <Progress
                      max={100}
                      value={profilePercentage}
                      color="primary"
                      barClassName="position-relative"
                    >
                      <div
                        className="progress-value d-block text-muted h6"
                        style={{ margin: '-5px' }}
                      ></div>
                    </Progress>
                  </div>
                </Col>
              </Row>
            </Fade>
          )}

          <Row className="inner_row mx-0">
            <Col md={10} xs={7} className="col_whole_card">
              <div className="w-5/6 m-auto">
                <div className="mt-10 pb-2">
                  <Slider
                    {...filterslider}
                    className="overflow-hidden filter_slider"
                  >
                    {(filterdata || []).map((filter, key) => (
                      <div
                        className="d-flex list-unstyled profession_ul"
                        key={key}
                      >
                        <div
                          className={`text-center profession_li mt-1 ${activeFilterLink === filter.filterName
                            ? 'active'
                            : ''
                            }`}
                          onClick={() => {
                            professionLink(
                              filter.filterName,
                              filter.value,
                              filter.type,
                            );
                          }}
                        >
                          <img
                            src={filter.image}
                            className="professionIcon"
                            alt={filter.filterName}
                          />
                        </div>
                        <p
                          className={`filter_title fw-normal m-auto  ${activeFilterLink === filter.filterName ? 'active_text' : ''}`}
                          style={{ padding: `5px 9px 5px 9px` }}
                        >
                          {filter.filterName}
                        </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Col>
            <Col md={2} xs={4} className="pt-2">
              <div
                style={{
                  padding: `5px 0px 5px 0px`,
                  height: `70px`,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                className={`text-center profession_custum_li  profession_li mt-2 d-flex justify-content-evenly ${activeFilterLink == 'other' ? 'active' : ''}`}
                onClick={() => openfilterModal()}
              >
                <img
                  src={filterIcon}
                  style={{
                    height: `19px`,
                    width: `17px`,
                    margin: `22px`,
                  }}
                />
              </div>
              <p className="custum-filter fw-normal text-center pt-1">
                Custom Filter
              </p>
            </Col>
          </Row>

          {!showMapPage && (
            <Row className="mt-3 mb-1 mx-auto">
              <Col md={8} xs={12} className="">
                <form className="srch_frm_sction"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearchItem();
                  }}
                >
                  <Input
                    type="text"
                    placeholder="Search by name, profession, location"
                    value={searchItem}
                    className="search_input rounded-pill px-3 border-primary d-inline me-1"
                    onChange={(e) => setSearchItem(e.target.value)}
                  />

                  <button
                    className={`btn btn-outline-primary me-2`}
                    disabled={!searchItem}
                    onClick={() => handleSearchItem()}
                  >
                    Search
                  </button>
                  <div
                    className="`btn btn-outline-primary`"
                    onClick={() => {
                      getAllDetails(1);
                      setSearchItem('');
                      setCurrentPage(1);
                      setCurrentPageNo(1);
                      setFilterData(null);
                      setCustumFilterData(null);
                      setActiveFilterLink('');
                    }}
                  >
                    <i
                      className={`fa fa-refresh position-static fa-lg refresh-button btn btn-outline-primary`}
                      aria-hidden="true"
                    ></i>{' '}
                    {/* Refresh */}
                  </div>

                </form>
              </Col>


            </Row>
          )}

          <Row style={{ minHeight: '80vh' }}>
            <Col md={12} xs={12}>
              {showMapPage ? (
                <MapFunction membertype={4} />
              ) : (
                <>
                  {isLoading ? (
                    <Row>
                      {[1, 2, 3, 4].map((key) => (
                        <Col
                          lg="3"
                          md="6"
                          xs="12"
                          key={key}
                          className="mt-4 pt-2 text-center"
                        >
                          <Card className="catagories overflow-hidden rounded border-0">
                            <ContentLoader
                              speed={2}
                              width={380}
                              height={500}
                              viewBox="0 0 380 500"
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                            >
                              {/* Image skeleton */}
                              <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="280"
                                height="200"
                              />

                              {/* Other content placeholders */}
                              <rect
                                x="0"
                                y="220"
                                rx="5"
                                ry="5"
                                width="280"
                                height="20"
                              />
                              <rect
                                x="0"
                                y="250"
                                rx="5"
                                ry="5"
                                width="280"
                                height="20"
                              />
                              <rect
                                x="0"
                                y="280"
                                rx="5"
                                ry="5"
                                width="140"
                                height="20"
                              />
                              <rect
                                x="150"
                                y="280"
                                rx="5"
                                ry="5"
                                width="130"
                                height="20"
                              />
                              <circle cx="30" cy="330" r="15" />
                              <circle cx="70" cy="330" r="15" />
                              <circle cx="110" cy="330" r="15" />
                            </ContentLoader>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      <Col className='d-flex justify-content-center my-3'>
                        {
                          configuredata.currentlocationid ? <h1>
                            Top Profiles Near You
                          </h1> : <h1>
                            Potential Matching Profiles
                          </h1>
                        }
                      </Col>
                      {communityMemberList.length > 0 ? (
                        <Row>
                          {(filterData
                            ? filterData
                            : custumFilterData
                              ? custumFilterData
                              : communityMemberList || []
                          ).map((item, key) => (
                            <Col
                              key={key}
                              className="mt-2 pt-2 text-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12  "
                            >
                              <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary job-box job-primary candidate-list  border-0 shadow card">
                                <CardBody className="rounded overflow-hidden p-0 ">
                                  <ul
                                    className="list-unstyled align-items-center width100 position-absolute  "
                                    style={{ zIndex: `9`, marginBottom: `0px` }}
                                  >
                                    <li className="list-inline-item float-start m-2"></li>
                                    <li className="list-inline-item float-end m-2">
                                      <Button
                                        className="like btn-transparent p-0"
                                        style={{
                                          background: `none`,
                                          border: `none`,
                                        }}
                                        onClick={() =>
                                          openConfirmModal(
                                            item.memberuuid,
                                            item.likedstatus,
                                          )
                                        }
                                      >
                                        <i
                                          className={`mdi mdi-heart h5 mb-0 ${item.likedstatus === 1 ? 'redheart' : ''}`}
                                          style={{ textShadow: `none` }}
                                        ></i>
                                      </Button>
                                    </li>
                                  </ul>

                                  <Slider
                                    className="imageSlider image-container"
                                    {...settings}
                                  >
                                    <img
                                      src={displayImage(
                                        item.photothumb1,
                                        item.gender,
                                      )}
                                      className="img-fluid img_thumb"
                                    />
                                    <img
                                      src={displayImage(
                                        item.photothumb2,
                                        item.gender,
                                      )}
                                      className="img-fluid img_thumb"
                                    />
                                    <img
                                      src={displayImage(
                                        item.photothumb3,
                                        item.gender,
                                      )}
                                      className="img-fluid img_thumb"
                                    />
                                  </Slider>
                                </CardBody>
                              </Card>
                              <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow">
                                <CardBody className="rounded shadow p-2 fixHeight">
                                  <p className="text-dark h5 name mb-0">
                                    <span style={{ color: `#0D6EFD` }}>
                                      {item.firstname}{' '}
                                    </span>
                                  </p>
                                  <p className="m-0 font_size14">
                                    <i className="uil uil-graduation-cap h4 mb-0 me-2 text-primary"></i>
                                    {item.jobprofession
                                      ? item.jobprofession
                                      : 'Unknown Profession'}{' '}
                                    |{' '}
                                  </p>
                                  <p className="m-0 font_size14">
                                    <FeatherIcon
                                      icon="map-pin"
                                      className="fea icon-sm me-1"
                                    />
                                    {item.currentlocationname}
                                  </p>
                                  {/* Skillset */}
                                  <div className="d-flex justify-content-center">
                                    {item.skillset.length > 0 && (
                                      <div>
                                        <Link
                                          className="rounded bg-light py-1 px-2 small"
                                          title={item.skillset[0]}
                                        >
                                          {item.skillset[0]}
                                        </Link>
                                        {item.skillset.length > 1 && (
                                          <button
                                            className="btn btn-link"
                                            onClick={() =>
                                              toggleSkills(item.skillset)
                                            }
                                            style={{ fontSize: `12px` }}
                                          >
                                            +{item.skillset.length - 1} more
                                          </button>
                                        )}
                                      </div>
                                    )}
                                    {item.skillset.length == 0 && (
                                      <Link className="rounded bg-light py-1 px-2 small mt-2">
                                        Skills Not Mentioned
                                      </Link>
                                    )}
                                  </div>

                                  <span className="text-muted">
                                    <FeatherIcon
                                      icon="briefcase"
                                      className="fea icon-sm me-1"
                                    />
                                    Experience{' '}
                                    <span className="text-success">
                                      {item.experience
                                        ? item.experience
                                        : 'Unknown'}{' '}
                                      years
                                    </span>
                                  </span>
                                  <div className=" d-flex justify-content-center">
                                    <div className="m-1">
                                      <button
                                        title="View Profile"
                                        onClick={() =>
                                          openProfileModal(item.memberuuid)
                                        }
                                        className="btn  designation btn-outline-primary viewBtn"
                                      >
                                        <FeatherIcon
                                          icon="eye"
                                          className="fea icon-sm icons"
                                        />
                                      </button>
                                    </div>
                                    <div className="m-1">
                                      <button
                                        title="Message"
                                        onClick={() => openShortChat(item)}
                                        className="btn  designation btn-outline-info viewBtn"
                                      >
                                        <i className="fa fa-comments"></i>
                                      </button>
                                    </div>
                                    <div className="m-1">
                                      {!item.connectionstatus && (
                                        <button
                                          title="Send Connection Request"
                                          className="btn btn-outline-success"
                                          onClick={() =>
                                            openConnectionModal(
                                              item.memberuuid,
                                              item.connectionstatus,
                                            )
                                          }
                                        >
                                          <span>
                                            <i
                                              className="fa fa-handshake-o"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </button>
                                      )}
                                      {item.connectionstatus ==
                                        'Connecting' && (
                                          <button
                                            title="Waiting To Connect"
                                            className="btn btn-outline-danger"
                                          >
                                            <span>
                                              <i
                                                className="fa fa-exchange"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </button>
                                        )}

                                      {item.connectionstatus == 'Connected' && (
                                        <button
                                          className="btn btn-outline-success"
                                          title="Connected"
                                        >
                                          <FeatherIcon
                                            icon="user-check"
                                            className="fea icon-sm icons "
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}

                          <Col xs={12} className="my-3 pt-2">
                            {totalPages > 1 && (
                              <ul className="pagination justify-content-center mb-0">
                                <PaginationItem disabled={currentPageNo === 1}>
                                  <PaginationLink
                                    to="#"
                                    aria-label="Previous"
                                    onClick={() =>
                                      handlePageClick(currentPageNo - 1)
                                    }
                                  >
                                    {'<<'}
                                  </PaginationLink>
                                </PaginationItem>
                                {pageNumbers.map((number) => (
                                  <PaginationItem
                                    active={number === currentPageNo}
                                    key={number}
                                    disabled={
                                      number === '...' || number === '....'
                                    }
                                  >
                                    <PaginationLink
                                      onClick={() => handlePageClick(number)}
                                    >
                                      {number}
                                    </PaginationLink>
                                  </PaginationItem>
                                ))}
                                <PaginationItem
                                  disabled={currentPageNo === totalPages}
                                >
                                  <PaginationLink
                                    to="#"
                                    aria-label="Next"
                                    onClick={() =>
                                      handlePageClick(currentPageNo + 1)
                                    }
                                  >
                                    {'>>'}
                                  </PaginationLink>
                                </PaginationItem>
                              </ul>
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <div className="text-center mt-4 mb-4 label_text fs-3">
                          No Members Found
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Col>
        <div
          id="chatbox"
          style={{
            display: 'none',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 99999,
            width: '300px',
          }}
        >
          <div className="card">
            <div className="card-header">
              <h6 className="m-0">
                {' '}
                Messages
                <button
                  className="btn btn-sm btn-link float-end"
                  onClick={() => closeShortChat()}
                >
                  <i className="fa fa-times fa-2x position-static"></i>
                </button>
              </h6>
            </div>
            <div className="card-body p-0">
              <ChatBoxFunction memberdata={chatMemberData} />
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={memberModal} size="xl">
        <ModalHeader toggle={openModal}>
          Profile Details
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {/* <button
            type="button"
            className="btn shareBtn"
            onClick={() => openCopyModal(memberFullData)}
          >
            <span aria-hidden="true" className="d-flex">
              {' '}
              <span className="d-none d-md-block">Share This Profile </span>
              <i>
                <FeatherIcon
                  icon="share-2"
                  className="fea icon-sm icons px-1"
                  style={{ width: `30px` }}
                />
              </i>
            </span>
          </button> */}
        </ModalHeader>
        {isModalLoading ? (
          <ModalBody>
            <ModalBodySkeleton />
          </ModalBody>
        ) : (
          <>
            {memberFullData && Object.keys(memberFullData).length > 0 && (
              <ProfileModal
                memberData={memberFullData}
                location={[nativeLocation, currentLocation, companyLocation]}
                dob={dob}
              />
            )}
          </>
        )}
      </Modal>
      <Modal isOpen={openModal} toggle={closelikeModal}>
        <ModalHeader toggle={closelikeModal}>
          Confirmation
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closelikeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className="mb-0">Are You Sure to {likeStatus} ?</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button color="secondary" onClick={closelikeModal} type="button">
            Close
          </Button>
          <Button color="danger" type="button" onClick={favoriteMember}>
            {likeStatus}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={copyModalOpen} toggle={toggleCopyModal}>
        <ModalHeader toggle={toggleCopyModal}>
          Share Url
          <button
            type="button"
            className="btn shareBtn shareUrlbtn btn-dark text-white btn-outline-secondary"
            onClick={handleCopyText}
          >
            <span aria-hidden="true">
              {copyButton == 'Copy Url' ? (
                <i>
                  <FeatherIcon icon="copy" className="fea icon-sm icons" />
                </i>
              ) : (
                <i>
                  <FeatherIcon icon="check" className="fea icon-sm icons" />
                </i>
              )}{' '}
              {copyButton}
            </span>
          </button>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggleCopyModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody className="copy_Modal_body">
          <Row>
            <Col lg="12">
              <p className="mb-0">
                {pageUrl}
                {'index/'}
                {sharedMemberUuid}
              </p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={connectionmodalOpen} toggle={closeConnectionModal}>
        <ModalHeader toggle={closeConnectionModal}>
          Confirmation
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeConnectionModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className="mb-0">Are You Sure to Connect?</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button
            color="secondary"
            onClick={closeConnectionModal}
            type="button"
          >
            Close
          </Button>
          <Button
            color="success"
            type="button"
            onClick={() => updateConnection()}
          >
            Connect
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={filtermodalOpen} toggle={closeFilterModal} size="lg">
        <ModalHeader toggle={closeFilterModal}>
          Custom Filters
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeFilterModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <Card
                className=" border-0 rounded shadow"
                style={{ zIndex: '9' }}
              >
                <CardBody className="card-body">
                  <Row className="">
                    <div>
                      <Label className="form-label label_text">
                        Profession
                      </Label>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <div className="form-icon position-relative">
                            <ul className="ks-cboxtags p-0">
                              {visibleOccupationList.map((response, index) => (
                                <div
                                  className="form-check form-check-inline form_check_box"
                                  key={index}
                                >
                                  <li>
                                    <div className="mb-0">
                                      <div className="form-check p-0">
                                        <input
                                          type="checkbox"
                                          id={response.label + 'occupation'}
                                          onChange={(event) =>
                                            handleOccupation(
                                              event.target.checked,
                                              response.value,
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={
                                            response.label + 'occupation'
                                          }
                                        >
                                          {response.label}
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            </ul>
                          </div>
                          {occupationList.length > 3 && (
                            <button
                              className="btn show_more_btn"
                              onClick={toggleShowAllOccupation}
                            >
                              {showAllOccupation ? 'Show Less' : 'Show More'}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      {otherFilterRequestBody.occupationList ? (
                        <>
                          <Label className="form-label label_text">
                            Skillset
                          </Label>
                          <div className="features feature-primary p-1">
                            <div className="mb-3">
                              <div className="form-icon position-relative">
                                <ul className="ks-cboxtags p-0">
                                  {(visibleskillsList || []).map(
                                    (response, index) => (
                                      <div
                                        className="form-check form-check-inline form_check_box"
                                        key={index}
                                      >
                                        <li>
                                          <div className="mb-0">
                                            <div className="form-check p-0">
                                              <input
                                                checked={otherFilterRequestBody.skillsetList.includes(
                                                  response.value,
                                                )}
                                                type="checkbox"
                                                id={response.label + 'skillset'}
                                                onChange={(event) =>
                                                  handleSkillset(
                                                    event.target.checked,
                                                    response.value,
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={
                                                  response.label + 'skillset'
                                                }
                                              >
                                                {response.label}
                                              </label>
                                            </div>
                                          </div>
                                        </li>
                                      </div>
                                    ),
                                  )}
                                </ul>
                              </div>
                              {skillsetList.length > 3 && (
                                <button
                                  className="btn show_more_btn"
                                  onClick={toggleShowAllSkills}
                                >
                                  {showAllskills ? 'Show Less' : 'Show More'}
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <hr />

                    <div className="features feature-primary p-1">
                      <div className="mb-3">
                        <Label className="form-label label_text">
                          Degree Level
                        </Label>
                        <div className="form-icon position-relative">
                          <ul className="ks-cboxtags p-0">
                            {degreeLevelList.map((response, index) => (
                              <div
                                className="form-check form-check-inline form_check_box"
                                key={index}
                              >
                                <li>
                                  <div className="mb-0">
                                    <div className="form-check p-0">
                                      <Input
                                        type="checkbox"
                                        checked={otherFilterRequestBody.degreeLevelList.includes(
                                          response.value,
                                        )}
                                        id={response.label + 'degreelevel'}
                                        onChange={(event) =>
                                          handleDegreeLevel(
                                            event.target.checked,
                                            response.value,
                                          )
                                        }
                                      />
                                      <Label
                                        className="form-check-label"
                                        htmlFor={response.label + 'degreelevel'}
                                      >
                                        {response.label}{' '}
                                      </Label>
                                    </div>
                                  </div>
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="features feature-primary p-1">
                      <div className="mb-3">
                        <Label className="form-label label_text">
                          Preferred Degree Names
                        </Label>
                        <div className="form-icon position-relative">
                          <ul className="ks-cboxtags p-0">
                            {visibleDegreeNameList.map((response, index) => (
                              <div
                                className="form-check form-check-inline form_check_box"
                                key={index}
                              >
                                <li>
                                  <div className="mb-0">
                                    <div className="form-check p-0">
                                      <input
                                        type="checkbox"
                                        id={response.label + 'degreename'}
                                        checked={otherFilterRequestBody.degreeNameList.includes(
                                          response.value,
                                        )}
                                        onChange={(event) =>
                                          handleDegreeNames(
                                            event.target.checked,
                                            response.value,
                                          )
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={response.label + 'degreename'}
                                      >
                                        {response.label}
                                      </label>
                                    </div>
                                  </div>
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                        {degreeNameList.length > 3 && (
                          <button
                            className="btn show_more_btn"
                            onClick={toggleShowAllDegreeNames}
                          >
                            {showAllDegreeNames ? 'Show Less' : 'Show More'}
                          </button>
                        )}
                      </div>
                    </div>

                    <hr />

                    <Col md={6} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Degree College Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            onChange={(event) =>
                              setOtherFilterRequestBody({
                                ...otherFilterRequestBody,
                                degreecollege: event.target.value,
                              })
                            }
                            placeholder="Search By Degree College Name"
                          />
                        </div>
                      </div>
                    </Col>
                    <hr />

                    <Col md={6} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Company Name
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            onChange={(event) =>
                              setOtherFilterRequestBody({
                                ...otherFilterRequestBody,
                                companyname: event.target.value,
                              })
                            }
                            placeholder="Search By Company Name"
                          />
                        </div>
                      </div>
                    </Col>

                    <div>
                      <Row>
                        <Label className="form-label label_text">
                          Location
                        </Label>
                        <Col md={6}>
                          <div className="features feature-primary p-1 filter">
                            <div className="mb-3">
                              <PlacesAutocomplete
                                value={currentAddress}
                                onChange={handleCurrentAddressChange}
                                onSelect={handleCurrentAddressSelect}
                                className="form-control form_input"
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => {
                                  const uniqueSuggestions = new Set();

                                  return (
                                    <div>
                                      <input
                                        {...getInputProps({
                                          placeholder: 'Search Places ...',
                                          className: 'location-search-input',
                                        })}
                                      />
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(
                                          (suggestion, index) => {
                                            const className = suggestion.active
                                              ? 'suggestion-item--active'
                                              : 'suggestion-item';

                                            const words =
                                              suggestion.description.split(',');
                                            const trimmedArray = words.map(
                                              (str) => str.trim(),
                                            );
                                            const lastThreeWords = trimmedArray
                                              .slice(-3)
                                              .join(',');
                                            // Check if the suggestion is unique based on place_id
                                            if (
                                              !uniqueSuggestions.has(
                                                lastThreeWords,
                                              )
                                            ) {
                                              uniqueSuggestions.add(
                                                lastThreeWords,
                                              );

                                              // inline style for demonstration purpose
                                              const style = suggestion.active
                                                ? {
                                                  backgroundColor: '#fafafa',
                                                  cursor: 'pointer',
                                                }
                                                : {
                                                  backgroundColor: '#ffffff',
                                                  cursor: 'pointer',
                                                };

                                              return (
                                                <div
                                                  key={index}
                                                  {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                      className,
                                                      style,
                                                    },
                                                  )}
                                                >
                                                  <span>{lastThreeWords}</span>
                                                </div>
                                              );
                                            } else {
                                              return null; // Skip rendering duplicate suggestions
                                            }
                                          },
                                        )}
                                      </div>
                                    </div>
                                  );
                                }}
                              </PlacesAutocomplete>
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div>
                            {selectedPlaces.map((place, index) => (
                              <div key={index} className="selected-place">
                                <span>{place.address}</span>{' '}
                                {/* Render address property */}
                                {/* Optionally, you can render placeid as well */}
                                <Link
                                  onClick={() => handleRemovePlace(index)}
                                  className="rounded p-2"
                                >
                                  <FeatherIcon
                                    icon="delete"
                                    className="fea icon-sm fea-social text-danger"
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <div>
                      <Label className="form-label label_text">
                        Mentorship
                      </Label>
                      <div className="form-icon position-relative">
                        <ul className="ks-cboxtags p-0">
                          {mentorshipList.map((response, index) => (
                            <div
                              className="form-check form-check-inline form_check_box"
                              key={index}
                            >
                              <li>
                                <div className="mb-0">
                                  <div className="form-check p-0">
                                    <Input
                                      type="checkbox"
                                      checked={otherFilterRequestBody.mentorshipList.includes(
                                        response.value,
                                      )}
                                      id={response.label + 'mentorship'}
                                      onChange={(event) =>
                                        handleMentor(
                                          event.target.checked,
                                          response.value,
                                        )
                                      }
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor={response.label + 'mentorship'}
                                    >
                                      {response.label}{' '}
                                    </Label>
                                  </div>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            onClick={() => {
              otherFilterApply();
            }}
          >
            Show Results
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openSkillsModal} toggle={closeSkillModal}>
        <ModalHeader toggle={closeSkillModal}>
          Skills
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeSkillModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              {(skillsetData || []).map((item, index) => (
                <div key={index} className="d-flex">
                  <i
                    className="fa fa-angle-double-right mt-1"
                    aria-hidden="true"
                  ></i>
                  &nbsp; &nbsp;
                  <div className="cursor-pointer"> {item}</div>
                </div>
              ))}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CommunityMemberFunction;
