import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

// Modal Video
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//import image
import attentionImg from '../../assets/images/mettamate/ConnectSafe Layout Icons/Attention Icon.svg';
import communicationImg from '../../assets/images/mettamate/ConnectSafe Layout Icons/Communication Icon.svg';
import protectionImg from '../../assets/images/mettamate/ConnectSafe Layout Icons/Protection Icon.svg';
import verificationImg from '../../assets/images/mettamate/ConnectSafe Layout Icons/Verification Icon.svg';
import about1 from '../../assets/images/company/about2.png';
export default class Feature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      features: [
        {
          id: 1,
          image: protectionImg,
          title: 'Protection',
          description:
            'Your safety is provided by leading anti scam system in the industry',
        },
        {
          id: 2,
          image: verificationImg,
          title: 'Verification',
          description:
            'All members are personally confirmed by our staff to prove they are real ',
        },
        {
          id: 3,
          image: attentionImg,
          title: 'Attention',
          description:
            'Receive lots of attention from attractive  members from your own community',
        },
        {
          id: 4,
          image: communicationImg,
          title: 'Communication',
          description:
            'Free Chat, send line messages, connect on phone and share your photos',
        },
      ],
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <section
          className="py-4"
          style={{ marginBottom: '50px', backgroundColor: '#ffe5cc' }}
        >
          <Container>
            <Row
              className="justify-content-center"
              style={{ marginBottom: '50px' }}
            >
              <Col className={'col-12 text-center'}>
                <div className="">
                  <h1 className="title"> Connecting on Safe Platform</h1>
                </div>
              </Col>
            </Row>
            <Row className="">
              <Col lg={12}>
                <div className="">
                  <Row>
                    {this.state.features.map((items, key) => (
                      <Col lg={3} md={6} xs={12} key={key}>
                        <Card
                          className="features feature-clean explore-feature p-4 px-md-3 border-1 border border-warning rounded-md shadow text-center "
                          style={{ marginBottom: '10px' }}
                        >
                          <div className="icons text-primary text-center mx-auto">
                            <img src={items.image}></img>
                          </div>

                          <CardBody className="p-0  ">
                            <h3 className="mt-4">
                              <Link to="#" className="title text-dark fontSize18" style={{fontWeight:'600'}}>
                                {items.title}
                              </Link>
                            </h3>
                            <span className="fontSize16">{items.description}</span>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
