import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Progress,
  Card,
  CardBody,
  Badge,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../modal.js';
import '../../Home/css/userHomePage.css';
import { useFormik } from 'formik';
import apiUrl from '../../server.js';
import FeatherIcon from 'feather-icons-react';
import linkedinIcon from '../../../assets/images/MettaMateImages/linkedin.png';
import instagramIcon from '../../../assets/images/MettaMateImages/instagram.png';
import facebookIcon from '../../../assets/images/MettaMateImages/facebook.png';
import twitterIcon from '../../../assets/images/MettaMateImages/twitter.png';
import youtubeIcon from '../../../assets/images/MettaMateImages/youtube.png';
import snapchatIcon from '../../../assets/images/MettaMateImages/snapchat.png';
import websiteIcon from '../../../assets/images/MettaMateImages/website.png'
const SocialFunction = () => {
  let memberID = localStorage.getItem('memberID');
  const [socialData, setSocialData] = useState({});
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    socialDetails();
  }, []);
  const socialDetails = async () => {
    try {
      const response = await axios.get(apiUrl + 'members/social/' + memberID, {
        headers: {
          Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      if (response.data.length > 0) {
        setSocialData(response.data[0]);
      } else {
        setSocialData([]);
      }
    } catch (error) { }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      memberuuid: memberID,
      instagram: '',
      facebook: '',
      twitter: '',
      youtube: '',
      snapchat: '',
      linkedin: '',
      website: ''
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      var LinkedIn = document.getElementById('Linkedin');
      values.linkedin = LinkedIn.value;
      var instagram = document.getElementById('instagram');
      values.instagram = instagram.value;
      var facebook = document.getElementById('facebook');
      values.facebook = facebook.value;
      var twitter = document.getElementById('twitter');
      values.twitter = twitter.value;
      var youtube = document.getElementById('youtube');
      values.youtube = youtube.value;
      var snapchat = document.getElementById('snapchat');
      values.snapchat = snapchat.value;
      var website = document.getElementById('website');
      values.website = website.value;
      try {
        const response = await axios
          .post(apiUrl + 'members/social/', values, {
            headers: {
              Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            socialDetails();
            setIsLoading(false);

            setSuccess({
              title: 'SUCCESS!',
              message: res.data.message,
            });
          })
          .catch((err) => {
            setIsLoading(false);

            setError({
              title: 'An Error Occured!',
              message: err.response.data.message,
            });
          });
      } catch (error) {
        setIsLoading(false);

        setError({
          title: 'An error occurred!',
          message: error.data.message,
        });
      }
    },
  });
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setSocialData([]);
  };
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      {
        isLoading ? <LoaderComponent /> :
          (

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Row className="inner_row m-0 mt-1">
                <h6 className="title  title_bar">
                  Socials
                </h6>
                <Col md={12} className='mt-3'>
                  <img src={linkedinIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >LinkedIn</span>
                  <Row className="mt-3">
                    <Col md={4}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">Linkedin Url </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon icon="user" className="fea icon-sm icons" />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="LinkedIn"
                            name="LinkedIn"
                            id="Linkedin"
                            defaultValue={socialData.linkedin}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>
                  <img src={instagramIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Instagram</span>
                  <Row className="mt-3">
                    <Col md={4}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text ">Instagram Id </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="instagram"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="Instagram Id"
                            id="instagram"
                            defaultValue={socialData.instagram}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>
                <Col md={12} className='mt-3'>
                  <img src={facebookIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Facebook</span>
                  <Row className="mt-3">
                    <Col md={4}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">Facebook </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="facebook"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="Facebook"
                            defaultValue={socialData.facebook}
                            name="facebook"
                            id="facebook"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>
                  <img src={twitterIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Twitter</span>
                  <Row className="mt-3">
                    <Col md={4}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">Twitter </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon icon="twitter" className="fea icon-sm icons" />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="twitter"
                            name="twitter"
                            defaultValue={socialData.twitter}
                            id="twitter"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={youtubeIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Youtube</span>
                  <Row className="mt-3">
                    <Col md={4}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">Youtube </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon icon="youtube" className="fea icon-sm icons" />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="youtube"
                            name="youtube"
                            id="youtube"
                            defaultValue={socialData.youtube}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>
                  <img src={snapchatIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Snapchat</span>
                  <Row className="mt-3">
                    <Col md={4}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">Snapchat </Label>
                          <div className="form-icon position-relative">
                            <i className="fa fa-snapchat-square" aria-hidden="true"></i>

                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="snapchat"
                            name="snapchat"
                            id="snapchat"
                            defaultValue={socialData.snapchat}
                          />
                        </div>
                      </div>
                    </Col>


                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <Col md={12} className='mt-3'>
                  <img src={websiteIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Website</span>
                  <Row className="mt-3">
                    <Col md={4}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">Website </Label>
                          <div className="form-icon position-relative">
                            <i className="fa fa-globe" aria-hidden="true"></i>

                          </div>
                          <Input
                            type="text"
                            className="form-control form_Input ps-5"
                            placeholder="website"
                            name="website"
                            id="website"
                            defaultValue={socialData.website}
                          />
                        </div>
                      </div>
                    </Col>


                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "
                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary  mt-2 ms-2"
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment>
  );
};
export default SocialFunction;
