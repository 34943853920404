import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Progress,
  Card,
  CardBody,
  Badge,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal.js';
import FeatherIcon from 'feather-icons-react';
import '../../Home/css/userHomePage.css';
import { useFormik } from 'formik';
import apiUrl from '../../server';
import noofsiblingIcon from '../../../assets/images/MettaMateImages/children.png';
import positionofsiblingIcon from '../../../assets/images/MettaMateImages/positionsibling.png';
import GuardianIcon from '../../../assets/images/MettaMateImages/happy.png';
import FamilytypeIcon from '../../../assets/images/MettaMateImages/family.png';
import geneticIcon from '../../../assets/images/MettaMateImages/dna.png';

const FamilyBackgroundFunction = () => {
  let memberID = localStorage.getItem('memberID');
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [noOfSiblingList, setNoOfSublingList] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchFamilyData();
  }, []);
  const fetchFamilyData = async () => {
    try {
      const response = await axios.get(apiUrl + 'members/family/' + memberID, {
        headers: {
          Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      if (response.data.length > 0) {
        let familyBGData = response.data[0]
        setFamilyFormData({
          memberuuid: localStorage.getItem("memberID"),
          familytype: familyBGData.familytype ? familyBGData.familytype : 0,
          mother: familyBGData.mother ? familyBGData.mother : 0,
          father: familyBGData.father ? familyBGData.father : 0,
          noofsibling: familyBGData.noofsibling ? familyBGData.noofsibling : 0,
          geneticdiseases: familyBGData.geneticdiseases ? familyBGData.geneticdiseases : 0,
          yourposition: familyBGData.yourposition ? familyBGData.yourposition : 0
        });
      } else {
        setFamilyFormData({
          memberuuid: localStorage.getItem("memberID"),
          familytype: 0,
          motherrequired: 0,
          fatherrequired: 0,
          noofsibling: 0,
          geneticdiseases: 0,
          yourposition: 0
        })

      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // setError({
      //   title: 'An error occurred!',
      //   message: response.data.message,
      // });
    }
  };

  const [familyFormData, setFamilyFormData] = useState({
    memberuuid: localStorage.getItem("memberID"),
    familytype: 0,
    mother: 0,
    father: 0,
    noofsibling: 0,
    geneticdiseases: 0,
    yourposition: 0
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    let result = await axios.post(`${apiUrl}members/family/`, familyFormData, {
      headers: {
        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
        "Content-Type": "application/json"
      },
    }).then((res) => {
      fetchFamilyData();
      setIsLoading(false);

      setSuccess({
        title: 'SUCCESS!',
        message: res.data.message,
      });
    }).catch((err) => {
      setIsLoading(false);

      setError({
        title: 'An Error Occured!',
        message: err.response.data.message,
      });
    });

  }

  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };

  const handleReset = () => {
    setFamilyFormData({
      memberuuid: localStorage.getItem("memberID"),
      familytypes: [],
      motherrequired: 0,
      fatherrequired: 0,
      numofsiblings: 0,
      geneticdiseases: 0
    })

  };
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      {
        isLoading ? <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row className="inner_row m-0 mt-1">
                <h6 className="title  title_bar">
                  Family Background
                </h6>
                <Col md={12} className='mt-3'>
                  <img src={FamilytypeIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Family</span>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Family Type

                          </Label>

                          <section className="radio-section">
                            <div className="radio-list">
                              <div className="radio-item">
                                <input
                                  name="familyTyperadio"
                                  id="radionuclear"
                                  type="radio"
                                  value="1"
                                  checked={familyFormData.familytype == 1}
                                  onChange={event => setFamilyFormData({ ...familyFormData, familytype: 1 })}
                                />
                                <label htmlFor="radionuclear">Nuclear</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  name="familyTyperadio"
                                  id="radiojoint"
                                  type="radio"
                                  value="2"
                                  checked={familyFormData.familytype == 2}
                                  onChange={event => setFamilyFormData({ ...familyFormData, familytype: 2 })}
                                />
                                <label htmlFor="radiojoint">Joint</label>
                              </div>
                            </div>
                          </section>

                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={GuardianIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Parents/Guardian</span>
                  <Row className="mt-3">
                    <Col md={4} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Father?

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              <div className="radio-item">
                                <input
                                  name="father"
                                  id="yesradiofather"
                                  type="radio"
                                  value="1"
                                  checked={familyFormData.father == 1}
                                  onChange={event => setFamilyFormData({ ...familyFormData, father: 1 })}
                                />
                                <label htmlFor="yesradiofather">Yes</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  name="father"
                                  id="noradiofather"
                                  type="radio"
                                  value="2"
                                  checked={familyFormData.father == 2}
                                  onChange={event => setFamilyFormData({ ...familyFormData, father: 2 })}
                                />
                                <label htmlFor="noradiofather">No</label>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Mother?

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              <div className="radio-item">
                                <input
                                  name="mother"
                                  id="yesradiomother"
                                  type="radio"
                                  value="1"
                                  checked={familyFormData.mother == 1}
                                  onChange={event => setFamilyFormData({ ...familyFormData, mother: 1 })}
                                />
                                <label htmlFor="yesradiomother">Yes</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  name="mother"
                                  id="noradiomother"
                                  type="radio"
                                  value="2"
                                  checked={familyFormData.mother == 2}
                                  onChange={event => setFamilyFormData({ ...familyFormData, mother: 2 })}
                                />
                                <label htmlFor="noradiomother">No</label>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={noofsiblingIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >No. Of Sibling</span>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Number of Siblings

                          </Label>
                          <div className="form-icon position-relative">
                            <ul className="ks-cboxtags p-0">
                              <section className="radio-section">
                                <div className="radio-list">
                                  {(noOfSiblingList || []).map((response, index) => (

                                    <div className="form-check form-check-inline" key={index}>
                                      <div className="radio-item ">
                                        <Input name={"sibling"} id={"sibling" + index} type="radio"
                                          checked={response == familyFormData.noofsibling}
                                          onChange={event => setFamilyFormData({ ...familyFormData, noofsibling: response })}
                                        />
                                        <label htmlFor={"sibling" + index}>{response}</label>
                                      </div>
                                    </div>
                                  ))}

                                </div>
                              </section>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={positionofsiblingIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Your Position</span>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Your Position in Siblings

                          </Label>
                          <div className="form-icon position-relative">
                            <ul className="ks-cboxtags p-0">
                              <section className="radio-section">
                                <div className="radio-list">
                                  {(noOfSiblingList || []).map((response, index) => (

                                    <div className="form-check form-check-inline" key={index}>
                                      <div className="radio-item ">
                                        <Input name={"positionsibling"} id={"positionsibling" + index} type="radio"
                                          checked={response == familyFormData.yourposition}
                                          onChange={event => setFamilyFormData({ ...familyFormData, yourposition: response })}
                                        />
                                        <label htmlFor={"positionsibling" + index}>{response}</label>
                                      </div>
                                    </div>
                                  ))}

                                </div>
                              </section>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Col>

                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={geneticIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName">Genes</span>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Genetic Diseases

                          </Label>

                          <section className="radio-section">
                            <div className="radio-list">
                              <div className="radio-item">
                                <input
                                  name="genetic"
                                  id="yesradiogenetic"
                                  type="radio"
                                  value="1"
                                  checked={familyFormData.geneticdiseases == 1}
                                  onChange={event => setFamilyFormData({ ...familyFormData, geneticdiseases: 1 })}
                                />
                                <label htmlFor="yesradiogenetic">Yes</label>
                              </div>
                              <div className="radio-item">
                                <input
                                  name="genetic"
                                  id="noradiogenetic"
                                  type="radio"
                                  value="2"
                                  checked={familyFormData.geneticdiseases == 2}
                                  onChange={event => setFamilyFormData({ ...familyFormData, geneticdiseases: 2 })}
                                />
                                <label htmlFor="noradiogenetic">No</label>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>


                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "

                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary  mt-2 ms-2"

                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment>

  );
};
export default FamilyBackgroundFunction;
