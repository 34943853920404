import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal.js';
import FeatherIcon from 'feather-icons-react';
import '../../Home/css/userHomePage.css';
import apiUrl from '../../server';

const CareerFunction = () => {

  const [startdateValue, setstartDateValue] = useState(new Date());
  const [enddateValue, setendDateValue] = useState(new Date());
  const [address, setAddress] = useState('');

  const [careerFormData, setCareerFormData] = useState({
    occupationalid : '',
    memberuuid: '',
    jobtitles: [],
    jobprofessions: [],
    startsalary: null,
    endsalary: null,
    salarycurrency: '',
    companycategory: [],
    companylocationid: []

  });


  const [currencyList, setCurrencyList] = useState([])
  const [companyTypeList, setCompanyTypeList] = useState([])
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

     const fetchData = async () => {
      setCareerFormData({ ...careerFormData, memberuuid: localStorage.getItem("memberID") });
      try {
        const [currecncyRes, companyTypeRes] = await Promise.all([
          axios.get(`${apiUrl}codes/salarycurrency`),
          axios.get(`${apiUrl}codes/companytype`),
          // axios.get(`${apiUrl}codes/complexion`),
          // axios.get(`${apiUrl}codes/disability`),
        ]);
        setCurrencyList(currecncyRes.data.list);
        setCompanyTypeList(companyTypeRes.data.list);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    fetchData();
    fetchcareerDetails();
  }, []);



  const fetchcareerDetails = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/occupational/' + localStorage.getItem('memberID'),
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );


      if (response.data.length > 0) {

        let careerData = response.data[0];
        console.log()
        setCareerFormData({
          "occupationalid" : careerData.occupationalid ? careerData.occupationalid : "",
          "memberuuid": careerData.memberuuid ? careerData.memberuuid : '',
          "jobtitles": careerData.jobtitles ? careerData.jobtitles : [],
          "jobprofessions": careerData.jobprofessions ? careerData.jobprofessions : [],
          "startsalary": careerData.startsalary ? careerData.startsalary : null,
          "endsalary": careerData.endsalary ? careerData.endsalary : null,
          "salarycurrency": careerData.salarycurrency ? careerData.salarycurrency : 0,
          "companycategory": careerData.companycategory ? careerData.companycategory : 0,
          "companylocationid": careerData.companylocationid ? careerData.companylocationid : 0,
        });
      } else {
        setCareerFormData({
          "occupationalid" : '',
          "memberuuid":localStorage.getItem("memberID"),
          "jobtitles": [],
          "jobprofessions": [],
          "startsalary": null,
          "endsalary": null,
          "salarycurrency": 0,
          "companycategory": [],
          "companylocationid": []
        });

      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // setError({
      //   title: 'An error occurred!',
      //   message: response.data.message,
      // });
    }
  };


  const handleCompanyType = (checkedFlag, companyTypeObject) => {
    let selectedCompanyType = careerFormData.companycategory;
    if (checkedFlag) {
      if (!selectedCompanyType.includes(companyTypeObject.value)) {
        selectedCompanyType.push(companyTypeObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedCompanyType.includes(companyTypeObject.value)) {
        selectedCompanyType.splice(selectedCompanyType.findIndex(x => companyTypeObject.value === x), 1);
      }
    }
    setCareerFormData({ ...careerFormData, companycategory: selectedCompanyType });
  };






  const handleSubmit = async () => {
    setIsLoading(true);
    let memberuuid = localStorage.getItem("memberID");

    await setCareerFormData({ ...careerFormData, memberuuid: memberuuid });
    console.log(careerFormData)
    let result = await axios.post(`${apiUrl}preference/occupational`, careerFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res) => {
      fetchcareerDetails();
      setIsLoading(false);
      setSuccess({
        title: 'SUCCESS!',
        message: res.data.message,
      });
    }).catch((err) => {
      setIsLoading(false);
      setError({
        title: 'An Error Occured!',
        message: err.response.data.message,
      });
    });
  }

  const LoaderComponent = () => (
    <div id="preloader">
        <div id="status">
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>
    </div>
);


  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {

  };
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      {
        isLoading ? <LoaderComponent/> : (
          <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Row className="inner_row mt-1">
            <h6 className="title  title_bar">
              Career preference{' '}
            </h6>
            <Col md={12} xs={12}>
              <div className="features feature-primary p-1">
                <div className="mb-3">
                  <Label className="form-label label_text">Company Type</Label>
                  <div className="form-icon position-relative">
                    <ul className="ks-cboxtags p-0">
                      {companyTypeList.map((response, index) => (
                        <div className="form-check form-check-inline" key={index}>
                          <li>
                            <div className="mb-0">
                              <div className="form-check p-0">
                                <Input type="checkbox"
                                  id={response.label + "companytype"}
                                  checked={careerFormData.companycategory.includes(response.value)}
                                  onChange={event => handleCompanyType(event.target.checked, response)}
                                />
                                <Label className="form-check-label" htmlFor={response.label + "companytype"}>{response.label}  </Label>
                              </div>
                            </div>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
  
  
            <Col md={12} sm={12}>
  
              <div className=" features feature-primary p-1">
                <div className="mb-3">
                  <Label className="form-label label_text">Salary Currency</Label>
                  <section className="radio-section">
                    <div className="radio-list">
                      {currencyList.map((response, index) => (
                        <div className="form-check form-check-inline" key={index}>
                          <div className="radio-item ">
                            <Input name="currency" id={"currency" + index} type="radio"
                              checked={response.value == careerFormData.salarycurrency}
                              onChange={event => setCareerFormData({ ...careerFormData, salarycurrency: response.value })} />
                            <label htmlFor={"currency" + index}>{response.label}</label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
              </div>
            </Col>
  
  
  
            <Col md={3} sm={6} xs={6}>
              <div className="mb-3">
                <Label className="form-label label_text">Min Salary</Label>
                <div className="form-icon position-relative">
                  <FeatherIcon icon="book" className="fea icon-sm icons"></FeatherIcon>
                  <Input name="minsalary" type='number'
                    id="minsalary" value={careerFormData.startsalary}
                    className="form-control ps-5" placeholder="Enter Minimum Salary"
                    onChange={event => setCareerFormData({ ...careerFormData, startsalary: event.target.value })}
  
                  />
                </div>
              </div>
            </Col>
  
            <Col md={3} sm={6} xs={6}>
              <div className="mb-3">
                <Label className="form-label label_text">Max Salary</Label>
                <div className="form-icon position-relative">
                  <FeatherIcon icon="book" className="fea icon-sm icons"></FeatherIcon>
                  <Input name="maxsalary" type='number'
                    id="maxsalry" value={careerFormData.endsalary}
                    className="form-control ps-5" placeholder="Enter Max Salary"
                    onChange={event => setCareerFormData({ ...careerFormData, endsalary: event.target.value })}
                  />
                </div>
              </div>
            </Col>
  
  
            <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
              <button
                type="submit"
                className="btn btn-outline-primary mt-2 "
           
              >
                {' '}
                Save Changes{' '}
              </button>{' '}
              <button
                onClick={handleReset}
                className="btn btn-outline-secondary  mt-2 ms-2"
            
              >
                Reset
              </button>
            </div>
          </Row>
        </Form>
        )

      }

    </React.Fragment>
  );
};

export default CareerFunction;
