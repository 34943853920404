import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import CommonModal from '../modal';
import MatchMakerIcon from '../../../assets/images/MettaMateImages/matchmaker.png';
import numberIcon from '../../../assets/images/MettaMateImages/numbers.png';
import servicesIcon from '../../../assets/images/MettaMateImages/services.png';
import apiUrl from '../../server';
import '../css/userHomePage.css';
import {
    Row,
    Col,
    Label,
    Input,
    Form,
    Button,
} from 'reactstrap';

const ExpertiseFunction = () => {
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [experienceData, setExperienceData]= useState('');
    const [expertiseuuidData, setexpertiseuuid] = useState('')
    const [formData, setFormData] = useState({
        expertiseuuid: '',
        memberuuid:  localStorage.getItem('memberID'),
        experience: '', 
        noofmatches: null,
        services: '',
    });
    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];
    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                apiUrl + 'members/expertise/' + localStorage.getItem('memberID'),
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.data.length > 0) {
                let expertiseData = response.data[0];
                console.log(expertiseData);
                setExperienceData(expertiseData.experience);
                setexpertiseuuid(expertiseData.expertiseuuid)
                setFormData({
                    memberuuid: expertiseData.memberuuid || localStorage.getItem('memberID'),
                    noofmatches: expertiseData.noofmatches || null,
                    services: expertiseData.services || '',
                });
            } else {
                setFormData({
                    expertiseuuid: '',
                    memberuuid:  localStorage.getItem('memberID'),
                    experience: '', 
                    noofmatches: null,
                    services: '',
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleExperienceContentChange = (content) => {
        setExperienceData(content);
        
    };

    const handleServicesContentChange = (content) => {
        setFormData({ ...formData, services: content });
    };

    const handleSubmit = async () => {
        formData.expertiseuuid = expertiseuuidData;
        formData.experience = experienceData;

        try {
            const res = await axios.post(`${apiUrl}members/expertise`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            fetchData();
            setSuccess({
                title: 'SUCCESS!',
                message: res.data.message,
            });
        } catch (err) {
            setError({
                title: 'An Error Occurred!',
                message: err.response.data.message,
            });
        }
    };

    const handleReset = () => {
        setFormData({
            ...formData,
            experience: '',
            memberuuid: '',
            expertiseuuid: '',
            noofmatches: null,
            services:''
        });
    };

    const errorHandler = () => {
        setError(null);
    };

    const successHandler = () => {
        setSuccess(null);
    };

    return (
        <>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}

            <Form   onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                <Row className="inner_row">
                    <h5 className="title text-center title_bar">Fill Your Expertise Details</h5>

                    <Col md={12} className="mt-3">
                        <img src={MatchMakerIcon} className="legendIcon" style={{ width: `60px` }} />
                        &nbsp; <span className="m-2 legendName">Match Making Experience</span>

                        <Row className="mt-3">
                            <Col md={12}>
                                <div className="features feature-primary p-1">
                                    <div className="mb-3">
                                        <div className="form-icon position-relative">
                                            <ReactQuill
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                value={experienceData}
                                                placeholder="Match Making Experience ...."
                                                onChange={handleExperienceContentChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ boxShadow: `0px 1px 1px` }} />
                            </Col>

                            <Col md={12}>
                                <img src={numberIcon} className='legendIcon' style={{ width: `60px` }} />
                                &nbsp; <span className="m-2 legendName" >Number Of Matches</span>

                                <div className="features feature-primary p-1">
                                    <div className="mb-3">
                                        <Input style={{ width: `240px` }}
                                            type="number"
                                            className="form-control form_Input ps-5"
                                            placeholder="No. Of Matches"
                                            name="matches"
                                            id="noofmatches"
                                            value={formData.noofmatches}
                                            onChange={(e) => setFormData({ ...formData, noofmatches: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <hr style={{ boxShadow: `0px 1px 1px` }} />
                            </Col>
                            <Col md={12}>
                                <img src={servicesIcon} className='legendIcon' style={{ width: `45px` }} />
                                &nbsp; <span className="m-2 legendName" >Services Offering</span>

                                <div className="features feature-primary p-1">
                                    <div className="mb-3">
                                        <div className="form-icon position-relative">
                                            <ReactQuill
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                value={formData.services}
                                                placeholder="Services Offering ...."
                                                onChange={handleServicesContentChange}
                                            >
                                            </ReactQuill>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ boxShadow: `0px 1px 1px` }} />
                            </Col>
                        </Row>

                        <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                            <Button type="submit" className="btn btn-primary mt-2" style={{ background: `#2f55d4` }}>
                                Save Changes
                            </Button>
                            <Button
                                onClick={handleReset}
                                className="btn btn-outline-primary mt-2 ms-2"
                                style={{ background: `none`, color: `#2f55d4` }}
                            >
                                Reset
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default ExpertiseFunction;
