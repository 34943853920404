import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import {
  Container,
  Row,
  Col,
  Progress,
  Card,
  CardBody,
  Badge,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal';
 import apiUrl from '../../server';
import '../../Home/css/userHomePage.css';
import religionIcon from '../../../assets/images/MettaMateImages/korea.png';
import casteIcon from '../../../assets/images/MettaMateImages/caste.png';
import meditationIcon from '../../../assets/images/MettaMateImages/yoga.png';

const SpiritualFunction = () => {
  let memberID = localStorage.getItem('memberID');
  const [religionList, setReligionList] = useState([]);
  const [casteList, setCasteList] = useState([]);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [religionList, casteList] = await Promise.all([
          axios.get(`${apiUrl}codes/religion`),
          axios.get(`${apiUrl}codes/caste`),
         ]);
        setReligionList(religionList.data.list);
        setCasteList(casteList.data.list);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    fetchSpiritualData();
  }, []);


  const fetchSpiritualData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/spiritual/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {

        let spiritualData = response.data[0]
        setSpiritualPrefenerceFormData({
          memberuuid: localStorage.getItem('memberID'),
          currentreligion: spiritualData.currentreligion ? spiritualData.currentreligion : [],
          previousreligion: spiritualData.previousreligion ? spiritualData.previousreligion : [],
          caste: spiritualData.caste ? spiritualData.caste : [],
          vipassananumbers: spiritualData.vipassananumbers ? spiritualData.vipassananumbers : 0,
          othermeditationstatus: spiritualData.othermeditationstatus ? spiritualData.othermeditationstatus : 0,
        });
        } else {

        setSpiritualPrefenerceFormData({
          memberuuid: localStorage.getItem('memberID'),
          currentreligion: [],
          previousreligion: [],
          caste: [],
          vipassananumbers: 0,
          othermeditationstatus: 0,
        })
        // setCurrentReligion({});
        // setPreviousReligion({});
        // setCaste({});
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const [spiritualPreferenceFormData, setSpiritualPrefenerceFormData] = useState({
    memberuuid: localStorage.getItem('memberID'),
    currentreligion: [],
    previousreligion: [],
    caste: [],
    vipassananumbers: 0,
    othermeditationstatus: 0,
  });



  const handleCurrentReligion = (checkedFlag, currentReligionObject) => {
    let selectedCurrentReligion = spiritualPreferenceFormData.currentreligion;
    if (checkedFlag) {
      if (!selectedCurrentReligion.includes(currentReligionObject.value)) {
        selectedCurrentReligion.push(currentReligionObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedCurrentReligion.includes(currentReligionObject.value)) {
        selectedCurrentReligion.splice(selectedCurrentReligion.findIndex(x => currentReligionObject.value === x), 1);
      }
    }
    setSpiritualPrefenerceFormData({ ...spiritualPreferenceFormData, currentreligion: selectedCurrentReligion });
  };


  const handlePrevReligion = (checkedFlag, prevReligionObject) => {
    let selectedPrevReligion = spiritualPreferenceFormData.previousreligion;
    if (checkedFlag) {
      if (!selectedPrevReligion.includes(prevReligionObject.value)) {
        selectedPrevReligion.push(prevReligionObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedPrevReligion.includes(prevReligionObject.value)) {
        selectedPrevReligion.splice(selectedPrevReligion.findIndex(x => prevReligionObject.value === x), 1);
      }
    }
    setSpiritualPrefenerceFormData({ ...spiritualPreferenceFormData, previousreligion: selectedPrevReligion });
  };


  const handleCaste = (checkedFlag, casteObject) => {
    let selectedCaste = spiritualPreferenceFormData.caste;
    if (checkedFlag) {
      if (!selectedCaste.includes(casteObject.value)) {
        selectedCaste.push(casteObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedCaste.includes(casteObject.value)) {
        selectedCaste.splice(selectedCaste.findIndex(x => casteObject.value === x), 1);
      }
    }
    setSpiritualPrefenerceFormData({ ...spiritualPreferenceFormData, caste: selectedCaste });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let result = await axios.post(`${apiUrl}preference/spiritual`, spiritualPreferenceFormData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json"
      },
    }).then((res) => {
      fetchSpiritualData();
      setIsLoading(false);
      setSuccess({
        title: 'SUCCESS!',
        message: res.data.message,
      });
    }).catch((err) => {
      setIsLoading(false);
      setError({
        title: 'An Error Occured!',
        message: err.response.data.message,
      });
    });

  }


  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setSpritualData([]);
    // setCurrentReligion([]);
    // setCaste({});
  };

  const LoaderComponent = () => (
    <div id="preloader">
        <div id="status">
            <div className="spinner">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        </div>
    </div>
);

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {
        isLoading ? <LoaderComponent/> :
        (
 <Form
        onSubmit={(e) => {
          e.preventDefault();
          // validation.handleSubmit();
          handleSubmit()
        }}
      >
        <Row className="inner_row mt-1">
          <h6 className="title  title_bar">
            Spiritual Preference
          </h6>

          <Col md={12} xs={12}>
          <img src={religionIcon} className='legendIcon' style={{ width: `40px` }} />
            &nbsp; <span className="m-2 legendName" >Religion</span>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Current Religion</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    {religionList.map((response, index) => (
                      <div className="form-check form-check-inline" key={index}>
                        <li>
                          <div className="mb-0">
                            <div className="form-check p-0">
                              <Input type="checkbox"
                                id={response.label + "currentreligion"}
                                checked={spiritualPreferenceFormData.currentreligion.includes(response.value)}
                                onChange={event => handleCurrentReligion(event.target.checked, response)}
                              />
                              <Label className="form-check-label" htmlFor={response.label + "currentreligion"}>{response.label}  </Label>
                            </div>
                          </div>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* <hr style={{ boxShadow: `0px 1px 1px` }} /> */}
          </Col>



          <Col md={12} xs={12}>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Previous Religion</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    {religionList.map((response, index) => (
                      <div className="form-check form-check-inline" key={index}>
                        <li>
                          <div className="mb-0">
                            <div className="form-check p-0">
                              <Input type="checkbox"
                                id={response.label + "prevreligion"}
                                checked={spiritualPreferenceFormData.previousreligion.includes(response.value)}
                                onChange={event => handlePrevReligion(event.target.checked, response)}
                              />
                              <Label className="form-check-label" htmlFor={response.label + "prevreligion"}>{response.label}  </Label>
                            </div>
                          </div>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ boxShadow: `0px 1px 1px` }} />
          </Col>



          <Col md={12} sm={12}>

          <img src={casteIcon} className='legendIcon' style={{ width: `40px` }} />
            &nbsp; <span className="m-2 legendName" >Caste Category</span>


            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Caste</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    {casteList.map((response, index) => (
                      <div className="form-check form-check-inline" key={index}>
                        <li>
                          <div className="mb-0">
                            <div className="form-check p-0">
                              <Input type="checkbox"
                                id={response.label + "caste"}
                                checked={spiritualPreferenceFormData.caste.includes(response.value)}
                                onChange={event => handleCaste(event.target.checked, response)}
                              />
                              <Label className="form-check-label" htmlFor={response.label + "caste"}>{response.label}  </Label>
                            </div>
                          </div>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ boxShadow: `0px 1px 1px` }} />
          </Col>


          <Col md="12">
          <img src={meditationIcon} className='legendIcon' style={{ width: `40px` }} />
            &nbsp; <span className="m-2 legendName">Meditation</span>
            
            <Row>

         
              <Col md={6} sm={12}>
                <div className="features feature-primary p-1">
                  <div className="mb-3">
                    <Label className="form-label label_text">
                      Number Of Vipassana Courses Completed !
                    </Label>
                    <Input
                      type="number"
                      className="form-control form_Input ps-5"
                      placeholder="No. Of Vipassana"
                      name="vipassana"
                      id="noofvipassana"
                      value={spiritualPreferenceFormData.vipassananumbers}
                      onChange={(event) => setSpiritualPrefenerceFormData({ ...spiritualPreferenceFormData, vipassananumbers: event.target.value })}
                    />
                  </div>
                </div>
              </Col>


              <Col md={6} sm={12}>
                <div className=" features feature-primary p-1">
                  <div className="mb-3">
                    <Label className="form-label label_text">Other Meditation Done !</Label>
                  

                    <section className="radio-section">
                      <div className="radio-list">
                        <div className="radio-item ">
                          <Input name="radio" id="meditation1" type="radio"
                            checked={spiritualPreferenceFormData.othermeditationstatus == 1}
                            onClick={event => setSpiritualPrefenerceFormData({ ...spiritualPreferenceFormData, othermeditationstatus: 1 })}
                          />
                          <label htmlFor="meditation1">Yes</label>
                        </div>

                        <div className="radio-item ">
                          <Input name="radio" id="meditation2" type="radio"
                            checked={spiritualPreferenceFormData.othermeditationstatus == 2}
                            onClick={event => setSpiritualPrefenerceFormData({ ...spiritualPreferenceFormData, othermeditationstatus: 2 })}
                          />
                          <label htmlFor="meditation2">No</label>
                        </div>


                      </div>
                    </section>
                  </div>
                </div>
              </Col>

              <hr style={{ boxShadow: `0px 1px 1px` }} />

            </Row>

          </Col>

          <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
            <button
              type="submit"
              className="btn btn-outline-primary mt-2 "
            >
              {' '}
              Save Changes{' '}
            </button>{' '}
            <button
              onClick={handleReset}
              className="btn btn-outline-secondary mt-2 ms-2"
            >
              Reset
            </button>
          </div>
        </Row>
      </Form>
        )
      }
     
    </React.Fragment>
  );
};
export default SpiritualFunction;
