
import React, { useEffect, useState } from 'react';
import apiUrl from '../server';
import axios from 'axios';

export async function logoutFunction() {
  try {
    let result = await axios.post(`${apiUrl}auth/logout`, { memberuuid: localStorage.getItem('memberID') }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json"
      }
    });
    localStorage.clear();
  } catch (error) {
    console.error('Error logging out:', error);
  }

}

