// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Button,
  Input,
  Card,
  CardBody,
  CardImg,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

import blog01 from 'assets/images/blog/BabasahebRamu.jpeg';

import author from 'assets/images/testimonies/pallaviGK2.png';

class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMsg: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ successMsg: true });
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }
  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
        document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
        document
          .querySelector('.settingbtn')
          ?.classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="form-icon position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col lg="8" md="6">
                <Card className="blog blog-detail border-0 shadow rounded">
                  <img src={blog01} className="img-fluid rounded-top" alt="" />
                  <CardBody className="content">
                    <h6>
                      {/* <i className="mdi mdi-tag text-primary me-1"></i> */}
                      <Link to="#" className="text-primary h5">
                        Perks of choosing Ambedkarite as a life partner
                      </Link>
                    </h6>
                    <p className="mt-3 fontSize16">
                      1. SUPPORTIVE ENVIRONMENT: Ambedkarites value empathy,
                      compassion, and understanding. Choosing an Ambedkarite
                      partner means being in a supportive and nurturing
                      environment where your partner values your growth and
                      well-being.
                    </p>
                    <p className=" mt-3 fontSize16">
                      2. SHARED VALUES: When you choose an Ambedkarite as a life
                      partner, you are likely to share core values such as
                      equality, respect, and dignity for all individuals. This
                      shared value system forms a strong foundation for a
                      harmonious and fulfilling relationship.
                    </p>
                    <p className=" mt-3 fontSize16">
                      3. LEARNING AND GROWTH: Being with an Ambedkarite partner
                      can offer opportunities for personal growth and learning.
                      You may gain insights into different perspectives,
                      histories, and cultures that can broaden your horizons and
                      deepen your understanding of social issues.
                    </p>
                    <p className=" mt-3 fontSize16">
                      4. ACTIVISM AND ADVOCACY: Ambedkarites are often engaged
                      in activism and advocacy for social change. Choosing an
                      Ambedkarite partner means having a supportive ally in your
                      efforts to create a more just and equitable world.
                    </p>
                    <p className=" mt-3 fontSize16">
                      5. CULTURAL ENRICHMENT: Ambedkarites come from a rich
                      cultural heritage that celebrates diversity and
                      inclusivity. Being with an Ambedkarite partner can expose
                      you to new traditions, beliefs, and practices, enriching
                      your own cultural experiences.
                    </p>
                    <p className=" mt-3 fontSize16">
                      6. SUPPORT FOR PERSONAL GOALS: An Ambedkarite partner is
                      likely to support your personal goals and aspirations,
                      encouraging you to pursue your dreams with confidence and
                      determination.
                    </p>
                    <p className=" mt-3 fontSize16">
                      7. BUILDING A BETTER FUTURE TOGETHER: By choosing an
                      Ambedkarite as a life partner, you are not only investing
                      in a fulfilling relationship but also working towards a
                      shared vision of a more equitable and compassionate future
                      for yourselves and others.
                    </p>
                    <p className=" mt-3 fontSize16">
                      8. EMOTIONAL CONNECTION: Ambedkarites often prioritize
                      emotional connection, empathy, and understanding in their
                      relationships. Choosing an Ambedkarite partner can lead to
                      a deep and meaningful emotional bond based on mutual
                      respect and support.
                    </p>
                    <p className=" mt-3 fontSize16">
                      In conclusion, choosing an Ambedkarite as a life partner
                      can bring numerous benefits, including shared values,
                      intellectual stimulation, emotional connection, and a
                      shared commitment to social justice and equality.
                    </p>

                    <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        " Together, you can create a relationship that is not
                        only fulfilling and supportive but also contributes
                        positively to the larger goal of creating a more just
                        and inclusive society. "
                      </p>
                      <br />
                      <p className="text-muted mb-0 fst-italic">
                        - MettaMate Team
                      </p>
                    </blockquote>
                    {/* <div className="post-meta mt-3">
                        <ul className="list-unstyled mb-0">
                          <li className="list-inline-item me-2">
                            <Link to="#" className="text-muted like">
                              <i className="uil uil-heart me-1"></i>33
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <Link to="#" className="text-muted comments">
                              <i className="uil uil-comment me-1"></i>08
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                  </CardBody>
                </Card>
              </Col>

              {/* sidebar */}
              <Col lg={4} md={5} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 sidebar  ms-lg-4">
                  <CardBody className="p-0">
                    <div className="text-center">
                      <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Author
                      </span>

                      <div className="mt-4">
                        <img
                          src={author}
                          className="img-fluid avatar avatar-medium rounded-pill shadow-md d-block mx-auto"
                          alt=""
                        />

                        <Link
                          to="/blog-about"
                          className="text-primary h5 mt-4 mb-0 d-block"
                        >
                          Dr. Pallavi G. K.
                        </Link>
                        <small className="text-muted d-block">
                          Doctor
                        </small>
                      </div>
                    </div>

                    <div className="widget mt-4">
                      <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Social Media
                      </span>

                      <ul className="list-unstyled social-icon social text-center mb-0 mt-4">
                        <li className="list-inline-item">
                          <Link to="https://www.facebook.com/pallavi.gk.7?mibextid=ZbWKwL" className="rounded">
                            <FeatherIcon
                              icon="facebook"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link to="https://www.instagram.com/drpallavigk?igsh=OGQ5ZDc2ODk2ZA==" className="rounded">
                            <FeatherIcon
                              icon="instagram"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link to="https://x.com/DrPallavigk4?t=8EYFUEds7-jheWokkdQZ5A&s=09" className="rounded">
                            <FeatherIcon
                              icon="twitter"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="linkedin"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        {/* <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="github"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '} */}
                        <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="youtube"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        {/* <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="gitlab"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '} */}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default BlogDetails;
