import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Label, Input, Form } from 'reactstrap';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { Link, } from 'react-router-dom';
import Sliderr, { Range } from 'rc-slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImgCrop from 'antd-img-crop';
import { Button, Upload, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import apiUrl from '../../server';
import adsImg from '../../../assets/images/MettaMateImages/adsimg.jpg';
import '../css/userHomePage.css';
import CommonModal from '../modal';
const UploadAdsFunction = () => {
    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);
    const [file3, setFile3] = useState([]);
    const [filename1, setFileName1] = useState({});
    const [filename2, setFileName2] = useState({})
    const [filename3, setFileName3] = useState({})
    const [rangeValues, setRangeValues] = useState([0, 100]);
    const [imageIndex, setImageIndex] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [previewmodalVisible, previewsetModalVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileToRemove, setFileToRemove] = useState(null);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [address, setAddress] = useState('');
    const [selectedplaceId, setSelectedplaceId] = useState([]);
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const imageSizeList = [{ value: 1, label: '1X1' }, { value: 2, label: '2x1' }, { value: 3, label: '3x1' }]
    const [adsformData, setadsFormData] = useState({
        advertisementuuid: '',
        memberuuid: '',
        images: [],
        title: '',
        description: '',
        buttontext: '',
        url: '',
        imagesize: '',
        btncolor: '',
        startdate: '',
        minage: '',
        maxage: '',
        locationidlist: [],
        validity: '2024-04-10'
    })
    const handleChange = (fileListSetter, imageIndex) => ({ fileList: newFileList }) => {
        fileListSetter(newFileList);
        setImageIndex(imageIndex);
    };

    const openSelectionform1 = () => {
        const fileInput = document.getElementById('profileimage');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const openSelectionform2 = () => {
        const fileInput = document.getElementById('image1');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const openSelectionform3 = () => {
        const fileInput = document.getElementById('image2');
        if (fileInput) {
            fileInput.click();
        } else {
            console.error("File input element with id 'profileimage' not found");
        }
    }
    const onPreview1 = async (file) => {
        let originalFileUrl = await getPhotoUrlDetails(filename1.replace("_thumb", ""))
        setPreviewImage(originalFileUrl);
        previewsetModalVisible(true);
    };
    const onPreview2 = async (file) => {
        let originalFileUrl = await getPhotoUrlDetails(filename2.replace("_thumb", ""))
        setPreviewImage(originalFileUrl);
        previewsetModalVisible(true);
    };
    const onPreview3 = async (file) => {
        let originalFileUrl = await getPhotoUrlDetails(filename3.replace("_thumb", ""))
        setPreviewImage(originalFileUrl);
        previewsetModalVisible(true);
    };
    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };
    const handleUploadImage = async ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('memberUUID', localStorage.getItem('memberID'));
        formData.append('imageindex', imageIndex);

        let response = await axios
            .post(apiUrl + 'members/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                },
            })
            .then((res) => {
                const filename = res.data.filename;
                setadsFormData(prevFormData => {
                    if (!prevFormData.images.includes(filename)) {
                        return { ...prevFormData, images: [...prevFormData.images, filename] };
                    }
                    return prevFormData;
                });

                if (imageIndex == 6) {
                    setFileName1(filename)
                }
                else if (imageIndex == 7) {
                    setFileName2(filename)
                }
                else {
                    setFileName3(filename)
                }
                onSuccess();
                setSuccess({
                    title: 'Upload Complete!',
                    message: 'Your Image has been uploaded Successfully!',
                });
            })
            .catch((err) => {

                onError();
                // setError({
                //     title: 'Upload Failed!',
                //     message: err.data.message,
                // });
            });
    };
    const showDeleteConfirm = async (file, index) => {
        setModalVisible(true);
        setFileToRemove(file);
        setImageIndex(index);
    };
    const handleOk = async () => {
        setModalVisible(false);
        let requestbody = {
            memberUUID: localStorage.getItem('memberID'),
            fileName: fileToRemove.name,
            index: imageIndex,
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(requestbody),
        };
        fetch(apiUrl + 'members/deleteImage', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.message == 'OK') {
                    setSuccess({
                        title: 'Image Deleted!',
                        message: 'Your Image has been Deleted Successfully!',
                    });

                } else {
                    setError({
                        title: 'An Error Occured!',
                        message: 'Your Image is not Deleted. Please Try Again',
                    });
                }
            });
        setFileToRemove(null);
        if (imageIndex == 6) { setFile1([]) }
        else if (imageIndex == 7) { setFile2([]) }
        else { setFile3([]) }
    };

    const handleCancel = () => {
        setModalVisible(false);
        setFileToRemove(null);
    };

    const handlepreviewOk = () => {
        setPreviewImage(''); // Clear the preview image when modal is closed
        setTimeout(() => { previewsetModalVisible(false); }, 50);
    };

    const getPhotoUrlDetails = async (fileName) => {
        try {
            const response = await axios.get(
                apiUrl + 'members/filepath/' + fileName,
                {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );
            return response.data.filepath;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const errorHandler = () => {
        setError(null);
    };
    const successHandler = () => {
        setSuccess(null);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const selectedPlacesIdOnly = selectedPlaces.map(place => place.placeid);;
        const updatedRequestBody = {
            ...adsformData,
            locationidlist: selectedPlacesIdOnly,
            memberuuid: localStorage.getItem('memberID')
        };
        let result = await axios.post(`${apiUrl}advertisement/advertisement`, updatedRequestBody, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setIsLoading(false);

            setSuccess({
                title: 'SUCCESS!',
                message: res.data.message,
            });
        }).catch((err) => {
            setIsLoading(false);

            setError({
                title: 'An Error Occured!',
                message: err.response.data.message,
            });
        });

    }
    const dateOnChange = (e) => {
        setadsFormData({ ...adsformData, startdate: e.target.value });
    };
    const handleCurrentAddressChange = (address) => {
        setAddress(address);
    };
    const handleCurrentAddressSelect = (address, placeid) => {
        setSelectedPlaces(prevState => [...prevState, { address: address, placeid: placeid }]);
        setAddress('');

    };
    const handleRemovePlace = (index) => {
        setSelectedPlaces(prevState => prevState.filter((place, i) => i !== index));
    }
    const handleRangeChange = (values) => {
        setRangeValues(values);
        setadsFormData(prevState => ({
            ...prevState,
            minage: values[0],
            maxage: values[1]
        }));
    };
    const tipFormatter = (value) => {
        return `${value}`;
    }
    const LoaderComponent = () => (
        <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        </div>
    );
    return (
        <>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            {
                isLoading ? <LoaderComponent /> :
                    (

                        <Col md={12} className='inner_row p-0  mt-1' xs={12}>
                            <Row className=" m-0">
                                <h5 className="title title_bar">
                                    Upload Your Ads
                                </h5>

                                <Col md={12} className=" text-center">
                                    <h4 className="heading mb-3">
                                        Welcome to Ad Centre
                                    </h4>
                                </Col>


                                <Col md={12} className=''>

                                    {/* <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        > */}
                                    <Row className='px-5'>
                                        <Col md={6} >
                                            <Label className="form-label label_text">
                                                Headline
                                            </Label>
                                            <div className="form-icon position-relative">
                                                <i>
                                                    <FeatherIcon
                                                        icon="bold"
                                                        className="fea icon-sm icons"
                                                    />
                                                </i>
                                            </div>
                                            <Input
                                                type="text"
                                                className="form-control ps-5 form_Input"
                                                placeholder="Enter Headline"
                                                name="headline"
                                                id="headLine" onChange={event => setadsFormData({ ...adsformData, title: event.target.value })}
                                            />
                                        </Col>
                                        <Col md={6} className=''>
                                            <Label className="form-label label_text text-left">
                                                Description
                                            </Label>
                                            <div className="form-icon position-relative">
                                                <i>
                                                    <FeatherIcon
                                                        icon="menu"
                                                        className="fea icon-sm icons"
                                                    />
                                                </i>
                                            </div>
                                            <textarea style={{ resize: `none` }}
                                                type="text"
                                                className="form-control ps-5 form_Input"
                                                placeholder="Enter Description"
                                                name="description"
                                                id="description" onChange={event => setadsFormData({ ...adsformData, description: event.target.value })}
                                            ></textarea>
                                        </Col>
                                        <Col md={6} className=' py-2'>
                                            <Label className="form-label label_text">
                                                Ads Button Text
                                            </Label>
                                            <div className="form-icon position-relative">
                                                <i>
                                                    <FeatherIcon
                                                        icon="mouse-pointer"
                                                        className="fea icon-sm icons"
                                                    />
                                                </i>
                                            </div>
                                            <Input
                                                type="text" required
                                                className="form-control ps-5 form_Input"
                                                placeholder="Enter Ad Button"
                                                name="adsbutton" onChange={event => setadsFormData({ ...adsformData, buttontext: event.target.value })}
                                                id="adsButton"
                                            />
                                        </Col>
                                        <Col md={6} className=' py-2'>
                                            <Label className="form-label label_text">
                                                Url
                                            </Label>
                                            <div className="form-icon position-relative">
                                                <i>
                                                    <FeatherIcon
                                                        icon="link"
                                                        className="fea icon-sm icons"
                                                    />
                                                </i>
                                            </div>
                                            <Input
                                                type="text" required
                                                className="form-control ps-5 form_Input"
                                                placeholder="Enter Url"
                                                name="url" onChange={event => setadsFormData({ ...adsformData, url: event.target.value })}
                                                id="url"
                                            />
                                        </Col>

                                        <Col md={6} sm={12} xs={12} className=' py-2'>
                                            <Label className="form-label label_text">
                                                Button Color
                                            </Label>

                                            <Input
                                                type="color" required
                                                className="form-control ps-5 form_Input"
                                                placeholder="Enter Url"
                                                name="url" onChange={event => setadsFormData({ ...adsformData, btncolor: event.target.value })}
                                                id="url"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <Label className="form-label label_text">
                                                        Start Date

                                                    </Label>

                                                    <input type="date" className='form-control'
                                                        onChange={dateOnChange} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <Label className="form-label label_text">Location</Label>
                                            <div className="features feature-primary p-1 filter">
                                                <div className="mb-3">

                                                    <PlacesAutocomplete
                                                        value={address}
                                                        onChange={handleCurrentAddressChange}
                                                        onSelect={handleCurrentAddressSelect}
                                                        className="form-control form_input"
                                                    >
                                                        {({
                                                            getInputProps,
                                                            suggestions,
                                                            getSuggestionItemProps,
                                                            loading,
                                                        }) => {
                                                            const uniqueSuggestions = new Set();

                                                            return (
                                                                <div>
                                                                    <input
                                                                        {...getInputProps({
                                                                            placeholder: 'Search Places ...',
                                                                            className: 'location-search-input',
                                                                        })}
                                                                    />
                                                                    <div className="autocomplete-dropdown-container">
                                                                        {loading && <div>Loading...</div>}
                                                                        {suggestions.map((suggestion, index) => {
                                                                            const className = suggestion.active
                                                                                ? 'suggestion-item--active'
                                                                                : 'suggestion-item';

                                                                            const words = suggestion.description.split(',');
                                                                            const trimmedArray = words.map((str) => str.trim());
                                                                            const lastThreeWords = trimmedArray
                                                                                .slice(-3)
                                                                                .join(',');
                                                                            // Check if the suggestion is unique based on place_id
                                                                            if (!uniqueSuggestions.has(lastThreeWords)) {
                                                                                uniqueSuggestions.add(lastThreeWords);

                                                                                // inline style for demonstration purpose
                                                                                const style = suggestion.active
                                                                                    ? {
                                                                                        backgroundColor: '#fafafa',
                                                                                        cursor: 'pointer',
                                                                                    }
                                                                                    : {
                                                                                        backgroundColor: '#ffffff',
                                                                                        cursor: 'pointer',
                                                                                    };

                                                                                return (
                                                                                    <div
                                                                                        key={index}
                                                                                        {...getSuggestionItemProps(suggestion, {
                                                                                            className,
                                                                                            style,
                                                                                        })}
                                                                                    >
                                                                                        <span>{lastThreeWords}</span>
                                                                                    </div>
                                                                                );
                                                                            } else {
                                                                                return null; // Skip rendering duplicate suggestions
                                                                            }
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    </PlacesAutocomplete>
                                                </div>
                                            </div>
                                            {selectedPlaces.map((place, index) => (
                                                <div key={index} className="selected-place">
                                                    <span>{place.address}</span> {/* Render address property */}
                                                    {/* Optionally, you can render placeid as well */}

                                                    <Link onClick={() => handleRemovePlace(index)} className="rounded p-2">
                                                        <FeatherIcon
                                                            icon="delete"
                                                            className="fea icon-sm fea-social text-danger"
                                                        />
                                                    </Link>
                                                </div>
                                            ))}

                                        </Col>
                                        <Col md={6}>
                                            <div className="features feature-primary p-1">
                                                <div className="mb-3">
                                                    <Label className="form-label label_text">Age Range</Label>
                                                    <Sliderr range
                                                        defaultValue={[0, 100]}
                                                        onChange={handleRangeChange}
                                                        tipFormatter={tipFormatter} />
                                                    <Row >
                                                        <Col md={2} className='d-flex mt-2'>

                                                            <p className='form-control'> {rangeValues[0]} </p>
                                                            <span className="mx-4 mt-2"> - </span>
                                                            <p className='form-control'> {rangeValues[1]} </p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>



                                        <Col md={12} sm={12} xs={12} className='py-2'>
                                            <div className="features feature-primary py-0 ">
                                                <div className="mb-3">
                                                    <Label className="form-label label_text">
                                                        Image Size
                                                    </Label>
                                                    <section className="radio-section">
                                                        <div className="radio-list">
                                                            {(imageSizeList || []).map((type, index) => (
                                                                <div className="radio-item" key={index} >
                                                                    <Input name="imagesizeradio" type="radio" value={type.value}
                                                                        checked={adsformData.imagesize === type.value} id={`radioimagesize${index}`}
                                                                        onChange={event => setadsFormData({ ...adsformData, imagesize: type.value })} />
                                                                    <label className={`radioimagesize${index}`} htmlFor={`radioimagesize${index}`}
                                                                        style={{ border: `dotted` }}>  {type.label}</label>

                                                                </div>
                                                            ))}
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </Col>




                                        <div className="fieldset shadow mt-4" style={{ background: `white` }}>
                                            <div className="legend text-left text-primary" style={{ top: `-14px` }}>Select Media</div>
                                            <Row>
                                                <Col md={4} className='text-center'>

                                                    <div className='uploadimg_div mx-auto mt-2 ' >
                                                        <ImgCrop
                                                            showGrid
                                                            rotationSlider
                                                            showReset remove={false}
                                                            aspect={3 / 4}
                                                        >
                                                            <Upload id="profileimage"
                                                                listType="picture-card"
                                                                fileList={file1}
                                                                onChange={handleChange(setFile1, 6)}
                                                                onPreview={onPreview1}
                                                                customRequest={(file) =>
                                                                    handleUploadImage(file, 6)
                                                                }
                                                                onRemove={() => { showDeleteConfirm(file1[0], 6); }}

                                                            >
                                                                {file1.length < 1 && (
                                                                    <div>
                                                                        <img src={adsImg} style={{ width: '100%', height: '258px', opacity: `0.5` }} />
                                                                    </div>
                                                                )}
                                                            </Upload>
                                                        </ImgCrop>

                                                    </div>
                                                    <div className='text-center'>
                                                        {file1.length < 1 && (
                                                            <Button onClick={openSelectionform1} icon={<UploadOutlined />}>Upload</Button>
                                                        )}
                                                        {file1.length > 0 && (
                                                            <Button onClick={() => { showDeleteConfirm(file1[0], 6) }} style={{ marginLeft: '5px' }}>Delete</Button>
                                                        )}
                                                    </div>

                                                </Col>
                                                <Col md={4} className='text-center'>

                                                    <div className='uploadimg_div mx-auto mt-2'>
                                                        <ImgCrop
                                                            showGrid
                                                            rotationSlider
                                                            aspect={3 / 4}
                                                            showReset remove={false}
                                                        >
                                                            <Upload id="image1"
                                                                listType="picture-card"
                                                                fileList={file2}
                                                                onChange={handleChange(setFile2, 7)}
                                                                onPreview={onPreview2}
                                                                customRequest={handleUploadImage}
                                                                onRemove={(file) =>
                                                                    showDeleteConfirm(file, 7)
                                                                }
                                                            >
                                                                {file2.length < 1 && (
                                                                    <div>
                                                                        <div>
                                                                            <img src={adsImg} style={{ width: '100%', height: '258px', opacity: `0.5` }} />
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </Upload>
                                                        </ImgCrop>
                                                    </div>
                                                    <div className='text-center'>
                                                        {file2.length < 1 && (
                                                            <Button onClick={openSelectionform2} icon={<UploadOutlined />}>Upload</Button>
                                                        )}
                                                        {file2.length > 0 && (
                                                            <Button onClick={() => { showDeleteConfirm(file2[0], 7) }} style={{ marginLeft: '5px' }}>Delete</Button>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col md={4} className='sqs-block-html text-center'>
                                                    <div className='uploadimg_div mx-auto mt-2'>
                                                        <ImgCrop
                                                            showGrid
                                                            rotationSlider
                                                            aspect={3 / 4}
                                                            showReset remove={false}
                                                        >
                                                            <Upload id="image2"
                                                                listType="picture-card"
                                                                fileList={file3}
                                                                onChange={handleChange(setFile3, 8)}
                                                                onPreview={onPreview3}
                                                                customRequest={handleUploadImage}
                                                                onRemove={(file) =>
                                                                    showDeleteConfirm(file, 8)
                                                                }
                                                            >
                                                                {file3.length < 1 && (
                                                                    <div>
                                                                        <img src={adsImg} style={{ width: '100%', height: '258px', opacity: `0.5` }} />
                                                                    </div>
                                                                )}

                                                            </Upload>
                                                        </ImgCrop>
                                                    </div>
                                                    <div className='text-center'>
                                                        {file3.length < 1 && (
                                                            <Button onClick={openSelectionform3} icon={<UploadOutlined />}>Upload</Button>
                                                        )}
                                                        {file3.length > 0 && (
                                                            <Button onClick={() => { showDeleteConfirm(file3[0], 8); }} style={{ marginLeft: '5px' }}>Delete</Button>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="mt-md-4 mt-3 mb-2 px-2 mt-sm-0 text-md-end">
                                            <button
                                                type="submit" onClick={() => handleSubmit()}
                                                className="btn btn-primary mt-2 "
                                                style={{ background: `#2f55d4` }}
                                            >
                                                {' '}
                                                Upload Ad{' '}
                                            </button>{' '}
                                            <button type="reset"
                                                // onClick={handleReset} 
                                                className="btn btn-outline-secondary  mt-2 ms-2"
                                            >
                                                Reset
                                            </button>
                                        </div>

                                    </Row>
                                    {/* </Form> */}

                                </Col>

                            </Row >
                        </Col >
                    )
            }
            <Modal
                title="Confirm Deletion"
                open={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
                onClose={handleOk}
            >
                Are you sure you want to delete this image?
            </Modal>

            <Modal
                title="Avatar Preview"
                open={previewmodalVisible}
                onOk={handlepreviewOk}
                okText="Close"
                onClose={handlepreviewOk}
            >
                <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    )
}

export default UploadAdsFunction;