const appUrl = 'https://mettamate.ai/';

//const appUrl = 'http://f9m4wt-3000.csb.app';

// TEST credentials 
// const razorKeyID = 'rzp_test_uDQdRNfrycdt6i';
// const razorKeySecret = '7MNpevhPY22dYuQgwQvflYUD';

// Live Credentials 
const razorKeySecret = 'WDhHSpozCNBjeA7H0NRRONNA';
const razorKeyID = 'rzp_live_582ZmIPuMhKJsM';
export default { appUrl, razorKeyID, razorKeySecret };
