import React from 'react';
import ContentLoader, {
    Code,
    List,
    BulletList, 
} from 'react-content-loader';
import { Row, Col, } from 'reactstrap'
const ModalBodySkeleton = () => (
    <>
        <Row>
            <Col md={5}>
                <ContentLoader
                    speed={2}
                    width={1000}
                    height={300}
                    viewBox="0 0 1000 300"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="5" ry="5" width="400" height="300" />
                    <rect x="0" y="200" rx="5" ry="5" width="280" height="20" />
                    <rect x="0" y="250" rx="5" ry="5" width="280" height="20" />
                    <rect x="0" y="280" rx="5" ry="5" width="140" height="20" />
                    <rect x="150" y="280" rx="5" ry="5" width="130" height="20" />
                </ContentLoader>
                <br /><br />
                <List />
                <br />
                <hr />
                <List />
                <br />
                
            </Col>
            <Col md={6}>
                <List />
                <hr />
                <List />
                <hr />
                <List />
                <hr />
                <List />

            </Col>
        </Row>
    </>


);

export default ModalBodySkeleton;
