import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {
    Container,
    Row,
    Col,
    Label,
    Input,
    FormFeedback,
    Button,
    Form,
    Table,
    PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import ContentLoader from 'react-content-loader';
import CommonModal from './modal.js';
import '../Home/css/userHomePage.css';
import apiUrl from '../server.js';
import usermanagement from '../../assets/images/MettaMateImages/usermanagement.png';
import eventsmanagement from '../../assets/images/MettaMateImages/eventsmanagement.png';

const SubscriptionManagement = () => {
    let memberID = localStorage.getItem('memberID');

    const [eventData, setEventData] = useState(
        {
            "eventid": "",
            "eventname": "",
            "discount": '',
            "status": 1,
            "startdate": "",
            "enddate": "",
            "tokenname": ""
        }
    );

    const [status, setStatus] = useState([{ value: 1, label: "Active" }, { value: 2, label: "Inactive" }]);


    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [reqIsLoading, setReqIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(1000);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
        const itemsPerPage=5;
    
        const [subscriptinRequestbody, setsubscriptinRequestbody] = useState({
        pageNo: currentPage,
        pageSize: pageSize,
        memberuuid: localStorage.getItem('memberID'),
        search: ""
    })
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [newSubsriptionVisible, setNewSubscriptionVisible] = useState(false);

    const [StatusConfimModel, setStatusConfimModel] = useState(false);
    const StatusModalToggle = () => setStatusConfimModel(!StatusConfimModel);
    const [changeStatus, setChangeStatus] = useState({});

    useEffect(() => {
        getAllEventDetails(currentPage, pageSize, '');
    }, [])

    const getAllEventDetails = async (currentPage, pageSize, search) => {
        setIsLoading(true);
        subscriptinRequestbody.currentPage = currentPage;
        // subscriptinRequestbody.pageSize = pageSize;
        // subscriptinRequestbody.membertype = membertype;
        subscriptinRequestbody.search = search;

        try {
            const response = await axios.post(`${apiUrl}events/getallevents`, subscriptinRequestbody, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setTotalPages(Math.ceil(parseInt(response.data.totalCount) / itemsPerPage));
            setTotalCount(response.data.totalCount);
            setSubscriptionData(response.data.details);
            console.log(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        let result = await axios.post(`${apiUrl}events/event/`, eventData, {
            headers: {
                Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                "Content-Type": "application/json"
            },
        }).then((res) => {
            setIsLoading(false);
            setNewSubscriptionVisible(false);
            setSuccess({
                title: 'SUCCESS!',
                message: res.data.message,
            });
            getAllEventDetails(currentPage, pageSize, '');
            handleReset();
        }).catch((err) => {
            setIsLoading(false);
            setNewSubscriptionVisible(false);
            setError({
                title: 'An Error Occured!',
                message: err.response.data.message,
            });
        });

    }
    const errorHandler = () => {
        setError(null);
    };
    const successHandler = () => {
        setSuccess(null);
    };
    const handleReset = () => {
        setEventData({
            "eventid": "",
            "eventname": "",
            "discount": '',
            "status": '',
            "startdate": "",
            "enddate": "",
            "tokenname": ""
        });
    };

    // const handleRefresh = () => {
    //     setCurrentPage(1);
    //     getAllEventDetails();
    //     // setSearchItem('');
    //     // if (activeLink == 1) {
    //     //      getAllMemberDetails(1, pageSize);
    //     // } else if (activeLink == 4) {
    //     //    getAllMemberDetails(1, pageSize, activeLink, '');
    //     // }
    //     // getAllMemberDetails(1, pageSize, memberType);
    // }
    // const handleKeyPress = (e) => {
    //     if (e.key === 'Enter') {
    //         setsubscriptinRequestbody({ ...subscriptinRequestbody, search: e.target.value })
    //         getAllEventDetails(currentPage, pageSize, e.target.value);
    //         // getAllMemberDetails(1, pageSize, activeLink, e.target.value)
    //         // setSearchItem(e.target.value);
    //         // setCurrentPage(1);
    //     }
    // }
    //serverside pagination
    // const renderPaginationItems = () => {
    //     const items = [];

    //     // Always include the first page
    //     items.push(
    //         <PaginationItem key={1} active={currentPage === 1}>
    //             <PaginationLink onClick={() => handlePageChange(1)}>
    //                 1
    //             </PaginationLink>
    //         </PaginationItem>
    //     );

    //     // Conditionally add ellipses
    //     if (currentPage > 3) {
    //         items.push(
    //             <PaginationItem key="ellipsis1" disabled>
    //                 <PaginationLink>...</PaginationLink>
    //             </PaginationItem>
    //         );
    //     }

    //     // Determine the range of pages to display
    //     let start = Math.max(2, currentPage - 1);
    //     let end = Math.min(currentPage + 1, totalPages - 1);

    //     for (let i = start; i <= end; i++) {
    //         items.push(
    //             <PaginationItem key={i} active={currentPage === i}>
    //                 <PaginationLink onClick={() => handlePageChange(i)}>
    //                     {i}
    //                 </PaginationLink>
    //             </PaginationItem>
    //         );
    //     }

    //     // Optionally add ellipses before the last page
    //     if (currentPage < totalPages - 2) {
    //         items.push(
    //             <PaginationItem key="ellipsis2" disabled>
    //                 <PaginationLink>...</PaginationLink>
    //             </PaginationItem>
    //         );
    //     }

    //     // Always include the last page if it's not the first page
    //     if (totalPages > 1) {
    //         items.push(
    //             <PaginationItem key={totalPages} active={currentPage === totalPages}>
    //                 <PaginationLink onClick={() => handlePageChange(totalPages)}>
    //                     {totalPages}
    //                 </PaginationLink>
    //             </PaginationItem>
    //         );
    //     }
    //     return items;
    // };
    // const handlePageChange = async (pageNumber) => {
    //     setCurrentPage(pageNumber); // Increment currentPage using functional update
    //     // if (activeLink == 1) {
    //     //     if (searchItem.length > 0) {
    //     //         await getAllMemberDetails(pageNumber, pageSize, activeLink, searchItem)
    //     //     } else {
    //     //         await getAllMemberDetails(pageNumber, pageSize);
    //     //     }
    //     // } else if (activeLink == 4) {
    //     //     if (searchItem.length > 0) {
    //     //         await getAllMemberDetails(pageNumber, pageSize, activeLink, searchItem);
    //     //     } else {
    //     //         await getAllMemberDetails(pageNumber, pageSize, activeLink, '');
    //     //     }
    //     // }
    //     await getAllEventDetails(pageNumber, pageSize, '');
    // }
    const handleSubscriptionForm = () => {
        setNewSubscriptionVisible(true);
    }
    const convertToDate = (date) => {
        return format(parseISO(date), 'yyyy-MM-dd')
    }
    const handleStatusChange = (e, item) => {
        StatusModalToggle();
        const status = Number(e.target.value);
        setChangeStatus({
            eventid: item.eventid,
            status: status
        })
    }

    const changeEventStatus = async () => {
        StatusModalToggle();
        setReqIsLoading(true);
        try {
            const response = await axios.post(`${apiUrl}events/changeeventstatus`, changeStatus, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            setReqIsLoading(false);
            setSuccess({
                title: 'SUCCESS!',
                message: response.data.message,
            });
            getAllEventDetails(currentPage, pageSize, '');

        } catch (error) {
            setReqIsLoading(false);
            setError({
                title: 'An Error Occured!',
                message: error.response.data.message,
            });
        }
        setChangeStatus({});
    }
    const LoaderComponent = () => (
        <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        </div>
    );
    
  //client side pagination
  const handlePageClick = newPage => {
    setCurrentPage(newPage);
  };

  let currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return subscriptionData.slice(indexOfFirstItem, indexOfLastItem);
}, [currentPage, itemsPerPage, subscriptionData]);

  let pageNumbers = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
}, [totalPages]);

  pageNumbers = useMemo(() => {
    const pages = [1];  // Start with the first page

    // Calculate dynamic start and end indices
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(currentPage + 1, totalPages - 1);

    // Add ellipsis if there's a gap after the first page
    if (startPage > 2) pages.push('...');

    // Add page numbers in the current range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add ellipsis before the last page if there's a gap
    if (endPage < totalPages - 1) pages.push('...');

    // Include the last page if more than one page exists
    if (totalPages > 1) pages.push(totalPages);
    return pages;
  }, [currentPage, totalPages]); 

  console.log(totalPages);


    return (
        <>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}

            {
                isLoading ?
                    <ContentLoader style={{ width: `100%` }}
                        speed={2}
                        height={200}
                        viewBox="0 0 1000 200"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="0" y="0" rx="3" ry="3" width="1000" height="20" />
                        <rect x="0" y="30" rx="3" ry="3" width="1000" height="20" />
                        <rect x="0" y="60" rx="3" ry="3" width="1000" height="20" />
                        <rect x="0" y="90" rx="3" ry="3" width="1000" height="20" />
                        <rect x="0" y="120" rx="3" ry="3" width="1000" height="20" />
                        {/* Add more rows as needed */}
                    </ContentLoader> :
                    (
                        <>
                            {/* <Col className='mt-3' md={12}>
                                <img src={JobIcon} className='legendIcon' />
                                &nbsp; <span className="m-2 legendName" >Subscriptions</span>
                            </Col> */}
                            <Row className="m-0 mt-1">
                                <Col className='mt-2' md={12}>
                                    <Row className='justify-content-end me-1'>
                                        <Col md={6}></Col>
                                        <Col md={6}>
                                            <Row>

                                                <div className="col-md-12 d-flex justify-content-end mb-3">
                                                    <a href="#newform"> <button className="btn btn-primary" onClick={() => handleSubscriptionForm()} >
                                                        + Add New
                                                        <i className="fa fa-plus" aria-hidden="true"></i> </button></a>
                                                </div></Row>
                                        </Col>

                                    </Row>
                                    {/* <div className='col-md-9'></div> */}
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>
                                                    Event Name
                                                </th>
                                                <th>
                                                    Token Name

                                                </th>
                                                <th>
                                                    Discount
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    Start Date
                                                </th>
                                                <th>
                                                    End Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(currentItems || []).map((item, key) => (
                                                <tr key={key}>
                                                    <th scope="row" className='text-center'>
                                                        {key + 1}
                                                    </th>
                                                    <td >
                                                        {item.eventname}
                                                    </td>
                                                    <td>
                                                        {item.tokenname}
                                                    </td>
                                                    <td>
                                                        {item.discount}
                                                    </td>
                                                    <td>
                                                        
                                                        <select
                                                            className="form-select form-control form_Input"

                                                            // value={item.status===1?'Active':'Block'}
                                                            value={item.status}
                                                            onChange={(e) => handleStatusChange(e, item)}
                                                        >
                                                            <option selected={item.status == 1} disabled={item.status == 1} value={1} >Active</option>
                                                            <option selected={item.status != 1} disabled={item.status != 1} value={2}>Inactive</option>

                                                        </select>
                                                    </td>
                                                    <td>
                                                        {convertToDate(item.startdate)}
                                                    </td>
                                                    <td>
                                                        {convertToDate(item.enddate)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    

                                    {/* {totalCount > pageSize ? (
                                        <>
                                            {totalCount > 1 && (
                                                <ul className="pagination justify-content-end mb-0">
                                                    <PaginationItem disabled={currentPage === 1}>
                                                        <PaginationLink
                                                            to="#"
                                                            aria-label="Previous"
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                        >
                                                            {'<<'}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    {renderPaginationItems()}
                                                    <PaginationItem disabled={currentPage === totalPages}>
                                                        <PaginationLink
                                                            to="#"
                                                            aria-label="Next"
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                        >
                                                            {'>>'}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </ul>
                                            )}
                                        </>
                                    ) : null} */}


                                </Col>
                                <Col md={6}>
                                        <div className=''>
                                            Total Counts :
                                            <span className='totalCount'>{totalCount}</span>
                                        </div>
                                    </Col>
                                <Col md={6} xs={6} className="" style={{ display: `${totalPages < 2 ? 'none' : 'block'}` }}>
                                <ul className="pagination justify-content-end mb-0" >
                                  <PaginationItem disabled={currentPage === 1}>
                                    <PaginationLink previous onClick={() => handlePageClick(currentPage - 1)}>
                                      {'<<'}
                                    </PaginationLink>
                                  </PaginationItem>
                                  {pageNumbers.map((number) =>
                                  (
                                    <PaginationItem active={number === currentPage} key={number} disabled={number === '...'}>
                                      <PaginationLink onClick={() => handlePageClick(number)}>
                                        {number}
                                      </PaginationLink>
                                    </PaginationItem>
                                  )
                                  )}
                                  <PaginationItem disabled={currentPage === totalPages}>
                                    <PaginationLink next onClick={() => handlePageClick(currentPage + 1)}>
                                      {'>>'}
                                    </PaginationLink>
                                  </PaginationItem>
                                </ul>
                              </Col>
                            </Row>
                            {
                                newSubsriptionVisible &&
                                <Col md={12} className='inner_row mt-5 mx-auto p-4'>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit()
                                    }} id='newform' >
                                        <div className="text-end">
                                            <i className="fa fa-times text-end start-0 z-index-1 dissmissicon position-relative" aria-hidden="true" aria-controls="example-fade-text" onClick={() => setNewSubscriptionVisible(false)}
                                            style={{top: "-10px"}}></i>
                                        </div>
                                        <Row className='my-2'>
                                            <Col md={2}><div>
                                                <Label className="form-label label_text">
                                                    Event Name
                                                </Label>
                                            </div>
                                            </Col>
                                            <Col md={4}>
                                                <div>
                                                    <div className="form-icon position-relative">
                                                        <i className="fa fa-calendar" aria-hidden="true"></i>
                                                    </div>
                                                    <Input
                                                        type="text"
                                                        className="form-control form_Input ps-5"
                                                        placeholder="Event Name"
                                                        id="companyname"
                                                        name="companyName"
                                                        defaultValue={eventData.eventname}
                                                        onChange={(e) => setEventData({ ...eventData, eventname: e.target.value })}
                                                    />
                                                </div>
                                            </Col>




                                            <Col md={2}>
                                                <div>
                                                    <Label className="form-label label_text">
                                                        Token Name

                                                    </Label>

                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div>

                                                    <div className="form-icon position-relative">
                                                        <i className="fa fa-money" aria-hidden="true"></i>
                                                    </div>
                                                    <Input
                                                        type="text"
                                                        className="form-control form_Input ps-5"
                                                        placeholder="Token Name"
                                                        id="companyname"
                                                        name="companyName"
                                                        defaultValue={eventData.tokenname}
                                                        onChange={(e) => setEventData({ ...eventData, tokenname: e.target.value })}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='my-2'>
                                            <Col md={2}>
                                                <div >
                                                    <Label className="form-label label_text">
                                                        Discount

                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div >
                                                    <div className="form-icon position-relative">
                                                        <i className="fa fa-minus-circle" aria-hidden="true"></i>
                                                    </div>
                                                    <Input
                                                        type="number"
                                                        className="form-control form_Input ps-5"
                                                        placeholder="Discount"
                                                        id="companyname"
                                                        name="companyName"
                                                        defaultValue={eventData.discount}
                                                        onChange={(e) => setEventData({ ...eventData, discount: Number(e.target.value) })}
                                                    />
                                                </div>
                                            </Col>


                                            {/* <Col md={2}>
                                                <div>
                                                    <Label className="form-label label_text">
                                                        Status
                                                    </Label></div>
                                            </Col> */}
                                            {/* <Col md={4}>
                                                <div>
                                                    <section className="radio-section">
                                                        <div className="radio-list">
                                                            {(status || []).map((item, key) => (
                                                                <div className="radio-item" key={key} >
                                                                    <Input name="jobradio" id={`radiojob${key}`} type="radio" defaultValue={item.value}
                                                                        checked={eventData.status == item.value}
                                                                        onChange={(e) => setEventData({ ...eventData, status: Number(e.target.value) })} />
                                                                    <label htmlFor={`radiojob${key}`}>{item.label} </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </section></div>
                                            </Col> */}
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <Label className="form-label label_text">
                                                    Start Date

                                                </Label>
                                            </Col>
                                            <Col md={4}>
                                                <div className="mb-3">


                                                    <input type="date" className='form-control' defaultValue={eventData.startdate}
                                                        onChange={(e) => setEventData({ ...eventData, startdate: e.target.value })}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={2}>
                                                <Label className="form-label label_text">
                                                    End Date

                                                </Label>
                                            </Col>
                                            <Col md={4}>
                                                <input type="date" className='form-control' defaultValue={eventData.enddate}
                                                    onChange={(e) => setEventData({ ...eventData, enddate: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="mt-md-2 mt-3 mb-2 mt-sm-0 text-md-end">
                                                <button
                                                    type="submit"
                                                    className="btn btn-outline-primary mt-2 "
                                                >
                                                    {' '}
                                                    Save Changes{' '}
                                                </button>{' '}
                                                <button
                                                    onClick={handleReset} type="reset"
                                                    className="btn btn-outline-secondary  mt-2 ms-2"
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </Row>
                                    </Form>
                                </Col>
                            }
                        </>
                    )
            }
            {
                reqIsLoading && <LoaderComponent />
            }

            <Modal isOpen={StatusConfimModel} toggle={StatusModalToggle} >
                <ModalHeader toggle={StatusModalToggle}>Confirmation</ModalHeader>
                <ModalBody>
                    Are you sure to <b>{changeStatus.status == 1 ? 'Active' : 'Inactive'}</b> ?
                </ModalBody>
                <ModalFooter>
                    <Button color="accent text-white" onClick={changeEventStatus}>
                        Yes
                    </Button>{' '}
                    <Button color="secondary" onClick={StatusModalToggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

export default SubscriptionManagement;