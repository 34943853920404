import React, { useEffect, useState } from 'react';
 
 
const PaymentFail = () => {
  

  

    return (
        <div className='mt-5' style={{ minHeight: "300px", marginTop: '100px' , background: 'lightgreen' }}>
            <p className='text-center'>Payment Failed</p>
        </div>
    );
};

export default PaymentFail;
