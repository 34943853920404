// React Basic and Bootstrap
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import blog01 from 'assets/images/blog/BabasahebRamu.jpeg';
import blog02 from 'assets/images/blog/trueLove.jpeg';
import blog03 from 'assets/images/blog/secreteToImpress.jpeg';
import blog04 from 'assets/images/blog/treatPartner.jpeg';
import blog05 from 'assets/images/mettamate/Blog1mod2.png';
import blog06 from 'assets/images/blog/longDistance1.png';
import blog07 from 'assets/images/blog/igniteHearts.jpeg';
import blog08 from 'assets/images/blog/newlyWed.jpeg';
import blog09 from 'assets/images/blog/embracingIntimacy.jpeg';

// import defaultBlogImage from '../../assets/images/mettamate/defaultBlogImage.jpg';

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [
        {
          id: 1,
          image: blog01,
          title: 'Perks of choosing Ambedkarite as a life partner',
          description:
            'Ambedkarites value empathy, compassion, and understanding. Choosing an Ambedkarite partner means ' +
            'being in a supportive and nurturing environment where your partner values your growth and well-being.',
          url: '/blogdetails1',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },
        {
          id: 2,
          image: blog02,
          title: 'Four Elements of True Love',
          description:
            'Loving-kindness is not only the desire to make someone happy, ' +
            'to bring joy to a beloved person; it is the ability to bring joy and happiness ' +
            'to the person you love, because even if your intention is to love this person, ' +
            'your love might make him or her suffer.',
          url: '/blogdetails2',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },
        {
          id: 3,
          image: blog09,
          title: 'Embracing Intimacy',
          description:
            'Introverts often find solace and strength in solitude, but when it comes to matters of the heart, their approach to love is just as deep and meaningful. In a world that often celebrates extroverted expressions of affection, its important to recognize and appreciate the unique beauty of love for introverts',
          url: '/blogdetails3',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },
        {
          id: 4,
          image: blog04,
          title: 'How to treat your partner after marriage',
          description:
            'Marriage is an amazing journey where two individuals commence  , ' +
            'vowing to love and cherish each other for the rest of their lives. ' +
            'As the years go by, it is important to keep the flame of love alive and ' +
            'constantly nurture the bond with your partner.',
          url: '/blogdetails4',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },
        {
          id: 5,
          image: blog05,
          title: 'Body positivity and sexual confidence',
          description:
            'Body positivity and sexual confidence are crucial aspects of overall well-being and self-acceptance, particularly in the context of intimate relationships and sexuality',
          url: '/blogdetails5',
          like: '33',
          comment: '08',
          autor: 'Dr. Pallavi G. K.',
          date: '19th May, 2023',
        },

        {
          id: 6,
          image: blog03,
          title: 'Secrets to impress a partner',
          description:
            'Remember that relationships thrive on mutual respect, trust, and appreciation. ' +
            'By incorporating these important things into your relationship, you can impress your partner' +
            ' and strengthen your connection with them. ',
          url: '/blogdetails6',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },
        {
          id: 7,
          image: blog07,
          title: 'Navigating the Joys and Challenges of Newlywed Life',
          description:
            'Being a newlywed bride is a beautiful and exciting experience, but it also comes with its own set of challenges. Here are some common challenges that newlywed brides may face',
          url: '/blogdetails7',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },
        {
          id: 8,
          image: blog08,
          title: 'Words that ignite the heart,',
          description:
            'One of the best ways to release negativity and then communicate more lovingly is to use the Love Letter Technique' +
            'Through writing out your feelings in a particular manner, the negative emotions automatically lessen and the positive feelings increase.',
          url: '/blogdetails8',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },

        {
          id: 9,
          image: blog06,
          title: 'Long distance relationship chaos',
          description:
            'Maintaining a long-distance relationship can be challenging, but there are certain pitfalls that can be avoided to ' +
            'help strengthen the bond between partners. Here are ten things to avoid in long-distance relationships',
          url: '/blogdetails9',
          like: '33',
          comment: '08',
          autor: 'FirstName LastName',
          date: '19th May, 2023',
        },
      ],
    };
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
        document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
        document
          .querySelector('.settingbtn')
          ?.classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}

        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              {/* blog box */}
              {this.state.blogs.map((blog, key) => (
                <Col lg="4" md="6" className="mb-4 pb-2" key={key} name="blog">
                  <Card className="shadow rounded border-0 overflow-hidden">
                    <img src={blog.image}></img>
                    <CardBody>
                      <h5 className="card-title">{blog.title}</h5>
                      <p className="text-muted">{blog.description}</p>
                      <Link to={blog.url} className="text-primary">
                        Read More{' '}
                        <i className="uil uil-angle-right-b align-middle"></i>
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              ))}

              {/* <Col xs="12">
                <Pagination listClassName="justify-content-center mb-0">
                  <PaginationItem>
                    <PaginationLink to="#">
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink to="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col> */}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default Blogs;
