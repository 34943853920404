// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Label,
  Button,
  Input,
  Card,
  CardBody,
  CardImg,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import components
import CommentsBox from './CommentBox';

import blogImage from 'assets/images/blog/igniteHearts.jpeg';

import authorImage from 'assets/images/testimonies/pallaviGK2.png';

class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMsg: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ successMsg: true });
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }
  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
        document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
        document
          .querySelector('.settingbtn')
          ?.classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="form-icon position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col lg="8" md="6">
                <Card className="blog blog-detail border-0 shadow rounded">
                  <img
                    src={blogImage}
                    className="img-fluid rounded-top"
                    alt=""
                  />
                  <CardBody className="content">
                    <h6>
                      {/* <i className="mdi mdi-tag text-primary me-1"></i> */}

                      <Link to="#" className="text-primary h5">
                        {' '}
                        Navigating the Joys and Challenges of Newlywed Life
                      </Link>
                    </h6>
                    <p className="mt-3 fontSize16">
                      Being a newlywed bride is a beautiful and exciting
                      experience, but it also comes with its own set of
                      challenges. Here are some common challenges that newlywed
                      brides may face:
                    </p>
                    <p className=" mt-3 fontSize16">
                      1. Adjusting to Married Life: Transitioning from being
                      single to being married can be a significant adjustment.
                      Learning to live with a partner, sharing responsibilities,
                      and making decisions together can be challenging as you
                      both navigate your new roles in the relationship.{' '}
                    </p>
                    <p className=" mt-3 fontSize16">
                      1. Adjusting to Married Life: Transitioning from being
                      single to being married can be a significant adjustment.
                      Learning to live with a partner, sharing responsibilities,
                      and making decisions together can be challenging as you
                      both navigate your new roles in the relationship.{' '}
                    </p>
                    <p className=" mt-3 fontSize16">
                      2. Communication: Effective communication is key to a
                      successful marriage. Learning to communicate openly and
                      honestly with your partner, especially when discussing
                      sensitive topics or resolving conflicts, can be a
                      challenge that requires patience and understanding.{' '}
                    </p>
                    <p className=" mt-3 fontSize16">
                      3. Balancing Personal Space and Togetherness: Finding the
                      right balance between spending quality time together as a
                      couple and maintaining individual interests and personal
                      space can be a challenge. It's important to nurture your
                      relationship while also prioritizing self-care and
                      personal growth.{' '}
                    </p>
                    <p className=" mt-3 fontSize16">
                      4. Family Dynamics: Adjusting to each other's families and
                      managing expectations from in-laws can be challenging.
                      Balancing relationships with both sets of families and
                      establishing boundaries can require open communication and
                      mutual respect.
                    </p>
                    <p className=" mt-3 fontSize16">
                      5. Financial Management: Managing finances as a couple,
                      setting financial goals, and making joint decisions about
                      budgeting and spending can be a challenge. It's important
                      to have honest conversations about money and financial
                      priorities early on in the marriage.{' '}
                    </p>
                    <p className=" mt-3 fontSize16">
                      5. Quality Time: Make an effort to spend quality time
                      together, doing activities you both enjoy. Whether it's
                      going for a walk, watching a movie, or trying a new hobby
                      together, quality time strengthens your bond.
                    </p>
                    <p className=" mt-3 fontSize16">
                      6. Career and Personal Goals: Balancing career aspirations
                      and personal goals with the demands of married life can be
                      a challenge. Supporting each other's ambitions and finding
                      ways to pursue individual goals while maintaining a strong
                      partnership requires understanding and compromise.
                    </p>
                    <p className=" mt-3 fontSize16">
                      7. Intimacy and Emotional Connection: Building and
                      maintaining intimacy and emotional connection in a
                      marriage is essential but can be a challenge, especially
                      as the honeymoon phase fades. Finding ways to keep the
                      spark alive, communicate affection, and prioritize each
                      other's needs can require effort and creativity.{' '}
                    </p>
                    <p className=" mt-3 fontSize16">
                      8. Social Expectations and Pressure: Dealing with societal
                      expectations, cultural norms, and pressure from external
                      sources can be challenging for newlywed brides. It's
                      important to focus on your own values, priorities, and the
                      unique dynamics of your relationship rather than
                      succumbing to outside influences.{' '}
                    </p>

                    <blockquote className="blockquote mt-3 p-3">
                      <p className="text-muted mb-0 fst-italic">
                        "Navigating these challenges as a newlywed bride
                        requires patience, understanding, and open communication
                        with your partner. Remember that every marriage has its
                        ups and downs, and facing these challenges together can
                        strengthen your bond and lead to a fulfilling and
                        lasting partnership."
                      </p>
                      <br />
                      <p className="text-muted mb-0 fst-italic">
                        - Dr.Pallavi.G.K
                      </p>
                    </blockquote>
                    {/* <div className="post-meta mt-3">
                        <ul className="list-unstyled mb-0">
                          <li className="list-inline-item me-2">
                            <Link to="#" className="text-muted like">
                              <i className="uil uil-heart me-1"></i>33
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <Link to="#" className="text-muted comments">
                              <i className="uil uil-comment me-1"></i>08
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                  </CardBody>
                </Card>

                {/* comments */}
                {/* <CommentsBox comments={this.state.comments} />

                <Card className="shadow rounded border-0 mt-4">
                  <CardBody>
                    <h5 className="card-title mb-0">Leave A Comment :</h5>
                    <Alert
                      color="primary"
                      isOpen={this.state.successMsg}
                      toggle={() => {
                        this.setState({ successMsg: !this.state.successMsg });
                      }}
                    >
                      Data sended successfully.
                    </Alert>
                    <Form onSubmit={this.handleSubmit} className="mt-3">
                      <Row>
                        <Col md="12">
                          <div className="mb-3">
                            <Label className="form-label">Your Comment</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="message-circle"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <textarea
                              id="message"
                              placeholder="Your Comment"
                              rows="5"
                              name="message"
                              className="form-control ps-5"
                              required
                            ></textarea>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="mb-3">
                            <Label className="form-label">
                              Name <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="user"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              id="name"
                              name="name"
                              type="text"
                              placeholder="Name"
                              className="form-control ps-5"
                              required
                            />
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              id="email"
                              type="email"
                              placeholder="Email"
                              name="email"
                              className="form-control ps-5"
                              required
                            />
                          </div>
                        </Col>

                        <Col md="12">
                          <div className="send">
                            <Button
                              type="submit"
                              className="btn w-100"
                              color="primary"
                            >
                              Send Message
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                <Card className="shadow rounded border-0 mt-4">
                  <CardBody>
                    <h5 className="card-title mb-0">Related Posts :</h5>

                    <Row>
                      {this.state.blogs.map((blog, key) => (
                        <Col lg="6" className="mt-4 pt-2" key={key} name="blog">
                          <Card className="blog rounded border-0 shadow">
                            <div className="form-icon position-relative">
                              <CardImg
                                top
                                src={blog.image}
                                className="rounded-top"
                                alt=""
                              />
                              <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <CardBody className="content">
                              <h5>
                                <Link
                                  to="#"
                                  className="card-title title text-dark"
                                >
                                  {blog.title}
                                </Link>
                              </h5>
                              <div className="post-meta d-flex justify-content-between mt-3">
                                <ul className="list-unstyled mb-0">
                                  <li className="list-inline-item me-2  mb-0">
                                    <Link to="#" className="text-muted like">
                                      <i className="uil uil-heart me-1"></i>
                                      {blog.like}
                                    </Link>
                                  </li>{' '}
                                  <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="text-muted comments"
                                    >
                                      <i className="uil uil-comment me-1"></i>
                                      {blog.comment}
                                    </Link>
                                  </li>
                                </ul>
                                <Link
                                  to="/page-blog-detail"
                                  className="text-muted readmore"
                                >
                                  Read More{' '}
                                  <i className="uil uil-angle-right-b align-middle"></i>
                                </Link>
                              </div>
                            </CardBody>
                            <div className="author">
                              <small className="text-light user d-block">
                                <i className="uil uil-user"></i> {blog.autor}
                              </small>
                              <small className="text-light date">
                                <i className="uil uil-calendar-alt"></i>{' '}
                                {blog.date}
                              </small>
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card> */}
              </Col>

              {/* sidebar */}
              <Col lg={4} md={5} className="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 sidebar  ms-lg-4">
                  <CardBody className="p-0">
                    <div className="text-center">
                      <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Author
                      </span>

                      <div className="mt-4">
                        <img
                          src={authorImage}
                          className="img-fluid avatar avatar-medium rounded-pill shadow-md d-block mx-auto"
                          alt=""
                        />

                        <Link
                          to="/blog-about"
                          className="text-primary h5 mt-4 mb-0 d-block"
                        >
                          Dr. Pallavi G. K.
                        </Link>
                        <small className="text-muted d-block">
                          Doctor 
                        </small>
                      </div>
                    </div>

                    {/* <div className="widget mt-4">
                        <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                          Recent Post
                        </span>
  
                        <div className="mt-4">
                          <div className="d-flex align-items-center">
                            <img src={blog01} className="avatar avatar-small rounded" style={{ width: "auto" }} alt="" />
                            <div className="flex-1 ms-3">
                              <Link to="#" className="d-block title text-dark">Consultant Business</Link>
                              <span className="text-muted">15th April 2021</span>
                            </div>
                          </div>
  
                          <div className="d-flex align-items-center mt-3">
                            <img src={blog02} className="avatar avatar-small rounded" style={{ width: "auto" }} alt="" />
                            <div className="flex-1 ms-3">
                              <Link to="#" className="d-block title text-dark">Grow Your Business</Link>
                              <span className="text-muted">15th April 2021</span>
                            </div>
                          </div>
  
                          <div className="d-flex align-items-center mt-3">
                            <img src={blog03} className="avatar avatar-small rounded" style={{ width: "auto" }} alt="" />
                            <div className="flex-1 ms-3">
                              <Link to="#" className="d-block title text-dark">Look On The Glorious Balance</Link>
                              <span className="text-muted">15th April 2021</span>
                            </div>
                          </div>
                        </div>
                      </div> */}

                    {/* <div className="widget mt-4">
                        <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                          Tagclouds
                        </span>
  
                        <div className="tagcloud text-center mt-4">
                          <Link to="#" className="rounded">Business</Link>{" "}
                          <Link to="#" className="rounded">Finance</Link>{" "}
                          <Link to="#" className="rounded">Marketing</Link>{" "}
                          <Link to="#" className="rounded">Fashion</Link>{" "}
                          <Link to="#" className="rounded">Bride</Link>{" "}
                          <Link to="#" className="rounded">Lifestyle</Link>{" "}
                          <Link to="#" className="rounded">Travel</Link>{" "}
                          <Link to="#" className="rounded">Beauty</Link>{" "}
                          <Link to="#" className="rounded">Video</Link>{" "}
                          <Link to="#" className="rounded">Audio</Link>
                        </div>
                      </div> */}
                    <div className="widget mt-4">
                      <span className="bg-light d-block py-2 rounded shadow text-center h6 mb-0">
                        Social Media
                      </span>

                      <ul className="list-unstyled social-icon social text-center mb-0 mt-4">
                        <li className="list-inline-item">
                          <Link to="https://www.facebook.com/pallavi.gk.7?mibextid=ZbWKwL" className="rounded">
                            <FeatherIcon
                              icon="facebook"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link to="https://www.instagram.com/drpallavigk?igsh=OGQ5ZDc2ODk2ZA==" className="rounded">
                            <FeatherIcon
                              icon="instagram"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link to="https://x.com/DrPallavigk4?t=8EYFUEds7-jheWokkdQZ5A&s=09" className="rounded">
                            <FeatherIcon
                              icon="twitter"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="linkedin"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        {/* <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="github"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '} */}
                        <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="youtube"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '}
                        {/* <li className="list-inline-item">
                          <Link to="#" className="rounded">
                            <FeatherIcon
                              icon="gitlab"
                              className="fea icon-sm fea-social"
                            />
                          </Link>
                        </li>{' '} */}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default BlogDetails;
