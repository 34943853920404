import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Profilecard from '../../Home/Profile_card.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//partner preference
import PartnerPersonalDetails from '../../Home/Preference/Personal.jsx';
import PartnerRegionalDetails from '../../Home/Preference/Regional.jsx';
import PartnerEducationDetails from '../../Home/Preference/Education.jsx';
import PartnerFamilyDetails from '../../Home/Preference/FamilyB.jsx';
import PartnerSpiritualDetails from '../../Home/Preference/Spiritual.jsx';
import PartnerLifestyleDetails from '../../Home/Preference/Lifestyle.jsx';
import PertnerCareerDetails from '../../Home/Preference/Career.jsx';
import SettingsDetails from '../../Home/Profile/Settings.js';
import personalInfoIcon from '../../../assets/images/MettaMateImages/personalInfo.jpg';
import careerInfo from '../../../assets/images/MettaMateImages/career.png';
import educationIcon from '../../../assets/images/MettaMateImages/education.png';
import locationIcon from '../../../assets/images/MettaMateImages/location.png';
import lifestyleIcon from '../../../assets/images/MettaMateImages/lifestyle.png';
import spiritualIcon from '../../../assets/images/MettaMateImages/spiritual.png';
import familyIcon from '../../../assets/images/MettaMateImages/familyb.png';

const UserProfileEditFunction = () => {
    const [isPartnerPrefVisible, setPartnerPrefVisibility] = useState('personal');
    const [activeLink, setActiveLink] = useState('personal');
    const menudata = [
        { icon: personalInfoIcon, menuName: 'Personal', value: 'personal' },
        { icon: careerInfo, menuName: 'Career', value: 'career' },
        { icon: educationIcon, menuName: 'Education', value: 'education' },
        { icon: locationIcon, menuName: 'Regional/Location', value: 'regional' },
        { icon: spiritualIcon, menuName: 'Spiritual', value: 'spiritual' },
        { icon: lifestyleIcon, menuName: 'Lifestyle', value: 'lifestyle' },
        { icon: familyIcon, menuName: 'Family Background', value: 'family' },

    ];
    useEffect(() => {

    }, []);


    const handlePartnerLinkClick = (link) => {
        setActiveLink(link);
        setPartnerPrefVisibility(link);
    }

    const scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
            document.querySelector('.settingbtn')?.classList.remove('btn-light');
            document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
            document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
            document.getElementById('topnav')?.classList.add('nav-sticky');
        } else {
            document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
            document
                .querySelector('.settingbtn')
                ?.classList.remove('btn-soft-primary');
            document.querySelector('.shoppingbtn')?.classList.add('btn-light');
            document.querySelector('.settingbtn')?.classList.add('btn-light');
            document.getElementById('topnav')?.classList.remove('nav-sticky');
        }


    };

    const menuslider = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6, // Show 5 items at a time
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (

        <React.Fragment>
            <Profilecard />
            <Container className="mt-5 pt-1">
                <section className="section_user_home_page">
                    <Container>
                        <Row>
                            <Col lg="12" className='Col_12'>

                                <Row>
                                    <Col md={12} xs={12} className="inner_row ">
                                        <Row >
                                            <div className='w-5/6 m-auto'>
                                                <div className='mt-20 pb-3'>
                                                    <Slider {...menuslider} className="overflow-hidden menu_slider ">
                                                        {(menudata || []).map((item, key) => (
                                                            <div className=' list-unstyled profession_ul text-center' key={key}>
                                                                <div
                                                                    style={{ padding: `5px 10px 5px 5px` }}
                                                                    className={`text-center profession_li mt-1 ${activeLink === item.value ? 'active' : ''
                                                                        }`}
                                                                    onClick={() => handlePartnerLinkClick(item.value)}>
                                                                    <img src={item.icon} className='center' style={{ width: `80px`, height: `60px` }} />
                                                                </div>
                                                                <p className="filter_title m-auto" style={{ fontSize: `16px` }}><span className={` ${activeLink === item.value ? 'active_text' : ''}`} >{item.menuName}</span></p>
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </div>
                                            </div>
                                        </Row>

                                    </Col>

                                    {isPartnerPrefVisible == 'personal' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            {' '}
                                            <PartnerPersonalDetails />
                                        </Col>
                                    )}
                                    {isPartnerPrefVisible == 'career' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            <PertnerCareerDetails />
                                        </Col>
                                    )}
                                    {isPartnerPrefVisible == 'education' && (
                                        <Col
                                            md={12}
                                            xs={12}
                                            style={{
                                                transition: 'right 1s ease-in-out',
                                            }}
                                        >
                                            <PartnerEducationDetails />
                                        </Col>
                                    )}
                                    {isPartnerPrefVisible == 'regional' && (
                                        <Col md={12} xs={12}>
                                            <PartnerRegionalDetails />
                                        </Col>
                                    )}
                                    {isPartnerPrefVisible == 'spiritual' && (
                                        <Col md={12} xs={12}>
                                            <PartnerSpiritualDetails />
                                        </Col>
                                    )}
                                    {isPartnerPrefVisible == 'lifestyle' && (
                                        <Col md={12} xs={12}>
                                            <PartnerLifestyleDetails />
                                        </Col>
                                    )}
                                    {isPartnerPrefVisible == 'family' && (
                                        <Col md={12} xs={12}>
                                            <PartnerFamilyDetails />
                                        </Col>
                                    )}
                                    {isPartnerPrefVisible == 'settings' && (
                                        <Col md={12} xs={12} className='p-0'>
                                          
                                            <SettingsDetails />
                                        </Col>
                                    )}
                                </Row>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>

        </React.Fragment>
    );
};

export default UserProfileEditFunction;
