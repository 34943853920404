import React, { useEffect, useMemo, useState } from 'react';
import '../Home/css/userHomePage.css';
import CommonModal from './modal.js';
import apiUrl from '../server.js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Profilecard from '../Home/Profile_card.jsx';
import ContentLoader, { List } from 'react-content-loader';
import SettingsDetails from '../Home/Profile/Settings.js';
import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';
import {
  Card,
  CardBody,
  Input,
  Container,
  Col,
  Button,
  Form,
  Pagination,
  Row,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const FavouriteListFunction = () => {
  const [openModal, setOpenModal] = useState(false);
  const [likedmemberuuid, setlikedmemberuuid] = useState({});
  const [likeStatus, setLikedStatus] = useState('Like');
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [favouriteList, setFavouriteList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showSetting, setShowSettings] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const itemsPerPage = 10;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'members/favourites/' + localStorage.getItem('memberID'),
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.details.length > 0) {
        setFavouriteList(response.data.details);
      }
      setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  // handlePageChange = (pageNumber) => {
  //   this.setState({ pageNo: pageNumber }, () => {
  //     this.fetchData(this.state.pageNo, this.state.itemsPerPage);
  //   });
  // }
  const favoriteMember = async () => {
    try {
      let request = {
        likedmemberuuid: likedmemberuuid,
        memberuuid: localStorage.getItem('memberID'),
      };
      let result = await axios
        .post(`${apiUrl}members/favourites`, request, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then(async (res) => {
          fetchData();
          setOpenModal(false);
        });
    } catch (error) {}
  };

  const openConfirmModal = (memberUUID, likedstatus) => {
    if (likedstatus === 1) {
      setlikedmemberuuid(memberUUID);
      setLikedStatus('Unlike');
      setOpenModal(true);
    } else {
      setlikedmemberuuid(memberUUID);
      setLikedStatus('Like');
      setOpenModal(true);
    }
  };
  const closelikeModal = () => {
    setOpenModal(false);
  };
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now ? age_now : 'XX';
  };
  const displayImage = (imagepath, gender) => {
    if (!imagepath) {
      if (gender.toLowerCase() == 'male') {
        return manavatar;
      } else if (gender.toLowerCase() == 'female') {
        return femaleavatar;
      } else {
        return transgenderavatar;
      }
    } else {
      return imagepath;
    }
  };
  const errorHandler = () => {
    setError(null);
  };

  const successHandler = () => {
    setSuccess(null);
  };
  const handlesettingclick = () => {
    setShowSettings(true);
  };
  const toggleModal = () => setOpenModal(!openModal);

  //client side pagination
  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  let currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return favouriteList.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, itemsPerPage, favouriteList]);

  let pageNumbers = useMemo(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }, [totalPages]);

  pageNumbers = useMemo(() => {
    const pages = [1]; // Start with the first page

    // Calculate dynamic start and end indices
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(currentPage + 1, totalPages - 1);

    // Add ellipsis if there's a gap after the first page
    if (startPage > 2) pages.push('...');

    // Add page numbers in the current range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add ellipsis before the last page if there's a gap
    if (endPage < totalPages - 1) pages.push('...');

    // Include the last page if more than one page exists
    if (totalPages > 1) pages.push(totalPages);
    return pages;
  }, [currentPage, totalPages]);

  return (
    <>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}
      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {/* <Profilecard handleSettingsClick={handlesettingclick} /> */}
      {/* <Container className="">
        <section className="section_user_home_page mt-5">
          <Container>
            <Row> */}
      <Col lg="12" className="Col_12">
        <Row>
          {showSetting == true ? (
            <Col md={12} xs={12} className="p-0">
              <SettingsDetails />
            </Col>
          ) : (
            <Col md={12} xs={12} className="inner_row pb-4">
              {isLoading ? (
                <Row className="mt-3">
                  <Col md={6}>
                    <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">
                      <List />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">
                      <List />
                    </div>
                  </Col>
                </Row>
              ) : (
                <>
                  {currentItems.length == 0 ? (
                    <div className="text-center mt-3 label_text">
                      No Favourites !!! Love your Community Members and Keep
                      track on them !
                    </div>
                  ) : (
                    <Row>
                      {(currentItems || []).map((item, key) => (
                        <Col key={key} lg="6" md="6" className="mt-4 pt-2">
                          <Card className="team team-primary text-left border-0">
                            <CardBody>
                              <div className="position-relative">
                                <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">
                                  <img
                                    src={displayImage(
                                      item.photothumb1,
                                      item.gender,
                                    )}
                                    className="avatar avatar-md-sm favourite_avatar_img"
                                    alt=""
                                  />
                                  <div className="ms-md-4 mt-4 mt-sm-0">
                                    <a className="text-dark h5">
                                      {item.firstname} {item.lastname}{' '}
                                    </a>
                                    <ul className="list-unstyled mb-0 mt-2">
                                      <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                                        <i className="uil uil-map-marker text-primary"></i>{' '}
                                        <Link to="#" className="text-dark">
                                          {item.location}
                                        </Link>
                                      </li>{' '}
                                      <li className="d-lg-inline text-muted h6 mb-0">
                                        <i className="uil uil-bill text-primary"></i>{' '}
                                        {item.gender}
                                      </li>{' '}
                                      <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                                        <i className="uil uil-clock text-primary"></i>{' '}
                                        {calculate_age(item.dob)}
                                      </li>
                                    </ul>

                                    <ul className="list-unstyled mb-0 mt-2">
                                      <li className="d-lg-inline text-muted h6 mb-0 me-lg-2">
                                        <i className="uil uil-bag-alt text-primary"></i>{' '}
                                        <Link to="#" className="text-dark">
                                          {item.jobprofession}
                                        </Link>
                                      </li>{' '}
                                      <li className="d-lg-inline  h6 mb-0 me-lg-2">
                                        <i className="uil uil-graduation-cap text-primary"></i>{' '}
                                        {item.degreename}
                                      </li>{' '}
                                    </ul>
                                  </div>

                                  <div className="position-absolute top-0 end-0 mt-3 me-3">
                                    <Link
                                      title="Unlike"
                                      onClick={() =>
                                        openConfirmModal(
                                          item.memberuuid,
                                          item.likedstatus,
                                        )
                                      }
                                      className="btn btn-icon btn-pills btn-soft-primary"
                                    >
                                      <i
                                        className={`mdi mdi-heart align-middle ${item.likedstatus === 1 ? 'redheart' : ''}`}
                                      ></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                      {/* {currentItems.map((item, key) => (
                                <Col xs={6} className="mt-4 pt-2" key={key}>
                                  <Col xs={6} className="mt-4 pt-2" key={key}>
                                    <div className="job-box bg-white d-md-flex align-items-center border-0 shadow rounded p-4 position-relative ">
                                      <img src={item.photothumb1} className="avatar avatar-md-sm favourite_avatar_img" alt="" />
                                      <div className="ms-md-4 mt-4 mt-sm-0">
                                        <Link to="/job-detail-one" className="text-dark h5">{item.firstname}
                                          {' '} {item.lastname} </Link>
                                        <ul className="list-unstyled mb-0 mt-2">
                                          <li className="d-lg-inline text-muted h6 mb-0 me-lg-2"><i className="uil uil-map-marker text-primary"></i> <Link to="#" className="text-dark">{item.location}</Link></li>{" "}
                                          <li className="d-lg-inline text-muted h6 mb-0"><i className="uil uil-bill text-primary"></i> {item.gender}</li>{" "}
                                          <li className="d-lg-inline text-muted h6 mb-0 me-lg-2"><i className="uil uil-clock text-primary"></i> {calculate_age(item.dob)}</li>
                                        </ul>

                                        <ul className="list-unstyled mb-0 mt-2">
                                          <li className="d-lg-inline text-muted h6 mb-0 me-lg-2"><i className="uil uil-bag-alt text-primary"></i> <Link to="#" className="text-dark">{item.jobprofession}</Link></li>{" "}
                                          <li className="d-lg-inline  h6 mb-0 me-lg-2"><i className="uil uil-graduation-cap text-primary"></i> {item.degreename}</li>{" "}
                                        </ul>
                                      </div>

                                      <div className="position-absolute top-0 end-0 mt-3 me-3">
                                        <Link title="Unlike" onClick={() => openConfirmModal(item.memberuuid, item.likedstatus)} className="btn btn-icon btn-pills btn-soft-primary"><i className="uil uil-heart align-middle "></i></Link>
                                      </div>
                                    </div>
                                  </Col>
                                </Col>
                              ))} */}
                      {/* <Pagination aria-label="Page navigation example">
                                {pageNumbers.map(number => (
                                  <PaginationItem key={number} active={number === currentPage}>
                                    <PaginationLink onClick={() => handlePageClick(number)}>
                                      {number}
                                    </PaginationLink>
                                  </PaginationItem>
                                ))}
                              </Pagination> */}

                      <Col
                        xs={12}
                        className="mt-4 pt-2"
                        style={{
                          display: `${totalPages < 2 ? 'none' : 'block'}`,
                        }}
                      >
                        <ul className="pagination justify-content-center mb-0">
                          <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink
                              previous
                              onClick={() => handlePageClick(currentPage - 1)}
                            >
                              {'<<'}
                            </PaginationLink>
                          </PaginationItem>
                          {pageNumbers.map((number) => (
                            <PaginationItem
                              active={number === currentPage}
                              key={number}
                              disabled={number === '...'}
                            >
                              <PaginationLink
                                onClick={() => handlePageClick(number)}
                              >
                                {number}
                              </PaginationLink>
                            </PaginationItem>
                          ))}
                          <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink
                              next
                              onClick={() => handlePageClick(currentPage + 1)}
                            >
                              {'>>'}
                            </PaginationLink>
                          </PaginationItem>
                        </ul>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Col>
          )}
        </Row>
      </Col>
      {/* </Row>
          </Container>
        </section>
      </Container> */}

      <Modal isOpen={openModal} toggle={closelikeModal}>
        <ModalHeader toggle={closelikeModal}>
          Confirmation
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closelikeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className="mb-0">Are You Sure to Dislike ?</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button color="secondary" onClick={closelikeModal} type="button">
            Close
          </Button>
          <Button color="danger" type="button" onClick={favoriteMember}>
            Dislike
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FavouriteListFunction;
