import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import {
  Container,
  Row,
  Col,
  Progress,
  Card,
  CardBody,
  Badge,
  Label,
  Input,
  FormFeedback,
  Button,
  Form,
} from 'reactstrap';
import CommonModal from '../../Home/modal';
import apiUrl from '../../server';
import '../../Home/css/userHomePage.css';
import DegreeCapIcon from '../../../assets/images/MettaMateImages/education.png'

const EducationFunction = () => {

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [degreeLevelList, setDegreeLevelList] = useState([]);
  const [degreeNameList, setDegreeNameList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [degreeLevelRes, degreeNameRes] = await Promise.all([
          axios.get(`${apiUrl}codes/degreelevel`),
          axios.get(`${apiUrl}codes/degreenames`),
        ]);
        setDegreeLevelList(degreeLevelRes.data.list);
        setDegreeNameList(degreeNameRes.data.list);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
    fetchEducationalPrefData();
  }, []);


  const fetchEducationalPrefData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/educational/' + localStorage.getItem("memberID"),
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {

        let educationalPrefRes = response.data[0]
        setEducationalPrefenerceFormData({
          memberuuid: localStorage.getItem('memberID'),
          degreelevels: educationalPrefRes.degreelevels ? educationalPrefRes.degreelevels : [],
          degreenames: educationalPrefRes.degreenames ? educationalPrefRes.degreenames : [],
          degreeuniversities: educationalPrefRes.degreeuniversities ? educationalPrefRes.degreeuniversities : []
        });
      } else {

        setEducationalPrefenerceFormData({
          memberuuid: localStorage.getItem('memberID'),
          degreelevels: [],
          degreenames: [],
          degreeuniversities: []
        })

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const [educationalPreferenceFormData, setEducationalPrefenerceFormData] = useState({
    memberuuid: localStorage.getItem("memberID"),
    degreelevels: [],
    degreenames: [],
    degreeuniversities: []
  });




  const handleDegreeLevel = (checkedFlag, degreeLevelObject) => {
    let selectedDegreeLevel = educationalPreferenceFormData.degreelevels;
    if (checkedFlag) {
      if (!selectedDegreeLevel.includes(degreeLevelObject.value)) {
        selectedDegreeLevel.push(degreeLevelObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedDegreeLevel.includes(degreeLevelObject.value)) {
        selectedDegreeLevel.splice(selectedDegreeLevel.findIndex(x => degreeLevelObject.value === x), 1);
      }
    }
    setEducationalPrefenerceFormData({ ...educationalPreferenceFormData, degreelevels: selectedDegreeLevel });
  };


  const handleDegreeNames = (checkedFlag, degreeNameObject) => {
    let selectedDegreeName = educationalPreferenceFormData.degreenames;
    if (checkedFlag) {
      if (!selectedDegreeName.includes(degreeNameObject.value)) {
        selectedDegreeName.push(degreeNameObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedDegreeName.includes(degreeNameObject.value)) {
        selectedDegreeName.splice(selectedDegreeName.findIndex(x => degreeNameObject.value === x), 1);
      }
    }
    setEducationalPrefenerceFormData({ ...educationalPreferenceFormData, degreenames: selectedDegreeName });
  };


  const handleSubmit = async () => {
    setIsLoading(true);
    let result = await axios.post(`${apiUrl}preference/educational`, educationalPreferenceFormData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json"
      },
    }).then((res) => {
      fetchEducationalPrefData();
      setIsLoading(false);
      setSuccess({
        title: 'SUCCESS!',
        message: res.data.message,
      });
    }).catch((err) => {
      setIsLoading(false); 
      setError({
        title: 'An Error Occured!',
        message: err.response.data.message,
      });
    });

  }

  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setSpritualData([]);
  };

  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {
        isLoading ?
          <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                // validation.handleSubmit();
                handleSubmit()
              }}
            >
              <Row className="inner_row mt-1">
                <h6 className="title  title_bar">
                  Educational Preferences
                </h6>

                <Col md={12} xs={12}>
                  <img src={DegreeCapIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Degree Description</span>

                  <div className="features feature-primary p-1">
                    <div className="mb-3">
                      <Label className="form-label label_text">Preferred Degree Level</Label>
                      <div className="form-icon position-relative">
                        <ul className="ks-cboxtags p-0">
                          {degreeLevelList.map((response, index) => (
                            <div className="form-check form-check-inline" key={index}>
                              <li>
                                <div className="mb-0">
                                  <div className="form-check p-0">
                                    <Input type="checkbox"
                                      id={response.label + "degreelevel"}
                                      checked={educationalPreferenceFormData.degreelevels.includes(response.value)}
                                      onChange={event => handleDegreeLevel(event.target.checked, response)}
                                    />
                                    <Label className="form-check-label" htmlFor={response.label + "degreelevel"}>{response.label}  </Label>
                                  </div>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <hr style={{ boxShadow: `0px 1px 1px` }} /> */}
                </Col>


                <Col md={12} xs={12}>
                  <div className="features feature-primary p-1">
                    <div className="mb-3">
                      <Label className="form-label label_text">Preferred Degree Names</Label>
                      <div className="form-icon position-relative">
                        <ul className="ks-cboxtags p-0">
                          {degreeNameList.map((response, index) => (
                            <div className="form-check form-check-inline" key={index}>
                              <li>
                                <div className="mb-0">
                                  <div className="form-check p-0">
                                    <Input type="checkbox"
                                      id={response.label + "degreename"}
                                      checked={educationalPreferenceFormData.degreenames.includes(response.value)}
                                      onChange={event => handleDegreeNames(event.target.checked, response)}
                                    />
                                    <Label className="form-check-label" htmlFor={response.label + "degreename"}>{response.label} ({response.short}) </Label>
                                  </div>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>


                {/* <Col md={12} xs={12}>
            <div className="features feature-primary p-1">
              <div className="mb-3">
                <Label className="form-label label_text">Preferred Degree Colleges</Label>
                <div className="form-icon position-relative">
                  <ul className="ks-cboxtags p-0">
                    {degreeNameList.map((response, index) => (
                      <div className="form-check form-check-inline" key={index}>
                        <li>
                          <div className="mb-0">
                            <div className="form-check p-0">
                              <Input type="checkbox"
                                id={response.degreename + "degreecollege"}
                                checked={educationalPreferenceFormData.degreenames.includes(response.degreename)}
                                onChange={event => handleDegreeNames(event.target.checked, response)}
                              />
                              <Label className="form-check-label" htmlFor={response.degreename + "degreecollege"}>{response.degreename}  </Label>
                            </div>
                          </div>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <hr style={{ boxShadow: `0px 1px 1px` }} />
          </Col> */}

                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button type="submit"
                    className="btn btn-outline-primary mt-2 "
                  >
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset}
                    className="btn btn-outline-secondary mt-2 ms-2"
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }

    </React.Fragment>
  );
};
export default EducationFunction;
