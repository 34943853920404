import React, { useEffect } from 'react';
import './Payments.css';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const payment_intent = queryParams.get('payment_intent'); // Retrieve the value of a specific query parameter
    const payment_intent_client_secret = queryParams.get('payment_intent_client_secret'); // Retrieve the value of a specific query parameter
    const redirect_status = queryParams.get('redirect_status'); // Retrieve the value of a specific query parameter
    console.log(payment_intent, payment_intent_client_secret, redirect_status)
  }, []);



  return (
    <>
      <section style={{ marginTop: '100px' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="message-box _success">
                <i
                  className="fa fa-check-circle position-static"
                  aria-hidden="true"
                ></i>
                <h2> Payment Success </h2>
                <p>
                  Your Payment is Successfully done.
                  <br /> Now you are a{' '}
                </p>{' '}
                <h3 className="text-accent">Gold Member</h3>
                <br />
                <div
                  className="btn btn-primary"
                  onClick={() => navigate('/configuregeneralmember')}
                >
                  {/* <i style={{width:'10px'}} className="fa fa-chevron-left position-static me-2" aria-hidden="true"></i>  */}
                  Back to Home
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentSuccess;
