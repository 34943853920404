import React, { useEffect, useState, useMemo } from 'react';
import { Session } from '@talkjs/react';
import Talk from 'talkjs';
import { useParams } from 'react-router-dom';
import apiUrl from '../../server';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import Slider from 'react-slick';
import '../../Home/css/chat.css';

const ChatFunction = () => {
  // const [me, setMe] = useState();
  // const [other, setOther] = useState();
  // const [session, setSession] = useState();
  // const params = useParams();
  // const [advertiseData, setAdvertiseData] = useState([])
  // const [pageNo, setPageNo] = useState({});
  // const [itemPerPage, setItemPerPage] = useState({})
  // const [configurationData,setConfigurationData]=useState(JSON.parse(localStorage.getItem('configurationData')))
  // const getAdvertisementDetails = async (pageNo, itemPerPage) => {
  //     let clearinterval;
  //     try {
  //         const response = await axios.post(`${apiUrl}advertisement/getalladvertisement`, {
  //             pageNo: pageNo, pageSize: itemPerPage, search: ''
  //         }, {
  //             headers: {
  //                 Authorization: `Bearer ${localStorage.getItem("token")}`
  //             }
  //         });
  //         setAdvertiseData(response.data.details);
  //         let totalCount = response.data.details.length;
  //         if (pageNo == (totalCount / itemPerPage)) {
  //             pageNo = 1;
  //             console.log(pageNo)
  //             // getAdvertisementDetails(pageNo, itemPerPage)
  //         }
  //         else {
  //             // setTimeout(() => {
  //             //     pageNo = pageNo + 1;
  //             //     getAdvertisementDetails(pageNo, itemPerPage)
  //             // }, 60000);
  //         }

  //     }
  //     catch (error) { }
  // }

  // // // Fetch owner data
  // useEffect(() => {
  //     const fetchOwnerData = async () => {
  //         let ownerData = JSON.parse(localStorage.getItem("configurationData"));
  //         const me1 = new Talk.User({
  //             id: ownerData.memberuuid,
  //             name: ownerData.firstname,
  //         });
  //         setMe(me1);
  //     };
  //     fetchOwnerData();

  //     if (params.id) {
  //         axios.get(`${apiUrl}members/short/${params.id}`, {
  //             headers: {
  //                 Authorization: 'Bearer ' + localStorage.getItem('token'),
  //                 'Content-Type': 'application/json',
  //             },
  //         })
  //             .then(res => {
  //                 let otherMemberData = res.data;
  //                 const other1 = new Talk.User({
  //                     id: otherMemberData.memberuuid,
  //                     name: otherMemberData.firstname,
  //                 });
  //                 setOther(other1);
  //                 // createConversation(me, other1);
  //             })
  //             .catch(err => {
  //                 console.warn(err);
  //             });
  //     }

  // }, [params.id]);

  useEffect(() => {
    getAdvertisementDetails(1, 1);
  }, []);
  useEffect(() => {
    if (me && other) {
      const session = new Talk.Session({
        appId: 'tyhQslBf',
        me: me,
      });
      setSession(session);
      const conversation = session.getOrCreateConversation(
        Talk.oneOnOneId(me, other),
      );
      conversation.setParticipant(me);
      conversation.setParticipant(other);
    }
  }, []);

  return (
    <>
      <section className="py-3 bg-white">
        <Container className="mt-70 ">
          {/* section title */}
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="">
                <h1 className="title mb-0"> Find your community</h1>
              </div>
            </Col>
          </Row>

          <div className="container mb-1">
            <div
              className={`row ${configurationData.memberstatuscode === 3 || configurationData.memberstatuscode === 0 ? 'pointer_event_none' : ''}`}
            >
              <div className="col-md-8">
                <div
                  id="talkjs-container"
                  style={{ marginTop: '100px', height: '500px' }}
                >
                  <i>Loading chat...</i>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ChatFunction;
