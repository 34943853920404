import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import {
  Container,
  Form,
  Modal,
  ModalBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  CardImg,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';
import apiUrl from '../../apppages/server';
import axios from 'axios';
//Import images
import MMlogo from '../../assets/images/mettamate/logo.svg';
import withRouter from '../../common/data/withRouter';

import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';
import settingIcon from '../../assets/images/settingIcon.svg';
import accountSetting from '../../assets/images/settings (1).png';
import profile from '../../assets/images/user (1).png';
import logout from '../../assets/images/logout.png';
import { logoutFunction } from '../../apppages/Home/logout';
import {
  navLinks,
  sessionnavLinks,
  communityMsessionnavLinks,
  keyConstantMenus,
  keyConstantMenusAfterLogin,
} from './menu';
// import { navLinks } from './menu';
// import { MenuItem } from './NavBarComponents';

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navlink: '',
      isOpen: false,
      dropdownOpenShop: false,
      wishlistModal: false,
      dropdownIsOpen: false,
      open: false,
      position: 'end',
      dropdownOpen: false,
      landing: false,
      components: false,
      demo: false,
      doc: false,
      pages: false,
      company: false,
      account: false,
      email: false,
      blog: false,
      case: false,
      auth: false,
      login: false,
      signup: false,
      reset: false,
      utility: false,
      special: false,
      contact: false,
      multi: false,
      level2: false,
      isOffcanvasOpen: false,
      isLoggedIn: false,
      memberTypeCheck: 0,
      configurationData: {
        memberuuid: '',
        firstname: '',
        lastname: '',
        gendercode: 0,
        memberstatuscode: 0,
        memberstatusname: '',
        gendername: '',
        photopath1: '',
        photopath2: '',
        photopath3: '',
        subscription: '',
        jobprofession: '',
        linkedin: '',
        instagram: '',
        facebook: '',
        twitter: '',
        youtube: '',
        snapchat: '',
        recommended: 0,
        membertypeid: '',
      },
      profilePhoto: localStorage.getItem('profilePicture'),
    };
    this.wrapperRef = createRef();

    this.toggleLine = this.toggleLine.bind(this);
    this.toggleDropdownShop.bind(this);
    this.toggleWishlistModal.bind(this);
    this.toggleDropdownIsOpen.bind(this);
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);
    this.toggleDropdown.bind(this);
    this.togglemodal.bind(this);
    this.removeActivation = this.removeActivation.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state.configurationData = JSON.stringify(
      localStorage.getItem('token'),
    );
    this.state.isLoggedIn = localStorage.getItem('token') ? true : false;
  }

  /**
   * Right sidebar drawer
   **/

  toggleOffcanvas = () => {
    this.setState((prevState) => ({
      isOffcanvasOpen: !prevState.isOffcanvasOpen,
    }));
  };

  closeOffcanvas = () => {
    this.setState({ isOffcanvasOpen: false });
  };

  toggleRightDrawer = () => {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  };
  onDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleWishlistModal = () => {
    this.setState((prevState) => ({
      wishlistModal: !prevState.wishlistModal,
    }));
  };

  toggleDropdownShop = () => {
    this.setState({
      dropdownOpenShop: !this.state.dropdownOpenShop,
    });
  };
  toggleDropdownIsOpen = () => {
    this.setState({
      dropdownIsOpen: !this.state.dropdownIsOpen,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  // Topbar Page Activation

  activateParentDropdown() {
    var menuItems = document.getElementsByClassName('sub-menu-item');

    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].pathname === window.location.pathname) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');

        const immediateParent = matchingMenuItem.closest('li');
        if (immediateParent) {
          immediateParent.classList.add('active');
        }
        var parent = matchingMenuItem.closest('.parent-menu-item');

        if (parent) {
          parent.classList.add('active');

          var parentMenuitem = parent.querySelector('a');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }
          var parentOfParent = parent.closest('.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          parentOfParent = matchingMenuItem.closest('.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
      return false;
    }
    return false;
  }

  UNSAFE_componentWillMount() {
    this.setState({
      profilePhoto: localStorage.getItem('profilePicture'),
    });
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  componentDidUpdate(prevProps, prevState) {
    window.scrollTo(0, 0);
    this.initializeNavigation();
    /*
    const loggedInUserData = localStorage.getItem('configurationData');
    if (!loggedInUserData) {
      localStorage.clear();
      // window.location.href = '/';// Redirect to the main index page
    }
    */

    if (
      prevState.isLoggedIn !== (localStorage.getItem('token') ? true : false)
    ) {

      this.setState({
        isLoggedIn: localStorage.getItem('token') ? true : false,
        configurationData: JSON.parse(
          localStorage.getItem('configurationData'),
        ),
      });
    }

    if (this.state.profilePhoto != localStorage.getItem('profilePicture')) {
      this.setState({
        profilePhoto: localStorage.getItem('profilePicture'),
      });
    }
    //set underline to home page
    if (this.state.isLoggedIn) {
      if (this.state.navlink == '' || prevState.navlink == '') {
        this.updateNavlink(this.props.router.location.pathname);
      }
    }
    // this.authenticateToken()
  }

  initializeNavigation = () => {
    const pathName = window.location.pathname;
    var matchingMenuItem = null;
    var ul = document.getElementById('navigation');
    var items = ul.getElementsByTagName('a');
    this.removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  };

  removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        if (parent.classList.contains('active')) {
          parent.classList.remove('active');
        }
      }
    }
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token && localStorage.getItem('configurationData')) {
      // first check token validity(expired or not)
      this.setState({ isLoggedIn: true });
      const configurationData = JSON.parse(
        localStorage.getItem('configurationData'),
      );
      if (
        configurationData &&
        configurationData.memberuuid !== this.state.configurationData.memberuuid
      ) {
        this.setState({ configurationData: configurationData });
      }
    } else {
      // this.logout()
    }
    document.addEventListener('click', this.handleClickOutside, true);
  }
  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ showProfileDropdown: false });
    }
  };

  logout = async () => {
    /*
    let userMemberUUID = localStorage.getItem('memberID');
    if (!userMemberUUID) {
      localStorage.clear();
      //Navigate('/'); // Redirect to the main index page
    }
    */

    let result = await axios.post(
      `${apiUrl}auth/logout`,
      { memberuuid: localStorage.getItem('memberID') },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
    );
    localStorage.clear();

    this.setState({ isLoggedIn: false, sessionnavLinks: [], navlink: '' }); // Reset sessionnavLinks
    // window.location.href = '/'; // Redirect to the main index page
  };

  authenticateToken = async () => {
    try {
      let result = await axios.get(`${apiUrl}auth/authenticatetoken`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (result.status === 200) {
      } else {
        localStorage.clear();
        this.setState({ isLoggedIn: false, sessionnavLinks: [], navlink: '' });
        logoutFunction();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        this.setState({ isLoggedIn: false, sessionnavLinks: [], navlink: '' });
      } else {
      }
    }
  };

  displayImage = () => {
    if (this.state.profilePhoto != 'undefined') {
      if (this.state.configurationData.gendername == 'Male') {
        return manavatar;
      } else if (this.state.configurationData.gendername == 'Female') {
        return femaleavatar;
      } else if (this.state.configurationData.gendername == 'Other') {
        return transgenderavatar;
      }
    } else {
      return this.state.profilePhoto;
    }
  };

  login = async () => {
    this.setState({ isLoggedIn: true, sessionnavLinks: sessionnavLinks });
  };
  updateNavlink = (data) => {
    this.setState({ navlink: data });
  };

  redirectHome = () => {
    const link =
      this.state.configurationData.membertypeid == 4
        ? '/communitymember-home-page'
        : '/allainceseeker-home-page';
    this.updateNavlink(link);
  };

  getTargetURL = () => {
    if (this.state.isLoggedIn) {
      if (this.state.configurationData.membertypeid == 4) {
        return '/communitymember-home-page';
      }
      return '/allainceseeker-home-page';
    }
    return '/';
  };

  getMenuItems = () => {
    let collectedMenus = navLinks;
    if (this.state.isLoggedIn) {
      if (this.state.configurationData.membertypeid == 4) {
        collectedMenus = communityMsessionnavLinks;
      } else {
        collectedMenus = sessionnavLinks;
      }
    }
    return collectedMenus.map((item, key) => {
      const hasChildren = item.child && item.child.length;
      return (
        <li
          key={key}
          className="list-inline-item me-2 pt-3 mb-2"
          title={item.title}
        >
          <Link
            to={item.link}
            className={item.linkClassName}
            style={{
              minHeight: '18px',
            }}
          >
            <span>{item.title}</span>
          </Link>
        </li>
      );
    });
  };

  reDirectToLogin = () => {
    return (
      <div>
        <Navigate to="/subscriptionPlan" />
      </div>
    );
  };

  getProfileImage = (imageSourcePath) => {
    return (
      <CardImg
        className="rounded-top"
        src={imageSourcePath}
        style={{
          objectFit: 'contain',
          width: '40px',
          height: '40px',
        }}
        onError={(e) => {
          e.target.src = settingIcon; // replace with icon
          e.target.style.maxWidth = '30px';
          e.target.style.maxHeight = '30px';
        }}
        alt="ProfilePic"
      />
    );
  };
  toggleProfileDropdown = () => {
    this.setState((prevState) => ({
      showProfileDropdown: !prevState.showProfileDropdown,
    }));
  };

  getAfterLoginKeyMenus = () => {
    return [
      <li
        className="list-inline-item"
        key="2"
        ref={(node) => {
          this.dropdownMenuRef = node;
        }}
      >
        <div>
          <div
            className="nav-link p-0 d-flex"
            onClick={this.toggleProfileDropdown}
            style={{ cursor: 'pointer' }}
          >
            <div className="profile-pic me-1 profilegroup1">
              {this.state.profilePhoto !== 'undefined' ? (
                <>{this.getProfileImage(this.state.profilePhoto)}</>
              ) : (
                <>
                  {this.state.configurationData.gendername === 'Male' &&
                    this.getProfileImage(manavatar)}
                  {this.state.configurationData.gendername === 'Female' &&
                    this.getProfileImage(femaleavatar)}
                  {this.state.configurationData.gendername === 'Transgender' &&
                    this.getProfileImage(transgenderavatar)}
                </>
              )}
            </div>
            <div>
              <i>
                <FeatherIcon
                  icon="chevron-down"
                  className="fea icon-sm profile_down_arrow"
                />
              </i>
            </div>
          </div>
          {this.state.showProfileDropdown && (
            <ul
              className="dropdown-menu profile_dropdown_menu py-0"
              style={{ display: 'block', position: 'absolute' }}
              ref={this.wrapperRef}
            >
              <li className="profile_dropdown_menu_li">
                <Link
                  to="/my-profile-edit-page"
                  className="dropdown-item"
                  onClick={() => this.setState({ showProfileDropdown: false })}
                >
                  <span>
                    <img src={profile} />
                  </span>
                  &nbsp; View Profile
                </Link>
              </li>
              <li className="profile_dropdown_menu_li">
                <Link
                  to="/settings"
                  className="dropdown-item"
                  onClick={() => this.setState({ showProfileDropdown: false })}
                >
                  <span>
                    <img src={accountSetting} />
                  </span>{' '}
                  &nbsp; Account Settings
                </Link>
              </li>
              <li className="profile_dropdown_menu_li">
                <Link
                  to="/user-login-page"
                  onClick={this.logout}
                  className="dropdown-item"
                >
                  <img src={logout} /> &nbsp; <span> Logout</span>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </li>,
    ];
  };

  getKeyMenus = () => {
    let keyMenus = keyConstantMenus;
    if (this.state.isLoggedIn) {
      return this.getAfterLoginKeyMenus();
    }
    return keyMenus.map((item, key) => {
      const hasChildren = item.child && item.child.length;
      return (
        <li className="list-inline-item me-2" title={item.title} key={key}>
          <Link
            style={item.styles}
            to={item.link}
            className={item.linkClassName}
          >
            <i>
              <FeatherIcon icon={item.icon} className="fea icon-sm " />
            </i>
            <span
              style={{ fontSize: `16px` }}
              className=" d-none d-md-block mx-1"
            >
              {item.title}
            </span>
          </Link>
        </li>
      );
    });
  };

  render() {
    return <ul className="list-inline">{this.getKeyMenus()}</ul>;
  }

  getKeyMenus = () => {
    let keyMenus = keyConstantMenus;
    if (this.state.isLoggedIn) {
      return this.getAfterLoginKeyMenus();
    }
    if (this.props?.router?.location?.pathname == '/user-login-page') {
      keyMenus = keyMenus.filter((item) => item.id !== 1);
    }
    if (this.props?.router?.location?.pathname == '/user-registration-page') {
      keyMenus = keyMenus.filter((item) => item.id !== 2);
    }
    return keyMenus.map((item, key) => {
      const hasChildren = item.child && item.child.length;
      return (
        <li className="list-inline-item me-2" title={item.title} key={key}>
          <Link
            // style={{
            //   width: `100%`,
            //   padding: `0px 10px`,
            //   pointerEvents: 'auto',
            // }}
            style={item.styles}
            to={item.link}
            className={item.linkClassName}
          >
            <i>
              <FeatherIcon icon={item.icon} className="fea icon-sm " />
            </i>
            <span
              style={{ fontSize: `16px` }}
              className=" d-none d-md-block mx-1"
            >
              {item.title}
            </span>
          </Link>
        </li>
      );
    });
  };

  /* Render the Given top-bar now */
  render() {
    const { isOffcanvasOpen } = this.state;
    const { isLoggedIn } = this.state;

    return (
      <React.Fragment>
        {this.props.tagline ? this.props.tagline : null}

        <header
          id="topnav"
          className="defaultscroll sticky"
          style={{ flexShrink: 0 }}
        >
          <Container>
            <div>
              {/* logo */}
              <Link className="logo" to={this.getTargetURL()}>
                <img
                  src={MMlogo}
                  height="42"
                  className="logo-light-mode"
                  alt=""
                />
                <img
                  src={MMlogo}
                  height="42"
                  className="logo-dark-mode"
                  alt=""
                />
              </Link>
            </div>

            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={this.toggleLine}
                  className={
                    this.state.isOpen ? 'navbar-toggle open' : 'navbar-toggle'
                  }
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            {(() => {
              return (
                <ul className="buy-button list-inline mb-0 ">
                  {this.getKeyMenus()}
                </ul>
              );
            })()}

            <div
              id="navigation"
              style={{ display: this.state.isOpen ? 'block' : 'none' }}
            >
              {/* Menu Dropdown */}
              <ul className="navigation-menu nav-dark" id="top-menu">
                {this.getMenuItems()}
              </ul>
            </div>
          </Container>
        </header>
      </React.Fragment>
    );
  }
}

export default withRouter(Topbar);
