import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
//Import Components
import Community from './Community.js';
// import Services from './Services.js';
// import ConnectSafe from './connectSafe';

// Import images
import Banner from '../../assets/images/mainBG-image.jpg';
import HerosImg from '../../assets/images/mettamate/Heros.png';
import OurHeros from '../../assets/images/mettamate/OurHeros.jpeg';

const Section = () => {
  return (
    <React.Fragment>
      {/* Hero Start */}
      <section className="d-table w-100 pt-5 mt-5" id="home">
        <Container className="section-three">
          <Row className="pt-5">
            <Col lg={6} className="mt-5">
              <Card
                className="title-heading studio-home rounded shadow mb-5 "
                style={{
                  background:
                    'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%)',
                  backgroundSize: '100% 200px',
                  backgroundPosition: '0% 100%',
                  transition: 'background-position 0.5s ease-in-out',
                }}
              >
                <h2 className="heading mb-3">
                  Empowering Ambedkarites Through Community Network at{' '}
                  <span className="text-primary">MettaMate</span>
                </h2>
              </Card>
            </Col>
            <Col lg={6} className="border border-0">
              <Card className="mb-5 " style={{ border: '0' }}>
                <CardBody>
                  <img
                    src={OurHeros}
                    className="img-fluid rounded work-image"
                    alt="vision"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Section;
