// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, CardBody, Card } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

class TermsAndConditions extends Component {
  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
        document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
        document
          .querySelector('.settingbtn')
          ?.classList.add('btn-soft-primary');
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <section
          className="section"
          style={{ padding: '50px', paddingBottom: '10px' }}
        >
          {/* <Container> */}
          <Row className="mt-5 justify-content-center">
            {/* <Col lg={12} className="text-center"> */}
            <div className="pages-heading">
              <h4 className="title text-center">Terms and Conditions</h4>
              {/* <ul className="list-unstyled mt-4 mb-0">
                                <li className="list-inline-item h6 date text-muted"> <span className="text-dark">Last Revised :</span> 23th Sep, 2021</li>
                            </ul> */}
            </div>
            {/* </Col>   */}
          </Row>
        </section>

        <section className="section" style={{ padding: '50px' }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="card shadow rounded border-0">
                  <CardBody>
                    <h5 className="card-title ">
                      Terms and Conditions for MettaMate{' '}
                    </h5>
                    <p className="text-muted fontSize16">
                      Welcome to MettaMate, a platform dedicated to fostering
                      connections and relationships within the community. By
                      using the MettaMate Matrimony and Community App (referred
                      to as "the App"), you agree to abide by the following
                      terms and conditions:
                    </p>

                    <h5 className="card-title">1. Eligibility:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        1.1. You must be at least 18 years old to use the App.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        1.2. By using the App, you represent and warrant that
                        you have the right, authority, and capacity to enter
                        into this agreement and abide by all terms and
                        conditions.
                      </li>
                    </ul>
                    <h5 className="card-title">2. Account Registration:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        2.1. You agree to provide accurate, current, and complete information during the registration process.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        2.2. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        2.3. You must notify us immediately of any unauthorized use of your account or any other breach of security.                      </li>
                    </ul>
                    <h5 className="card-title">3. Code of Conduct:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        3.1. You agree to use the App for lawful purposes only.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        3.2. You will not engage in any activities that are harmful, offensive, or disruptive to other users of the App.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        3.3. You will not use the App to promote or engage in any illegal or unethical activities.</li>
                    </ul>
                    <h5 className="card-title">4. User Content:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        4.1. By posting or sharing content on the App, you grant MettaMate a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content.
                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        4.2. You are solely responsible for the content you post on the App, and you agree not to post any content that is defamatory, obscene, or infringes on the rights of others.
                      </li>
                    </ul>
                    <h5 className="card-title">5. Privacy:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        5.1. Your privacy is important to us. Please refer to our Privacy Policy for details on how we collect, use, and protect your personal information.                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        5.2. By using the App, you consent to the collection and use of your information as described in our Privacy Policy.                      </li>
                    </ul>
                    <h5 className="card-title">6. Termination:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        5.1. Your privacy is important to us. Please refer to our Privacy Policy for details on how we collect, use, and protect your personal information.                      </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        5.2. By using the App, you consent to the collection and use of your information as described in our Privacy Policy.                      </li>
                    </ul>
                    <h5 className="card-title">7. Disclaimer of Warranties:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        7.1. The App is provided on an "as is" and "as available" basis. We make no warranties or representations about the accuracy or reliability of the App.                        </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        7.2. We do not guarantee that the App will be error-free, uninterrupted, or free from viruses or other harmful components.
                        </li>
                    </ul>
                    <h5 className="card-title">8. Limitation of Liability:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        8.1. MettaMate shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the App.
                                                   </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        8.2. Our total liability for any claim arising out of or relating to the App shall not exceed the amount you have paid to us in the past 12 months.
                        </li>
                    </ul>
                    <h5 className="card-title">9. Governing Law:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        9.1. These terms and conditions are governed by and construed in accordance with the laws of [Country/State], without regard to its conflict of law principles.
                                                   </li>
                      <li className="fontSize16">
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2 fontSize16"
                          />
                        </i>
                        9.2. Any disputes arising out of or relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts in [Country/State].
                        </li>
                    </ul>
                    {/* <h5 className="card-title">
                      Information Provided Voluntarily :
                    </h5> */}
                    <p className="text-muted fontSize16">
                    By using the MettaMate Matrimony and Community App, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you do not agree with any part of these terms, please do not use the App. Thank you for being a part of the MettaMate community!
                    </p>
                    {/* <Link to="#" className="btn btn-soft-primary d-print-none">
                      Print
                    </Link> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default TermsAndConditions;
