// DataContext.js
import React, { createContext, useState } from 'react';

const DataContext = createContext({
  activeLink: '', // Default value for activeLink
  setActiveLink: () => {} 
});
export const DataProvider = ({ children }) => {
  const [activeLink, setActiveLink] = useState('allainceseeker');

  return (
    <DataContext.Provider value={{ activeLink, setActiveLink }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
