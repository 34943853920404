// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import img1 from '../../assets/images/work/1.jpg';
import img2 from '../../assets/images/work/1.jpg';
import img3 from '../../assets/images/work/1.jpg';
import img4 from '../../assets/images/work/1.jpg';
import img5 from '../../assets/images/work/1.jpg';
import img6 from '../../assets/images/work/1.jpg';

//Import components
import SectionTitle from './SectionTitle';
import ReviewsSlider from './ReviewsSlider';
// import { reviews } from "../../common/data";
     
//Import Images
import work1 from '../../assets/images/work/1.jpg';
import work2 from '../../assets/images/work/2.jpg';
import work3 from '../../assets/images/work/3.jpg';
import work4 from '../../assets/images/work/4.jpg';

const PageServices = () => {
  const features = [
    {
      id: 1,
      icon: 'uil uil-edit-alt h2 text-primary',
      title: 'Matrimonial Services',
      description:
        'Mettamate offers a unique and personalized approach to matchmaking, ensuring that individuals can find compatible partners who share their values and beliefs.',
    },
    {
      id: 2,
      icon: 'uil uil-vector-square h2 text-primary',
      title: 'Community Empowerment Platform',
      description:
        'Beyond matrimony, Mettamate serves as a platform for community empowerment, providing resources, support, and opportunities for growth and development.',
    },
    {
      id: 3,
      icon: 'uil uil-file-search-alt h2 text-primary',
      title: 'Educational Resources & Mentorship',
      description:
        'We offer a range of educational resources, including articles,webinars, and workshops, aimed at promoting awareness, understanding, and empowerment within the Ambedkarite community.',
    },
  ];
  const works = [
    {
      imgUrl: work1,
      title: 'Shifting Perspective',
      subtitle: 'Studio',
      author: 'Calvin Carlo',
      date: '13th August, 2019',
    },
    {
      imgUrl: work2,
      title: 'Colors Magazine',
      subtitle: 'Web Design',
      author: 'Calvin Carlo',
      date: '13th August, 2019',
    },
    {
      imgUrl: work3,
      title: 'Spa Cosmetics',
      subtitle: 'Developing',
      author: 'Calvin Carlo',
      date: '13th August, 2019',
    },
    {
      imgUrl: work4,
      title: 'Riser Coffee',
      subtitle: 'Branding',
      author: 'Calvin Carlo',
      date: '13th August, 2019',
    },
  ];
  useEffect(() => {
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
        document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
        document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
        document
          .querySelector('.settingbtn')
          ?.classList.add('btn-soft-primary');
      }
    }
  };
  const reviews = [
    {
      id: 1,
      img: img1,
      name: 'Thomas Israel',
      post: 'C.E.O',
      desc: 'It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.',
      rating: 5,
    },
    {
      id: 2,
      img: img2,
      name: 'Barbara McIntosh',
      post: 'M.D',
      desc: 'One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.',
      rating: 4.5,
    },
    {
      id: 3,
      img: img3,
      name: 'Carl Oliver',
      post: 'P.A',
      desc: "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
      rating: 5,
    },
    {
      id: 4,
      img: img4,
      name: 'Christa Smith',
      post: 'Manager',
      desc: 'According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.',
      rating: 5,
    },
    {
      id: 5,
      img: img5,
      name: 'Dean Tolle',
      post: 'Developer',
      desc: 'There is now an abundance of readable dummy texts. These are usually used when a text is required.',
      rating: 5,
    },
    {
      id: 6,
      img: img6,
      name: 'Jill Webb',
      post: 'Designer',
      desc: 'Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.',
      rating: 5,
    },
  ];
  return (
    <React.Fragment>
      {/* breadcrumb */}
      <section className="bg-half-170 bg-light d-table w-100">
        <Container>
          <Row className="mt-5 justify-content-center">
            <Col lg={12} className="text-center">
              <div className="pages-heading">
                <h4 className="title mb-0"> Services </h4>
              </div>
            </Col>
          </Row>

          <div className="position-breadcrumb">
            <nav aria-label="breadcrumb" className="d-inline-block">
              <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li className="breadcrumb-item active" aria-current="page">
                  OUR SERVICES
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <Container>
          {/* feature box */}
          <Row>
            {features.map((item, key) => (
              <Col key={key} md={4} className="col-12 mt-5">
                <div className="features feature-primary">
                  <div className="image position-relative d-inline-block">
                    <i className={item.icon}></i>
                  </div>

                  <div className="content mt-4">
                    <h5>{item.title}</h5>
                    <p className="text-muted mb-0">{item.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>

        <Container className="mt-100 mt-60">
          {/* Render Section Title */}
          <SectionTitle
            title="Client Reviews"
            desc="that can provide everything you need to generate awareness, drive traffic, connect."
          />

          {/* clients slider */}
          <ReviewsSlider reviews={reviews} colClassName="mt-4" />
        </Container>
      </section>

      <section className="section bg-light">
        <Container>
          {/* Render Section Title */}
          <SectionTitle
            title="Join us on this journey of empowerment and community building."
            desc="Join us in becoming part of Mettamate community.Together, we can create a brighter and more inclusive future for all Ambedkarites"
          />

          <Row>
            {works.map((work, key) => (
              <Col key={key} md={6} xs={12} className="mt-4 pt-2">
                <Card className="work-container work-modern position-relative overflow-hidden shadow rounded border-0">
                  <CardBody className=" p-0">
                    <img
                      src={work.imgUrl}
                      className="img-fluid rounded"
                      alt="work"
                    />
                    <div className="overlay-work bg-dark"></div>
                    <div className="content">
                      <Link
                        to="page-work-detail"
                        className="title text-white d-block fw-bold"
                      >
                        {work.title}
                      </Link>
                      <small className="text-light">{work.subtitle}</small>
                    </div>
                    <div className="client">
                      <small className="text-light user d-block">
                        <i className="mdi mdi-account"></i> {work.author}
                      </small>
                      <small className="text-light date">
                        <i className="mdi mdi-calendar-check"></i> {work.date}
                      </small>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>

        <Container className="mt-100 mt-60">
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title">
                <h4 className="title mb-4">
                   With Mettamate, empower yourself, and empower your community.
                </h4>
                <p className="text-muted para-desc mx-auto">
                  Whether you are looking for a life partner, seeking support and resources, or simply want to connect with like-minded individuals,{' '}
                  <span className="text-primary fw-bold">Mettamate</span> is
                  here for you.
                </p>
                <div className="mt-4">
                  <Link to="#" className="btn btn-primary mt-2 me-2">
                    Get Started Now
                  </Link>{' '}
                  <Link to="#" className="btn btn-outline-primary mt-2">
                    Free Trial
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PageServices;
