import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Label, Input, Button, Form } from 'reactstrap';
import CommonModal from '../../Home/modal';
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import apiUrl from '../../server';
import '../../Home/css/userHomePage.css';
import Select from 'react-select';
import vegIcon from '../../../assets/images/MettaMateImages/vegetarian.png'
import habitIcon from '../../../assets/images/MettaMateImages/bad-habits.png';
import exerciseIcon from '../../../assets/images/MettaMateImages/running.png'
import gamesIcon from '../../../assets/images/MettaMateImages/basketball.png';
import hobbyIcon from '../../../assets/images/MettaMateImages/hobbies.png';
import petPrefIcon from '../../../assets/images/MettaMateImages/dog.png';
import ngoIcon from '../../../assets/images/MettaMateImages/organization.png';

const LifestyleFunction = () => {
  let memberID = localStorage.getItem('memberID');
  const [deitprefList, setdeitprefList] = useState([]);
  const [smokingList, setsmokingList] = useState([]);
  const [drinkingList, setdrinkingList] = useState([]);
  const [ExerciseList, setExerciseList] = useState([]);
  const [gamesList, setgamesList] = useState([]);
  const [petPrefList, setpetPrefList] = useState([]);
  const [partofNGOList, setpartofNGOList] = useState([]);
  const [lifestyleData, setlifestyleData] = useState({});

  const [Exercisedata, setExercisedata] = useState({});
  const [gamesData, setgamesData] = useState([]);
  const [gamesObjectData, setGamesObjectData] = useState([]);
  const [partofNGOData, setpartofNGOData] = useState({});
  const [petPrefObjectData, setpetPrefObjectData] = useState([]);
  const [petValue, setPetValue] = useState()
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [dietPrefselectedOption, setdietPrefselectedOption] = useState();
  const [smokingselectedOption, setsmokingselectedOption] = useState();
  const [drinkingselectedOption, setdrinkingselectedOption] = useState();
  const [exerciseselectedOption, setexerciseselectedOption] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchlifeStyleData();
    // fetchData(); 
    const fetchData = async () => {
      try {
        const [deitPrefRes, smokinghabitRes, drinkinghabitRes, gamesRes, excerciseRes, partOfNGORes, petPrefRes] = await Promise.all([
          axios.get(`${apiUrl}codes/dietpref`),
          axios.get(`${apiUrl}codes/smokinghabit`),
          axios.get(`${apiUrl}codes/drinkinghabit`),
          axios.get(`${apiUrl}codes/games`),
          axios.get(`${apiUrl}codes/exercise`),
          axios.get(`${apiUrl}codes/partofNGO`),
          axios.get(`${apiUrl}codes/petpref`),

        ]);
        setdeitprefList(deitPrefRes.data.list);
        setsmokingList(smokinghabitRes.data.list);
        setdrinkingList(drinkinghabitRes.data.list);
        setgamesList(gamesRes.data.list);
        setExerciseList(excerciseRes.data.list);
        setpartofNGOList(partOfNGORes.data.list);
        setpetPrefList(petPrefRes.data.list);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const fetchlifeStyleData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'members/lifestyle/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {
        let lifestyleData = response.data[0]
        setFormData({
          memberuuid: localStorage.getItem('memberID'),
          diet: lifestyleData.diet ? lifestyleData.diet : 0,
          exerciseroutine: lifestyleData.exerciseroutine ? lifestyleData.exerciseroutine : 0,
          games: lifestyleData.games ? lifestyleData.games : [],
          hobbies: lifestyleData.hobbies ? lifestyleData.hobbies : '',
          partofngo: lifestyleData.partofngo ? lifestyleData.partofngo : 0,
          petpreference: lifestyleData.petpreference ? lifestyleData.petpreference : [],
          drinkinghabit: lifestyleData.drinkinghabit ? lifestyleData.drinkinghabit : 0,
          smokinghabit: lifestyleData.smokinghabit ? lifestyleData.smokinghabit : 0,
          lifestyleid: lifestyleData.lifestyleid ? lifestyleData.lifestyleid : 0
        });
      } else {

        setSpiritualPrefenerceFormData({
          memberuuid: localStorage.getItem('memberID'),
          diet: 0,
          exerciseroutine: 0,
          games: [],
          hobbies: '',
          partofngo: 0,
          petpreference: [],
          drinkinghabit: 0,
          smokinghabit: 0,
          lifestyleid: 0,
        })
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getpetPrefDetails = (petselectedOptions) => {
    if (!Array.isArray(petselectedOptions)) {
      return;
    }
    const petselectedValues = petselectedOptions.map(petoption => petoption.value);
    setpetPrefObjectData(petselectedOptions);
    validation.setFieldValue('petpreference', petselectedValues); // Update form field value  }
  }


  const [formData, setFormData] = useState({
    memberuuid: localStorage.getItem('memberID'),
    diet: 0,
    exerciseroutine: 0,
    games: [],
    hobbies: '',
    partofngo: 0,
    petpreference: [],
    drinkinghabit: 0,
    smokinghabit: 0,
    lifestyleid: 0,
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    formData.hobbies = document.getElementById('hobbies').value;
    let result = await axios.post(`${apiUrl}members/lifestyle/`, formData, {
      headers: {
        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
        "Content-Type": "application/json"
      },
    }).then((res) => {
      fetchlifeStyleData();
      setIsLoading(false);
      setSuccess({
        title: 'SUCCESS!',
        message: res.data.message,
      });
    }).catch((err) => {
      setIsLoading(false);
      setError({
        title: 'An Error Occured!',
        message: err.response.data.message,
      });
    });

  }

  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setlifestyleData([]);
    setdeitprefdata({});
    setsmokingData({});
    setdrinkingData({});
    setExercisedata({});
    setgamesData({});
    setpetPrefData({});
    setpartofNGOData({});
  };


  const handlegames = (checkedFlag, gamesObject) => {
    let selectedgames = formData.games;
    if (checkedFlag) {
      if (!selectedgames.includes(gamesObject.value)) {
        selectedgames.push(gamesObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedgames.includes(gamesObject.value)) {
        selectedgames.splice(selectedgames.findIndex(x => gamesObject.value === x), 1);
      }
    }
    setFormData({ ...formData, games: selectedgames });
  };

  const handlePetPreference = (checkedFlag, petObject) => {
    let selectedpets = formData.petpreference;
    if (checkedFlag) {
      if (!selectedpets.includes(petObject.value)) {
        selectedpets.push(petObject.value);
      }
    }
    if (!checkedFlag) {
      if (selectedpets.includes(petObject.value)) {
        selectedpets.splice(selectedpets.findIndex(x => petObject.value === x), 1);
      }
    }
    setFormData({ ...formData, petpreference: selectedpets });
  };
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {
        isLoading ? <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit()
              }}
            >
              <Row className="inner_row m-0 mt-1">
                <h6 className="title  title_bar">
                  Lifestyle
                </h6>
                <Col md={12} className='mt-3'>
                  <img src={vegIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Diet</span>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Diet Preferences
                          </Label>

                          <section className="radio-section">
                            <div className="radio-list">
                              {(deitprefList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="dietradio" type="radio" value={type.value}
                                    checked={formData.diet == type.value} id={`radiodiet${index}`}
                                    onChange={event => setFormData({ ...formData, diet: type.value })} />
                                  <label htmlFor={`radiodiet${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <Col md={12} className='mt-3'>
                  <img src={habitIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Habits</span>
                  <Row className="mt-3">

                    <Col md={4} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Smoking Habits

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(smokingList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="smokingradio" type="radio" value={type.value}
                                    checked={formData.smokinghabit == type.value} id={`radiosmoking${index}`}
                                    onChange={event => setFormData({ ...formData, smokinghabit: type.value })} />
                                  <label htmlFor={`radiosmoking${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>

                    </Col>

                    <Col md={4} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Drinking Habits

                          </Label>
                          <section className="radio-section">
                            <div className="radio-list">
                              {(drinkingList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="drinkingradio" type="radio" value={type.value}
                                    checked={formData.drinkinghabit == type.value} id={`radiodrinking${index}`}
                                    onChange={event => setFormData({ ...formData, drinkinghabit: type.value })} />
                                  <label htmlFor={`radiodrinking${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={exerciseIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Physical Activity</span>
                  <Row className="mt-3">

                    <Col md={12} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Exercise Routines

                          </Label>

                          <section className="radio-section">
                            <div className="radio-list">
                              {(ExerciseList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="exerciseradio" type="radio" value={type.value}
                                    checked={formData.exerciseroutine == type.value} id={`radioexercise${index}`}
                                    onChange={event => setFormData({ ...formData, exerciseroutine: type.value })} />
                                  <label htmlFor={`radioexercise${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>
                  <img src={gamesIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Physical Activity</span>
                  <Row className="mt-3">

                    <Col md={12} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Games Routines
                          </Label>

                          <div className="form-icon position-relative">
                            <ul className="ks-cboxtags p-0">
                              {gamesList.map((response, index) => (
                                <div className="form-check form-check-inline" key={index}>
                                  <li>
                                    <div className="mb-0">
                                      <div className="form-check p-0">
                                        <Input type="checkbox" className='label_text'
                                          id={response.label + "games"}
                                          checked={formData.games.includes(response.value)}
                                          onChange={event => handlegames(event.target.checked, response)}
                                        />
                                        <Label className="form-check-label" htmlFor={response.label + "games"}>{response.label}  </Label>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            </ul>
                          </div>

                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <Col md={12} className='mt-3'>
                  <img src={hobbyIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Leisure Interest</span>
                  <Row className="mt-3">
                    <Col md={4} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Hobbies_Interest <span title="Comma Seperated"><i className="fa fa-info-circle" aria-hidden="true" style={{ position: `relative`, top: `0.5px`, left: `5px`, color: `#ffbd45` }}></i></span>


                          </Label>
                          <div className="form-icon position-relative">
                            <i className="fa fa-magic" ></i>

                            <Input
                              type="text"
                              className="form-control form_Input ps-5"
                              defaultValue={formData.hobbies}
                              placeholder=" Hobbies_Interest "
                              id="hobbies"
                              name="hobbies"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>

                <Col md={12} className='mt-3'>
                  <img src={ngoIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >NGOs</span>
                  <Row className="mt-3">
                    <Col md={6} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Part of Social NGO

                          </Label>

                          <section className="radio-section">
                            <div className="radio-list">
                              {(partofNGOList || []).map((type, index) => (
                                <div className="radio-item" key={index} >
                                  <Input name="ngoradio" type="radio" value={type.value}
                                    checked={formData.partofngo == type.value} id={`radiongo${index}`}
                                    onChange={event => setFormData({ ...formData, partofngo: type.value })} />
                                  <label htmlFor={`radiongo${index}`}> {type.label}</label>
                                </div>
                              ))}
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <Col md={12} className='mt-3'>
                  <img src={petPrefIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Pet Preference</span>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Pet Preference

                          </Label>
                          <div className="form-icon position-relative">
                            <ul className="ks-cboxtags p-0">
                              {petPrefList.map((response, index) => (
                                <div className="form-check form-check-inline" key={index}>
                                  <li>
                                    <div className="mb-0">
                                      <div className="form-check p-0">
                                        <Input type="checkbox"
                                          id={response.label + "petPref"}
                                          checked={formData.petpreference.includes(response.value)}
                                          onChange={event => handlePetPreference(event.target.checked, response)}
                                        />
                                        <Label className="form-check-label" htmlFor={response.label + "petPref"}>{response.label}  </Label>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              ))}
                            </ul>
                          </div>

                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "
                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary  mt-2 ms-2"
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment>
  );
};
export default LifestyleFunction;
