// React Basic and Bootstrap
import React, { useState } from "react";
import { Link } from "react-router-dom";
import apiUrl from '../server';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Input
} from "reactstrap";

// //Formik Validation
import { useFormik, useFormikContext } from "formik";
import { useNavigate } from 'react-router-dom';
import CommonModal from '../Home/modal';
// //Import Icons
import FeatherIcon from "feather-icons-react";
import ThemeSwitcher from '../../components/Layout/ThemeSwitcher'
// // import images
import recoveryimg from "../../assets/images/user/recovery.svg";
import axios from "axios";
const PageForgotPassword = () => {
  const [isVisible, setVisibility] = useState('email');
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },

    onSubmit: async (values) => {

      let requestbody = {
        email: validation.values.email
      };
      let otpresponse = await axios.post(`${apiUrl}auth/sendotpemailrecovery`, requestbody, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('memberID')}`,
          "Content-Type": "application/json"
        }
      }).then((res) => {
        if (res.status == 200) {
          localStorage.setItem('memberID', res.data.memberUUID);
          setVisibility('verifyotp')
          setSuccess({
            title: 'OTP Sent Successfully!',
            message: '',
          });

        }
        else {
          setError({
            title: 'An Error Occured!',
            message: res.data.message,
          });
        }

      })
        .catch((err) => {


        });

    }
  });


  const [formData, setFormData] = useState({
    otp: '',
  });

  const otpVerification = async () => {
    let otpfield = document.getElementById('otpfield');
    let requestbody = {
      otp: otpfield.value,
      memberUUID: localStorage.getItem('memberID')
    }
    let response = await axios.post(`${apiUrl}registration/validate`, requestbody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('memberID')}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      if (res.status == 200) {

        setVisibility('resetPassword');
        setSuccess({
          title: 'OTP Verified Successfully!',
          message: 'You can Change Your Password',
        });
      }
      else {
        setError({
          title: 'An Error Occured!',
          message: res.data.message,
        });
      }

    })
      .catch((err) => {


      });

  }

  const resetPassword = async () => {
    let newPassword = document.getElementById('newpassword');
    let requestbody = {
      newpassword: newPassword.value,
      memberuuid: localStorage.getItem('memberID')
    }

    let response = await axios.post(`${apiUrl}auth/recoverpassword`, requestbody, {
      headers: {
        // Authorization: `Bearer ${localStorage.getItem('memberID')}`,
        "Content-Type": "application/json"
      }
    }).then((res) => {
      if (res.status == 200) {
        setSuccess({
          title: 'Password Changed!',
          message: 'You can Login with Your New Password',
        });
        setTimeout(() => {
          navigate('/user-login-page')
        }, 2000);
      }
      else {
        setError({
          title: 'An Error Occured!',
          message: res.data.message,
        });
      }

    })
      .catch((err) => {
      });
  }
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const matchPassword = (e) => {
    var newpassword = document.getElementById('newpassword').value;
    if (newpassword == e.target.value) {
      setIsButtonDisabled(false);

    }
    else {
      setErrorMessage('New Password and Confirm Password is not matched');
      setIsButtonDisabled(true);

      setTimeout(() => {
        setErrorMessage('')
      }, 3000);
    }
  }
 
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-home d-flex align-items-center">
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={6}>
              <div className="me-lg-5">
                <img
                  src={recoveryimg}
                  className="img-fluid d-block mx-auto"
                  alt=""
                />
              </div>
            </Col>

            <Col lg={5} md={6}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <h4 className="card-title text-center">Recover Account</h4>
                  {isVisible == 'email' && (
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      className="login-form mt-4"
                    >
                      <Row>
                        <Col lg={12}>
                          <p className="text-muted">
                            Please enter your email address. You will receive a
                            OTP to create a new password.
                          </p>
                          <div className="mb-3">
                            <Label className="form-label" for="email">
                              Email address{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              type="text"
                              className="form-control ps-5"
                              name="email"
                              id="email" required
                              placeholder="Enter Your Email Address"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}

                      
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="d-grid">
                            <Button color="primary" type="submit">
                              Send
                            </Button>
                          </div>
                        </Col>
                        <div className="mx-auto">
                          <p className="mb-0 mt-3">
                            <small className="text-dark me-2">
                              Remember your password ?
                            </small>{" "}
                            <Link
                              to="/user-login-page"
                              className="text-dark fw-bold"
                            >
                              Sign in
                            </Link>
                          </p>
                        </div>
                      </Row>
                    </Form>
                  )}

                  {isVisible == 'verifyotp' && (

                    <Row>

                      <Col lg={12}>
                        <p className="text-muted">
                          Please enter OTP to verify your Authorized Email.
                        </p>
                        <div className="mb-3">
                          <Label className="form-label" for="otp">
                            Enter OTP{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input type="text"
                            className="form-control ps-5"
                            name="otp"
                            id="otpfield"
                            placeholder="*** ***"

                          />
                        </div>
                      </Col>
                      <Col lg={12} className="mt-3">
                        <div className="d-grid">
                          <Button color="primary" type="submit" onClick={otpVerification}>
                            Verify OTP
                          </Button>
                        </div>
                      </Col>
                    </Row>


                  )}

                  {isVisible == 'resetPassword' && (
                    <Row>

                      <Col md={12} sm={12}>
                        <p className="text-muted">
                          Please set your new password and Login In.
                        </p>
                        <div className="mb-3">
                          <Label className="form-label">New Password </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="key"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                          type={showNewPassword ? 'text' : 'password'}
                            className="form-control form_Input ps-5"
                            placeholder="New Password"
                            name="newpassword"
                            required
                            id="newpassword"
                          />
                                <span
                              style={{
                                position: 'absolute',
                                right: '26px',
                                top: '70%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                              }}
                              onClick={toggleNewPasswordVisibility}
                            >
                              {showNewPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1.4em"
                                  height="1.4em"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1.4em"
                                  height="1.4em"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                  />
                                </svg>
                              )}
                            </span>
                        </div>
                      </Col>

                      <Col md={12} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label ">
                            Confirm Password{' '}
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="key"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                            className="form-control form_Input ps-5"
                            placeholder="Confirm Password"
                            name="confirmpassword"
                            required onBlur={matchPassword}
                            id="confirmpassword"
                          />
                           <span
                                    style={{
                                      position: 'absolute',
                                      right: '26px',
                                      top: '59%',
                                      transform: 'translateY(-50%)',
                                      cursor: 'pointer',
                                    }}
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    {showConfirmPassword ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.4em"
                                        height="1.4em"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.4em"
                                        height="1.4em"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                  <small className='text-danger' style={{ position: `absolute` }}>{errorMessage}</small>
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="d-grid">
                          <Button color="primary" type="submit"  disabled={isButtonDisabled} onClick={resetPassword}>
                            Reset Password
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <div>

                  </div>





                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  );
};
export default PageForgotPassword;
