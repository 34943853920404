// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';
// import images
import contactDetail from '../../assets/images/contact-detail.jpg';
import Topbar from '../../components/Layout/Topbar';

class PageContactDetail extends Component {
  constructor(props) {
    super(props);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
  }

  componentDidMount() {
    document.body.classList = '';
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  sendMail() {
    window.location.href = 'mailto:contact@example.com';
  }

  callNumber() {
    window.location.href = 'https://wa.me/917022484674';
  }

  render() {
    return (
      <React.Fragment>
        <Topbar hasDarkTopBar={true} />

        <section
          className="vh-100 d-flex align-items-center"
          style={{ background: `url(${contactDetail}) center center` }}
        >
          <div className="bg-overlay bg-overlay-white"></div>
          <Container>
            <Row className="align-items-center">
              <Col lg={{ size: 6, offset: 6 }} md={{ size: 7, offset: 5 }}>
                <div className="title-heading  pt-4" style={{boxShadow:'0px 0px 15px grey', borderRadius:'25px',padding:'50px'}}>
                  <h3 className="pb-3 pt-3">
                    <span className="text-primary">MettaMate </span>
                    {' '}PLATFORM PRIVATE LIMITED{' '}
                  </h3>
                  <p className="para-desc" style={{fontSize:'20px'}}>
                    Shipping &  Delivery Policy
                  </p>
                  <p className="para-desc" style={{fontSize:'20px'}}>
                    Shipping is not applicable for business
                  </p>

                  {/* <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <FeatherIcon
                        icon="mail"
                        className="fea icon-m-md text-dark me-3"
                      />
                    </div>
                    <div className="flex-1 content">
                      <h5 className="title fw-bold mb-0">Email</h5>
                      <Link
                        to="#"
                        onClick={this.sendMail}
                        className="text-primary fontSize16"
                      >
                        admin@MettaMate.ai
                      </Link>
                    </div>
                  </div> */}

                  {/* <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <FeatherIcon
                        icon="message-square"
                        className="fea icon-m-md text-dark me-3"
                      />
                    </div>
                    <div className="flex-1 content">
                      <Link
                        to="https://wa.me/917022484674"
                        className="text-primary"
                      >
                        <h5 className="title fw-bold mb-0">Whatsapp</h5>
                      </Link>
                    </div>
                  </div> */}

                  {/* <ul className="list-unstyled social-icon mb-0 mt-4">
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="facebook"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="instagram"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="twitter"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="linkedin"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="github"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="youtube"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>{' '}
                    <li className="list-inline-item">
                      <Link to="#" className="rounded">
                        <FeatherIcon
                          icon="gitlab"
                          className="fea icon-sm fea-social"
                        />
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageContactDetail;
